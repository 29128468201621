import { NAVBAR_BREADCRUMBS_ID } from 'common/layouts/navbar/NavBar';
import { Portal } from 'react-portal';
import { Link } from 'react-router-dom';
import styles from './PageBreadcrumbsPortal.module.scss';
import { FaHome } from 'react-icons/fa';

interface BreadcrumbPath {
    name: string;
    url: string;
}

interface Props {
    breadcrumbs: BreadcrumbPath[];
}

const PageBreadcrumbsPortal = ({ breadcrumbs }: Props) => {
    return (
        <Portal node={document.getElementById(NAVBAR_BREADCRUMBS_ID)}>
            <div className={styles.container}>
                <FaHome />
                <div className={styles.breadcrumbs}>
                    {breadcrumbs.map((breadcrumb, i) => (
                        <div key={i} className={styles.breadcrumbItem}>
                            <Link to={breadcrumb.url}>{breadcrumb.name}</Link>
                        </div>
                    ))}
                </div>
            </div>
        </Portal>
    );
};

export default PageBreadcrumbsPortal;
