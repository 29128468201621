import { useTranslation } from 'react-i18next';
import MoneyFormat from '../moneyFormat/MoneyFormat';
import { Label } from '../texts/Texts';
import styles from './DimensionInput.module.scss';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import { Dropdown } from 'react-bootstrap';
import { FaCaretDown } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import { SelectInputOption } from '../selectInput/SelectInput';
import UnitsService from 'api/units/UnitsService';
import { UnitDto } from 'api/units/models/UnitDto';

export interface UnitOption {
    label: string;
    value: string;
}

export interface DimensionInputProps {
    minValue: number | null | undefined;
    maxValue: number | null | undefined;
    disabled?: boolean;
    unit?: string;
    onChangeMinValue: (value: number | null | undefined) => void;
    onChangeMaxValue: (value: number | null | undefined) => void;
    isGroupStart?: boolean;
    unitPrefix?: string;
    onChangeUnit?: (unit: string | null | undefined) => void;
    hasError?: boolean;
}

const DimensionInput = ({ minValue, maxValue, disabled, unit = '€', onChangeMinValue, onChangeMaxValue, isGroupStart,unitPrefix = '',onChangeUnit ,hasError }: DimensionInputProps) => {
    const { t } = useTranslation();
    const [units,setUnits] = useState<UnitDto[]>([])
    const currentUnit = units ? units.find(x => x.id === unit)?.symbol : unit;

    useEffect(() => {
        void getUnits()
    },[])

    const getUnits = async() => {
        const unitsData = await UnitsService.getFormattedCatalog()
        setUnits(unitsData);
    }

    // eslint-disable-next-line react/display-name
    const CustomToggle = React.forwardRef(({ ...p }, ref: any) => (
        <div
            {...p}
            className={`${styles.sideButton} ${unitsOptions ? styles.clickable : ''} ${isGroupStart ? styles.sideButtonGroupStart : ''}${hasError ? styles.hasError : ''}`}
            ref={ref}
        />
    ));

    // eslint-disable-next-line react/display-name
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={`${className} ${styles.dropdown}`}
                    aria-labelledby={labeledBy}
                >
                    <ul className={`list-unstyled ${styles.list}`}>
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || (child as any).props.children.toLowerCase().startsWith(value),
                        )}
                    </ul>
                </div>
            );
        },
    );

    const unitsOptions = units.map((x: UnitDto) => {
        return {
            label: x.name,
            value: x.id,
        }
    })

    return (
        <div className={styles.container}>
            <div className={styles.inputsContainer}>
                <div className={`${styles.inputContainer} ${hasError ? styles.hasError : ''} ${disabled ? styles.disabled : ''}`}>
                    <Label className={styles.label}>{t('common.min').toLowerCase()}</Label>
                    <MoneyFormat
                        value={minValue}
                        displayType={'input'}
                        className={`${styles.input}`}
                        suffix=''
                        disabled={disabled}
                        onValueChange={e => onChangeMinValue(e.floatValue)}
                        placeholder='0'
                    />
                </div>
                <div className={`${styles.inputContainer} ${hasError ? styles.hasError : ''} ${disabled ? styles.disabled : ''}`}>
                    <Label className={styles.label}>{t('common.max').toLowerCase()}</Label>
                    <MoneyFormat
                        value={maxValue}
                        displayType={'input'}
                        className={`${styles.input}`}
                        suffix=''
                        disabled={disabled}
                        onValueChange={e => onChangeMaxValue(e.floatValue)}
                        placeholder='0'
                    />
                </div>
            </div>
            {/* <div className={`${styles.sideButton}`}>
                {unit}
            </div> */}
            <Dropdown drop='down' className={styles.dropdownContainer}>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    {unitPrefix ?? ''}
                    {currentUnit && unitPrefix ? '/' : ''}
                    {currentUnit}
                    {unitsOptions && <FaCaretDown />}
                </Dropdown.Toggle>

                {!disabled && <Dropdown.Menu as={CustomMenu}>
                    {(unitsOptions ?? []).map((option, i) => (
                        <Dropdown.Item
                            key={i}
                            eventKey={i}
                            active={Boolean(option.value === unit)}
                            onClick={() => onChangeUnit && onChangeUnit(option.value)}
                        >
                            {option.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>}
            </Dropdown>
        </div>
    )
};

type DimensionInputControllerProps<T extends FieldValues> = DimensionInputProps & UseControllerProps<T>;

export const DimensionInputController = <T extends FieldValues>(props: DimensionInputControllerProps<T>) => {
    const { field: fieldMin } = useController({ ...props, disabled: false });
    const { field: fieldMax } = useController({ ...props, disabled: false });

    return (
        <DimensionInput
            {...props}
            minValue={fieldMin.value}
            maxValue={fieldMax.value}
            onChangeMinValue={(e) => {
                fieldMin.onChange(e);
                if (props.onChangeMinValue) {
                    props.onChangeMinValue(e);
                }
            }}
            onChangeMaxValue={(e) => {
                fieldMax.onChange(e);
                if (props.onChangeMaxValue) {
                    props.onChangeMaxValue(e);
                }
            }}
        />
    );
};

export default DimensionInput;
