import { Paged } from 'api/common/types/Page';
import Http from '../../common/services/Http';

import { BudgetFileModelDto } from './models/BudgetFileModelDto';
import { BudgetFileModelSearchCriteria } from './models/BudgetFileModelSearchCriteria';
import CustomFile from 'common/models/CustomFile';
import { SelectInputOption } from 'common/components/selectInput/SelectInput';

class BudgetFileModelsService {
    public getById (id: string) {
        return Http.get<BudgetFileModelDto>('budgetFileModels/' + id);
    }

    public getList (criteria: BudgetFileModelSearchCriteria) {
        return Http.get<Paged<BudgetFileModelDto>>('budgetFileModels', criteria);
    }

    public getCatalog (criteria?: BudgetFileModelSearchCriteria) {
        return Http.get<SelectInputOption[]>('budgetFileModels/catalog', criteria);
    }

    public getModelsCatalog () {
        return Http.get<string[]>('budgetFileModels/models-catalog');
    }

    public getModelPaymentTerms (id: string) {
        return Http.get<string>('budgetFileModels/payment-terms/' + id);
    }

    public create(model: BudgetFileModelDto, medias: CustomFile[]): Promise<string> {
        return Http.postWithFile('budgetFileModels', model,medias);
    }

    public update(model: BudgetFileModelDto, medias: CustomFile[]): Promise<string> {
        return Http.putWithFile('budgetFileModels', model,medias);
    }

    public remove (model: BudgetFileModelDto) {
        return Http.put('budgetFileModels/deactivate',model);
    }
}

export default new BudgetFileModelsService();
