import Button from 'common/components/button/Button';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaTrashAlt } from 'react-icons/fa';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import PageHeader from 'common/components/pageHeader/PageHeader';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import { Col, Row } from 'react-bootstrap';
import { TextInputController } from 'common/components/textInput/TextInput';
import { Label } from 'common/components/texts/Texts';
import Loading from 'common/services/Loading';
import styles from './UserScreen.module.scss'
import { LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import UsersService from 'api/users/UsersService';
import { UserDto } from 'api/users/models/UserDto';
import { SelectInputController, SelectInputOption } from 'common/components/selectInput/SelectInput';
import RolesService from 'api/roles/RolesService';
import InputError from 'common/components/inputError/InputError';
import Toast from 'common/services/Toast';
import { Form } from 'common/components/form/Form';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { PhoneCodeOption, PhoneInputController } from 'common/components/phoneInput/PhoneInput';
import { CountryDto } from 'api/countries/models/CountryDto';
import CountriesService from 'api/countries/CountriesService';

function UserScreen(): JSX.Element | null {
    const { id, type } = useParams<{ id: string, type: string }>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const form = useForm<UserDto>({ shouldUnregister: false });
    const errors = form.formState.errors;

    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [roles, setRoles] = useState<SelectInputOption[]>();
    const [user, setUser] = useState<UserDto>();

    const url = '/backoffice/users';
    const currentUrl = url + '/' + type + (id ? ('/' + id) : '');

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['USERS_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['USERS_READ'])

    const [countries, setCountries] = useState<CountryDto[]>([]);
    const phoneCodesOptions: PhoneCodeOption[] = countries.filter(x => x.phoneCode).map(p => ({ value: p.id, name: p.isoCodeSmall, phoneCode: p.phoneCode }));

    useEffect(() => {
        getData().catch(console.error);
    }, [id, type]);

    const getData = async () => {
        try {
            const [
                rolesResult,
                countriesResult
            ] = await Promise.all([
                RolesService.catalog(),
                CountriesService.getCatalog()
            ]);

            setRoles(rolesResult);
            setCountries(countriesResult)

            let result: any = { id: '', name: '', roleId: '' };
            if (id) {
                Loading.show();
                result = await UsersService.getById(id);
                if (result) {
                    result.roleId = result.roles[0].id;
                }
            }
            setUser(result);
            form.reset(result);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get user information', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    };

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`${url}/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate(url);
        }
    }

    const onSubmit = async (event: any) => {
        try {
            Loading.show();

            const formData = form.getValues();
            const model: UserDto = {
                id: formData.id,
                realName: formData.realName,
                email: formData.email,
                roleId: formData.roleId,
                phoneNumber: formData.phoneNumber,
                phoneCodeId: formData.phoneCodeId
            };

            if (model?.id) {
                await UsersService.update(model)
                // navigateTo('details', id);
            } else if (model) {
                const id = await UsersService.create(model)
                navigateTo('edit', id);
            }
            Loading.hide();
            Toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            if (error?.response?.status === 409) {
                Toast.warning(t('users.user.user_already_exists'));
            } else {
                if (id) {
                    Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the user with id: ${id}`, error);
                }
                Toast.error(t('messages.record_save_error'));
            }
            Loading.hide();
        }
    };

    const onInvalid = () => {
        Toast.warning(t('messages.required_fields_empty'));
    }

    const onDelete = async (item: boolean) => {
        if (!item) {
            setShowConfirmDeleteModal(false)
            return;
        }

        Loading.show();

        try {
            await UsersService.remove(user as UserDto)
            Toast.success(t('messages.record_delete_success'));
            navigateTo();
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete user', error);
            Toast.error(t('messages.record_delete_error'));
        } finally {
            Loading.hide();
            setShowConfirmDeleteModal(false);
        }
    }

    const title = (type === 'create' ? t('common.new') : (type === 'edit' ? t('common.edit') : t('common.details'))) + ' ' + t('users.user_title');

    if (!canRead) {
        return null;
    }

    return (
        <ScreenTitle title={t('users.title')}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: t('users.title'), url },
                    { name: title, url: currentUrl },
                ]}
            />
            <Form handleSubmit={form.handleSubmit} onSubmit={onSubmit} onInvalid={onInvalid} noValidate style={{ height: '100%' }}>
                <PageHeader title={t('users.title')} informationText={t('common.go_back')} onGoBack={() => navigateTo()}>
                    {canWrite && !isDetails && <Button type='submit'>{t('common.save')}</Button>}
                    {canWrite && isDetails && <Button fw onClick={() => { navigateTo('edit', user?.id); }}>{t('common.edit')}</Button>}
                    {canWrite && isDetails && <Button variant='secondary' onClick={() => setShowConfirmDeleteModal(true)}> <FaTrashAlt /> </Button>}
                </PageHeader>

                <PageContainer>
                    <Row>
                        <Col md={6} lg={3} className={styles.marginBottomMd}>
                            <Label space>{t('users.user.name')}{!isDetails ? '*' : ''}</Label>
                            <TextInputController
                                name='realName'
                                control={form.control}
                                placeholder={t('users.user.name')}
                                disabled={isDetails}
                                rules={{ required: true }}
                                hasError={Boolean(errors.realName)}
                            />
                            <InputError error={errors.realName} />
                        </Col>
                        <Col md={6} lg={3} className={styles.marginBottomMd}>
                            <Label space>{t('users.user.email')}{!isDetails ? '*' : ''}</Label>
                            <TextInputController
                                name='email'
                                type='email'
                                control={form.control}
                                placeholder={t('users.user.email')}
                                disabled={isDetails}
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: 'email',
                                    }
                                }}
                                hasError={Boolean(errors.email)}
                            />
                            <InputError error={errors.email} />
                        </Col>
                        <Col md={6} lg={3} className={styles.marginBottomSm}>
                            <Label space>{t('users.user.phone_number')}</Label>
                            <PhoneInputController
                                name='phoneNumber'
                                control={form.control}
                                onChange={(number) => {}}
                                disabled={isDetails}
                                phoneCodes={phoneCodesOptions}
                                phoneCodeValueName='phoneCodeId'
                            />
                            <InputError error={errors.phoneNumber} />
                        </Col>
                        <Col md={6} lg={3}>
                            <Label space>{t('users.user.role')}{!isDetails ? '*' : ''}</Label>
                            <SelectInputController
                                control={form.control}
                                name='roleId'
                                options={roles || []}
                                rules={{ required: true }}
                                disabled={isDetails}
                                hasError={Boolean(errors.roleId)}
                            />
                            <InputError error={errors.roleId} />
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                </PageContainer>
            </Form>

            <ConfirmDeleteModal
                itemName={user?.realName}
                isOpen={showConfirmDeleteModal}
                onClose={(result) => onDelete(result)}
            />
        </ScreenTitle>

    )
}

export default UserScreen;
