import { ParameterSectionParameterType } from 'common/components/parametersSections/models';
import { ImagesParameter, MaxMinParameter, NumberParameter, ProdutParameter, TextParameter } from '../ElementParamentersComponents';
import { ElementParameterDto } from 'api/elements/Models/ElementDto';

interface Props {
    parameter: ElementParameterDto;
    disabled: boolean;
    onChange: (item: ElementParameterDto) => void
    hasSubmited: boolean;
    activeParameterKey?: string;
    onClick?: (key: string) => void;
}
const ElementParameter = ({ parameter, disabled,onChange, onClick, hasSubmited, activeParameterKey }: Props) => {
    const renderParam = () => {
        switch (parameter.parameterType) {
            case ParameterSectionParameterType.TEXT:
                return <TextParameter value={parameter.text}
                    required={parameter.parameterIsRequired} disabled={disabled} onChange={(x) => onChange({ ...parameter, text: x })} hasSubmited={hasSubmited}/>
            case ParameterSectionParameterType.IMAGES:
                return <ImagesParameter medias={parameter.images ?? []}
                    required={parameter.parameterIsRequired} disabled={disabled} onChange={(x) => onChange({ ...parameter, images: x })} hasSubmited={hasSubmited}/>
            case ParameterSectionParameterType.NUMBER:
                return <NumberParameter value={parameter.number}
                    required={parameter.parameterIsRequired} disabled={disabled} unitSymbol={parameter.unitSymbol} onChange={(x) => onChange({ ...parameter, number: x })} hasSubmited={hasSubmited}/>
            case ParameterSectionParameterType.QUANTITY:
                return <NumberParameter value={parameter.quantity}
                    required={parameter.parameterIsRequired} disabled={disabled} unitSymbol={parameter.unitSymbol} onChange={(x) => onChange({ ...parameter, quantity: x })} hasSubmited={hasSubmited}/>
            case ParameterSectionParameterType.PRODUCTS:
                return <ProdutParameter items={parameter.products ?? []}
                    required={parameter.parameterIsRequired} productsFamilyId={parameter.parameterFamilyId ?? undefined}
                    onChange={(x) => onChange({ ...parameter, products: x })} disabled={disabled} hasSubmited={hasSubmited}
                    onClick={onClick} selectedSectionKey={activeParameterKey}/>
            case ParameterSectionParameterType.NUMBER_MIN_MAX:
                return <MaxMinParameter
                    item={parameter.minMax ?? { unitId: parameter.unitId }}
                    disabled={disabled} onChange={(x) => onChange({ ...parameter, minMax: x })}
                    required={parameter.parameterIsRequired} productsFamilyId={parameter.parameterFamilyId ?? undefined} hasSubmited={hasSubmited}
                    onClick={onClick} selectedSectionKey={activeParameterKey}/>
        }
    }
    return (<div style={{ padding: '0 1rem' }}>
        {renderParam()}
    </div>)
}

export default ElementParameter;
