import { ListingTableColumn, TableRow } from '../models';
import { ColumnOrder } from '../ListingTableCache';
import ColumnsOptionsSidebar from 'common/components/columnsOptionsSidebar/ColumnsOptionsSidebar';

interface Props<TRow> {
    columns: ListingTableColumn<TRow>[];
    hiddenColumns: (keyof TRow)[];
    onChangeHiddenColumns: (hiddenColumns: (keyof TRow)[]) => void;
    onChangeColumnsOrder: (columnsOrder: ColumnOrder<TRow>[]) => void;
}

const ListingTableSidebar = <TRow extends TableRow>({ columns, hiddenColumns, onChangeHiddenColumns, onChangeColumnsOrder }: Props<TRow>) => {
    return (
        <ColumnsOptionsSidebar<keyof TRow>
            columns={columns}
            hiddenColumns={hiddenColumns}
            onChangeHiddenColumns={onChangeHiddenColumns}
            onChangeColumnsOrder={onChangeColumnsOrder}
        />
    );
}

export default ListingTableSidebar;
