import { BudgetLineParameterDto } from 'api/budgets/models/BudgetLineParameterDto';
import ParametersList from '../parametersList/ParametersList';
import styles from './ParameterSection.module.scss';
import { BudgetLineDto } from 'api/budgets/models/BudgetDto';

interface Props {
    parameter: BudgetLineParameterDto;
    level: number;
    line: BudgetLineDto;
    disabled: boolean;
}

function ParameterSection({ parameter, line, level, disabled }: Props): JSX.Element | null {
    return (
        <div>
            <div className={level > 0 ? styles.subSection : styles.section}>
                {parameter.title}
            </div>
            {parameter.children?.length > 0 && (
                <ParametersList
                    parameters={parameter.children}
                    level={level + 1}
                    line={line}
                    disabled={disabled}
                />
            )}
        </div>
    );
}

export default ParameterSection;
