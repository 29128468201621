import styles from './InlineDropdownInput.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import TextInput from '../textInput/TextInput';
import { useTranslation } from 'react-i18next';

export interface UnitOption {
    selectedLabel: string;
    label: string;
    value: string;
}

export interface InlineDropdownInputProps {
    value: string | undefined | null;
    options: UnitOption[];
    className?: string;
    disabled?: boolean;
    onChange: (unit: string | null | undefined) => void;
}

const InlineDropdownInput = ({ value = '', disabled, options, className, onChange }: InlineDropdownInputProps) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const currentUnit = options ? options.find(x => x.value === value)?.selectedLabel : null;

    // eslint-disable-next-line react/display-name
    const CustomToggle = React.forwardRef(({ ...p }, ref: any) => (
        <div
            {...p}
            className={`${styles.text} ${disabled ? styles.disabled : ''} ${className ?? ''}`}
            ref={ref}
        />
    ));

    // eslint-disable-next-line react/display-name
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
            const [value, setValue] = useState('');
            const textInputRef = useRef<HTMLInputElement>(null);

            useEffect(() => {
                if (showDropdown && textInputRef.current) {
                    textInputRef.current.focus();
                }
            }, [showDropdown]);

            return (
                <div
                    ref={ref}
                    style={style}
                    className={`${className} ${styles.dropdown}`}
                    aria-labelledby={labeledBy}
                >
                    <TextInput
                        placeholder={t('common.write_here')}
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        className="mx-3 my-2 w-auto"
                        ref={textInputRef}
                    />
                    <ul className={`list-unstyled ${styles.list}`}>
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || (child as any).props.children.toLowerCase().startsWith(value),
                        )}
                    </ul>
                </div>
            );
        },
    );

    const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.container} ref={containerRef}>
            <Dropdown
                drop='down'
                align="start"
                className={styles.dropdownContainer}
                show={showDropdown}
                onToggle={disabled ? undefined : () => setShowDropdown(true)}
                onSelect={disabled ? undefined : () => setShowDropdown(false)}
                autoClose={false}
                key={showDropdown + ''}
            >
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" disabled={disabled}>
                    {currentUnit ?? '-'}
                </Dropdown.Toggle>

                {options && <Dropdown.Menu as={CustomMenu}>
                    {(options ?? []).map((option, i) => (
                        <Dropdown.Item
                            key={i}
                            eventKey={i}
                            active={Boolean(option.value === value)}
                            onClick={() => onChange(option.value)}
                        >
                            {option.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>}
            </Dropdown>
        </div>
    )
};

export default InlineDropdownInput;
