import { forwardRef, Ref, useContext, useState } from 'react';
import { TreeItemComponentProps } from 'common/components/sortableTree/types';
import clsx from 'clsx';
import useIsMobileOrTablet from 'common/hooks/useDeviceType';
import { ParameterSectionBaseModel } from 'common/components/parametersSections/models';
import { useTranslation } from 'react-i18next';
import { getLevelFromParentKeys, lineInnerActiveStyle, lineInnerLevelStyle, lineLevelStyle, lineOutterLineStyle } from 'screens/business/budgets/budget/lines/utils';
import styles from './ConfigurableElementsLine.module.scss'
import SectionMenu from 'common/components/collapsableSection/sectionMenu/SectionMenu';

import { ElementParameterDto, ParameterProductLine } from 'api/elements/Models/ElementDto';

import { canMoveDown,canMoveUp, isLastInLevel } from 'screens/library/elements/utils';

import { Label } from '../texts/Texts';
import { Row, Col } from 'react-bootstrap';

import CheckInput from '../checkInput/CheckInput';
import SearchableSelectInput, { SearchableSelectInputOption } from '../searchableSelectInput/SearchableSelectInput';
import { OptionTitleSubTitle } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';
import MoneyInput from '../moneyInput/MoneyInput';
import ElementsService from 'api/elements/ElementsService';
import InputError from '../inputError/InputError';
import { ElementParameterComponentDataProvider } from '../elementParameters/ElementParameterComponentDataProvider';
import { newProductLine } from '../elementParameters/utils';
import { ElementParameterDataProvider } from 'screens/library/elements/element/ElementParameterDataProvider';

export const ELEMENT_PARAMETER_SECTION_ID_PREFIX = 'ElementParameterSection_';

export interface ElementSelectTitleSubTitleOptionDto extends SearchableSelectInputOption {
    subTitle: string;
    imageUrl?: string;
    price?: number;
    description?: string;
    reference?: string;
    unitId?: string;
    taxId?: string;
    unitSymbol?: string;
    canShowNoImage?: boolean;
    pdfVisibility: string;
    name?: string;
    family?: string;
    elementId?: string;
    quantity?: number;
    isComponent?: boolean;
    isElement?: boolean;
}

function ConfigurableElementsLine({
    item,
    index: i,
    depth: level,
    handleProps,
    clone,
    ghost,
    disableInteraction,
    disableSelection,
    style,
    parentsKeys,
    childCount,
    onItemChange,
    onItemCreate,
    onMoveItem,
    onSelectItem,
    disabled,
    wrapperRef
}: TreeItemComponentProps<ParameterProductLine>, ref: Ref<HTMLDivElement>): JSX.Element | null {
    const { t } = useTranslation();
    const { hideBaseCheckBox } = useContext(ElementParameterDataProvider);
    const { selectedSectionKey,lines,hasSubmited,productsFamilyId,required } = useContext(ElementParameterComponentDataProvider);
    const isMobileOrTablet = useIsMobileOrTablet();
    const isLastInSelectedLevel = selectedSectionKey != null ? isLastInLevel(lines as ParameterSectionBaseModel[], selectedSectionKey, item.key) : false;

    const disableMoveUp = !canMoveUp(lines as ElementParameterDto[], selectedSectionKey);
    const disableMoveDown = !canMoveDown(lines as ElementParameterDto[], selectedSectionKey);

    const [products, setProducts] = useState<ElementSelectTitleSubTitleOptionDto[]>([])

    const onAdd = () => {
        const newLine = newProductLine();
        if (onItemCreate) {
            onItemCreate({
                ...newLine,
            });
        }
    }

    const onRemove = () => {
        if (onItemChange) {
            onItemChange({
                key: item.key
            }, 'remove');
        }
    }

    const onMoveUp = () => {
        if (onMoveItem) {
            onMoveItem(item.key,'up')
        }
    }

    const onMoveDown = () => {
        if (onMoveItem) {
            onMoveItem(item.key,'down')
        }
    }

    const _onClickSection = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (!disabled && onSelectItem) { onSelectItem(item.key); };
    }

    const loadOptions = (
        inputValue: string,
        callback: (options: any[], hasMore: boolean) => void,
        isStart: boolean,
        page: number
    ) => {
        void ElementsService.getElementsCatalogByFamilyId({
            familyId: productsFamilyId,
            itemsPerPage: 10,
            page,
            name: inputValue
        })
            .then(result => {
                const prods = result.items;
                if (prods?.length) {
                    prods.forEach(x => { x.canShowNoImage = true });
                }

                setProducts(prods);
                callback(prods, result.currentPage < result.totalPages);
            });
    };

    const onChangeBase = (checked: boolean) => {
        if (onItemChange) {
            onItemChange({
                key: item.key,
                checked
            }, 'change');
        }
    }

    const onChangePrice = (value: number | null | undefined) => {
        if (onItemChange) {
            onItemChange({
                key: item.key,
                price: value
            }, 'change');
        }
    }

    const onChangeQuantity = (value: number | null | undefined) => {
        if (onItemChange) {
            onItemChange({
                key: item.key,
                quantity: value
            }, 'change');
        }
    }

    const onChangeProduct = (id: string | null | undefined) => {
        if (onItemChange) {
            const prod = products.find((x: ElementSelectTitleSubTitleOptionDto) => x.value === id);
            onItemChange({
                key: item.key,
                elementId: id,
                price: prod?.price ?? null,
                unitId: prod?.unitId,
                unitSymbol: prod?.unitSymbol,
            },'change')
        }
    }

    const parentIsSelected = selectedSectionKey != null ? parentsKeys.includes(selectedSectionKey) : false;
    const levelsUntilParent = selectedSectionKey != null && parentIsSelected ? getLevelFromParentKeys(selectedSectionKey, parentsKeys) : 0;
    const isActive = selectedSectionKey === item.key;

    if (clone) {
        return null;
    }

    return (
        <div
            className={clsx(
                styles.line,
                clone && styles.lineClone,
                ghost && styles.lineGhost,
                ghost && styles.indicator,
                disableSelection && styles.lineDisableSelection,
                disableInteraction && styles.lineDisableIteraction,
            )}
            style={{
                ...style,
                ...lineLevelStyle(level, clone ?? false, levelsUntilParent),
                ...lineOutterLineStyle(isActive)
            }}
            id={ELEMENT_PARAMETER_SECTION_ID_PREFIX + item.key}
            ref={wrapperRef as any}
            onClick={_onClickSection}
        >
            {isActive && !disabled && <SectionMenu
                isEditing={false}
                level={level}
                handleProps={handleProps}
                showMove={!isMobileOrTablet}
                showMoveUp={isMobileOrTablet}
                showMoveDown={isMobileOrTablet}
                onMoveUp={onMoveUp}
                onMoveDown={onMoveDown}
                onRemove={onRemove}
                onAdd={onAdd}
                disabledMoveDown={disableMoveDown}
                disabledMoveUp={disableMoveUp}
            />}
            <div
                className={styles.contentContainer}
                style={{
                    ...(lineInnerLevelStyle(parentIsSelected, levelsUntilParent)),
                    ...(lineInnerActiveStyle(isActive, parentIsSelected, isLastInSelectedLevel, (childCount ?? 0) > 0, item.isOpen)),
                }}
            >
                <Row className={styles.lineItem} ref={ref}>
                    {!hideBaseCheckBox && <Col>
                        <Label className={`${styles.label} ${i > 0 ? styles.hiddenXl : ''}`}>{t('products_list.base')}</Label>
                        <CheckInput
                            onChange={(e) => onChangeBase(e.target.checked)}
                            checked={item.checked}
                            key={item.id}
                            disabled={disabled}
                        />
                    </Col>}
                    <Col md={12} xxl={7}>
                        <Label className={`${styles.label} ${i > 0 ? styles.hiddenXl : ''}`}>{t('products_list.element')} {required ? '*' : ''}</Label>
                        <SearchableSelectInput<ElementSelectTitleSubTitleOptionDto>
                            value={item.elementId}
                            objectValue={item.selectObject as any}
                            loadOptions={(inputValue, cb, isStart, page) => loadOptions(inputValue, cb, isStart, page)}
                            formatOptionLabel={OptionTitleSubTitle}
                            onChange={(value) => { onChangeProduct(value) }}
                            onClickGoToDetails={() => {
                                if (item.elementId) {
                                    window.open('/library/elements-items/details/' + item.elementId);
                                }
                            }}
                            hasError={required && hasSubmited && !item.elementId}
                            disabled={disabled}
                            noFilter={true}
                        />
                        {required && hasSubmited && !item.elementId && <InputError error={{ type: 'required' }} />}
                    </Col>
                    <Col md={12} lg={5} xxl={2}>
                        <Label className={`${styles.label} ${i > 0 ? styles.hiddenXl : ''}`}>{t('products_list.quantity')}</Label>
                        <MoneyInput

                            value={item.quantity}
                            unitPrefix={item.unitSymbol ?? undefined}
                            disabled={disabled}
                            onChange={(value) => onChangeQuantity(value)}
                        />
                    </Col>
                    <Col md={12} lg={5} xxl={2}>
                        <Label className={`${styles.label} ${i > 0 ? styles.hiddenXl : ''}`}>{t('products_list.price_ht')}</Label>
                        <MoneyInput
                            value={item.price}
                            unitPrefix='€'
                            unit={item.unitSymbol ?? undefined}
                            onChange={(value) => onChangePrice(value)}
                            disabled={true}
                        />
                    </Col>
                </Row>
            </div>
        </div>)
}

export default forwardRef<HTMLDivElement, TreeItemComponentProps<ParameterProductLine>>(ConfigurableElementsLine);
