import React, { DetailedHTMLProps, InputHTMLAttributes, ReactElement, Ref } from 'react';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import styles from './TextInput.module.scss';

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { hasError?: boolean; };

const TextInputPlain = ({ hasError, ...props }: InputProps, ref: Ref<HTMLInputElement> | undefined) => {
    return (
        <input
            type="text"
            {...props}
            ref={ref}
            className={`${styles.input} ${hasError ? styles.hasError : ''} ${props.className ?? ''}`}
        />
    );
};

const TextInput = React.forwardRef(TextInputPlain);

export default TextInput;

type InputControllerProps<T extends FieldValues> = InputProps & UseControllerProps<T>;

const TextInputControllerPlain = <T extends FieldValues>(props: InputControllerProps<T>, ref: Ref<HTMLInputElement> | undefined) => {
    const { field } = useController({ ...props, disabled: false });
    return (
        <TextInput
            {...props}
            ref={ref}
            defaultValue={undefined}
            value={field.value ?? ''}
            name={field.name}
            onBlur={field.onBlur}
            onChange={(e) => {
                field.onChange(e);
                if (props.onChange) {
                    props.onChange(e);
                }
            }}
        />
    );
};

export const TextInputController = React.forwardRef(TextInputControllerPlain) as
    <T extends FieldValues>(p: InputControllerProps<T> & { ref?: Ref<HTMLDivElement> }) => ReactElement;
