import MoneyInput from 'common/components/moneyInput/MoneyInput';
import { useContext } from 'react';
import { LinesDataProvider } from '../../../../LinesDataProvider';
import { Col, Row } from 'react-bootstrap';
import { BudgetLineParameterDto, BudgetLineParameterElementDto } from 'api/budgets/models/BudgetLineParameterDto';
import ParameterChildrenContainer from '../parameterChildrenContainer/ParameterChildrenContainer';
import ElementsList from '../elementsList/ElementsList';
import styles from './ParameterMinMax.module.scss';
import { AtLeast } from 'common/types/Atleast';
import { BudgetLineDto } from 'api/budgets/models/BudgetDto';

interface Props {
    parameter: BudgetLineParameterDto;
    line: BudgetLineDto;
    disabled: boolean;
    onChange: (parameter: Partial<BudgetLineParameterDto>, calculateTotals: boolean) => void;
}

function ParameterMinMax({ parameter, line, disabled, onChange }: Props): JSX.Element | null {
    const { units } = useContext(LinesDataProvider);

    const onChangeElement = (newElement: AtLeast<BudgetLineParameterElementDto, 'key'>) => {
        onChange({
            minMax: {
                ...parameter.minMax,
                elements: (parameter.minMax?.elements ?? []).map(p => {
                    if (p.key === newElement.key) {
                        return { ...p, ...newElement }
                    }
                    return p;
                }),
            }
        }, true);
    }

    const onBlurValue = (value: number | null | undefined) => {
        if (!parameter.minMax) {
            return;
        }

        const inRange = Boolean(value !== null && value !== undefined && value >= (parameter.minMax?.minValue ?? 0) && value <= (parameter.minMax?.maxValue ?? 0));
        onChange({
            minMax: {
                ...parameter.minMax,
                value,
                elements: inRange
                    ? (parameter.minMax.elements || []).map(e => ({
                        ...e,
                        checked: false,
                        parameters: [],
                        checkedForParameters: false,
                    }))
                    : parameter.minMax.elements
            },
        }, false);
    }

    const value = parameter.minMax?.value;
    const showProducts = Boolean(value !== null && value !== undefined && ((value < (parameter.minMax?.minValue ?? 0) || value > (parameter.minMax?.maxValue ?? 0)) && (parameter.minMax?.elements ?? []).length > 0));

    return (
        <div>
            <Row>
                <Col xs={12} sm={4}>
                    <MoneyInput
                        value={value}
                        unit={parameter.unitId ? units.find(x => x.id === parameter.unitId)?.symbol : null}
                        onBlur={(v) => onBlurValue(v)}
                        disabled={disabled}
                    />
                </Col>
            </Row>
            {showProducts && (
                <ParameterChildrenContainer className={styles.elementsContainer} showInfo={!disabled}>
                    <ElementsList
                        parameter={parameter}
                        elements={parameter.minMax?.elements ?? []}
                        line={line}
                        onChangeElement={onChangeElement}
                        disabled={disabled}
                    />
                </ParameterChildrenContainer>
            )}
        </div>
    );
}

export default ParameterMinMax;
