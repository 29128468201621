import useNoInitialEffect from 'common/hooks/useNoInitialEffect';
import { useState, useRef, useEffect } from 'react';
import styles from './InlineTextInput.module.scss';

export function getCaret(el: HTMLElement) {
    let caretAt = 0;
    const sel = window.getSelection();
    if (!sel) {
        return;
    }

    if (sel.rangeCount === 0) { return caretAt; }

    const range = sel.getRangeAt(0);
    const preRange = range.cloneRange();
    preRange.selectNodeContents(el);
    preRange.setEnd(range.endContainer, range.endOffset);
    caretAt = preRange.toString().length;

    return caretAt;
}

export function setCaret(el: HTMLElement, offset: number) {
    const sel = window.getSelection();
    if (!sel) {
        return;
    }
    const range = document.createRange();

    if (!el.childNodes.length) {
        el.appendChild(document.createTextNode(''));
    }

    const textNode = el.childNodes[0];
    range.setStart(textNode, Math.min(offset, textNode.textContent?.length ?? 0));
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
}

export interface InlineTextInputProps {
    value: string | null | undefined;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    onChange?: (value: string | null | undefined) => void;
    onBlur?: (value: string | null | undefined) => void;
}

const InlineTextInput = ({ value, disabled, className, placeholder, onChange, onBlur }: InlineTextInputProps) => {
    const [tempValue, setTempValue] = useState(value);
    const divRef = useRef<HTMLDivElement>(null);
    const caretPos = useRef<number>();

    const onBlurHandle = () => {
        if (onBlur) {
            onBlur(tempValue);
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            divRef.current?.blur();
        }
    };

    useNoInitialEffect(() => {
        if (tempValue !== value) {
            setTempValue(value);
        }
    }, [value])

    useEffect(() => {
        if (divRef.current && placeholder) {
            if (tempValue === null || tempValue === undefined || tempValue === '') {
                divRef.current.setAttribute('data-placeholder', placeholder);
            } else {
                divRef.current.removeAttribute('data-placeholder');
            }
        }
    }, [tempValue, placeholder]);

    useNoInitialEffect(() => {
        if (divRef.current) {
            setCaret(divRef.current, caretPos.current ?? 0);
            // divRef.current.focus();
        }
    }, [tempValue]);

    return (
        <div className={styles.container}>
            <div
                contentEditable={!disabled}
                ref={divRef}
                className={`${styles.input} ${disabled ? styles.disabled : ''} ${className ?? ''}`}
                onInput={e => {
                    if (divRef.current) {
                        caretPos.current = getCaret(divRef.current);
                    }

                    const newValue = e.currentTarget.textContent;
                    setTempValue(newValue);
                    if (onChange) {
                        onChange(newValue);
                    }
                }}
                onBlur={onBlurHandle}
                onKeyDown={handleKeyDown}
                dangerouslySetInnerHTML={{ __html: tempValue ?? '' }}
                spellCheck={false}
            ></div>
        </div>
    )
};

export default InlineTextInput;
