import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import ParametersList from './parametersList/ParametersList';
import { useTranslation } from 'react-i18next';

interface Props {
    line: BudgetLineDto;
    disabled: boolean;
}

function ConfigureTab({ line, disabled }: Props): JSX.Element | null {
    const { t } = useTranslation();
    return (
        <div>
            {line.parameters.length > 0 && (
                <ParametersList
                    parameters={line.parameters}
                    level={0}
                    line={line}
                    disabled={disabled}
                />
            )}
            {line.parameters.length === 0 && (
                <div>
                    {t('messages.no_info_to_show')}
                </div>
            )}
        </div>
    );
}

export default ConfigureTab;
