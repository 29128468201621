import { FaArrowDown, FaArrowUp, FaPencilAlt, FaPlus, FaTimes } from 'react-icons/fa';
import styles from './SectionMenu.module.scss';
import { IoCopyOutline, IoMove, IoTrash } from 'react-icons/io5';
import { useState } from 'react';
import { AddLinePlacement } from 'store/budget/type';
import { ParameterSectionType } from 'common/components/parametersSections/models';
import { BODY_TOOLTIP } from 'Config';
import { useTranslation } from 'react-i18next';

interface Props {
    isEditing: boolean;
    level: number;
    addMenuOptions?: AddLineMenuItem[];
    showMoveUp: boolean;
    showMoveDown: boolean;
    showMove: boolean;
    onAdd?: (lineType: ParameterSectionType, placement: AddLinePlacement) => void;
    onMoveUp?: () => void;
    onMoveDown?: () => void;
    onClone?: () => void;
    onRemove?: () => void;
    onEdit?: (v: boolean) => void;
    handleProps?: any;
    disabledMoveUp?: boolean
    disabledMoveDown?: boolean
}

export interface AddLineMenuItem {
    label: string;
    lineType: ParameterSectionType;
    placement: AddLinePlacement;
}

function SectionMenu({ onAdd, onMoveUp, onMoveDown, onEdit, onClone, onRemove, isEditing, addMenuOptions, showMoveUp, showMoveDown, showMove, handleProps, disabledMoveUp, disabledMoveDown }: Readonly<Props>): JSX.Element | null {
    const { t } = useTranslation();
    const [showAddMenu, setShowAddMenu] = useState(false);

    const onAddClick = () => {
        if (addMenuOptions) {
            setShowAddMenu(s => !s)
        } else if (onAdd) {
            onAdd(ParameterSectionType.PARAMETER, AddLinePlacement.INSIDE);
        }
    }

    const onAddLine = (menuItem: AddLineMenuItem) => {
        onAdd && onAdd(menuItem.lineType, menuItem.placement);
        setShowAddMenu(false);
    }

    return (
        <div className={styles.container}>
            <div className={styles.buttons}>
                {showMove && <button
                    className={`${styles.button} ${styles.handleButton}`}
                    type="button"
                    {...handleProps}
                >
                    <IoMove />
                </button>}
                {onAdd && !isEditing && <button className={`${styles.button} ${showAddMenu ? styles.active : ''}`} type="button" onClick={onAddClick}
                    data-tooltip-id={BODY_TOOLTIP}
                    data-tooltip-content={t('families.floating_menu.add')}>
                    <FaPlus />
                </button>}
                {showMoveDown && onMoveDown && !isEditing && <button className={styles.button} type="button" onClick={onMoveDown} disabled={disabledMoveDown}>
                    <FaArrowDown />
                </button>}
                {showMoveUp && onMoveUp && !isEditing && <button className={styles.button} type="button" onClick={onMoveUp} disabled={disabledMoveUp}>
                    <FaArrowUp />
                </button>}
                {onClone && !isEditing && <button className={styles.button} type="button" onClick={onClone}
                    data-tooltip-id={BODY_TOOLTIP}
                    data-tooltip-content={t('families.floating_menu.clone')}>
                    <IoCopyOutline />
                </button>}
                {onEdit && <button className={`${styles.button} ${isEditing ? styles.active : ''}`} type="button" onClick={() => onEdit(true)}
                    data-tooltip-id={BODY_TOOLTIP}
                    data-tooltip-content={t('families.floating_menu.edit')}>
                    <FaPencilAlt />
                </button>}
                {onEdit && isEditing && <button className={styles.button} type="button" onClick={() => onEdit(false)}
                    data-tooltip-id={BODY_TOOLTIP}
                    data-tooltip-content={t('families.floating_menu.cancel')}>
                    <FaTimes />
                </button>}
                {onRemove && !isEditing && <button className={styles.button} type="button" onClick={onRemove}
                    data-tooltip-id={BODY_TOOLTIP}
                    data-tooltip-content={t('families.floating_menu.remove')}>
                    <IoTrash />
                </button>}
            </div>
            {showAddMenu && addMenuOptions && (
                <div className={styles.menu}>
                    {addMenuOptions.map((option, i) => (
                        <div key={i} className={styles.menuItem} onClick={() => onAddLine(option)}>
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default SectionMenu;
