import { Row, Col } from 'react-bootstrap'
import RichTextInput from '../richTextInput/RichTextInput'
import DimensionInput from '../dimensionInput/DimensionInput'
import FilesUploader from '../filesUploader/FilesUploader'
import { AttachmentDto } from 'api/common/models/AttachmentDto'
import MoneyInput from '../moneyInput/MoneyInput'
import InputError from '../inputError/InputError'
import { ParameterMinMax, ParameterProductLine } from 'api/elements/Models/ElementDto'
import { SortableTree } from '../sortableTree/SortableTree'
import ConfigurableElementsLine from '../configurableElementsLine/ConfigurableElementsLine'
import { ElementParameterComponentDataProvider } from './ElementParameterComponentDataProvider'
import { FlattenedItem } from '../sortableTree/types'
import { AtLeast } from 'common/types/Atleast'
import { useTranslation } from 'react-i18next'
import styles from './ElementParametersComponent.module.scss'
import Button from '../button/Button'
import { IoAddSharp } from 'react-icons/io5'
import { newProductLine, onMove } from './utils'

interface Props {
    required?: boolean,
    disabled: boolean;
    productsFamilyId?: string
    hasSubmited: boolean;
    selectedSectionKey?: string
    onClick?: (key: string) => void;
}

export const TextParameter = ({ value,required,disabled,onChange,hasSubmited }: Props & { value?: string | null ; onChange: (x: string) => void }): JSX.Element => {
    const hasError = required && hasSubmited && value === undefined
    return (
        <Row className='mt-1 mb-2'>
            <Col>
                <RichTextInput
                    value={value}
                    onBlur={onChange}
                    disabled={disabled}
                    hasError={hasError}
                />
                {hasError && <InputError error={{ type: 'required' }} />}
            </Col>
        </Row>
    )
}

export const NumberParameter = ({ value,disabled,onChange,required,hasSubmited,unitSymbol }: Props & { value?: number | null;onChange: (x: number | null | undefined) => void,unitSymbol?: string | null }): JSX.Element => {
    const hasError = required && hasSubmited && value === undefined
    return (
        <Row className='mt-1 mb-2'>
            <Col xs={12} md={5} lg={4} xxl={2} xxxl={1}>
                <MoneyInput
                    value={value}
                    unitPrefix={unitSymbol ?? undefined}
                    hideUnitsDrop={!unitSymbol}
                    disabled={disabled}
                    onChange={onChange}
                    hasError={hasError}
                />
                {hasError && <InputError error={{ type: 'required' }} />}
            </Col>
        </Row>
    )
}

export const MaxMinParameter = ({ item, onChange,disabled,required,productsFamilyId,hasSubmited, onClick,selectedSectionKey }: Props &
{ item: ParameterMinMax;onChange: (item: ParameterMinMax) => void; }): JSX.Element => {
    // const [selectedSectionKey, setSelectedSectionKey] = useState<string>('');

    const onChangeMin = (value: number | null | undefined) => {
        onChange({
            ...item,
            minValue: value,
        });
    }

    const onChangeMax = (value: number | null | undefined) => {
        onChange({
            ...item,
            maxValue: value,
        });
    }

    const onChangeUnit = (value: string | null | undefined) => {
        onChange({
            ...item,
            unitId: value,
        });
    }
    const onChangeProducts = (items: ParameterProductLine[]) => {
        onChange({
            ...item,
            products: items,
        });
    }

    const onChangeProduct = (newItem: AtLeast<FlattenedItem<ParameterProductLine>, 'key'>,type: 'change' | 'remove' | 'change-self-and-children') => {
        if (type === 'change') {
            onChange({
                ...item,
                products: (item?.products ?? []).map(x => {
                    if (x.key === newItem.key) {
                        return { ...x,...newItem }
                    }
                    return x
                }) ?? [],
            });
        }

        if (type === 'remove') {
            const updatedLines = (item.products ?? []).filter(line => line.key !== newItem.key);
            onChangeProducts([...updatedLines])
        }
    }

    const onAddWhenEmpty = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        onChangeProducts([...(item.products ?? []), newProductLine()])
    }

    const onLineCreate = (newItem: ParameterProductLine) => {
        onChangeProducts([...(item.products ?? []), newItem])
    }

    const onMoveItem = (key: string, type: 'up' | 'down') => {
        const updatedLines = onMove(item.products ?? [],key,type)
        onChangeProducts([...updatedLines])
    }

    const onClickProduct = (key: string) => {
        // setSelectedSectionKey(key)
        if (onClick) {
            onClick(key)
        }
    }
    const hasError = required && hasSubmited && (item.minValue === undefined || item.maxValue === undefined || item.unitId === undefined)
    return (
        <>
            <Row className='mt-1 mb-2'>
                <Col xs={12} lg={6} xxl={5} xxxl={3}>
                    <DimensionInput
                        minValue={item.minValue}
                        maxValue={item.maxValue}
                        onChangeMinValue={onChangeMin}
                        onChangeMaxValue={onChangeMax}
                        onChangeUnit={onChangeUnit}
                        unit={item.unitId ?? ''}
                        disabled={disabled}
                        hasError={hasError}
                    />
                    {hasError && <InputError error={{ type: 'required' }} />}
                </Col>
            </Row>
            <Row>
                <Col>
                    {item.products && item.products.length > 0 && <ElementParameterComponentDataProvider.Provider value={{
                        selectedSectionKey: selectedSectionKey ?? '',
                        lines: item.products ?? [],
                        hasSubmited,
                        productsFamilyId,
                        required: false
                    }}>
                        <SortableTree
                            items={item.products ?? []}
                            onItemsChanged={(newItems) => onChangeProducts(newItems as ParameterProductLine[])}
                            onItemChange={onChangeProduct}
                            onItemCreate={onLineCreate}
                            onMoveItem={onMoveItem}
                            onSelectItem={onClickProduct}
                            TreeItemComponent={ConfigurableElementsLine}
                            canHaveChildren={false}
                            canRootHaveChildren={true}
                            dropAnimation={null}
                            indentationWidth={20}
                            disableSorting={disabled}
                        />
                    </ElementParameterComponentDataProvider.Provider>}
                    <>{addProductDiv(item.products ?? [],disabled, false,false,onAddWhenEmpty)}</>
                </Col>
            </Row>
        </>
    )
}

export const ImagesParameter = ({ medias,disabled,onChange,required,hasSubmited }: Props & { medias: AttachmentDto[];onChange: (x: AttachmentDto[]) => void }): JSX.Element => {
    const hasError = required && hasSubmited && (medias.length === 0 || medias.every(x => x.remove === true))

    return (
        <Row className='mt-1 mb-2'>
            <Col>
                <FilesUploader
                    files={medias}
                    type='images'
                    onChange={onChange}
                    disabled={disabled}
                    containerName={(f) => f.key ?? ''}
                    hasError={hasError}
                />
                {hasError && <InputError error={{ type: 'required' }} />}
            </Col>
        </Row>
    )
}

export const ProdutParameter = ({ items,onChange,disabled,required,productsFamilyId,hasSubmited, onClick ,selectedSectionKey }: Props &
{ items: ParameterProductLine[];onChange: (items: ParameterProductLine[]) => void }): JSX.Element => {
    // const [selectedSectionKey, setSelectedSectionKey] = useState<string>('');

    const onChangeProducts = (items: ParameterProductLine[]) => {
        onChange([...items])
    }

    const onChangeProduct = (newItem: AtLeast<FlattenedItem<ParameterProductLine>, 'key'>,type: 'change' | 'remove' | 'change-self-and-children') => {
        if (type === 'change') {
            onChange(
                items.map(x => {
                    if (x.key === newItem.key) {
                        return { ...x,...newItem }
                    }
                    return x
                })
            );
        }

        if (type === 'remove') {
            const updatedLines = items.filter(line => line.key !== newItem.key);
            onChange([...updatedLines])
        }
    }

    const onAddWhenEmpty = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        onChangeProducts([newProductLine()])
    }

    const onLineCreate = (newItem: ParameterProductLine) => {
        onChangeProducts([...items, newItem])
    }

    const onMoveItem = (key: string, type: 'up' | 'down') => {
        const updatedLines = onMove(items,key,type)
        onChangeProducts([...updatedLines])
    }

    const onClickProduct = (key: string) => {
        // setSelectedSectionKey(key)
        if (onClick) {
            onClick(key)
        }
    }

    return (
        <Row className='mt-1 mb-2'>
            <Col>
                {items.length > 0 && <ElementParameterComponentDataProvider.Provider value={{
                    selectedSectionKey: selectedSectionKey ?? '',
                    lines: items,
                    hasSubmited,
                    productsFamilyId,
                    required: false
                }}>
                    <SortableTree
                        items={items}
                        onItemsChanged={(newItems) => onChangeProducts(newItems as ParameterProductLine[])}
                        onItemChange={onChangeProduct}
                        onItemCreate={onLineCreate}
                        onMoveItem={onMoveItem}
                        onSelectItem={onClickProduct}
                        TreeItemComponent={ConfigurableElementsLine}
                        canHaveChildren={false}
                        canRootHaveChildren={true}
                        dropAnimation={null}
                        indentationWidth={20}
                        disableSorting={disabled}
                    />
                </ElementParameterComponentDataProvider.Provider>}
                <>{addProductDiv(items,disabled,required ?? false,hasSubmited,onAddWhenEmpty)}</>
            </Col>
        </Row>
    )
}

const addProductDiv = (items: ParameterProductLine[],disabled: boolean,required: boolean,hasSubmited: boolean,onAddWhenEmpty: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => {
    const { t } = useTranslation()
    if (!items.length) {
        if (disabled) {
            return <div className='mt-3'>
                <div className={`${styles.noItemsText}`}>
                    {t('messages.no_info_to_show')}
                </div>
            </div>
        } else {
            return <>
                {required && hasSubmited && <InputError error={{ type: 'required' }} />}
                <Button variant={required && hasSubmited ? 'danger' : 'third'} className={`${styles.addButton}`} onClick={onAddWhenEmpty}>
                    <IoAddSharp fontSize={18} />
                    <span className='v-align-middle'>
                        {t('elements.add_product')}
                    </span>
                </Button>
            </>
        }
    }
}
