import React, { CSSProperties, HTMLAttributes, ReactNode, useMemo } from 'react';
import {
    AnimateLayoutChanges,
    UseSortableArguments,
    useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { getIsOverParent, iOS } from './utilities';
import type { FlattenedItem, TreeItem, TreeItemComponentType } from './types';
import { UniqueIdentifier } from '@dnd-kit/core';
import { AtLeast } from 'common/types/Atleast';

export interface TreeItemProps<T> extends HTMLAttributes<HTMLLIElement> {
    childCount?: number;
    clone?: boolean;
    isOpen?: boolean;
    depth: number;
    disableInteraction?: boolean;
    disableSelection?: boolean;
    ghost?: boolean;
    handleProps?: any;
    indicator?: boolean;
    indentationWidth: number;
    item: TreeItem<T>;
    isLast: boolean;
    parentsKeys: string[];
    parent: FlattenedItem<T> | null;
    children?: ReactNode;
    onCollapse?: (id: UniqueIdentifier) => void;

    onRemove?: (id: UniqueIdentifier) => void;

    wrapperRef?: (node: HTMLLIElement) => void;
}

const animateLayoutChanges: AnimateLayoutChanges = ({
    isSorting,
    isDragging,
}) => (!(isSorting || isDragging));

type SortableTreeItemProps<
    T,
    TElement extends HTMLElement
> = TreeItemProps<T> & {
    id: string;
    index: number;
    TreeItemComponent: TreeItemComponentType<T, TElement>;
    disableSorting?: boolean;
    sortableProps?: Omit<UseSortableArguments, 'id'>;
    keepGhostInPlace?: boolean;
    onItemChange?: (item: AtLeast<FlattenedItem<T>, 'key'>, type: 'change' | 'remove' | 'change-self-and-children') => void;
    onItemCreate?: (item: T) => void;
    onSelectItem?: (key: string) => void;
    onMoveItem?: (key: string,type: 'up' | 'down') => void;
};

const SortableTreeItemNotMemoized = function SortableTreeItem<
    T,
    TElement extends HTMLElement
>({
    id,
    depth,
    isLast,
    parentsKeys,
    TreeItemComponent,
    parent,
    disableSorting,
    sortableProps,
    keepGhostInPlace,
    onItemChange,
    onItemCreate,
    onSelectItem,
    onMoveItem,
    index,
    ...props
}: SortableTreeItemProps<T, TElement>) {
    const {
        attributes,
        isDragging,
        isSorting,
        listeners,
        setDraggableNodeRef,
        setDroppableNodeRef,
        transform,
        transition,
        isOver,
        over,
    } = useSortable({
        id,
        animateLayoutChanges,
        disabled: disableSorting,
        ...sortableProps,
    });
    const isOverParent = useMemo(
        () => !!over?.id && getIsOverParent(parent, over.id),
        [over?.id]
    );
    const style: CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition: transition ?? undefined,
    };
    const localCollapse = useMemo(() => {
        if (!props.onCollapse) return undefined;
        return () => props.onCollapse?.(props.item.key);
    }, [props.item.key, props.onCollapse]);

    const localRemove = useMemo(() => {
        if (!props.onRemove) return undefined;

        return () => props.onRemove?.(props.item.key);
    }, [props.item.key, props.onRemove]);
    return (
        <TreeItemComponent
            {...props}
            ref={setDraggableNodeRef}
            wrapperRef={setDroppableNodeRef}
            style={keepGhostInPlace ? undefined : style}
            depth={depth}
            ghost={isDragging}
            disableSelection={iOS}
            disableInteraction={isSorting}
            isLast={isLast}
            parent={parent}
            parentsKeys={parentsKeys}
            handleProps={{
                ...attributes,
                ...listeners,
            }}
            onCollapse={localCollapse}
            onRemove={localRemove}
            disableSorting={disableSorting}
            disabled={disableSorting ?? false}
            isOver={isOver}
            isOverParent={isOverParent}
            onItemChange={onItemChange}
            onItemCreate={onItemCreate}
            onSelectItem={onSelectItem}
            onMoveItem={onMoveItem}
            index={index}
        />
    );
};

export const SortableTreeItem = React.memo(
    SortableTreeItemNotMemoized
) as typeof SortableTreeItemNotMemoized;
