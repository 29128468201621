import styles from './SectionList.module.scss';
import { ParameterSectionBaseModel, ParameterSectionType } from '../models';
import Section from '../section/Section';
import { IoAddSharp } from 'react-icons/io5';
import Button from 'common/components/button/Button';
import { useTranslation } from 'react-i18next';
import { duplicateItem, newSection } from '../utils';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import { SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';

interface Props {
    id: string;
    items: ParameterSectionBaseModel[];
    isChild: boolean;
    hasClearBackground: boolean;
    disabled?: boolean;
    unitOptions?: SearchableSelectTitleSubTitleOption[];
    selectInputOptions?: SearchableSelectTitleSubTitleOption[];
    onChange: (items: ParameterSectionBaseModel[]) => void;
}

const SectionList = ({ id, items, isChild, hasClearBackground, disabled, unitOptions, selectInputOptions, onChange }: Props) => {
    const { t } = useTranslation();
    const sectionsOnly = items.filter(s => s.type === ParameterSectionType.SECTION);
    const ids = sectionsOnly.map(x => x.key);

    const { setNodeRef } = useDroppable({
        id,
        disabled,
    });

    const onChangeSection = (item: ParameterSectionBaseModel) => {
        onChange(items.map((section) => {
            if (section.key === item.key) {
                return { ...section, ...item };
            }
            return section;
        }));
    }

    const onRemoveSection = (item: ParameterSectionBaseModel) => {
        onChange(items.filter(s => s.key !== item.key));
    }

    const onDuplicateSection = (item: ParameterSectionBaseModel, clean: boolean = false) => {
        onChange([
            ...items,
            duplicateItem(item, clean),
        ]);
    }

    const onClickAddNewSection = () => {
        onChange([
            ...items,
            newSection(),
        ]);
    }

    return (
        <SortableContext id={id} items={ids} strategy={verticalListSortingStrategy}>
            <div>
                <div className={styles.container} ref={setNodeRef}>
                    {sectionsOnly.map((section, i) => (
                        <Section
                            key={i}
                            section={section}
                            onChange={onChangeSection}
                            onRemove={() => onRemoveSection(section)}
                            onDuplicate={() => onDuplicateSection(section)}
                            isChild={isChild}
                            hasClearBackground={hasClearBackground}
                            disabled={disabled}
                            unitOptions={unitOptions}
                            selectInputOptions={selectInputOptions}
                        />
                    ))}
                </div>
                {!disabled && (
                    <div className="text-center">
                        <Button variant={isChild ? 'empty' : 'third'} className={`${styles.addButton} ${isChild ? styles.isChild : ''}`} onClick={onClickAddNewSection}>
                            <IoAddSharp fontSize={18} />
                            <span className='v-align-middle'>
                                {isChild ? t('common.parameters_sections.click_add_sub_section') : t('common.parameters_sections.click_add_section')}
                            </span>
                        </Button>
                    </div>
                )}
            </div>
        </SortableContext>
    );
};

export default SectionList;
