import { useTranslation } from 'react-i18next';
import styles from './SearchableSelectInput.module.scss';
import { AsyncPaginate as LibSelect, ShouldLoadMore } from 'react-select-async-paginate';
import { FaEye, FaPlus, FaSync, FaTrashAlt } from 'react-icons/fa';
import { ReactNode, useRef, useState } from 'react';
import { defaultSelectInputStyles } from '../selectInput/SelectInput';
import { FormatOptionLabelMeta, GroupBase, OptionsOrGroups, createFilter } from 'react-select';
import LoadingSpinner from '../loading/LoadingSpinner';

const isOptionSelected = (option: any, values: any) => {
    return values.some(({ value }: any) => option.value === value);
};

export interface SearchableSelectInputOption {
    label: string;
    value: string;
}

export interface SearchableSelectInputProps<T> {
    value: string | null | undefined;
    objectValue?: T;
    disabled?: boolean;
    hasError?: boolean;
    noFilter?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    loadOptions: ((inputValue: string, callback: (options: OptionsOrGroups<T, GroupBase<any>>, hasMore: boolean) => void, isStart: boolean, page: number) => void | Promise<OptionsOrGroups<T, GroupBase<any>>>);
    formatOptionLabel?: ((data: T, formatOptionLabelMeta: FormatOptionLabelMeta<T>) => ReactNode) | undefined;
    onClickAddNew?: () => void;
    onClickGoToDetails?: () => void;
    onChange: (value: string | null | undefined, option: T | null | undefined) => void;
    onClickRefreshAfterNew?: () => void;
}

const SearchableSelectInput = <T extends SearchableSelectInputOption>({ value, disabled, hasError, noFilter, loadOptions, formatOptionLabel, onChange, onClickAddNew, onClickGoToDetails, onClickRefreshAfterNew, objectValue }: SearchableSelectInputProps<T>) => {
    const { t } = useTranslation();
    const isStart = useRef(true)

    const [options, setOptions] = useState<T[]>([])
    const [needsRefresh, setNeedsRefresh] = useState(false);
    const [refreshKey, setRefreshKey] = useState<number>(0);

    const onLoadOptions = (inputValue: string, page: number) => {
        return new Promise((resolve) => {
            const cb = (prods: T[], hasMore?: boolean) => {
                resolve({
                    options: prods,
                    hasMore: !!hasMore,
                    additional: {
                        page: page + 1,
                    },
                });
                setOptions(prods);
            }
            void loadOptions(inputValue, cb as any, isStart.current, page);
            setTimeout(() => { isStart.current = false; }, 500);
        });
    }

    const shouldLoadMore: ShouldLoadMore = (
        scrollHeight,
        clientHeight,
        scrollTop,
    ) => {
        const bottomBorder = scrollHeight - clientHeight - 300;

        return bottomBorder < scrollTop;
    };

    return (
        <div className={styles.container}>
            <LibSelect
                key={refreshKey}
                hideSelectedOptions={false}
                noOptionsMessage={() => t('common.no_search_results')}
                value={(isStart.current && objectValue)
                    ? objectValue
                    : value ? (options ?? []).find(x => x.value === value) : null}
                onChange={v => {
                    onChange(v ? (v.value as string) : null, v ?? null)
                }}
                menuPortalTarget={document.body}
                isDisabled={disabled}
                styles={defaultSelectInputStyles(disabled ?? false, true, !!hasError, false)}
                placeholder={t('common.select_option')}
                formatOptionLabel={formatOptionLabel}
                defaultOptions
                loadOptions={async (inputValue, loadedOptions, additional) => {
                    const result = await onLoadOptions(inputValue, additional?.page ?? 1) as any;
                    return result;
                }}
                additional={{
                    page: 1,
                }}
                shouldLoadMore={shouldLoadMore}
                isOptionSelected={isOptionSelected}
                filterOption={noFilter
                    ? undefined :
                    createFilter({
                        ignoreCase: true,
                        ignoreAccents: true,
                        trim: true,
                        matchFrom: ('any' as const),
                        stringify: (option) => option.label + ' ' + (option as any).data.reference + ' ' + (option as any).data.subTitle
                    })}
                loadingMessage={() => <span><LoadingSpinner size={18} /> {t('common.is_loading')}</span>}
            />
            {Boolean(value && !disabled) && (
                <div className={`${styles.sideButton} ${(onClickGoToDetails && value) || Boolean(onClickAddNew) ? styles.sideButtonExtraLeft : ''}`} onClick={() => onChange(null, null)}>
                    <FaTrashAlt />
                </div>
            )}
            {/* <div className={`${styles.sideButton} ${onClickAddNew ? styles.sideButtonExtraLeft : ''} ${value && !disabled ? styles.sideButtonExtraRight : ''} ${styles.notClickable}`}>
                <FaSearch />
            </div> */}
            {Boolean(onClickAddNew) && (
                <div
                    className={`${styles.sideButton} ${onClickGoToDetails && value ? styles.sideButtonExtraLeft : ''} ${value && !disabled ? styles.sideButtonExtraRight : ''}`}
                    onClick={() => {
                        if (needsRefresh) {
                            setNeedsRefresh(false);
                            if (onClickRefreshAfterNew) {
                                onClickRefreshAfterNew();
                                setRefreshKey((x) => x + 1);
                            }
                        } else {
                            setNeedsRefresh(true);
                            if (onClickAddNew) {
                                onClickAddNew();
                            }
                        }
                    }}
                >
                    {needsRefresh ? <FaSync /> : <FaPlus />}
                </div>
            )}
            {Boolean(onClickGoToDetails) && Boolean(value) &&
                <div className={`${styles.sideButton}  ${value && !disabled ? styles.sideButtonExtraRight : ''}`} onClick={onClickGoToDetails}>
                    <FaEye />
                </div>
            }
        </div>
    );
};

export default SearchableSelectInput;
