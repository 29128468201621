import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import { AtLeast } from 'common/types/Atleast';
import styles from './BudgetSectionLine.module.scss';
import utilsStyles from '../../Utils.module.scss';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import Button from 'common/components/button/Button';
import { isColumnVisible, LinesTableColumn } from '../../utils';
import BudgetLineColumn from '../../column/BudgetLineColumn';
import InlineTextInput from 'common/components/inlineTextInput/InlineTextInput';
import { useTranslation } from 'react-i18next';
import { forwardRef, Ref, useContext } from 'react';
import { LinesDataProvider } from '../../LinesDataProvider';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { DEFAULT_DECIMAL_PLACES } from 'Config';
import budgetElementLineStyles from '../element/BudgetElementLine.module.scss'
const MAX_LEVEL_TO_LIGHTEN = 3;

interface Props {
    line: BudgetLineDto;
    disabled: boolean;
    level: number;
    isActive: boolean;
    onChange: (line: AtLeast<BudgetLineDto, 'key'>, calculateTotals: boolean) => void;
    childCount: number;
}

function BudgetSectionLine({ line, disabled, isActive, level, onChange ,childCount }: Props, ref: Ref<HTMLDivElement>): JSX.Element | null {
    const { t } = useTranslation();
    const { hiddenColumns } = useContext(LinesDataProvider);
    const showLineNumber = isColumnVisible(hiddenColumns, LinesTableColumn.LINE_NUMBER);
    const decimalPlaces = useSelector<Reducers, number>(x => x.budget.budget.decimalPlaces ?? DEFAULT_DECIMAL_PLACES);

    const onChangeValue = (newLine: Partial<BudgetLineDto>) => {
        onChange({ key: line.key, ...newLine }, false);
    }

    const onToggleOpen = () => {
        onChange({ ...line, isOpen: !line.isOpen }, false);
    }

    return (
        <div>
            <div
                className={styles.container}
                ref={ref}
            >
                <div
                    className={`${styles.section} ${level >= MAX_LEVEL_TO_LIGHTEN ? styles['level-' + MAX_LEVEL_TO_LIGHTEN] : styles['level-' + level]}`}
                >
                    <BudgetLineColumn column={LinesTableColumn.DESIGNATION} className={`${utilsStyles.column} ${styles.column} ${utilsStyles.columnAlignLeft} ${utilsStyles.designationColumn} ${styles.designationColumn}`}>
                        <Button size='normal' onClick={onToggleOpen} className={styles.toggleOpenButton}>
                            {!line.isOpen && <FaAngleRight />}
                            {line.isOpen && <FaAngleDown />}
                        </Button>
                        {showLineNumber && <span className={styles.number}>{line.number}</span>}
                        <InlineTextInput
                            value={line.designation}
                            onBlur={v => onChangeValue({ designation: v ?? '' })}
                            placeholder={t('budgets.budget.lines.without_designation')}
                            disabled={disabled}
                        />
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.QUANTITY} className={`${utilsStyles.column} ${styles.column} ${utilsStyles.quantityColumn}`}></BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.UNIT} className={`${utilsStyles.column} ${styles.column} ${utilsStyles.unityColumn}`}></BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.GROSS_UNIT_PRICE} className={`${utilsStyles.column} ${styles.column} ${utilsStyles.grossUnityColumn}`}></BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.MARGIN} className={`${utilsStyles.column} ${styles.column} ${utilsStyles.marginColumn}`}></BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.DISCOUNT} className={`${utilsStyles.column} ${styles.column} ${utilsStyles.discountColumn}`}></BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.UNIT_PRICE} className={`${utilsStyles.column} ${styles.column} ${utilsStyles.pricePerUnityColumn}`}></BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.UNIT_PRICE_WITH_CHILDREN} className={`${utilsStyles.column} ${styles.column} ${utilsStyles.unitPriceWithChildrenColumn}`}></BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.TOTAL_EXCLUDING_TAX} className={`${utilsStyles.column} ${styles.column} ${utilsStyles.totalWithoutTaxColumn}`}>
                        <MoneyFormat value={line.totalExcludingTax} decimalScale={decimalPlaces}/>
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.TOTAL_UNIT_EXCLUDING_TAX} className={`${utilsStyles.column} ${styles.column} ${utilsStyles.totalWithoutTaxColumn}`}>
                        <MoneyFormat value={line.totalUnitExcludingTax} decimalScale={decimalPlaces} />
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.TAX} className={`${utilsStyles.column} ${styles.column} ${utilsStyles.taxColumn}`}></BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.TOTAL_INCLUDING_TAX} className={`${utilsStyles.column} ${styles.column} ${utilsStyles.totalWithTaxColumn}`}>
                        <MoneyFormat value={line.totalIncludingTax} decimalScale={decimalPlaces}/>
                    </BudgetLineColumn>
                </div>
            </div>
            { line.isOpen && childCount === 0 && <div className={`${budgetElementLineStyles.container}`}>
                <div className={`${budgetElementLineStyles.element} ${styles.addLinesDiv}`}>
                    {t('budgets.budget.lines.add_lines_message')}
                </div>
            </div>}
        </div>

    );
}

export default forwardRef<HTMLDivElement, Props>(BudgetSectionLine);
