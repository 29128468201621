import Parameter from '../parameter/Parameter';
import { BudgetLineParameterDto } from 'api/budgets/models/BudgetLineParameterDto';
import { BudgetLineDto } from 'api/budgets/models/BudgetDto';

interface Props {
    parameters: BudgetLineParameterDto[];
    level: number;
    line: BudgetLineDto;
    disabled: boolean;
}

function ParametersList({ parameters, line, level, disabled }: Props): JSX.Element | null {
    return (
        <div>
            {parameters.map((parameter, i) => (
                <Parameter
                    key={i}
                    parameter={parameter}
                    level={level}
                    line={line}
                    disabled={disabled}
                />
            ))}
        </div>
    );
}

export default ParametersList;
