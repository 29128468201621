import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useTranslation } from 'react-i18next';
import { Text } from 'common/components/texts/Texts';

const baseUrl = '/dashboard';

function DashboardScreen(): JSX.Element | null {
    const { t } = useTranslation();

    return (
        <ScreenTitle title={t('dashboard.title')}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: t('dashboard.title'), url: baseUrl },
                ]}
            />
            <PageHeader title={t('dashboard.title')} showGoBack={false} addSidebarSpacing informationText={''} />
            <PageContainer>
                <Text>{t('common.in_development')}</Text>
            </PageContainer>
        </ScreenTitle>
    );
}

export default DashboardScreen;
