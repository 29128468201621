import { AttachmentDto } from 'api/common/models/AttachmentDto';
import CountriesService from 'api/countries/CountriesService';
import { CountryDto } from 'api/countries/models/CountryDto';
import Button from 'common/components/button/Button';
import ButtonDropdown, { ButtonDropdownOption } from 'common/components/buttonDropdown/ButtonDropdown';
import CheckInput from 'common/components/checkInput/CheckInput';
import DateInput from 'common/components/dateInput/DateInput';
import DimensionInput from 'common/components/dimensionInput/DimensionInput';
import FileInput from 'common/components/fileInput/FileInput';
import FilesUploader from 'common/components/filesUploader/FilesUploader';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import ConfirmModal from 'common/components/modal/confirmModal/ConfirmModal';
import YesNoModal from 'common/components/modal/yesNoModal/YesNoModal';
import MoneyInput, { UnitOption } from 'common/components/moneyInput/MoneyInput';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ParametersSections from 'common/components/parametersSections/ParametersSections';
import { ParameterSectionBaseModel, ParameterSectionType } from 'common/components/parametersSections/models';
import PhoneInput, { PhoneCodeOption } from 'common/components/phoneInput/PhoneInput';
import ProductCard from 'common/components/productCard/ProductCard';
import RadioInput from 'common/components/radioInput/RadioInput';
import RichTextInput from 'common/components/richTextInput/RichTextInput';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import SearchableSelectInput from 'common/components/searchableSelectInput/SearchableSelectInput';
import { OptionTitleSubTitle, SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';
import SelectInput, { SelectInputOption } from 'common/components/selectInput/SelectInput';
import TextInput from 'common/components/textInput/TextInput';
import { Header, InformationText, Label } from 'common/components/texts/Texts';
import Utils from 'common/services/Utils';
import { useEffect, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';

type TabKey = 'example1' | 'example2' | 'example3' | 'example4';

function TestScreen(): JSX.Element {
    const { t } = useTranslation();

    const [currentTab, setCurrentTab] = useState<TabKey>('example1');

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showYesNoModal, setShowYesNoModal] = useState(false);
    const [files, setFiles] = useState<AttachmentDto[]>([]);
    const [images, setImages] = useState<AttachmentDto[]>([]);
    const [singleFile, setSingleFile] = useState<AttachmentDto | null>(null);

    const selectOptions: SelectInputOption[] = [
        { label: 'Exemplo 1', value: 'example1' },
        { label: 'Exemplo 2', value: 'example2' },
        { label: 'Exemplo 3', value: 'example3' },
    ];

    const selectOptionsTitleSubTitle: SearchableSelectTitleSubTitleOption[] = [
        { label: 'Exemplo 1', subTitle: 'Sub titulo 1', value: 'example1' },
        { label: 'Exemplo 2', subTitle: 'Sub titulo 2', value: 'example2' },
        { label: 'Exemplo 3', subTitle: 'Sub titulo 3', value: 'example3' },
    ];

    const selectOptionsTitleSubTitleWithImages: SearchableSelectTitleSubTitleOption[] = [
        { label: 'Exemplo 1', subTitle: 'Sub titulo 1', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAn57BKLuWbt3srhNJOHuq8gPbmMI0-GKUFw&s', value: 'example1' },
        { label: 'Exemplo 2', subTitle: 'Sub titulo 2', imageUrl: 'https://5.imimg.com/data5/VW/HB/AP/SELLER-87899953/standard-modular-wooden-door-500x500.jpeg', value: 'example2' },
        { label: 'Exemplo 3', subTitle: 'Sub titulo 3', imageUrl: 'https://windowmart.ca/wp-content/uploads/2019/07/FR-08-D.png', value: 'example3' },
    ];

    const unitsOptions: UnitOption[] = [
        { label: 'un', value: 'un' },
        { label: 'pl', value: 'pal' },
        { label: 'bx', value: 'box' },
    ];

    const buttonDropdownOptions: ButtonDropdownOption[] = [
        { label: 'Exemplo 1', onClick: () => console.log('click') },
        { label: 'Exemplo 2', onClick: () => console.log('click') },
        { label: 'Exemplo 3', onClick: () => console.log('click') },
    ];

    const colourOptions: readonly any[] = [
        { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
        { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
        { value: 'purple', label: 'Purple', color: '#5243AA' },
        { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
        { value: 'orange', label: 'Orange', color: '#FF8B00' },
        { value: 'yellow', label: 'Yellow', color: '#FFC400' },
        { value: 'green', label: 'Green', color: '#36B37E' },
        { value: 'forest', label: 'Forest', color: '#00875A' },
        { value: 'slate', label: 'Slate', color: '#253858' },
        { value: 'silver', label: 'Silver', color: '#666666' },
    ];

    const productOptions: readonly any[] = [
        {
            mainImage: 'https://img.freepik.com/fotos-gratis/entrada-da-casa-japonesa_23-2149445290.jpg?t=st=1720603679~exp=1720607279~hmac=80a2d247e1d92f793d3665a54017f300d3fc0f2dbc6cb55e97b77d7b41701053&w=826',
            name: 'Paumelle à réglage 3D tri-dimensionnel',
            family: 'Paumelles',
            subfamily: 'Kits de fixation',
            quantity: 1,
            unit: 'un',
            price: 35,
            priceIncluded: false,
            images: [
                'https://img.freepik.com/fotos-gratis/entrada-da-casa-japonesa_23-2149445290.jpg?t=st=1720603679~exp=1720607279~hmac=80a2d247e1d92f793d3665a54017f300d3fc0f2dbc6cb55e97b77d7b41701053&w=826',
                'https://img.freepik.com/fotos-gratis/unhas-de-prata_144627-1645.jpg?t=st=1720603641~exp=1720607241~hmac=96cc30bc8321d731b6f8dabda856eacbb2cd9036dc17cd08938b6b9a66029c25&w=1380'
            ],
            unitOptions: unitsOptions
        },
        {
            mainImage: 'https://img.freepik.com/fotos-gratis/unhas-de-prata_144627-1645.jpg?t=st=1720603641~exp=1720607241~hmac=96cc30bc8321d731b6f8dabda856eacbb2cd9036dc17cd08938b6b9a66029c25&w=1380',
            name: 'Cheville plastique + vis 10 x 80 mm - Sachet de 50',
            family: 'Kits de fixation',
            quantity: 1,
            unit: 'un',
            price: 35,
            priceIncluded: true,
            fileUrl: 'https://file-examples.com/storage/fe7bf81a86668bb0a9d006b/2017/10/file-sample_150kB.pdf',
            images: [
                'https://img.freepik.com/fotos-gratis/entrada-da-casa-japonesa_23-2149445290.jpg?t=st=1720603679~exp=1720607279~hmac=80a2d247e1d92f793d3665a54017f300d3fc0f2dbc6cb55e97b77d7b41701053&w=826',
                'https://img.freepik.com/fotos-gratis/unhas-de-prata_144627-1645.jpg?t=st=1720603641~exp=1720607241~hmac=96cc30bc8321d731b6f8dabda856eacbb2cd9036dc17cd08938b6b9a66029c25&w=1380'
            ],
            unitOptions: unitsOptions
        },
        {
            name: 'Cheville plastique + vis 10 x 80 mm - Sachet de 50',
            family: 'Kits de fixation',
            quantity: 1,
            unit: 'un',
            price: 35,
            priceIncluded: true,
            fileUrl: 'https://file-examples.com/storage/fe7bf81a86668bb0a9d006b/2017/10/file-sample_150kB.pdf',
            images: [
                'https://img.freepik.com/fotos-gratis/entrada-da-casa-japonesa_23-2149445290.jpg?t=st=1720603679~exp=1720607279~hmac=80a2d247e1d92f793d3665a54017f300d3fc0f2dbc6cb55e97b77d7b41701053&w=826',
                'https://img.freepik.com/fotos-gratis/unhas-de-prata_144627-1645.jpg?t=st=1720603641~exp=1720607241~hmac=96cc30bc8321d731b6f8dabda856eacbb2cd9036dc17cd08938b6b9a66029c25&w=1380'
            ],
            unitOptions: unitsOptions
        }
    ];

    const [products, setProducts] = useState<any>(productOptions);
    const [phoneNumber, setPhoneNumber] = useState<string | null | undefined>(null);
    const [phoneCodeValue, setPhoneCodeValue] = useState<string | null | undefined>(null);
    const [phoneNumber2, setPhoneNumber2] = useState<string | null | undefined>(null);
    const [phoneCodeValue2, setPhoneCodeValue2] = useState<string | null | undefined>(null);
    const [phoneNumber3, setPhoneNumber3] = useState<string | null | undefined>(null);
    const [phoneCodeValue3, setPhoneCodeValue3] = useState<string | null | undefined>(null);
    const [country, setCountry] = useState<string | null | undefined>(null);
    const [countries, setCountries] = useState<CountryDto[]>([]);
    const countriesOptions = countries.map(p => ({ label: p.name, value: p.id }));
    const phoneCodesOptions: PhoneCodeOption[] = countries.filter(x => x.phoneCode).map(p => ({ value: p.id, name: p.isoCodeSmall, phoneCode: p.phoneCode }));

    const getData = async () => {
        const countriesData = await CountriesService.getCatalog();
        setCountries(countriesData);
    }

    useEffect(() => {
        void getData();
    }, [])

    const filterSelectColours = (inputValue: string) => {
        return colourOptions.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const filterSelectOptions = (inputValue: string) => {
        return selectOptionsTitleSubTitleWithImages.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const [sections, setSections] = useState<ParameterSectionBaseModel[]>([
        {
            key: Utils.newGuid(),
            type: ParameterSectionType.SECTION,
            title: null,
            closed: false,
            parentKey: Utils.newGuid(),
            isOpen: true
            // children: [
            //     {
            //         key: Utils.newGuid(),
            //         type: ParameterSectionType.PARAMETER,
            //         parameterType: null,
            //         parameterName: null,
            //         closed: false,
            //         children: [],
            //         parentKey: Utils.newGuid()
            //     }
            // ],
        },
    ])

    const loadOptions = (
        inputValue: string,
        callback: (options: any[], hasMore: boolean) => void
    ) => {
        setTimeout(() => {
            callback(filterSelectColours(inputValue), false);
        }, 1000);
    };

    const loadOptions2 = (
        inputValue: string,
        callback: (options: any[], hasMore: boolean) => void
    ) => {
        setTimeout(() => {
            callback(filterSelectOptions(inputValue), false);
        }, 1000);
    };

    return (
        <ScreenTitle title={'Teste Page'}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: 'Teste Page', url: '/test' },
                ]}
            />

            <PageHeader title={'Tests'} informationText={'Voltar'}>
                <Button>Engregistrer</Button>
            </PageHeader>

            <PageContainer>
                <Row>
                    <Col xs={4}>
                        <TextInput placeholder='Placeholder' />
                    </Col>
                    <Col xs={4}>
                        <TextInput placeholder='Placeholder' value="Exemplo" />
                    </Col>
                    <Col xs={4}>
                        <TextInput placeholder='Placeholder' value="Exemplo" disabled />
                    </Col>
                </Row>

                <br /><br />

                <Row>
                    <Col>
                        <SelectInput
                            value={null}
                            options={selectOptions}
                            onChange={() => { console.log('changed'); }}
                        />
                    </Col>
                    <Col>
                        <SelectInput
                            value={'example1'}
                            options={selectOptions}
                            onChange={() => { console.log('changed'); }}
                        />
                    </Col>
                    <Col>
                        <SelectInput
                            value={'example1'}
                            options={selectOptions}
                            onChange={() => { console.log('changed'); }}
                            disabled
                        />
                    </Col>
                </Row>

                <br />

                <Row>
                    <Col xs={4}>
                        <SelectInput
                            value={'example1'}
                            options={selectOptions}
                            onChange={() => console.log('changed')}
                            onClickAddNew={() => console.log('on add') }
                        />
                    </Col>
                    <Col xs={4}>
                        <SelectInput
                            value={'example1'}
                            options={selectOptions}
                            onChange={() => console.log('changed')}
                            onClickAddNew={() => console.log('on add') }
                            disabled
                        />
                    </Col>
                </Row>

                <br /><br />

                <Row>
                    <Col>
                        <MoneyInput
                            value={null}
                            unitPrefix='€'
                            onChange={() => { console.log('changed'); }}
                        />
                    </Col>
                    <Col>
                        <MoneyInput
                            value={30500.00}
                            unitPrefix='€'
                            onChange={() => { console.log('changed'); }}
                        />
                    </Col>
                    <Col>
                        <MoneyInput
                            value={30500.00}
                            unitPrefix='€'
                            onChange={() => { console.log('changed'); }}
                            disabled
                        />
                    </Col>
                </Row>

                <br />

                <Row>
                    <Col>
                        <MoneyInput
                            value={null}
                            unitPrefix='%'
                            onChange={() => { console.log('changed'); }}
                        />
                    </Col>
                    <Col>
                        <MoneyInput
                            value={null}
                            unitPrefix='€'
                            unit="un"
                            unitsOptions={unitsOptions}
                            onChange={() => { console.log('changed'); }}
                        />
                    </Col>
                    <Col>
                        <MoneyInput
                            value={null}
                            unitPrefix='€'
                            unit="un"
                            unitsOptions={unitsOptions}
                            onChange={() => { console.log('changed'); }}
                            disabled
                        />
                    </Col>
                </Row>
                <br />

                <Row>
                    <Col>
                        <MoneyInput
                            value={null}
                            unitPrefix='€'
                            unit="un"
                            onChange={() => { console.log('changed'); }}
                        />
                    </Col>
                    <Col>
                        <MoneyInput
                            value={null}
                            unitPrefix='€'
                            unit="un"
                            unitsOptions={unitsOptions}
                            onChange={() => { console.log('changed'); }}
                        />
                    </Col>
                    <Col>
                        <MoneyInput
                            value={null}
                            unitPrefix='€'
                            unit="un"
                            unitsOptions={unitsOptions}
                            onChange={() => { console.log('changed'); }}
                            disabled
                        />
                    </Col>
                </Row>

                <br /><br />

                <Row>
                    <Col>
                        <DateInput
                            value={null}
                            onChange={() => { console.log('changed'); }}
                        />
                    </Col>
                    <Col>
                        <DateInput
                            value={new Date()}
                            onChange={() => { console.log('changed'); }}
                        />
                    </Col>
                    <Col>
                        <DateInput
                            value={new Date()}
                            onChange={() => { console.log('changed'); }}
                            disabled
                        />
                    </Col>
                </Row>

                <br /><br />

                <Row>
                    <Col xs={4}>
                        <DimensionInput
                            minValue={null}
                            maxValue={null}
                            onChangeMinValue={() => { console.log('changed'); }}
                            onChangeMaxValue={() => { console.log('changed'); }}
                        />
                    </Col>
                    <Col xs={4}>
                        <DimensionInput
                            minValue={20}
                            maxValue={60}
                            onChangeMinValue={() => { console.log('changed'); }}
                            onChangeMaxValue={() => { console.log('changed'); }}
                        />
                    </Col>
                    <Col xs={4}>
                        <DimensionInput
                            minValue={20}
                            maxValue={60}
                            onChangeMinValue={() => { console.log('changed'); }}
                            onChangeMaxValue={() => { console.log('changed'); }}
                            disabled
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={4}>
                        <DimensionInput
                            minValue={null}
                            maxValue={null}
                            onChangeMinValue={() => { console.log('changed'); }}
                            onChangeMaxValue={() => { console.log('changed'); }}
                            unit="mm"
                        />
                    </Col>
                    <Col xs={4}>
                        <DimensionInput
                            minValue={20}
                            maxValue={60}
                            onChangeMinValue={() => { console.log('changed'); }}
                            onChangeMaxValue={() => { console.log('changed'); }}
                            unit="mm"
                        />
                    </Col>
                    <Col xs={4}>
                        <DimensionInput
                            minValue={20}
                            maxValue={60}
                            onChangeMinValue={() => { console.log('changed'); }}
                            onChangeMaxValue={() => { console.log('changed'); }}
                            unit="mm"
                            disabled
                        />
                    </Col>
                </Row>

                <br /><br />

                <Row>
                    <Col>
                        <Button fw>Principal</Button>
                        <Button variant='secondary' fw>Secundário</Button>
                        <Button variant='third' fw>Terciário</Button>
                        <Button disabled>Inativo</Button>
                    </Col>
                    <Col>
                        <Button size="medium" fw>Principal</Button>
                        <Button variant='secondary' size="medium" fw>Secundário</Button>
                        <Button variant='third' size="medium" fw>Terciário</Button>
                        <Button size="medium" disabled>Inativo</Button>
                    </Col>
                    <Col>
                        <Button size="small" fw>Principal</Button>
                        <Button variant='secondary' size="small" fw>Secundário</Button>
                        <Button variant='third' size="small" fw>Terciário</Button>
                        <Button size="small" disabled>Inativo</Button>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Button fw><FaTimes /></Button>
                        <Button variant='secondary' fw><FaTimes /></Button>
                        <Button variant='third' fw><FaTimes /></Button>
                        <Button disabled><FaTimes /></Button>
                    </Col>
                    <Col>
                        <Button size="medium" fw><FaTimes /></Button>
                        <Button variant='secondary' size="medium" fw><FaTimes /></Button>
                        <Button variant='third' size="medium" fw><FaTimes /></Button>
                        <Button size="medium" disabled><FaTimes /></Button>
                    </Col>
                    <Col>
                        <Button size="small" fw><FaTimes /></Button>
                        <Button variant='secondary' size="small" fw><FaTimes /></Button>
                        <Button variant='third' size="small" fw><FaTimes /></Button>
                        <Button size="small" disabled><FaTimes /></Button>
                    </Col>
                </Row>

                <br />
                <br />

                <Row>
                    <Col>
                        <ButtonDropdown fw options={buttonDropdownOptions}>Principal</ButtonDropdown>
                        <ButtonDropdown fw variant='secondary' options={buttonDropdownOptions}>Secundário</ButtonDropdown>
                        <ButtonDropdown fw variant='third' options={buttonDropdownOptions}>Terciário</ButtonDropdown>
                        <ButtonDropdown fw disabled options={buttonDropdownOptions}>Inativo</ButtonDropdown>
                    </Col>
                    <Col>
                        <ButtonDropdown fw size="medium" options={buttonDropdownOptions}>Principal</ButtonDropdown>
                        <ButtonDropdown fw size="medium" variant='secondary' options={buttonDropdownOptions}>Secundário</ButtonDropdown>
                        <ButtonDropdown fw size="medium" variant='third' options={buttonDropdownOptions}>Terciário</ButtonDropdown>
                        <ButtonDropdown fw size="medium" disabled options={buttonDropdownOptions}>Inativo</ButtonDropdown>
                    </Col>
                    <Col>
                        <ButtonDropdown fw size="small" options={buttonDropdownOptions}>Principal</ButtonDropdown>
                        <ButtonDropdown fw size="small" variant='secondary' options={buttonDropdownOptions}>Secundário</ButtonDropdown>
                        <ButtonDropdown fw size="small" variant='third' options={buttonDropdownOptions}>Terciário</ButtonDropdown>
                        <ButtonDropdown fw size="small" disabled options={buttonDropdownOptions}>Inativo</ButtonDropdown>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ButtonDropdown fw options={buttonDropdownOptions}><FaEllipsisV /></ButtonDropdown>
                        <ButtonDropdown fw variant='secondary' options={buttonDropdownOptions}><FaEllipsisV /></ButtonDropdown>
                        <ButtonDropdown fw variant='third' options={buttonDropdownOptions}><FaEllipsisV /></ButtonDropdown>
                        <ButtonDropdown fw disabled options={buttonDropdownOptions}><FaEllipsisV /></ButtonDropdown>
                    </Col>
                    <Col>
                        <ButtonDropdown fw size="medium" options={buttonDropdownOptions}><FaEllipsisV /></ButtonDropdown>
                        <ButtonDropdown fw size="medium" variant='secondary' options={buttonDropdownOptions}><FaEllipsisV /></ButtonDropdown>
                        <ButtonDropdown fw size="medium" variant='third' options={buttonDropdownOptions}><FaEllipsisV /></ButtonDropdown>
                        <ButtonDropdown fw size="medium" disabled options={buttonDropdownOptions}><FaEllipsisV /></ButtonDropdown>
                    </Col>
                    <Col>
                        <ButtonDropdown fw size="small" options={buttonDropdownOptions}><FaEllipsisV /></ButtonDropdown>
                        <ButtonDropdown fw size="small" variant='secondary' options={buttonDropdownOptions}><FaEllipsisV /></ButtonDropdown>
                        <ButtonDropdown fw size="small" variant='third' options={buttonDropdownOptions}><FaEllipsisV /></ButtonDropdown>
                        <ButtonDropdown fw size="small" disabled options={buttonDropdownOptions}><FaEllipsisV /></ButtonDropdown>
                    </Col>
                </Row>

                <br />
                <br />

                <Row>
                    <Col xs={4}>
                        <RadioInput checked={false} />
                        <RadioInput checked={true} />
                        <RadioInput checked={false} disabled />
                        <RadioInput checked={true} disabled />
                    </Col>
                    <Col>
                        <RadioInput checked={false}>Exemplo</RadioInput>
                        <RadioInput checked={true}>Exemplo</RadioInput>
                        <RadioInput checked={false} disabled>Exemplo</RadioInput>
                        <RadioInput checked={true} disabled>Exemplo</RadioInput>
                    </Col>
                </Row>

                <br />

                <Row>
                    <Col xs={4}>
                        <CheckInput checked={false} />
                        <CheckInput checked={true} />
                        <CheckInput checked={false} disabled />
                        <CheckInput checked={true} disabled />
                    </Col>
                    <Col>
                        <CheckInput checked={false}>Exemplo</CheckInput>
                        <CheckInput checked={true}>Exemplo</CheckInput>
                        <CheckInput checked={false} disabled>Exemplo</CheckInput>
                        <CheckInput checked={true} disabled>Exemplo</CheckInput>
                    </Col>
                </Row>

                <br /><br />

                <Row>
                    <Col xs={4}>
                        <Label space>Label</Label>
                        <Header space>Header</Header>
                        <InformationText space>Information Text</InformationText>
                        <InformationText space small>Information Text Small</InformationText>
                    </Col>
                </Row>

                <br />
                <br />

                <Row>
                    <Col>
                        <Tabs activeKey={currentTab} onSelect={(k) => setCurrentTab(k as TabKey)}>
                            <Tab eventKey={'example1'} title={'Exemplo 1'}>
                                Exemplo 1
                            </Tab>
                            <Tab eventKey={'example2'} title={'Exemplo 2'}>
                                Exemplo 2
                            </Tab>
                            <Tab eventKey={'example3'} title={'Exemplo 3'}>
                                Exemplo 3
                            </Tab>
                            <Tab eventKey={'example4'} title={'Exemplo 4'}>
                                Exemplo 4
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>

                <br />
                <br />

                <Row>
                    <Col xs={4}>
                        <SearchableSelectInput
                            value={null}
                            onChange={() => console.log('changed')}
                            loadOptions={loadOptions2}
                        />
                    </Col>
                    <Col xs={4}>
                        <SearchableSelectInput
                            value={'example1'}
                            onChange={() => console.log('changed')}
                            loadOptions={loadOptions2}
                        />
                    </Col>
                    <Col xs={4}>
                        <SearchableSelectInput
                            value={'example1'}
                            onChange={() => console.log('changed')}
                            onClickAddNew={() => console.log('add')}
                            loadOptions={loadOptions2}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={4}>
                        <SearchableSelectInput
                            value={'example1'}
                            onChange={() => console.log('changed')}
                            loadOptions={loadOptions2}
                            disabled
                        />
                    </Col>
                    <Col xs={4}>
                        <SearchableSelectInput<SearchableSelectTitleSubTitleOption>
                            value={null}
                            formatOptionLabel={OptionTitleSubTitle}
                            onChange={() => console.log('changed')}
                            loadOptions={loadOptions2}
                        />
                    </Col>
                    <Col xs={4}>
                        <SearchableSelectInput<SearchableSelectTitleSubTitleOption>
                            value={null}
                            formatOptionLabel={OptionTitleSubTitle}
                            onChange={() => console.log('changed')}
                            loadOptions={loadOptions2}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={4}>
                        <SearchableSelectInput<SearchableSelectTitleSubTitleOption>
                            value={null}
                            formatOptionLabel={OptionTitleSubTitle}
                            onChange={() => console.log('changed')}
                            loadOptions={loadOptions}
                        />
                    </Col>
                    <Col xs={4}>
                        <SearchableSelectInput<SearchableSelectTitleSubTitleOption>
                            value={null}
                            formatOptionLabel={OptionTitleSubTitle}
                            onChange={() => console.log('changed')}
                            loadOptions={loadOptions}
                        />
                    </Col>
                </Row>

                <br />
                <br />

                <Row>
                    <Col>
                        <Button fw onClick={() => setShowConfirmModal(true)}>Confirm Modal</Button>
                        <Button fw variant="danger" onClick={() => setShowConfirmDeleteModal(true)}>Confirm Delete Modal</Button>
                        <Button fw onClick={() => setShowYesNoModal(true)}>Yes No Modal</Button>
                    </Col>
                </Row>

                <ConfirmModal
                    message="1 500 produits mises a jour!"
                    isOpen={showConfirmModal}
                    onClose={() => setShowConfirmModal(false)}
                />

                <ConfirmDeleteModal
                    itemName="produit"
                    isOpen={showConfirmDeleteModal}
                    onClose={() => setShowConfirmDeleteModal(false)}
                />

                <YesNoModal
                    title="Queres um sim ou um não?"
                    message="Queres mesmo isso ou não?"
                    isOpen={showYesNoModal}
                    onClose={() => setShowYesNoModal(false)}
                />

                <br />
                <br />

                <Row>
                    <Col>
                        <ParametersSections sections={sections} onChange={setSections} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ParametersSections sections={sections} onChange={setSections} disabled />
                    </Col>
                </Row>

                <br />
                <br />

                <Row style={{ marginBottom: '14px' }}>
                    <Col>
                        <RichTextInput
                            value=""
                            placeholder={t('common.write_here')}
                            onChange={() => {}}
                        />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '14px' }}>
                    <Col>
                        <RichTextInput
                            value="What is Lorem Ipsum?
Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

Why do we use it?
It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).

Where does it come from?
Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.. comes from a line in section 1.10.32.

The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham."
                            placeholder={t('common.write_here')}
                            onChange={() => {}}
                        />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '14px' }}>
                    <Col>
                        <RichTextInput
                            value="What is Lorem Ipsum?
Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

Why do we use it?
It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).

Where does it come from?
Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.. comes from a line in section 1.10.32.

The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham."
                            placeholder={t('common.write_here')}
                            hasError
                            onChange={() => {}}
                        />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '14px' }}>
                    <Col>
                        <RichTextInput
                            value="What is Lorem Ipsum?
Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

Why do we use it?
It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).

Where does it come from?
Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.. comes from a line in section 1.10.32.

The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham."
                            onChange={() => {}}
                            disabled
                        />
                    </Col>
                </Row>

                <br /><br />

                <Row>
                    <Col>
                        <FilesUploader
                            files={files}
                            type='files'
                            onChange={setFiles}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <FilesUploader
                            files={files}
                            type='files'
                            onChange={setFiles}
                            hasError
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <FilesUploader
                            files={files}
                            type='files'
                            onChange={setFiles}
                            disabled
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <FilesUploader
                            files={images}
                            type='images'
                            canSetMain
                            onChange={setImages}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <FilesUploader
                            files={images}
                            type='images'
                            onChange={setImages}
                            disabled
                        />
                    </Col>
                </Row>

                <br />
                <br />

                <Row>
                    <Col xs={6}>
                        <FileInput
                            value={singleFile}
                            onChange={setSingleFile}
                        />
                    </Col>
                    <Col xs={6}>
                        <FileInput
                            value={singleFile}
                            onChange={setSingleFile}
                            disabled
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={6}>
                        <FileInput
                            value={singleFile}
                            onChange={setSingleFile}
                            hasError
                        />
                    </Col>
                </Row>

                <br />
                <br />
                <Row>
                    <Col xs={6}>
                        <PhoneInput
                            value={phoneNumber}
                            phoneCodeValue={phoneCodeValue}
                            onChange={setPhoneNumber}
                            onChangePhoneCode={setPhoneCodeValue}
                            phoneCodes={phoneCodesOptions}
                        />
                    </Col>
                    <Col xs={6}>
                        <PhoneInput
                            value={phoneNumber2}
                            phoneCodeValue={phoneCodeValue2}
                            onChange={setPhoneNumber2}
                            onChangePhoneCode={setPhoneCodeValue2}
                            phoneCodes={phoneCodesOptions}
                            disabled
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={6}>
                        <PhoneInput
                            value={phoneNumber3}
                            phoneCodeValue={phoneCodeValue3}
                            onChange={setPhoneNumber3}
                            onChangePhoneCode={setPhoneCodeValue3}
                            phoneCodes={phoneCodesOptions}
                            hasError
                        />
                    </Col>
                    <Col>
                        <SelectInput
                            value={country}
                            options={countriesOptions}
                            onChange={(v) => { setPhoneCodeValue(v); setCountry(v); }}
                        />
                    </Col>
                </Row>

                <br /><br />

                <Row>
                    {products.map((p: any, i: number) =>
                        <ProductCard
                            key={p.name}
                            mainImage={p.mainImage}
                            images={p.images}
                            name={p.name}
                            family={p.family}
                            subfamily={p.subfamily}
                            enableDataSheet={false}
                            quantity={p.quantity}
                            unit={p.unit}
                            unitOptions={p.unitOptions}
                            price={p.price}
                            priceIncluded={p.priceIncluded}
                            selected={p.selected}
                            onSelect={() => {
                                productOptions[i].selected = !products[i].selected;
                                setProducts(productOptions);
                            }}
                        />
                    )}
                </Row>

                <br /><br /><br /><br /><br /><br /><br />
                <br /><br /><br /><br /><br /><br /><br />
                <br /><br /><br /><br /><br /><br /><br />

            </PageContainer>
        </ScreenTitle>
    );
}

export default TestScreen;
