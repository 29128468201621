import { Col, Row } from 'react-bootstrap';
import { BudgetLineParameterDto } from 'api/budgets/models/BudgetLineParameterDto';
import RichTextInput from 'common/components/richTextInput/RichTextInput';

interface Props {
    parameter: BudgetLineParameterDto;
    disabled: boolean;
    onChange: (parameter: Partial<BudgetLineParameterDto>, calculateTotals: boolean) => void;
}

function ParameterText({ parameter, disabled,onChange }: Props): JSX.Element | null {
    return (
        <div>
            <Row>
                <Col xs={12} sm={4}>
                    <RichTextInput
                        value={parameter.text?.text ?? undefined}
                        onChange={(v) => onChange({ text: { text: v ?? '' } }, false)}
                        disabled={disabled}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default ParameterText;
