import React from 'react';
import ReactModal from 'react-modal';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../Modal';
import { Header } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/button/Button';
import styles from './HistoryModal.module.scss';

type Props = {
    title?: string;
    items?: any[];
    renderItem: (item: any) => JSX.Element;
    onClose: () => void;
} & ReactModal.Props;

const HistoryModal: React.FC<Props> = ({ title, items, renderItem, onClose, ...props }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal {...props} style={{ content: { minWidth: 550, maxHeight: '40vh', overflow: 'hidden' } }}>
            <div className={styles.modalContainer}>
                <ModalHeader>
                    <Header>{title}</Header>
                </ModalHeader>
                <ModalBody className={styles.bodyContent}>
                    <div className={styles.timeline}>
                        {items && items.map((item, index) => (
                            <div className={styles.timelineItem} key={`history-${index}`}>
                                <div className={styles.circle}></div>
                                <div className={styles.content}>
                                    {renderItem(item)}
                                </div>
                            </div>
                        ))}
                        {!items?.length && t('messages.no_info_to_show')}
                    </div>
                </ModalBody>
                <ModalFooter className={styles.footerContent}>
                    <Button onClick={onClose}>{t('common.ok')}</Button>
                </ModalFooter>
            </div>
        </Modal>
    );
};

export default HistoryModal;
