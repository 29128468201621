import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Toast from 'common/services/Toast';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import BudgetsService from 'api/budgets/BudgetsService';
import { BudgetLineParameterDto } from 'api/budgets/models/BudgetLineParameterDto';
import ParametersList from '../parametersList/ParametersList';
import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import ParameterChildrenContainer from '../parameterChildrenContainer/ParameterChildrenContainer';
import { mergeWithExistingParameters } from '../../../../utils';

interface Props {
    parameters: BudgetLineParameterDto[];
    elementId: string | null | undefined;
    checkedForParameters: boolean;
    line: BudgetLineDto;
    className?: string;
    disabled: boolean;
    onChange: (parameters: BudgetLineParameterDto[], checkedForParameters: boolean) => void;
    onLoading: (v: boolean) => void;
}

function ElementParameters({ parameters, elementId, line, checkedForParameters, className, disabled, onChange, onLoading }: Props): JSX.Element | null {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    const getData = async () => {
        if (!elementId || checkedForParameters || disabled) {
            setIsLoading(() => false);
            return;
        }

        try {
            setIsLoading(() => true);

            const parametersData = await BudgetsService.getElementParameters(elementId,false);
            onChange(mergeWithExistingParameters(parametersData.parameters ?? [], parameters), true);
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get the element data', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            setIsLoading(() => false);
        }
    }

    useEffect(() => {
        void getData();
    }, [elementId]);

    useEffect(() => {
        void onLoading(isLoading);
    }, [isLoading]);

    if (isLoading || parameters.length === 0) {
        return null;
    }

    return (
        <ParameterChildrenContainer className={className} showInfo={!disabled}>
            {/* {isLoading && <LoadingSpinnerCenter />} */}
            {!isLoading && parameters.length > 0 && (
                <ParametersList
                    parameters={parameters}
                    level={0}
                    line={line}
                    disabled={disabled}
                />
            )}
        </ParameterChildrenContainer>
    );
}

export default ElementParameters;
