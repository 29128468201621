import styles from './Texts.module.scss';
import { DetailedHTMLProps, HTMLAttributes, LabelHTMLAttributes } from 'react';

type LabelProps = { space?: boolean } & DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>

export const Label = ({ space, ...props }: LabelProps) => (
    <label {...props} className={`${styles.label} ${space ? styles.space : ''} ${props.className ?? ''}`} />
);

type HeaderProps = { space?: boolean } & DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;

export const Header = ({ space, ...props }: HeaderProps) => (
    <h1 {...props} className={`${styles.header} ${space ? styles.space : ''} ${props.className ?? ''}`} />
);

export const SubHeader = ({ space, ...props }: HeaderProps) => (
    <h2 {...props} className={`${styles.subHeader} ${space ? styles.space : ''} ${props.className ?? ''}`} />
);

type InformationTextProps = { space?: boolean; small?: boolean; medium?: boolean; } & DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

export const InformationText = ({ space, small, medium, ...props }: InformationTextProps) => (
    <span {...props} className={`${styles.informationText} ${space ? styles.space : ''} ${medium ? styles.medium : ''} ${small ? styles.small : ''} ${props.className ?? ''}`} />
);

type TextProps = { } & DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>;

export const Text = ({ ...props }: TextProps) => (
    <p {...props} className={`${styles.text} ${props.className ?? ''}`} />
);
