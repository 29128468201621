import { CSSProperties } from 'react';
import styles from './Image.module.scss';
import MediaIcon from 'assets/svg/image-default-icon.svg';

export interface ImageProps {
    src?: string | null;
    imageStyle?: CSSProperties;
    containerStyle?: CSSProperties;
    imageClassName?: string;
    containerClassName?: string;
}

export const Image = ({ src, containerStyle, imageStyle, imageClassName, containerClassName }: ImageProps) => {
    return (
        <div className={`${styles.image} ${containerClassName ?? ''} ${(src ? '' : styles.noImage)}`} style={containerStyle} >
            <img src={src || MediaIcon} style={imageStyle} className={`${(src ? '' : styles.noImage)} ${imageClassName ?? ''}`} />
        </div>
    );
}
