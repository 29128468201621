import styles from './PageContainer.module.scss';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { addBottomSpace?: boolean };

const PageContainer = ({ addBottomSpace, ...props }: Props) => (
    <div {...props} className={`${styles.container} wrapped-container ${addBottomSpace ? styles.bottomSpace : ''} ${props.className ?? ''}`} />
);

export default PageContainer;
