import styles from './ParametersList.module.scss';
import { ParameterSectionBaseModel, ParameterSectionType } from '../models';
import { IoAddSharp } from 'react-icons/io5';
import Button from 'common/components/button/Button';
import { useTranslation } from 'react-i18next';
import { newSectionParameter } from '../utils';
import Parameter from '../parameter/Parameter';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';

interface Props {
    id: string;
    items: ParameterSectionBaseModel[];
    disabled?: boolean;
    unitOptions?: SearchableSelectTitleSubTitleOption[];
    selectInputOptions?: SearchableSelectTitleSubTitleOption[];
    onChange: (items: ParameterSectionBaseModel[]) => void;
}

const ParametersList = ({ items, id, disabled, unitOptions, selectInputOptions, onChange }: Props) => {
    const { t } = useTranslation();
    const parametersOnly = items.filter(s => s.type === ParameterSectionType.PARAMETER);

    const ids = parametersOnly.map(x => x.key);

    const { setNodeRef } = useDroppable({
        id,
        disabled,
    });

    const onChangeParameter = (item: ParameterSectionBaseModel) => {
        onChange(items.map((parameter) => {
            if (parameter.key === item.key) {
                return { ...parameter, ...item };
            }
            return parameter;
        }));
    }

    const onRemoveParameter = (item: ParameterSectionBaseModel) => {
        onChange(items.filter(s => s.key !== item.key));
    }

    const onClickAddNewParameter = () => {
        onChange([
            ...items,
            newSectionParameter(),
        ]);
    }

    return (
        <SortableContext id={id} items={ids} strategy={verticalListSortingStrategy}>
            <div>
                <div className={styles.container} ref={setNodeRef}>
                    {parametersOnly.map((parameter, i) => (
                        <Parameter
                            key={i}
                            parameter={parameter}
                            onChange={onChangeParameter}
                            disabled={disabled}
                            onRemove={() => onRemoveParameter(parameter)}
                            unitOptions={unitOptions}
                            selectInputOptions={selectInputOptions}
                        />
                    ))}
                </div>
                {!disabled && <div className='text-right'>
                    <Button variant="third" className={styles.addButton} onClick={onClickAddNewParameter}>
                        <IoAddSharp fontSize={18} /> <span className='v-align-middle'>{t('common.parameters_sections.click_add_parameter')}</span>
                    </Button>
                </div>}
            </div>
        </SortableContext>
    );
};

export default ParametersList;
