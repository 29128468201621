import { LOGGER_LOG_TYPE } from 'Config';
import Toast from 'common/services/Toast';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';
import Logger from 'common/services/Logger';
import ListingTable from 'common/components/listingTable/ListingTable';
import { orderColumns } from 'common/components/listingTable/tableUtils';
import ListingTableCache, { ListingTableData } from 'common/components/listingTable/ListingTableCache';
import { WorksExternalPartsContactsSearchCriteria } from 'api/works/models/WorksSearchCriteria';
import { FilterData, ListingTableColumn, OrderData } from 'common/components/listingTable/models';
import TableActionButton from 'common/components/listingTable/actionButton/TableActionButton';
import Button from 'common/components/button/Button';
import ContactModal from './ContactModal';
import { SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';
import ExternalPartsService from 'api/externalPart/ExternalPartsService';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import { ExternalPartContactDto } from 'api/externalPart/models/ExternalPartDto';
import { WorkExternalPartContactDto, WorkExternalPartDto } from 'api/works/models/WorkDto';

interface Props {
    workContactsList: WorkExternalPartContactDto[];
    type: 'create' | 'details' | 'edit' | undefined;
    selectedExternalParts: (WorkExternalPartDto)[];
    onAddNewContact: (newContact: WorkExternalPartContactDto) => void;
    onRemoveContact: (contact: string) => void;
}

const PAGE_ID = 'WORK_EXTERNAL_PART_CONTACTS_LIST';

function WorkExternalPartsContactsList({ workContactsList, type, selectedExternalParts, onAddNewContact, onRemoveContact }: Readonly<Props>): JSX.Element | null {
    const { t } = useTranslation();
    const isDetails = type === 'details';

    const [refreshKey, setRefreshKey] = useState(0);

    const [showAddContactModal, setShowAddContactModal] = useState<boolean>(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<WorkExternalPartContactDto | null>(null);

    const [contactsList, setContactsList] = useState<ExternalPartContactDto[]>([]);

    const [filteredContactsList, setFilteredContactsList] = useState<WorkExternalPartContactDto[]>([]);

    const [tableData, setTableData] = useState<ListingTableData<WorkExternalPartContactDto, Partial<WorksExternalPartsContactsSearchCriteria>>>(ListingTableCache.get(PAGE_ID, {
        order: { field: 'name', orderColumn: 'epc.name', isOrderAsc: true },
        hiddenColumns: [],
        columnsOrder: [],
        columnsWidth: [],
        page: 1,
        filters: {},
        lastRowId: null,
    }));

    const contactsColumns: ListingTableColumn<WorkExternalPartContactDto>[] = orderColumns([
        {
            name: t('works.work.external_part'),
            field: 'externalPartName',
            filter: { type: 'text', value: tableData.filters.name ?? '', placeholder: t('works.work.external_part') },
            minWidth: '18rem',
            orderColumn: 'external_part_name',
        },
        {
            name: t('external_parts.contact.name_last_name'),
            field: 'name',
            filter: { type: 'text', value: tableData.filters.name ?? '', placeholder: t('external_parts.contact.name_last_name') },
            minWidth: '18rem',
        },
        {
            name: t('external_parts.contact.function'),
            field: 'function',
            filter: { type: 'text', value: tableData.filters.function ?? '', placeholder: t('external_parts.contact.function') },
            minWidth: '18rem',
        },
        {
            name: t('external_parts.contact.phone_number'),
            field: 'phoneNumber',
            filter: { type: 'text', value: tableData.filters.phoneNumber ?? '', placeholder: t('external_parts.contact.phone_number') },
            minWidth: '18rem',
            orderColumn: 'phone_number',
            renderCell: (row) => {
                return <div>{row.phoneCode ? `+${row.phoneCode}` : ''} {row.phoneNumber}</div>;
            }
        },
        {
            name: t('external_parts.contact.email'),
            field: 'email',
            filter: { type: 'text', value: tableData.filters.email ?? '', placeholder: t('external_parts.contact.email') },
            minWidth: '18rem',
        },
    ], tableData.columnsOrder);

    const onFilter = (col: ListingTableColumn<WorkExternalPartContactDto>, filterData: FilterData) => {
        setTableData(d => {
            const data = ({
                ...d
            })
            data.filters = {
                ...data.filters
            };
            if (col.field === 'externalPartName') {
                data.filters = {
                    ...data.filters,
                    externalPartName: filterData.value as string,
                };
            }
            if (col.field === 'name') {
                data.filters = {
                    ...data.filters,
                    name: filterData.value as string,
                };
            }
            if (col.field === 'function') {
                data.filters = {
                    ...data.filters,
                    function: filterData.value as string,
                };
            }
            if (col.field === 'phoneNumber') {
                data.filters = {
                    ...data.filters,
                    phoneNumber: filterData.value as string,
                };
            }
            if (col.field === 'email') {
                data.filters = {
                    ...data.filters,
                    email: filterData.value as string,
                };
            }
            return data;
        });
        setRefreshKey(k => k + 1);
    }

    const onClearFilters = () => {
        setTableData(d => ({ ...d, filters: {} }));
        setRefreshKey(k => k + 1)
    }

    const onOrder = (orderData: OrderData<WorkExternalPartContactDto>) => {
        setRefreshKey(k => k + 1)
        setTableData(d => ({ ...d, order: orderData }));
    }

    useEffect(() => {
        ListingTableCache.save(PAGE_ID, tableData);
    }, [tableData]);

    const onLoadExternalPartsContactsOptions = (
        inputValue: string,
        callback: (options: SearchableSelectTitleSubTitleOption[], hasMore: boolean) => void
    ) => {
        ExternalPartsService.getContactsByExternalPartsIds({
            externalPartsIds: selectedExternalParts.map(x => x.externalPartId)
        }).then(result => {
            setContactsList(result);
            const contactsListIds = workContactsList.map(x => x.externalPartContactId);

            const options: SearchableSelectTitleSubTitleOption[] = result.filter(item => !contactsListIds.includes(item.id))?.map(item => ({
                label: item.name ?? '',
                subTitle: (item.externalPartCategoryName + ': ' + item.externalPartName) ?? '',
                value: item.id,
            }));
            callback(options, false);
        }).catch((error) => {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get external parts contacts', error);
            Toast.error(t('messages.error_load_info'));
        });
    };

    const onDeleteContact = async (result: boolean) => {
        if (!result) {
            setShowConfirmDeleteModal(null)
            return;
        }

        if (!showConfirmDeleteModal) {
            return;
        }
        onRemoveContact(showConfirmDeleteModal.externalPartContactId);

        setShowConfirmDeleteModal(null);

        setRefreshKey(k => k + 1);
    }

    const onAddContactClick = () => {
        if (!selectedExternalParts?.length) {
            Toast.warning(t('works.work.external_parts_required'));
            return;
        }
        setShowAddContactModal(true);
    }

    const renderAction = (row: WorkExternalPartContactDto) => (
        <TableActionButton
            options={[
                { label: <span><FaTrash /> {t('common.delete')}</span>, onClick: () => setShowConfirmDeleteModal(row) },
            ]}
        />
    )

    useEffect(() => {
        const filters = tableData.filters;
        const order = tableData.order;

        let filtered = workContactsList.filter(contact => {
            // Helper function to clean phone numbers
            const cleanPhoneNumber = (phone: any) => phone ? phone.replace(/[^\w]/g, '').toLowerCase() : '';

            // Clean the filters and contact phone numbers
            const filterPhoneNumber = cleanPhoneNumber(filters.phoneNumber);
            const contactPhoneNumber = cleanPhoneNumber(((contact.phoneCode ?? '') + contact.phoneNumber));

            const matchExternalPartName = !filters.externalPartName || contact.externalPartName?.toLowerCase().includes(filters.externalPartName.toLowerCase());
            const matchName = !filters.name || contact.name?.toLowerCase().includes(filters.name.toLowerCase());
            const matchFunction = !filters.function || contact.function?.toLowerCase().includes(filters.function.toLowerCase());
            const matchPhoneNumber = !filters.phoneNumber || contactPhoneNumber.includes(filterPhoneNumber);
            const matchEmail = !filters.email || contact.email?.toLowerCase().includes(filters.email.toLowerCase());

            return matchExternalPartName && matchName && matchFunction && matchPhoneNumber && matchEmail;
        });

        if (order) {
            filtered = filtered.sort((a, b) => {
                const aValue = a[order.field];
                const bValue = b[order.field];
                if (aValue === bValue) return 0;
                if (!aValue) return order.isOrderAsc ? -1 : 1;
                if (!bValue) return order.isOrderAsc ? 1 : -1;
                return order.isOrderAsc
                    ? String(aValue).localeCompare(String(bValue))
                    : String(bValue).localeCompare(String(aValue));
            });
        }
        setFilteredContactsList(filtered);
    }, [workContactsList, tableData.filters, refreshKey]);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                {!isDetails && <Button onClick={onAddContactClick}>
                    {t('works.work.new_contact')}
                </Button>}
            </div>
            <ListingTable<WorkExternalPartContactDto>
                columns={contactsColumns}
                rows={filteredContactsList}
                order={tableData.order}
                hiddenColumns={tableData.hiddenColumns}
                onOrder={onOrder}
                onFilter={onFilter}
                onClearFilters={onClearFilters}
                onRenderAction={!isDetails ? renderAction : undefined}
                onRowId={r => r.externalPartContactId}
                columnsWidths={tableData.columnsWidth}
                onColResize={(w) => setTableData(d => ({ ...d, columnsWidth: w }))} />

            <ContactModal
                onLoadItems={onLoadExternalPartsContactsOptions}
                isOpen={showAddContactModal}
                selectedExternalParts={selectedExternalParts}
                onClose={(id) => {
                    setShowAddContactModal(false);
                    if (id) {
                        const newContact = contactsList.find(x => x.id === id);
                        if (newContact) {
                            const c: WorkExternalPartContactDto = {
                                externalPartCategoryId: newContact.externalPartCategoryId,
                                externalPartContactId: newContact.id,
                                externalPartId: newContact.externalPartId,
                                externalPartName: newContact.externalPartName,
                                name: newContact.name,
                                function: newContact.function,
                                email: newContact.email,
                                phoneNumber: newContact.phoneNumber,
                                phoneCodeId: newContact.phoneCodeId,
                                phoneCode: newContact.phoneCode
                            };
                            onAddNewContact(c);
                        }
                    }
                }}
            />

            <ConfirmDeleteModal
                itemName={showConfirmDeleteModal?.name}
                isOpen={!!showConfirmDeleteModal}
                onClose={onDeleteContact}
            />
        </div>
    );
}

export default WorkExternalPartsContactsList;
