import { Paged } from 'api/common/types/Page';
import Http from '../../common/services/Http';
import { TaxDto } from './models/TaxDto';
import { TaxSearchCriteria } from './models/TaxSearchCriteria';

class TaxesService {
    public getById (id: string) {
        return Http.get<TaxDto>('taxes/' + id);
    }

    public getList (criteria: TaxSearchCriteria) {
        return Http.get<Paged<TaxDto>>('taxes', criteria);
    }

    public getCatalog () {
        return Http.get<TaxDto[]>('taxes/catalog');
    }

    public getFormattedCatalog () {
        return Http.get<TaxDto[]>('taxes/get-formatted-catalog');
    }

    public create (model: TaxDto): Promise<string> {
        return Http.post('taxes',model);
    }

    public update (model: TaxDto) {
        return Http.put('taxes',model);
    }

    public remove (model: TaxDto) {
        return Http.put('taxes/deactivate',model);
    }
}

export default new TaxesService();
