import { useRef, useState } from 'react';
import styles from './InlineNumberInput.module.scss';
import useNoInitialEffect from 'common/hooks/useNoInitialEffect';
import { DEFAULT_DECIMAL_PLACES } from 'Config';
import { numericFormatter } from 'react-number-format';
import { getCaret, setCaret } from '../inlineTextInput/InlineTextInput';

type FormatType = 'plain' | 'money' | 'percentage';

export interface InlineNumberInputProps {
    value: number | null | undefined;
    className?: string;
    type?: FormatType;
    disabled?: boolean;
    decimalScale?: number;
    onChange?: (value: number | null | undefined) => void;
    onBlur?: (value: number | null | undefined) => void;
    onClick?: () => void;
}

const format = (val: string | null | undefined, decimalScale?: number, type?: FormatType) => {
    return numericFormatter(val || '0', {
        decimalScale: decimalScale ?? DEFAULT_DECIMAL_PLACES,
        suffix: type === 'plain' ? '' : (type === 'percentage' ? ' %' : ' €'),
        thousandSeparator: ' ',
        decimalSeparator: ',',
        fixedDecimalScale: true,
    })
}

const strToNumber = (str: string | null | undefined) => {
    const parsedValue = Number(str || 0);
    return isNaN(parsedValue) ? 0 : parsedValue;
}

const numberToStr = (value: number | null | undefined) => {
    return value?.toString() || '0';
}

const InlineNumberInput = ({
    value,
    className,
    disabled,
    decimalScale,
    type = 'plain',
    onChange,
    onBlur,
    onClick,
}: InlineNumberInputProps) => {
    const [tempValue, setTempValue] = useState<string>(numberToStr(value));
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const divRef = useRef<HTMLDivElement>(null);
    const caretPos = useRef<number>();

    const handleBlur = () => {
        if (onBlur) {
            onBlur(strToNumber(tempValue));
        }

        setIsEditing(() => false);
    };

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    }

    const selectAllText = () => {
        if (divRef.current) {
            const range = document.createRange();
            range.selectNodeContents(divRef.current);
            const selection = window.getSelection();
            if (selection) {
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    }

    const handleFocus = () => {
        setIsEditing(() => true);
        selectAllText();
    }

    const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
        if (divRef.current) {
            caretPos.current = getCaret(divRef.current);
        }

        let newValue = e.currentTarget.textContent ?? '';

        newValue = newValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot
        const dotIndex = newValue.indexOf('.');
        if (dotIndex !== -1) {
            // Ensure only one dot is allowed
            newValue = newValue.substring(0, dotIndex + 1) + newValue.substring(dotIndex + 1).replace(/\./g, '');
            // Limit to decimal places
            if (newValue.length - dotIndex - 1 > (decimalScale ?? DEFAULT_DECIMAL_PLACES)) {
                newValue = newValue.substring(0, dotIndex + 1 + (decimalScale ?? DEFAULT_DECIMAL_PLACES));
            }
        }

        if (!newValue) {
            newValue = '0';
        }

        setTempValue(() => newValue);

        if (divRef.current) {
            divRef.current.textContent = newValue; // Manually update the DOM content
            setCaret(divRef.current, caretPos.current ?? 0); // Restore caret position
        }

        if (onChange) {
            onChange(strToNumber(tempValue));
        }

        if (newValue === '0') {
            selectAllText();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent inserting a new line
            if (divRef.current) {
                divRef.current.blur(); // Trigger blur event
            }
        }
    };

    useNoInitialEffect(() => {
        const newVal = numberToStr(value);
        if (tempValue !== newVal) {
            setTempValue(newVal);
        }
    }, [value, decimalScale]);

    useNoInitialEffect(() => {
        if (divRef.current) {
            setCaret(divRef.current, caretPos.current ?? 0);
        }
    }, [tempValue]);

    return (
        <div
            ref={divRef}
            className={`${styles.container} ${isEditing ? styles.isEditing : ''} ${disabled ? styles.disabled : ''} ${onClick ? styles.clickable : ''} ${className ?? ''}`}
            contentEditable={!disabled}
            onBlur={handleBlur}
            onClick={handleClick}
            onInput={handleInput}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            suppressContentEditableWarning={true}
        >
            {isEditing ? tempValue : format(tempValue, decimalScale, type)}
        </div>
    );
};

export default InlineNumberInput;
