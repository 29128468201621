import React from 'react';
import { NavBar } from '../navbar/NavBar';
import SideBar from '../sidebar/Sidebar';

interface Props {
    children: React.ReactNode
}

const AuthenticatedLayout: React.FunctionComponent<Props> = ({ children }: Props) => {
    return (
        <div className="main-wrapper">
            <SideBar />
            <NavBar />
            <main className="main-container">
                {children}
            </main>
        </div>
    );
};

export default AuthenticatedLayout;
