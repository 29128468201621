import Http from 'common/services/Http';
import { Paged } from 'api/common/types/Page';
import { NumerationDto } from './models/NumerationDto';
import { NumerationsSearchCriteria } from './models/NumerationsSearchCriteria';

class NumerationsService {
    public getList(criteria: NumerationsSearchCriteria) {
        return Http.get<Paged<NumerationDto>>('numerations', criteria);
    }

    public getById(id: string) {
        return Http.get<NumerationDto>('numerations/' + id);
    }

    public update(model: NumerationDto) {
        return Http.put('numerations', model);
    }
}

export default new NumerationsService();
