import { forwardRef, Ref, useContext, useRef } from 'react';
import { AddLinePlacement } from 'store/budget/type';
import { TreeItemComponentProps } from 'common/components/sortableTree/types';
import clsx from 'clsx';
import useIsMobileOrTablet from 'common/hooks/useDeviceType';
import { ParameterSectionBaseModel, ParameterSectionType } from 'common/components/parametersSections/models';
import CheckInput from 'common/components/checkInput/CheckInput';
import Parameter from './Parameter';
import { useTranslation } from 'react-i18next';
import { getLevelFromParentKeys, lineInnerActiveStyle, lineInnerLevelStyle, lineLevelStyle, lineOutterLineStyle } from 'screens/business/budgets/budget/lines/utils';
import { FamilyParameterDataProvider } from './FamilyParameterDataProvider';
import { canMoveDown, canMoveUp, isLastInLevel, newSection, newSectionParameter } from './utils';
import styles from './ParameterLine.module.scss'
import SectionMenu from 'common/components/collapsableSection/sectionMenu/SectionMenu';
import Button from 'common/components/button/Button';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import InlineTextInput from 'common/components/inlineTextInput/InlineTextInput';
import utilsStyles from '../../../../business/budgets/budget/lines/Utils.module.scss';

export const FAMILY_PARAMETER_SECTION_ID_PREFIX = 'familyParameterSection_';
const MAX_LEVEL_TO_LIGHTEN = 3;

function ParameterLine({
    item: section,
    depth: level,
    handleProps,
    clone,
    ghost,
    disableInteraction,
    disableSelection,
    style,
    parentsKeys,
    childCount,
    onItemChange,
    onItemCreate,
    onMoveItem,
    onSelectItem,
    disabled,
    wrapperRef
}: TreeItemComponentProps<ParameterSectionBaseModel>, ref: Ref<HTMLDivElement>): JSX.Element | null {
    const { t } = useTranslation();
    const { family, familiesInputOptions, units,selectedSectionKey,sections } = useContext(FamilyParameterDataProvider);
    const isMobileOrTablet = useIsMobileOrTablet();
    const isLastInSelectedLevel = selectedSectionKey != null ? isLastInLevel(sections, selectedSectionKey, section.key) : false;
    const parentRef = useRef<boolean>(false)

    const disableMoveUp = !canMoveUp(sections, selectedSectionKey);
    const disableMoveDown = !canMoveDown(sections, selectedSectionKey);

    const onAdd = (lineType: ParameterSectionType, placement: AddLinePlacement) => {
        const parentKey = placement === AddLinePlacement.SAME ? section.parentKey : section.key
        const newParameter = lineType === ParameterSectionType.SECTION ? newSection(parentKey) : newSectionParameter(parentKey);
        newParameter.visibleOnlyInBudgets = placement === AddLinePlacement.SAME ? false : section.visibleOnlyInBudgets;
        if (placement === AddLinePlacement.SAME && section.parentKey === null) {
            newParameter.visibleOnlyInBudgets = false;
        } else if ((placement === AddLinePlacement.SAME && section.parentKey !== null) || placement === AddLinePlacement.INSIDE) {
            newParameter.visibleOnlyInBudgets = section.visibleOnlyInBudgets;
        }

        if (placement === AddLinePlacement.SAME) {
            newParameter.relativeKey = section.key;
        }

        if (onItemCreate) {
            onItemCreate({
                ...newParameter,
            });
        }
    }

    const onRemove = () => {
        if (onItemChange) {
            onItemChange({
                key: section.key
            }, 'remove');
        }
    }

    const getAddMenuOptions = (level: number, type: ParameterSectionType) => {
        const options = [];

        if (level === 0) {
            options.push({
                label: t('families.family.parameter_add_options.section') + ' ' + t('families.family.parameter_add_options.same_level'),
                lineType: ParameterSectionType.SECTION,
                placement: AddLinePlacement.SAME,
            });
            options.push({
                label: t('families.family.parameter_add_options.section') + ' ' + t('families.family.parameter_add_options.inside_level'),
                lineType: ParameterSectionType.SECTION,
                placement: AddLinePlacement.INSIDE,
            });
        } else {
            options.push({
                label: t('families.family.parameter_add_options.subsection') + ' ' + t('families.family.parameter_add_options.same_level'),
                lineType: ParameterSectionType.SECTION,
                placement: AddLinePlacement.SAME,
            });
            if (type === ParameterSectionType.SECTION) {
                options.push({
                    label: t('families.family.parameter_add_options.subsection') + ' ' + t('families.family.parameter_add_options.inside_level'),
                    lineType: ParameterSectionType.SECTION,
                    placement: AddLinePlacement.INSIDE,
                });
            }
        }

        if (type === ParameterSectionType.SECTION) {
            options.push({
                label: t('families.family.parameter_add_options.element') + ' ' + t('families.family.parameter_add_options.inside_level'),
                lineType: ParameterSectionType.PARAMETER,
                placement: AddLinePlacement.INSIDE,
            });
        } else {
            options.push({
                label: t('families.family.parameter_add_options.element') + ' ' + t('families.family.parameter_add_options.same_level'),
                lineType: ParameterSectionType.PARAMETER,
                placement: AddLinePlacement.SAME,
            });
        }

        return options;
    }

    const onChangeTitle = (title: string | null | undefined) => {
        if (section.type === 'SECTION') {
            section.title = title;
        } else {
            section.parameterName = title;
        }

        if (onItemChange) {
            onItemChange({
                ...section
            }, 'change');
        }
    }

    const onMoveUp = () => {
        if (onMoveItem) {
            onMoveItem(section.key,'up')
        }
    }

    const onMoveDown = () => {
        if (onMoveItem) {
            onMoveItem(section.key,'down')
        }
    }

    const onToggleVisibleOnlyInBudgets = (checked: boolean) => {
        if (onItemChange) {
            onItemChange({
                key: section.key,
                visibleOnlyInBudgets: checked
            }, 'change-self-and-children');
        }
    }

    const onToggleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if (onItemChange) {
            onItemChange({
                key: section.key,
                isOpen: !section.isOpen
            }, 'change');
        }
    }

    const _onClickSection = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (!disabled && onSelectItem) { onSelectItem(section.key); };
    }
    const parentIsSelected = selectedSectionKey != null ? parentsKeys.includes(selectedSectionKey) : false;
    const levelsUntilParent = selectedSectionKey != null && parentIsSelected ? getLevelFromParentKeys(selectedSectionKey, parentsKeys) : 0;
    const isActive = selectedSectionKey === section.key;

    parentRef.current = sections.find(x => x.key === section.parentKey)?.visibleOnlyInBudgets ?? false

    let content: JSX.Element | null;

    switch (section.type) {
        case ParameterSectionType.SECTION:
            content = <div className={styles.section} ref={ref}>
                <div className={`${styles.header} ${styles['level-' + (level >= MAX_LEVEL_TO_LIGHTEN ? MAX_LEVEL_TO_LIGHTEN : level)]} ${isActive ? styles.active : ''}`}>
                    <div className={`${utilsStyles.column} ${styles.column} ${utilsStyles.columnAlignLeft} ${utilsStyles.designationColumn} ${styles.designationColumn}`}>

                        <Button size='normal' onClick={onToggleOpen} className={styles.toggleOpenButton}>
                            {!section.isOpen && <FaAngleRight />}
                            {section.isOpen && <FaAngleDown />}
                        </Button>
                        {disabled
                            ? section.title
                            : <InlineTextInput
                                value={section.title}
                                onBlur={onChangeTitle}
                                placeholder={t('budgets.budget.lines.without_designation')}
                            />}
                    </div>
                    <div className={styles.rightContent}>
                        <div className={styles.sectionOption}>
                            <CheckInput
                                checked={section.visibleOnlyInBudgets ?? false}
                                disabled={disabled || parentRef.current }
                                onChange={(e) => onToggleVisibleOnlyInBudgets(e.target.checked)}
                            >
                                {t('families.family.visible_only_in_budgets')}
                            </CheckInput>
                        </div>
                    </div>
                </div>
            </div>
            break;
        case ParameterSectionType.PARAMETER:
            content = <Parameter
                ref={ref}
                parameter={section}
                onChange={(item) => onItemChange && onItemChange({ ...item },'change')}
                unitOptions={units}
                selectInputOptions={familiesInputOptions}
                disabled={disabled}
                family={family}
            />
            break;
        default:
            throw new Error(`Couldn't find a line type with the name '${section.type}'`);
    }

    if (clone) {
        return null;
    }
    const borderTopLeftWithoutRadius = !disabled || !isActive;

    return (
        <div
            className={clsx(
                styles.line,
                clone && styles.lineClone,
                ghost && styles.lineGhost,
                ghost && styles.indicator,
                disableSelection && styles.lineDisableSelection,
                disableInteraction && styles.lineDisableIteraction,
            )}
            style={{
                ...style,
                ...lineLevelStyle(level, clone ?? false, levelsUntilParent),
                ...lineOutterLineStyle(isActive)
            }}
            id={FAMILY_PARAMETER_SECTION_ID_PREFIX + section.key}
            ref={wrapperRef as any}
            onClick={_onClickSection}
        >
            {isActive && !disabled && <SectionMenu
                isEditing={false}
                level={level}
                handleProps={handleProps}
                showMove={!isMobileOrTablet}
                showMoveUp={isMobileOrTablet}
                showMoveDown={isMobileOrTablet}
                onRemove={onRemove}
                onAdd={onAdd}
                onMoveUp={onMoveUp}
                onMoveDown={onMoveDown}
                addMenuOptions={getAddMenuOptions(level, section.type)}
                disabledMoveDown={disableMoveDown}
                disabledMoveUp={disableMoveUp}
            />}
            <div
                className={styles.contentContainer}
                style={{
                    ...(lineInnerLevelStyle(parentIsSelected, levelsUntilParent)),
                    ...(lineInnerActiveStyle(isActive, parentIsSelected, isLastInSelectedLevel, (childCount ?? 0) > 0, section.isOpen, borderTopLeftWithoutRadius)),
                }}
            >
                {content}
            </div>
        </div>)
}

export default forwardRef<HTMLDivElement, TreeItemComponentProps<ParameterSectionBaseModel>>(ParameterLine);
