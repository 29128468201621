import { useState } from 'react';
import styles from './InfoMessage.module.scss';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';

interface Props {
    message: string;
    type: 'information' | 'success' | 'warning' | 'error';
}

const InfoMessage: React.FC<Props> = ({ message,type }: Props) => {
    const { t } = useTranslation();
    const [isOpen,setIsOpen] = useState<boolean>(true)
    const renderClass = () => {
        switch (type) {
            case 'information':
                return styles.infoMessage
            case 'success':
                return styles.successMessage
            case 'warning':
                return styles.warningMessage;
            case 'error':
                return styles.errorMessage
            default:
                return styles.infoMessage;
        }
    }

    const renderTitle = () => {
        switch (type) {
            case 'information':
                return t('common.information')
            case 'success':
                return t('common.success')
            case 'warning':
                return t('common.warning')
            case 'error':
                return t('common.error')
            default:
                return t('common.information')
        }
    }
    if (!isOpen) {
        return null
    }

    return (
        <div className={`${styles.container} ${renderClass()}`}>
            <span className={styles.title}>{renderTitle()}! </span>
            <span>{message}</span>
            <FaTimes className={styles.closeButton} onClick={() => setIsOpen(false)}/>
        </div>
    );
};

export default InfoMessage;
