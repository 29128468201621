import { ReactElement, ReactNode } from 'react';
import { Tab as RBTab, Tabs } from 'react-bootstrap';
import styles from './SmallTabs.module.scss';

interface TabProps<TKey> {
    eventKey: TKey;
    title: string;
    children: ReactNode;
}

export const Tab = <TKey extends string>({ eventKey, title, children }: TabProps<TKey>) => {
    return (
        <RBTab eventKey={eventKey} title={title}>
            {children}
        </RBTab>
    );
};

type TabElement<TKey> = ReactElement<TabProps<TKey>>;

interface Props<TKey> {
    activeKey: TKey;
    children: TabElement<TKey> | TabElement<TKey>[] | any;
    onSelect: (key: TKey) => void;
}

export const SmallTabs = <TKey extends string>({ activeKey, onSelect, children }: Props<TKey>) => {
    return (
        <Tabs activeKey={activeKey} onSelect={(k) => onSelect(k as TKey)} className={styles.tabs}>
            {children}
        </Tabs>
    );
};
