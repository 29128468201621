import React, { DetailedHTMLProps, InputHTMLAttributes, ReactElement, Ref } from 'react';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import styles from './TextAreaInput.module.scss';

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & { hasError?: boolean; };

const TextAreaInputPlain = ({ hasError, ...props }: InputProps, ref: Ref<HTMLTextAreaElement> | undefined) => {
    return (
        <textarea
            type="text"
            rows={3}
            {...props}
            ref={ref}
            className={`${styles.input} ${hasError ? styles.hasError : ''} ${props.className ?? ''}`}
        />
    );
};

const TextAreaInput = React.forwardRef(TextAreaInputPlain);

export default TextAreaInput;

type InputControllerProps<T extends FieldValues> = InputProps & UseControllerProps<T>;

const TextAreaInputControllerPlain = <T extends FieldValues>(props: InputControllerProps<T>, ref: Ref<HTMLTextAreaElement> | undefined) => {
    const { field } = useController({ ...props, disabled: false });
    return (
        <TextAreaInput
            {...props}
            ref={ref}
            defaultValue={undefined}
            value={field.value ?? ''}
            name={field.name}
            onBlur={field.onBlur}
            onChange={(e) => {
                field.onChange(e);
                if (props.onChange) {
                    props.onChange(e);
                }
            }}
        />
    );
};

export const TextAreaInputController = React.forwardRef(TextAreaInputControllerPlain) as
    <T extends FieldValues>(p: InputControllerProps<T> & { ref?: Ref<HTMLDivElement> }) => ReactElement;
