import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { initAuthentication } from './store/authentication/action';
import { AppDispatch } from './store/store';
import authorizeService from 'common/services/AuthorizeService';

interface Props {
    children: React.ReactElement
}

const Setup: React.FC<Props> = ({ children }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const { i18n } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [i18nLoaded, setI18nLoaded] = useState(false);

    useEffect(() => {
        dispatch(initAuthentication(() => {
            setLoaded(true);
            void authorizeService.ensureUserManagerInitialized();
        }) as any);
    }, []);

    useEffect(() => {
        if (i18n.isInitialized) {
            setI18nLoaded(true);
        }
    }, [i18n.isInitialized]);

    return loaded && i18nLoaded ? children : null;
};

export default Setup;
