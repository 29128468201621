import React, { BaseSyntheticEvent, DetailedHTMLProps, FormHTMLAttributes, ReactElement, Ref, useImperativeHandle } from 'react';
import { FieldValues, SubmitErrorHandler, SubmitHandler } from 'react-hook-form';

type Props<T extends FieldValues> = {
    handleSubmit: (onValid: SubmitHandler<T>, onInvalid?: SubmitErrorHandler<T> | undefined) => (e?: BaseSyntheticEvent) => Promise<void>
    onSubmit: (model: T) => void
    onInvalid?: () => void
} & Omit<DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>, 'onSubmit' | 'ref'>

export interface FormRef {
    onInvalid: () => void;
}

const FormPlain = <T extends FieldValues>({ handleSubmit, onSubmit, onInvalid, ...props }: Props<T>, ref: Ref<FormRef>) => {
    const onInvalidHandler = () => {
        setTimeout(() => {
            const elements: Element[] = [];
            Array.from(document.getElementsByClassName('InputError')).forEach(element => elements.push(element));
            elements.sort((a, b) => b.scrollHeight - a.scrollHeight);
            elements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            onInvalid && onInvalid();
        }, 50);
    }

    useImperativeHandle(ref, () => ({
        onInvalid: onInvalidHandler
    }));

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <form {...props} onKeyDown={handleKeyDown} onSubmit={handleSubmit(onSubmit, onInvalidHandler)} />
    );
}

export const Form = React.forwardRef(FormPlain) as
    <T extends FieldValues>(p: Props<T> & { ref?: Ref<FormRef> }) => ReactElement;
