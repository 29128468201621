import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import ReactQuill, { Quill } from 'react-quill';
import styles from './RichTextInput.module.scss';
import React, { Ref, useEffect, useRef, useState } from 'react';
import useNoInitialEffect from 'common/hooks/useNoInitialEffect';

const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'];

const Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

const colors: Array<string> = [
    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc',
    '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66',
    '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2',
    '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', '#99AA9C'
];

const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'ordered', 'indent',
    'link', 'image',
    'align', 'color', 'background', 'size',
    'clean', 'style', 'height', 'width',
    'header',
    'font'
];

interface RichTextInputProps {
    value: string | null | undefined;
    hasError?: boolean;
    disabled?: boolean;
    placeholder?: string;
    rows?: number;
    isInline?: boolean;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
}

const RichTextInputPlain = ({ value, disabled, hasError, isInline, placeholder, onChange, onBlur }: RichTextInputProps, ref: Ref<ReactQuill>) => {
    const [refreshKey, setRefreshKey] = useState(0);
    const modules = {
        toolbar: !disabled
            ? [
                [{ size: fontSizeArr }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ color: colors }, { background: [] }],
                ['clean']
            ]
            : false,
        clipboard: {
            matchVisual: false
        },
    };

    const [tempValue, setTempValue] = useState(value);
    const tempValueRef = useRef(value);

    const onBlurHandle = () => {
        if (onBlur) {
            onBlur(tempValue ?? '');
        }
    }

    useNoInitialEffect(() => {
        if (tempValueRef.current !== value) {
            setTempValue(value);
            setRefreshKey(y => y + 1);
        }
    }, [value])

    useEffect(() => { setRefreshKey(y => y + 1) },[disabled])

    return (
        <ReactQuill
            key={refreshKey}
            theme="snow"
            defaultValue={tempValue ?? undefined}
            onChange={(v) => {
                tempValueRef.current = v;
                setTempValue(v);
                if (onChange) {
                    onChange(v);
                }
            }}
            onBlur={onBlurHandle}
            readOnly={disabled}
            placeholder={placeholder}
            modules={modules}
            formats={formats}
            className={`${styles.quill} ${hasError ? styles.hasError : ''} ${isInline ? styles.isInline : ''}`}
            ref={ref}
            preserveWhitespace
        />
    );
};

const RichTextInput = React.forwardRef(RichTextInputPlain);

export default RichTextInput;

type InputControllerProps<T extends FieldValues> = Omit<RichTextInputProps, 'value'> & UseControllerProps<T>;

export const RichInputController = <T extends FieldValues>(props: InputControllerProps<T>) => {
    const { field } = useController({ ...props, disabled: false });
    return (
        <RichTextInput
            {...props}
            value={field.value ?? ''}
            onChange={(e) => { // use onBlur instead due to safari issues
                field.onChange(e);
                if (props.onChange) {
                    props.onChange(e);
                }
            }}
        />
    );
};
