import { ParameterProductLine } from 'api/elements/Models/ElementDto';
import { createContext } from 'react';

interface ElementParameterComponentDataProviderModel {
    selectedSectionKey: string;
    lines: ParameterProductLine[];
    hasSubmited: boolean;
    productsFamilyId: string | undefined;
    required: boolean;
}

const defaultValue: ElementParameterComponentDataProviderModel = {
    selectedSectionKey: '',
    lines: [],
    hasSubmited: false,
    productsFamilyId: undefined,
    required: false
}

export const ElementParameterComponentDataProvider = createContext(defaultValue);
