import { useState, useEffect } from 'react';
import { QueryParameterNames, ApplicationPaths, LoginActions } from '../../Config';
import authService, { AuthenticationResultStatus } from '../../common/services/AuthorizeService';

import { useDispatch } from 'react-redux';
import { authenticateUser } from '../../store/authentication/action';
import AccountService from '../../api/account/AccountService';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
    action: string
}

interface LoginState {
    message?: string
}

const LoginScreen = ({ action }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loginMessage, setLoginMessage] = useState<LoginState>({});
    useEffect(() => {
        switch (action) {
            case LoginActions.Login:
                void login(getReturnUrl(null));
                break;
            case LoginActions.LoginCallback:
                void processLoginCallback();
                break;
            case LoginActions.LoginFailed:
                onLoginFailed();
                break;
            case LoginActions.Profile:
                redirectToProfile();
                break;
            case LoginActions.Register:
                redirectToRegister();
                break;
            case LoginActions.Reset:
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }, []);

    const onLoginFailed = () => {
        const params = new URLSearchParams(window.location.search);
        const error = params.get(QueryParameterNames.Message);
        if (error != null) {
            setLoginMessage({ message: error });
        }
    };

    const login = async (returnUrl: any) => {
        const state = { returnUrl };
        const result: any = await authService.signIn(state);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                break;
            case AuthenticationResultStatus.Success:
                navigateToReturnUrl(returnUrl);
                break;
            case AuthenticationResultStatus.Fail:
                setLoginMessage({ message: result.message });
                break;
            default:
                throw new Error(`Invalid status result ${result.status}.`);
        }
    };

    const processLoginCallback = async () => {
        const url = window.location.href;
        const result: any = await authService.completeSignIn(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success: {
                const token = { token: result?.user.access_token, tokenType: result?.user.token_type, expires: 0 };
                const emptyProfile = { id: '', userName: '', realName: '', email: '', policies: [], roles: [] };
                dispatch(authenticateUser(emptyProfile, token) as any);
                const profile = await AccountService.profile();
                dispatch(authenticateUser(profile, token) as any);
                navigateToReturnUrl(getReturnUrl(null));
            }
                break;
            case AuthenticationResultStatus.Fail:
                setLoginMessage({ message: result.message });
                break;
            default:
                throw new Error(`Invalid authentication result status '${result.status}'.`);
        }
    };

    const getReturnUrl = (state: any) => {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery != null && !fromQuery.startsWith(`${window.location.origin}/`)) {
            throw new Error('Invalid return url. The return url needs to have the same origin as the current page.' + fromQuery);
        }
        return state?.returnUrl ?? fromQuery ?? `${window.location.origin}/`;
    };

    const redirectToRegister = () => { redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`); };

    const redirectToProfile = () => { redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath); };

    const redirectToApiAuthorizationPath = (apiAuthorizationPath: any) => { window.location.replace(`${window.location.origin}/${apiAuthorizationPath}`); };

    const navigateToReturnUrl = (returnUrl: any) => window.location.replace(returnUrl);

    if (loginMessage && loginMessage.message) {
        return (
            <div>
                <span>{t('common.errors.access_denied')}</span><br></br>
                <Link to={ApplicationPaths.LogOut}>{t('common.logout')}</Link>
            </div>
        );
    }

    return (
        <div></div>
    );
};
export default LoginScreen;
