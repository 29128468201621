import { LinesDataProvider } from '../LinesDataProvider';
import { DetailedHTMLProps, HTMLAttributes, useContext, useRef } from 'react';
import { isColumnVisible, LinesTableColumn } from '../utils';

type Props = { column: LinesTableColumn } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

function BudgetLineColumn({ column, ...props }: Props): JSX.Element | null {
    const { hiddenColumns } = useContext(LinesDataProvider);
    const ref = useRef<HTMLDivElement>(null);
    // const { addRef, removeRef } = useRefContext();

    // const updateWidth = useCallback(() => {
    //     if (ref.current) {
    //         addRef(column, ref.current);
    //     }
    // }, [column, addRef]);

    // useEffect(() => {
    //     if (ref.current) {
    //         const observer = new ResizeObserver(() => {
    //             updateWidth();
    //         });

    //         observer.observe(ref.current);
    //         updateWidth();

    //         return () => {
    //             observer.disconnect();
    //             if (ref.current) {
    //                 removeRef(column, ref.current);
    //             }
    //         };
    //     }
    // }, [updateWidth, column, removeRef]);

    if (!isColumnVisible(hiddenColumns, column)) {
        return null;
    }

    // style={{ width: `var(--max-width-${column})` }}

    return (
        <div {...props} ref={ref} />
    );
}

export default BudgetLineColumn;
