import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import { Action, ADD_LINE, AddLinePlacement, BudgetState, CALCULATE_ALL_LINES_TOTALS, CALCULATE_BUDGET_TOTALS, CALCULATE_LINE_TOTALS, CLONE_LINE, COPY_LINE, CopyType, CUT_LINE, MOVE_LINE_DOWN, MOVE_LINE_UP, PASTE_LINE, REMOVE_LINE, REMOVE_LINE_CHILDREN, SET_BUDGET, SET_LINES, SET_OPEN_LINES_INDEXES, SET_SELECTED_LINE_KEY, UPDATE_ALL_LINES, UPDATE_BUDGET, UPDATE_LINE, UPDATE_LINE_PARAMETER, UPDATE_LINE_QUANTITY, UPDATE_LINES_NUMBERS } from './type';
import { AtLeast } from 'common/types/Atleast';
import { BudgetLineType } from 'api/budgets/enums/BudgetLineType';
import Utils from 'common/services/Utils';
import MathUtils from 'common/services/MathUtils';
import { BudgetLineParameterDto, BudgetLineParameterElementDto } from 'api/budgets/models/BudgetLineParameterDto';
import { ParameterSectionParameterType } from 'common/components/parametersSections/models';

const initialState: BudgetState = {
    selectedLineKey: null,
    copiedLineKey: null,
    copyType: null,
    lines: [],
    tempLines: [],
    budget: {
        totalNetExcludingTax: 0,
        totalTax: 0,
        totalIncludingTax: 0,
        totalExcludingTax: 0,
        discount: 0,
        discountTaxId: null,
        decimalPlaces: 0
    },
    openLinesIndexes: []
};

function rootReducer(state: BudgetState = initialState, action: Action): BudgetState {
    switch (action.type) {
        case SET_SELECTED_LINE_KEY:
            return {
                ...state,
                selectedLineKey: action.selectedLineKey || null,
            };

        case SET_LINES:
            return {
                ...state,
                lines: action.lines || [],
            };

        case SET_BUDGET:
            return {
                ...state,
                budget: action.budget,
            };

        case UPDATE_LINE:
            return {
                ...state,
                lines: updateLine(state.lines, action.line),
            };

        case ADD_LINE:
            return {
                ...state,
                lines: addLine(state.lines, action.lineKey, action.placement, action.lineType, action.newLineKey),
            };

        case REMOVE_LINE:
            return {
                ...state,
                lines: removeLine(state.lines, action.lineKey),
            };

        case REMOVE_LINE_CHILDREN:
            return {
                ...state,
                lines: removeLineChildren(state.lines, action.parentLineKey),
            };

        case CLONE_LINE:
            return {
                ...state,
                lines: cloneLine(state.lines, action.lineKey),
            };

        case COPY_LINE:
            return {
                ...state,
                copiedLineKey: action.lineKey,
                copyType: action.lineKey ? 'copy' : null,
                tempLines: action.lineKey ? [...state.lines] : [],
            };

        case CUT_LINE:
            return {
                ...state,
                copiedLineKey: action.lineKey,
                copyType: action.lineKey ? 'cut' : null,
                tempLines: action.lineKey ? [...state.lines] : [],
            };

        case PASTE_LINE:
            return {
                ...state,
                lines: pasteLine(state.lines, state.tempLines, action.lineKey, state.copiedLineKey, action.newLineKey, state.copyType),
                copiedLineKey: state.copyType === 'cut' ? null : state.copiedLineKey,
                copyType: state.copyType === 'cut' ? null : state.copyType,
            };

        case CALCULATE_LINE_TOTALS:
            return {
                ...state,
                lines: calculateLineTotals(state.lines, action.lineKey, state.budget.decimalPlaces),
            };
        case CALCULATE_ALL_LINES_TOTALS:
            return {
                ...state,
                lines: calculateAllLinesTotals(state.lines, state.budget.decimalPlaces),
            };
        case CALCULATE_BUDGET_TOTALS:
            return {
                ...state,
                budget: calculateBudgetTotals(state, state.budget.decimalPlaces),
            };
        case UPDATE_BUDGET:
            return {
                ...state,
                budget: {
                    ...state.budget,
                    ...action.budget,
                }
            };
        case UPDATE_LINE_QUANTITY:
            return {
                ...state,
                lines: updateLineQuantity(state.lines, action.lineKey),
            };
        case UPDATE_ALL_LINES:
            return {
                ...state,
                lines: updateAllLines(state.lines, action.line),
            };
        case UPDATE_LINE_PARAMETER:
            return {
                ...state,
                lines: updateLineParameter(state.lines, action.lineKey, action.parameter),
            };
        case UPDATE_LINES_NUMBERS:
            return {
                ...state,
                lines: updateLinesNumbers(state.lines),
            };
        case MOVE_LINE_UP:
            return {
                ...state,
                lines: moveLine(state.lines, action.lineKey, 'up'),
            };
        case MOVE_LINE_DOWN:
            return {
                ...state,
                lines: moveLine(state.lines, action.lineKey, 'down'),
            };
        case SET_OPEN_LINES_INDEXES:
            return {
                ...state,
                openLinesIndexes: action.openLinesIndexes,
            };
        default:
            return state;
    }
}

function updateLine(lines: BudgetLineDto[], updatedLine: AtLeast<BudgetLineDto, 'key'>): BudgetLineDto[] {
    return lines.map(line => {
        if (line.key === updatedLine.key) {
            return {
                ...line,
                ...updatedLine,
            };
        }
        return line;
    });
}

export function createLine(lineType: BudgetLineType, parentKey: string | null, key: string | null): BudgetLineDto {
    return {
        id: null,
        designation: '',
        number: '',
        quantity: 0,
        grossUnitPrice: 0,
        discountPercentage: 0,
        unitPrice: 0,
        unitPriceWithChildren: 0,
        totalExcludingTax: 0,
        totalUnitExcludingTax: 0,
        totalIncludingTax: 0,
        totalTax: 0,
        marginPercentage: 0,
        taxValue: 0,
        lineType,
        key: key ?? Utils.newGuid(),
        parentKey,
        isOpen: true,
        parameters: [],
        level: 0,
        changedParameters: false,
        checkedForParameters: false,
        pdfVisibility: null
    };
}

function addLine(
    lines: BudgetLineDto[],
    lineKey: string | null,
    placement: AddLinePlacement,
    lineType: BudgetLineType,
    newLineKey: string | null
): BudgetLineDto[] {
    let newLines = [...lines];

    // Add to the same level
    if (placement === AddLinePlacement.SAME) {
        const index = lines.findIndex(l => l.key === lineKey);
        if (index > -1) {
            const totalChildren = getTotalLinesInside(lines, lineKey);
            const newLine: BudgetLineDto = createLine(lineType, lines[index].parentKey, newLineKey);
            newLines = [
                ...newLines.slice(0, index + 1 + totalChildren),
                newLine,
                ...newLines.slice(index + 1 + totalChildren),
            ];
        }
    } else if (placement === AddLinePlacement.ROOT) { // Add to root
        const newLine: BudgetLineDto = createLine(lineType, null, newLineKey);
        newLines = [
            ...newLines,
            newLine,
        ];
    } else if (placement === AddLinePlacement.INSIDE) { // Add inside the line
        const index = lines.findIndex(l => l.key === lineKey);
        if (index > -1) {
            const totalChildren = getTotalLinesInside(lines, lineKey);
            const newLine: BudgetLineDto = createLine(lineType, lineKey, newLineKey);
            newLines = [
                ...newLines.slice(0, index + 1 + totalChildren),
                newLine,
                ...newLines.slice(index + 1 + totalChildren),
            ];
        }
    }

    return newLines;
}

function pasteLine(
    lines: BudgetLineDto[],
    tempLines: BudgetLineDto[],
    lineKey: string | null,
    copiedLineKey: string | null,
    newLinekey: string,
    copyType: CopyType
): BudgetLineDto[] {
    if (copiedLineKey && tempLines) {
        const parentLineIndex = lines.findIndex(l => l.key === lineKey);
        if (parentLineIndex > -1) {
            let linesResult = [...lines];

            const indexToCopy = tempLines.findIndex(l => l.key === copiedLineKey);
            if (indexToCopy > -1) {
                const totalChildren = getTotalLinesInside(tempLines, lineKey)

                const clonedLine = {
                    ...cleanClonedLine(tempLines[indexToCopy]),
                    key: newLinekey,
                    parentKey: lineKey,
                    parentId: undefined,
                };

                const remainingLines = linesResult.slice(parentLineIndex + 1 + totalChildren);

                linesResult = [
                    ...linesResult.slice(0, parentLineIndex + 1 + totalChildren),
                    clonedLine,
                ];

                const cloneDescendants = (parentKey: string, newParentKey: string) => {
                    const descendants = tempLines.filter(line => line.parentKey === parentKey);
                    descendants.forEach(descendant => {
                        const newDescendant = {
                            ...cleanClonedLine(descendant),
                            parentKey: newParentKey,
                        };
                        linesResult.push(newDescendant);
                        cloneDescendants(descendant.key, newDescendant.key);
                    });
                };

                cloneDescendants(copiedLineKey, clonedLine.key);

                linesResult = [
                    ...linesResult,
                    ...remainingLines,
                ];
            }

            if (copyType === 'cut') {
                linesResult = removeLine(linesResult, copiedLineKey);
            }

            return linesResult;
        }
    }

    return lines;
}

function removeLine(stateLines: BudgetLineDto[], lineKey: string): BudgetLineDto[] {
    const removeDescendants = (lines: BudgetLineDto[], parentKey: string): BudgetLineDto[] => {
        const directChildren = lines.filter(line => line.parentKey === parentKey);

        directChildren.forEach(child => {
            lines = removeDescendants(lines, child.key);
        });

        return lines.filter(line => line.parentKey !== parentKey);
    };

    let updatedLines = stateLines.filter(line => line.key !== lineKey);
    updatedLines = removeDescendants(updatedLines, lineKey);

    return updatedLines;
}

function removeLineChildren(stateLines: BudgetLineDto[], parentLineKey: string): BudgetLineDto[] {
    const removeDescendants = (lines: BudgetLineDto[], parentKey: string): BudgetLineDto[] => {
        const directChildren = lines.filter(line => line.parentKey === parentKey);

        directChildren.forEach(child => {
            lines = removeDescendants(lines, child.key);
        });

        return lines.filter(line => line.parentKey !== parentKey);
    };

    let updatedLines = stateLines.filter(line => line.parentKey !== parentLineKey);
    const deletedLines = stateLines.filter(line => line.parentKey === parentLineKey);

    deletedLines.forEach(line => {
        updatedLines = removeDescendants(updatedLines, line.key);
    });

    return updatedLines;
}

function getTotalLinesInside(lines: BudgetLineDto[], lineKey: string | null) {
    const children = lines.filter(x => x.parentKey === lineKey);

    let totalCount = children.length;

    for (const child of children) {
        totalCount += getTotalLinesInside(lines, child.key);
    }

    return totalCount;
}

function cloneLine(lines: BudgetLineDto[], lineKey: string): BudgetLineDto[] {
    let linesResult = [...lines];

    const index = lines.findIndex(l => l.key === lineKey);
    if (index > -1) {
        const totalChildren = getTotalLinesInside(lines, lineKey)

        const clonedLine = cleanClonedLine(linesResult[index]);

        const remainingLines = linesResult.slice(index + 1 + totalChildren);

        linesResult = [
            ...linesResult.slice(0, index + 1 + totalChildren),
            clonedLine,
        ];

        const cloneDescendants = (parentKey: string, newParentKey: string) => {
            const descendants = lines.filter(line => line.parentKey === parentKey);
            descendants.forEach(descendant => {
                const newDescendant = {
                    ...cleanClonedLine(descendant),
                    parentKey: newParentKey,
                };
                linesResult.push(newDescendant);
                cloneDescendants(descendant.key, newDescendant.key);
            });
        };

        cloneDescendants(lineKey, clonedLine.key);

        linesResult = [
            ...linesResult,
            ...remainingLines,
        ];
    }

    return linesResult;
}

function cleanClonedLine(line: BudgetLineDto): BudgetLineDto {
    return {
        ...line,
        id: null,
        key: Utils.newGuid(),
    }
}

export function createLineNumber(levelName: string, i: number) {
    return levelName ? `${levelName}.${i.toString()}` : i.toString();
}

function replaceLinesByKey(items: BudgetLineDto[], newItems: BudgetLineDto[]): BudgetLineDto[] {
    const newItemMap = new Map<string, BudgetLineDto>(newItems.map(item => [item.key, item]));

    return items.map(item =>
        newItemMap.has(item.key) ? newItemMap.get(item.key)! : item
    );
}

function calculateAllLinesTotals(lines: BudgetLineDto[], decimalPlaces: number): BudgetLineDto[] {
    let resultLines = [...lines];
    const parents = resultLines.filter(x => x.parentKey === null);

    for (const line of parents) {
        const updatedLines = calculateLineTotalsValues(line, resultLines, decimalPlaces);
        resultLines = replaceLinesByKey(resultLines, updatedLines);
    }

    return resultLines;
}

function calculateLineTotals(lines: BudgetLineDto[], lineKey: string, decimalPlaces: number): BudgetLineDto[] {
    const line = findLineByKey(lineKey, lines);
    if (!line) {
        return lines;
    }

    const rootParent = findRootParent(line, lines);
    if (!rootParent) {
        return lines;
    }

    const linesToUpdate = calculateLineTotalsValues(rootParent, lines, decimalPlaces);

    return replaceLinesByKey(lines, linesToUpdate);
}

function calculateConfigurationProduct(p: BudgetLineParameterElementDto) {
    const productChildren = (p.parameters ?? []).map(calculateConfigurationParameters);

    const childrenTotal = productChildren.reduce((sum, child) => MathUtils.plus(sum, child), 0)
    return MathUtils.plus(MathUtils.multiply(childrenTotal ?? 0, p.quantity ?? 0), MathUtils.multiply(p.price ?? 0, p.quantity ?? 0));
}

function calculateConfigurationParameters(parameter: BudgetLineParameterDto): number {
    const children = (parameter.children ?? []).map(calculateConfigurationParameters);

    let values: number[] = [];

    switch (parameter.parameterType) {
        case ParameterSectionParameterType.PRODUCTS:
            values = (parameter.elements ?? []).filter(p => p.checked).map(calculateConfigurationProduct);
            break;
        case ParameterSectionParameterType.NUMBER_MIN_MAX:
            values = (parameter.minMax?.elements ?? []).filter(p => p.checked).map(calculateConfigurationProduct);
            break;

        default:
            break;
    }

    return MathUtils.plus(...children, ...values);
}

function calculateLineTotalsValues(lineToCalculate: BudgetLineDto, allLines: BudgetLineDto[], decimalPlaces: number): BudgetLineDto[] {
    const updatedLinesMap = new Map<string, BudgetLineDto>();

    const updateLineAndChildren = (currentLine: BudgetLineDto): BudgetLineDto => {
        // Find all children of the current line
        const findChildren = (parentKey: string): BudgetLineDto[] => {
            return allLines.filter(child => child.parentKey === parentKey).map(updateLineAndChildren);
        };

        // Update children
        const children = findChildren(currentLine.key);

        // Filter children to include only ELEMENT,OUVRAGE and SECTION types
        const childrenTotals = children.filter(l => l.lineType === BudgetLineType.ELEMENT || l.lineType === BudgetLineType.COMPONENT_ELEMENT || l.lineType === BudgetLineType.SECTION);

        // Calculate various totals and values
        const marginValue = MathUtils.round((
            currentLine.lineType === BudgetLineType.SECTION
                ? 0
                : MathUtils.multiply(currentLine.grossUnitPrice, MathUtils.divide(currentLine.marginPercentage, 100))
        ), decimalPlaces);

        const discountValue = MathUtils.round((
            currentLine.lineType === BudgetLineType.SECTION
                ? 0
                : MathUtils.multiply(currentLine.grossUnitPrice, MathUtils.divide(currentLine.discountPercentage, 100)) * -1
        ), decimalPlaces);

        const configurationProductsSum = MathUtils.plus(...currentLine.parameters.map(calculateConfigurationParameters));

        const unitPrice = (
            currentLine.lineType === BudgetLineType.SECTION
                ? 0
                : MathUtils.plus(currentLine.grossUnitPrice, marginValue, discountValue, configurationProductsSum)
        );

        const unitPriceWithChildren = (
            childrenTotals.reduce(
                (sum, child) => MathUtils.plus(sum, child.totalExcludingTax),
                unitPrice
            )
        );

        const totalUnitExcludingTax = (
            currentLine.lineType === BudgetLineType.SECTION
                ? childrenTotals.reduce((sum, child) => MathUtils.plus(sum, child.totalUnitExcludingTax), 0)
                : MathUtils.round(MathUtils.multiply(currentLine.quantity, unitPrice), decimalPlaces)
        );

        const totalExcludingTax = (
            currentLine.lineType === BudgetLineType.SECTION
                ? childrenTotals.reduce((sum, child) => MathUtils.plus(sum, child.totalExcludingTax), 0)
                : MathUtils.round(MathUtils.multiply(currentLine.quantity, unitPriceWithChildren), decimalPlaces)
        );

        const totalTax = currentLine.lineType === BudgetLineType.SECTION
            ? childrenTotals.reduce((sum, child) => MathUtils.plus(sum, child.totalTax), 0)
            : childrenTotals.reduce((sum, child) => MathUtils.plus(sum, child.totalTax * child.quantity), MathUtils.round(MathUtils.multiply(totalUnitExcludingTax, MathUtils.divide(currentLine.taxValue, 100)), decimalPlaces));

        const lineTotalIncludingTax = MathUtils.round((
            MathUtils.multiply(totalUnitExcludingTax, MathUtils.plus(1, MathUtils.divide(currentLine.taxValue, 100)))
        ), decimalPlaces);

        const totalIncludingTax = (
            currentLine.lineType === BudgetLineType.SECTION
                ? childrenTotals.reduce((sum, child) => MathUtils.plus(sum, child.totalIncludingTax), 0)
                : currentLine.quantity > 0
                    ? MathUtils.plus(
                        MathUtils.multiply(
                            childrenTotals.reduce((sum, child) => MathUtils.plus(sum, child.totalIncludingTax), 0),
                            currentLine.quantity
                        ),
                        lineTotalIncludingTax
                    )
                    : 0
        );

        // Create updated line
        const updatedLine = {
            ...currentLine,
            unitPrice,
            unitPriceWithChildren,
            totalUnitExcludingTax,
            totalExcludingTax,
            totalIncludingTax,
            totalTax,
        };

        // Store the updated line in the map
        updatedLinesMap.set(updatedLine.key, updatedLine);

        return updatedLine;
    };

    // Start the update from the specific line
    updateLineAndChildren(lineToCalculate);

    // Return updated lines
    const updatedLines = allLines.map(line => {
        return updatedLinesMap.get(line.key) || null;
    }).filter(x => x !== null);

    return updatedLines as BudgetLineDto[];
}

export function findLineByKey(key: string, lines: BudgetLineDto[]): BudgetLineDto | null {
    for (const l of lines) {
        if (l.key === key) {
            return l;
        }
    }
    return null;
}

function findRootParent(currentLine: BudgetLineDto, lines: BudgetLineDto[]): BudgetLineDto | null {
    if (!currentLine.parentKey) {
        return currentLine;
    }

    const parentLine = findLineByKey(currentLine.parentKey, lines);
    if (!parentLine) {
        return null;
    }

    return findRootParent(parentLine, lines);
}

function calculateBudgetTotals(state: BudgetState, decimalPlaces: number) {
    let totalNetExcludingTax = 0;
    let totalIncludingTax = 0;
    let totalExcludingTax = 0;
    let totalTax = 0;

    const lineParents = state.lines.filter(x => x.parentKey === null);
    lineParents.forEach(line => {
        totalNetExcludingTax = MathUtils.plus(totalNetExcludingTax, line.totalExcludingTax);
        totalTax = MathUtils.plus(totalTax, line.totalTax);
    });

    totalExcludingTax = MathUtils.minus(totalNetExcludingTax, state.budget.discount ?? 0);
    const discountTaxValue = MathUtils.round(MathUtils.multiply((state.budget.discount ?? 0), MathUtils.divide(state.budget.discountTaxValue ?? 0, 100)), decimalPlaces)
    totalTax = MathUtils.minus(totalTax, discountTaxValue ?? 0);
    totalIncludingTax = MathUtils.plus(totalExcludingTax, totalTax);

    return {
        ...state.budget,
        totalNetExcludingTax,
        totalExcludingTax,
        totalIncludingTax,
        totalTax,
    }
}

function getTotalParamQuantity(parameter: BudgetLineParameterDto): number {
    if (parameter.parameterType === ParameterSectionParameterType.QUANTITY) {
        return parameter.quantity?.quantity ?? 0;
    }
    return MathUtils.plus(...(parameter.children ?? []).map(getTotalParamQuantity));
}

function updateLineQuantity(lines: BudgetLineDto[], lineKey: string) {
    const line = findLineByKey(lineKey, lines);
    if (!line) {
        return lines;
    }

    const totalParamsQuantities = MathUtils.plus(...(line.parameters ?? []).map(getTotalParamQuantity));

    return updateLine(lines, { key: line.key, quantity: totalParamsQuantities });
}

function updateAllLines(lines: BudgetLineDto[], lineData: Partial<BudgetLineDto>) {
    return lines.map(line => {
        const newLine = {
            ...line,
            ...lineData,
        }
        return newLine;
    });
}

function updateLinesNumbers(stateLines: BudgetLineDto[]): BudgetLineDto[] {
    const lineMap: { [key: string]: BudgetLineDto } = {};

    stateLines.forEach(line => {
        lineMap[line.key] = { ...line, number: '', level: 0 };
    });

    function assignLineNumbers(lines: BudgetLineDto[], parentNumber: string, parentLevel: number): void {
        let levelIndex = 0;
        lines.forEach(line => {
            if (line.lineType === BudgetLineType.SECTION || line.lineType === BudgetLineType.ELEMENT || line.lineType === BudgetLineType.COMPONENT_ELEMENT) {
                levelIndex++;
            }

            lineMap[line.key].number = createLineNumber(parentNumber, levelIndex);
            lineMap[line.key].level = parentLevel;

            const children = stateLines.filter(childLine => childLine.parentKey === line.key);
            if (children.length > 0) {
                assignLineNumbers(children, lineMap[line.key].number, parentLevel + 1);
            }
        });
    }

    const parents = stateLines.filter(x => x.parentKey === null);
    assignLineNumbers(parents, '', 0);

    return stateLines.map(line => lineMap[line.key]);
}

function updateLineParameter(lines: BudgetLineDto[], lineKey: string, updatedParameter: AtLeast<BudgetLineParameterDto, 'key'>) {
    const line = findLineByKey(lineKey, lines);
    if (!line) {
        return lines;
    }

    const parameters = updateLineParameters(line.parameters, updatedParameter);

    return updateLine(lines, { key: lineKey, parameters, changedParameters: true });
}

function updateLineParameters(parameters: BudgetLineParameterDto[], updatedParameter: AtLeast<BudgetLineParameterDto, 'key'>): BudgetLineParameterDto[] {
    return parameters.map(parameter => {
        if (parameter.key === updatedParameter.key) {
            return {
                ...parameter,
                ...updatedParameter,
            };
        }

        return {
            ...parameter,
            children: updateLineParameters(parameter.children, updatedParameter),
            elements: parameter.elements?.map(product => ({
                ...product,
                parameters: updateLineParameters(product.parameters ?? [], updatedParameter),
            })) ?? [],
            minMax: parameter.minMax
                ? {
                    ...parameter.minMax,
                    elements: parameter.minMax.elements?.map(product => ({
                        ...product,
                        parameters: updateLineParameters(product.parameters ?? [], updatedParameter),
                    })) ?? [],
                }
                : parameter.minMax,
        };
    });
}

function moveLine(lines: BudgetLineDto[], lineKey: string, direction: 'up' | 'down') {
    const lineIndex = lines.findIndex(x => x.key === lineKey);
    if (lineIndex === -1) {
        return lines;
    }

    let newIndex: number | null = lineIndex;

    if (direction === 'up') {
        if (lineIndex === 0) {
            return lines; // Already at the top
        }
        newIndex = getPreviousIndexWithSameParentKey(lines, lineIndex);
    } else if (direction === 'down') {
        if (lineIndex === lines.length - 1) {
            return lines; // Already at the bottom
        }
        newIndex = getNextIndexWithSameParentKey(lines, lineIndex);
    } else {
        return lines; // Invalid direction
    }

    if (newIndex === null) {
        return lines;
    }

    // const movedLines = arrayMove(lines, lineIndex, newIndex);
    // return movedLines;

    // Get the subtree that needs to be moved
    const subtree = getSubtreeToMove(lines, lineIndex);
    const newLines = lines.filter(line => !subtree.includes(line));

    // Calculate the new position for the subtree
    let targetIndex = newIndex;
    if (direction === 'down') {
        targetIndex += subtree.length;
    }

    // Insert the subtree at the new position
    newLines.splice(targetIndex, 0, ...subtree);

    return newLines;
}

function getSubtreeToMove(lines: BudgetLineDto[], startIndex: number): BudgetLineDto[] {
    const startKey = lines[startIndex].key;
    const subtree = [lines[startIndex]];

    for (let i = startIndex + 1; i < lines.length; i++) {
        if (lines[i].parentKey === startKey) {
            subtree.push(...getSubtreeToMove(lines, i));
        }
    }

    return subtree;
}

export function getNextIndexWithSameParentKey(lines: BudgetLineDto[], currentIndex: number): number | null {
    if (currentIndex < 0 || currentIndex >= lines.length) {
        return null;
    }

    const currentParentKey = lines[currentIndex].parentKey;

    for (let i = currentIndex + 1; i < lines.length; i++) {
        if (lines[i].parentKey === currentParentKey) {
            return i;
        }
    }

    return null;
}

export function getPreviousIndexWithSameParentKey(array: BudgetLineDto[], currentIndex: number): number | null {
    if (currentIndex < 0 || currentIndex >= array.length) {
        return null;
    }

    const currentParentKey = array[currentIndex].parentKey;

    for (let i = currentIndex - 1; i >= 0; i--) {
        if (array[i].parentKey === currentParentKey) {
            return i;
        }
    }

    return null;
}

export function canMoveUp(lines: BudgetLineDto[], lineKey: string | null): boolean {
    if (lineKey === null) {
        return false;
    }

    const lineIndex = lines.findIndex(x => x.key === lineKey);
    if (lineIndex <= -1) {
        return false;
    }

    const moveIndex = getPreviousIndexWithSameParentKey(lines, lineIndex);

    return moveIndex != null && moveIndex !== lineIndex;
}

export function canMoveDown(lines: BudgetLineDto[], lineKey: string | null): boolean {
    if (lineKey === null) {
        return false;
    }

    const lineIndex = lines.findIndex(x => x.key === lineKey);
    if (lineIndex <= -1) {
        return false;
    }

    const moveIndex = getNextIndexWithSameParentKey(lines, lineIndex);

    return moveIndex != null && moveIndex !== lineIndex;
}

export default rootReducer;
