import { Paged } from 'api/common/types/Page';
import Http from 'common/services/Http';
import IdVersion from 'api/common/types/IdVersion';
import { ElementDto, ElementHistoryDto, ElementListDto, ElementUpdatePricesDto } from './Models/ElementDto';
import { ElementsSearchCriteria } from './Models/ElementsSearchCriteria';
import { ElementTypeDto } from './Models/ElementTypeDto';
import CustomFile from 'common/models/CustomFile';

import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import { ElementSelectTitleSubTitleOptionDto } from 'common/components/configurableElementsLine/ConfigurableElementsLine';

class ElementsService {
    public getList(criteria: ElementsSearchCriteria) {
        return Http.get<Paged<ElementListDto>>('elements', criteria);
    }

    public getById(id: string, showOnlyFilleParameters?: boolean) {
        return Http.get<ElementDto>('elements/' + id + '/' + showOnlyFilleParameters);
    }

    public getElementTypesCatalog() {
        return Http.get<ElementTypeDto[]>('elements/types/catalog',);
    }

    public create(model: ElementDto, medias: CustomFile[]): Promise<string> {
        return Http.postWithFile('elements', model, medias);
    }

    public update(model: ElementDto, medias: CustomFile[]) {
        return Http.putWithFile('elements', model, medias);
    }

    public remove(model: IdVersion) {
        return Http.put('elements/deactivate', model);
    }

    public getElementsCatalogByFamilyId(criteria: ElementsSearchCriteria) {
        return Http.get<Paged<ElementSelectTitleSubTitleOptionDto>>('elements/elements-by-family', criteria);
    }

    public getElementHistory(elementId: string) {
        return Http.get<ElementHistoryDto[]>('elements/element-history/' + elementId);
    }

    public getAllByCriteria(criteria: Omit<ElementsSearchCriteria, keyof PagedSearchCriteria>) {
        return Http.get<ElementListDto[]>('elements/get-all-by-criteria', criteria);
    }

    public updatePrices(model: ElementUpdatePricesDto) {
        return Http.put('elements/update-prices/', model);
    }
}

export default new ElementsService();
