import React from 'react';
import { IconProps } from './types';
import { calculateAspectRatioFit } from 'common/utils/calculateAspectRatioFit';

export const Home: React.FC<IconProps> = ({ size = 22, ...rest }) => {
    const newSize = calculateAspectRatioFit(22, 21.959, size, size);
    return (
        <svg id="icon-home" xmlns="http://www.w3.org/2000/svg" width={newSize.width} height={newSize.height} viewBox="0 0 22 21.959">
            <defs>
                <clipPath id="clip-path-home">
                    <rect id="Retângulo_890" data-name="Retângulo 890" width={newSize.width} height={newSize.height} fill="#27449c"/>
                </clipPath>
            </defs>
            <g id="Grupo_457" data-name="Grupo 457" clipPath="url(#clip-path-home)">
                <path id="Caminho_1272" data-name="Caminho 1272" d="M20.848,12.673a1.731,1.731,0,0,0,1.107-1.286,2,2,0,0,0-.533-1.834C18.96,7.123,15.721,3.912,12.5.619A2.061,2.061,0,0,0,11.012,0h0A2.061,2.061,0,0,0,9.539.612c-1.6,1.652-3.258,3.3-4.861,4.892Q3.7,6.472,2.73,7.443q-.333.333-.667.664c-.5.5-1.013,1.007-1.51,1.517a1.775,1.775,0,0,0-.471,1.828A1.731,1.731,0,0,0,1.5,12.758c.152.031.309.049.475.068.081.01.164.019.248.031l.182.025v.576c0,.618,0,1.235,0,1.854,0,1.428.009,2.9-.012,4.357a2.29,2.29,0,0,0,.684,1.658,2.158,2.158,0,0,0,1.591.632c.745-.025,1.5-.018,2.233-.012l1,0c.66,0,.744-.081.744-.73q0-.754,0-1.507c0-1.127,0-2.292,0-3.439a1.289,1.289,0,0,1,1.353-1.35c.837,0,1.476,0,2.074,0a1.229,1.229,0,0,1,1.195.819,1.79,1.79,0,0,1,.089.66q0,1.37,0,2.74,0,1.1,0,2.206c0,.524.251.6.494.6l.508,0c1.052,0,2.139.009,3.206-.008A2.132,2.132,0,0,0,19.6,19.885c.011-1.5.01-3.031.009-4.51q0-.936,0-1.872v-.587l.17-.032c.133-.026.262-.046.386-.064a3.574,3.574,0,0,0,.68-.147m-.9-.767c-.179-.008-.364,0-.543,0l-.111,0c-.412,0-.567.159-.568.565,0,.97,0,1.957,0,2.911v1.156q0,.464,0,.928c0,.7,0,1.416,0,2.124a1.348,1.348,0,0,1-1.487,1.486c-.354,0-.7,0-1.058,0H14.306l-.009-.2c0-.049,0-.095-.007-.138-.006-.092-.011-.179-.011-.268V18.894q0-1.262,0-2.526a2.212,2.212,0,0,0-2.36-2.351h-.032l-.42,0a17.17,17.17,0,0,0-1.873.059,2.131,2.131,0,0,0-1.845,2.11c-.007.987-.006,1.991,0,2.963q0,.639,0,1.279v.637h-.21c-.311,0-.617,0-.921.006-.725.007-1.41.014-2.108-.018A1.235,1.235,0,0,1,3.32,19.694q0-2.466,0-4.933V12.681c0-.667-.11-.776-.79-.776H2.342c-.135,0-.271,0-.407,0a.964.964,0,0,1-.691-1.7Q2.855,8.6,4.463,6.99L6.779,4.674,10.2,1.259a.987.987,0,0,1,1.638-.006l3.236,3.235q2.821,2.82,5.643,5.64a1.007,1.007,0,0,1,.338,1.192,1.021,1.021,0,0,1-1.1.586" transform="translate(0 0)" fill="#27449c"/>
            </g>
        </svg>
    );
}
