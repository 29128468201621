import { UserProfile } from 'api/account/models/UserProfile';
import { BudgetPDFVisibility, ElementDto, ElementHistoryDto, ElementParameterDto, FamilySelectModel } from 'api/elements/Models/ElementDto';
import Button from 'common/components/button/Button';
import InputError from 'common/components/inputError/InputError';
import { MoneyInputController } from 'common/components/moneyInput/MoneyInput';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { SelectInputController, SelectInputOption } from 'common/components/selectInput/SelectInput';
import { TextInputController } from 'common/components/textInput/TextInput';
import { Label, Text } from 'common/components/texts/Texts';
import { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt, FaHistory } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Reducers } from 'store/types';
import Loading from 'common/services/Loading';
import toast from 'react-hot-toast';
import { LOGGER_LOG_TYPE, DATE_TIME_FORMAT_DEFAULT } from 'Config';
import Logger from 'common/services/Logger';
import ElementsService from 'api/elements/ElementsService';
import UnitsService from 'api/units/UnitsService';
import TaxesService from 'api/taxes/TaxesService';
import { UnitDto } from 'api/units/models/UnitDto';
import { TaxDto } from 'api/taxes/models/TaxDto';
import WorkTypesService from 'api/workTypes/WorkTypesService';
import { RichInputController } from 'common/components/richTextInput/RichTextInput';
import FamiliesService from 'api/families/FamiliesService';
import { ElementTypeDto } from 'api/elements/Models/ElementTypeDto';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import { FileInputController } from 'common/components/fileInput/FileInput';
import FilesUploader from 'common/components/filesUploader/FilesUploader';
import { AttachmentDto } from 'api/common/models/AttachmentDto';
import CustomFile from 'common/models/CustomFile';
import { FamilyDto } from 'api/families/models/FamilyDto';
import Utils from 'common/services/Utils';
import styles from './ElementScreen.module.scss'
import UsersService from 'api/users/UsersService';
import Toast from 'common/services/Toast';
import { MultiLineDynamicListController } from 'common/components/multiLineDynamicList/MultiLineDynamicList';
import FamilySelector, { FamilySelectInputOption } from 'common/components/familySelector/FamilySelector';
import { ParameterSectionParameterType, ParameterSectionType } from 'common/components/parametersSections/models';
import { Form, FormRef } from 'common/components/form/Form';
import YesNoModal from 'common/components/modal/yesNoModal/YesNoModal';
import InformationModal from 'common/components/modal/informationModal/InformationModal';
import InfoIcon from 'common/components/infoIcon/InfoIcon';
import HistoryModal from 'common/components/modal/historyModal/HistoryModal';
import DateFormat from 'common/components/dateFormat/dateFormat';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import { SortableTree } from 'common/components/sortableTree/SortableTree';
import { ElementParameterDataProvider } from './ElementParameterDataProvider';
import ElementParameterLine from './ElementParameterLine';
import { FlattenedItem } from 'common/components/sortableTree/types';
import { AtLeast } from 'common/types/Atleast';
import { onMove } from '../utils';
import ListingTableCache, { DEFAULT_TABLE_DATA } from 'common/components/listingTable/ListingTableCache';
import { createPageId } from '../list/ElementsListScreen';
import { FamiliesListType } from 'screens/library/families/list/FamiliesListScreen';

interface Props {
    listType: FamiliesListType;
}

function ElementScreen({ listType }: Props): JSX.Element | null {
    const PAGE_ID = createPageId(listType)
    const listTypeLabel = listType === 'elements' ? 'element' : 'ouvrage';
    const isElement = listType === 'elements';
    const isComponent = listType === 'components'
    const { id, type } = useParams<{ id: string, type: 'create' | 'details' | 'edit' | 'duplicate' }>();
    const { t } = useTranslation()
    const navigate = useNavigate();

    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmParametersModal, setShowConfirmParametersModal] = useState(false);
    const [showConfirmDeleteFamilyModal, setShowConfirmDeleteFamilyModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [units, setUnits] = useState<UnitDto[]>([]);
    const [taxes, setTaxes] = useState<TaxDto[]>([]);
    const [workTypes, setWorkTypes] = useState<SelectInputOption[]>([]);
    const [elementTypes, setElementTypes] = useState<ElementTypeDto[]>([]);
    const [families, setFamilies] = useState<FamilyDto[]>([]);
    const [priceTagLabel, setPriceTagLabel] = useState<string>('€')
    const [medias, setMedias] = useState<AttachmentDto[]>([]);
    const [file, setFile] = useState<AttachmentDto | null>();

    const [parameters, setParameters] = useState<ElementParameterDto[]>([])
    const [familyItemChanged, setFamilyItemChanged] = useState<FamilySelectModel | null>(null);
    const [newFamilies, setNewFamilies] = useState<FamilySelectModel[]>([])
    const [elementHistory, setElementHistory] = useState<ElementHistoryDto[]>([]);

    const totalPrice = useRef(0)
    const [showTotalPriceInformationModal, setShowTotalPriceInformationModal] = useState(false);
    const form = useForm<ElementDto>({ shouldUnregister: false, shouldFocusError: true });
    const errors = form.formState.errors;
    const formRef = useRef<FormRef>(null);

    // const [familyTypeSelected, setFamilyTypeSelected] = useState< 'both' | 'element' | 'component' | null>(null);

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['ELEMENTS_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['ELEMENTS_READ'])

    const title = (type === 'create' ? t('common.new') : (type === 'edit' ? t('common.edit') : t('common.details'))) + ' ' + t(`elements.${listTypeLabel}`);
    const url = `/library/${listType}-items`;
    const currentUrl = url + '/' + type + (id ? ('/' + id) : '');

    const [refreshKey, setRefreshKey] = useState(0);
    const [totalRefreshKey, setTotalRefreshKey] = useState(0);

    const [selectedSectionKey, setSelectedSectionKey] = useState<string>('');

    useEffect(() => {
        void getData();
    }, [id, type]);

    const getData = async () => {
        try {
            Loading.show();
            let result: Partial<ElementDto> = {
                id,
                name: '',
                families: [{ id: Utils.newGuid(), familyId: null, subFamilyId: null , isComponent: false,isElement: false }],
                imagesToRemove: []
            }
            const familyIds: string[] = []
            if (id) {
                result = await ElementsService.getById(id, isDetails);

                setMedias(result.images ?? [])
                setFile(result.attachment)

                if (result?.parameters?.length) {
                    result.parameters.forEach((x) => {
                        x.isOpen = true;
                        if (x.parameterType === ParameterSectionParameterType.QUANTITY) {
                            x.unitSymbol = units.find(x => x.id === result.unitId)?.symbol
                        }
                    })
                    setParameters(result.parameters);
                }

                if (result.families?.length) {
                    result.families.forEach((x) => {
                        if (x.familyId) familyIds.push(x.familyId)
                        if (x.subFamilyId) familyIds.push(x.subFamilyId)
                    })
                }
                if (result && type === 'duplicate') {
                    result.name += ' - ' + t('common.copy');
                }

                void getElementHistories(id);
            }

            const [
                unitsData,
                taxesData,
                workTypesData,
                familiesData,
                elementTypesData
            ] = await Promise.all([
                UnitsService.getFormattedCatalog(),
                TaxesService.getFormattedCatalog(),
                WorkTypesService.getCatalog({ workTypeId: result.workTypeId }),
                FamiliesService.getElementsCatalog({ familyIds,isComponent,isElement }),
                ElementsService.getElementTypesCatalog(),
            ]);

            setUnits(unitsData)
            setTaxes(taxesData)
            setWorkTypes(workTypesData)
            setElementTypes(elementTypesData)
            setFamilies(familiesData)

            const tag = unitsData.find(y => y.id === result.unitId)?.symbol
            setPriceTagLabel(x => '€' + (tag ? `/${tag}` : ''));
            form.reset(result);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get elements', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const getElementHistories = async (elementId: string) => {
        try {
            Loading.show();

            const elementHistoryData = await ElementsService.getElementHistory(elementId);
            setElementHistory(elementHistoryData);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get element history', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const isInvalidFamily = (family: FamilySelectModel, families: FamilySelectModel[]) => {
        return families.filter(x => x.familyId === family.familyId && x.subFamilyId === family.subFamilyId).length > 1;
    }

    const hasInvalidFamilies = (families: FamilySelectModel[]) => {
        for (const family of families) {
            if (isInvalidFamily(family, families)) {
                return true;
            }
        }
        return false;
    }

    let mediasToSend: CustomFile[] = []
    const onSubmit = async (model: ElementDto) => {
        try {
            Loading.show();
            model.imagesToRemove = [];
            model.imagesIds = [];
            model.attachmentsToCopy = [];

            const allMedias = file ? medias.concat(file) : medias;

            allMedias.forEach((element) => {
                if (element.remove && element.id) {
                    model.imagesToRemove.push(element.id ?? '')
                } else if (!element.remove) {
                    if (element.isMain) {
                        model.mainImageKey = element.key ?? element.attachmentId
                    }

                    if (element.tempFile) {
                        element.tempFile.containerName = element.key
                        mediasToSend.push(element.tempFile)
                        model.imagesIds?.push(element.key)
                    } else if (type === 'duplicate' && element.attachmentId) {
                        model.attachmentsToCopy?.push(element.attachmentId);
                    }
                }
            });

            if (model.mainImageKey == null && medias.length) {
                model.mainImageKey = medias[0].key ?? medias[0].attachmentId;
            }
            if (file == null || file.id == null) {
                model.deleteAttachment = true;
            }

            const canSaveParameters = validateParameters(parameters, true)
            if (!canSaveParameters) {
                formRef.current?.onInvalid();
                Loading.hide();
                return;
            }

            if (hasInvalidFamilies(model.families ?? [])) {
                Toast.warning(t('elements.duplicated_families'));
                Loading.hide();
                return;
            }

            let paramsToSend: ElementParameterDto[] = [];
            paramsToSend = buildParametersObjectToSend(parameters, [], '', null)
            model.parameters = paramsToSend;
            model.families = model.families?.map(x => { return { ...x, key: x.id } })

            model.isComponent = isComponent;
            model.isElement = isElement;

            if (model && model.id && type !== 'duplicate') {
                await ElementsService.update(model, mediasToSend)
                // navigateTo('details', model.id);
                void getData();
            } else if (model) {
                const id = await ElementsService.create(model, mediasToSend)
                ListingTableCache.save(PAGE_ID, {
                    ...ListingTableCache.get(PAGE_ID, DEFAULT_TABLE_DATA),
                    order: { field: 'updatedDate', orderColumn: 'updated_date', isOrderAsc: false },
                    page: 1,
                    filters: {},
                    lastRowId: id,
                });
                navigateTo('edit', id);
            }
            mediasToSend = [];
            Loading.hide();
            Toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            if (error && error.response && error.response.status === 412 && error.response.data) {
                Toast.warning(t('elements.element_cycle_detected', { name: error.response.data.name }), 8000);
            } else {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the element with id: ${id}`, error);
                Toast.error(t('messages.record_save_error'));
            }

            Loading.hide();
        }
    };

    const validateParameters = (params: ElementParameterDto[], canSave: boolean) => {
        params.forEach(param => {
            if (param.type === ParameterSectionType.PARAMETER && param.parameterIsRequired && !param.visibleOnlyInBudgets) {
                switch (param.parameterType) {
                    case ParameterSectionParameterType.TEXT:
                        if (!param.text) { canSave = false; }
                        return;
                    case ParameterSectionParameterType.IMAGES:
                        if (!param.images || param.images.length === 0 || param.images.every(x => x.remove === true)) { canSave = false; }
                        return;
                    case ParameterSectionParameterType.NUMBER:
                        if (param.number === undefined || param.number === null) { canSave = false; }
                        return;
                    case ParameterSectionParameterType.QUANTITY:
                        if (param.quantity === undefined || param.quantity === null) { canSave = false; }
                        return;
                    case ParameterSectionParameterType.PRODUCTS:
                        if ((!param.products) || param.products.length === 0 || param.products.some(x => x.elementId === undefined || x.elementId === null)) { canSave = false; }
                        return;
                    case ParameterSectionParameterType.NUMBER_MIN_MAX:
                        if (!param.minMax || param.minMax.minValue === undefined || param.minMax.maxValue === undefined || param.minMax.unitId === undefined
                        ) { canSave = false; }
                }
            }
        });

        return canSave
    }

    const buildParametersObjectToSend = (params: ElementParameterDto[], objectToSent: ElementParameterDto[], familyRowId: string, isActive: boolean | null) => {
        let i = 0;
        params.forEach((param: ElementParameterDto) => {
            if (param.text === '<p> </p>' || param.text === '<p><br></p>') {
                param.text = null;
            }

            let images: AttachmentDto[] = [];
            if (param.images) {
                images = param.images?.filter(x => !x.remove)

                images.forEach((element) => {
                    if (element.tempFile && !element.remove) {
                        element.tempFile.containerName = element.key
                        mediasToSend.push(element.tempFile)
                    }
                });
            }

            objectToSent.push({
                ...param,
                elementFamilyKey: familyRowId || param.elementFamilyId,
                products: param.products?.filter(x => x.elementId || x.checked || x.unitId || x.quantity),
                active: isActive === null ? param.active : isActive,
                position: i
            })
            i++;
        })

        return objectToSent
    }

    const onInvalid = () => {
        Toast.warning(t('messages.required_fields_empty'));
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`${url}/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate(url);
        }
    }

    const onDelete = async (item: boolean) => {
        if (!item) {
            setShowConfirmDeleteModal(false)
            return;
        }
        try {
            Loading.show();

            await ElementsService.remove(form.getValues())
            navigateTo();

            Loading.hide();
            toast.success(t('messages.record_delete_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete element', error);
            toast.error(t('messages.record_delete_error'));
        }
        setShowConfirmDeleteModal(false)
    }

    const definePriceTagLabel = (x: any) => {
        const tag = units.find(y => y.id === x)?.symbol
        setPriceTagLabel(x => '€' + (tag ? `/${tag}` : ''))
        setRefreshKey(y => y + 1)
    }

    const getFamilyParameters = async (subFamilyId: string | null | undefined, familyId: string | null | undefined, elementFamilyId: string, keepPreviousParameters: boolean) => {
        if (!familyId && !subFamilyId && keepPreviousParameters) {
            return;
        }

        const temp: ElementParameterDto[] = []
        parameters.forEach(x => {
            if (x.elementFamilyId === elementFamilyId) {
                if (x.id) {
                    temp.push({ ...x, active: false })
                }
            } else {
                temp.push({ ...x })
            }
        })

        if (!familyId && !subFamilyId && !keepPreviousParameters) {
            setParameters(temp)
            return;
        }

        let params: ElementParameterDto[] = [];
        if (subFamilyId) {
            params = await FamiliesService.getFamilyParameters(subFamilyId)

            if (params.length === 0) {
                const hasFamilyParams = parameters.some(x => x.elementFamilyId === elementFamilyId && x.familyId === familyId)
                if (hasFamilyParams) {
                    return;
                } else {
                    params = await FamiliesService.getFamilyParameters(familyId ?? '')
                }
            }
        } else if (familyId) {
            params = await FamiliesService.getFamilyParameters(familyId)
        }

        if (params.length > 0) {
            params = prepareParams(params, elementFamilyId);
            if (keepPreviousParameters) {
                setParameters([...parameters, ...params])
            } else {
                setParameters([...temp, ...params])
            }
        } else if (!subFamilyId && !keepPreviousParameters) {
            setParameters(temp)
        }
    }

    const prepareParams = (params: ElementParameterDto[], elementFamilyId: string) => {
        params.forEach(param => {
            param.elementFamilyId = elementFamilyId;
            param.familyParameterId = param.id;
            param.id = undefined;
            param.isOpen = true;
        })

        return params
    }

    const onCloseConfirmParametersModal = (isToKeepPrevParameters: boolean) => {
        const itemToSearch = (form.getValues('families') ?? []).find(x => x.id === familyItemChanged?.id)
        void getFamilyParameters(itemToSearch?.subFamilyId, itemToSearch?.familyId, itemToSearch?.id ?? '', isToKeepPrevParameters)
        setShowConfirmParametersModal(false)
        setFamilyItemChanged(null)
    }

    const onCloseRemoveFamilyModal = (isToDeleteFamily: boolean) => {
        if (isToDeleteFamily) {
            const temp: ElementParameterDto[] = []
            parameters.forEach(x => {
                if (x.elementFamilyId === familyItemChanged?.id) {
                    if (x.id && type !== 'duplicate') {
                        temp.push({ ...x, active: false })
                    }
                } else {
                    temp.push({ ...x })
                }
            })

            setParameters(temp)
            form.setValue('families', newFamilies)
            setNewFamilies([])
        }

        setShowConfirmDeleteFamilyModal(false)
        setFamilyItemChanged(null)
    }

    const renderFamilyItem = (item: FamilySelectModel, i: number) => {
        return (
            <FamilySelector
                key={item.id}
                item={item}
                options={familiesOptions}
                onChange={(items: FamilySelectModel[], previousId: string | null) => {
                    void onFamilyChange(items.find(x => x.familyId)?.familyId ?? undefined);
                    const itemToSearch = items.find(x => x.id === item.id)

                    // if there is any parameters from the family or subfamily that was just changed
                    const hasParams = parameters.find(x => x.elementFamilyId === item.id && x.familyId === previousId);

                    // if the family was deleted and there is any parameters from that family and/or subfamilly
                    const isDeleteFamily = itemToSearch?.familyId === null && parameters.some(x => x.elementFamilyId === itemToSearch?.id)

                    if (isDeleteFamily) {
                        setFamilyItemChanged(item)
                        setShowConfirmDeleteFamilyModal(true)
                        setNewFamilies(items)
                        return
                    } else if (hasParams) {
                        setFamilyItemChanged(item)
                        setShowConfirmParametersModal(true)
                    } else {
                        const lastFamilies = form.getValues('families');
                        const familyAlreadyExisted = lastFamilies?.find(x => x.familyId === itemToSearch?.familyId && x.subFamilyId === itemToSearch?.subFamilyId);
                        if (itemToSearch !== null && !familyAlreadyExisted) {
                            void getFamilyParameters(
                                itemToSearch?.subFamilyId,
                                itemToSearch?.familyId,
                                itemToSearch?.id ?? '',
                                false
                            )
                        }
                    }

                    form.setValue('families', items);
                }}

                items={form.getValues('families') ?? []}
                disabled={isDetails}
                hasError={isInvalidFamily(item, form.watch('families') ?? [])}
                errorMessage={t('elements.duplicated_family')}
            />
        )
    };

    const onFamilyChange = async (familyId?: string) => {
        if (form.getValues('workTypeId')) {
            return;
        }

        const filteredWorkTypes = await WorkTypesService.getCatalog({ familyId });
        setWorkTypes(filteredWorkTypes);
    }

    const onChangeFamiliesList = (items: FamilySelectModel[]) => {
        const rowIds: string[] = items.map(x => x.id)
        // if its edit deactivate, if its create delete them from the array
        const temp =
            (id && type !== 'duplicate')
                ? parameters.map(x => {
                    if (!rowIds.includes(x.elementFamilyId ?? '')) {
                        x.active = false;
                    }
                    return x
                })
                : parameters.filter(x => rowIds.includes(x.elementFamilyId ?? ''))
        setParameters([...temp]);
    }

    const onWorkTypeChange = async () => {
        const workTypeId = form.getValues('workTypeId');
        const filteredFamilies = await FamiliesService.getElementsCatalog({ workTypeId ,isComponent,isElement });
        setFamilies(filteredFamilies);
    }

    const onChangePrice = (price: number) => {
        totalPrice.current = price;
        calculateTotalPrice(parameters)

        setTotalRefreshKey(x => x + 1)
    }

    const calculateTotalPrice = (params: ElementParameterDto[]) => {
        params.forEach((param) => {
            if (param.active && param.type === ParameterSectionType.PARAMETER) {
                if (param.products && param.products.length > 0) {
                    for (const element of param.products) {
                        if (element.checked || isComponent) {
                            totalPrice.current = totalPrice.current + ((element.quantity ?? 0) * (element.price ?? 0))
                        }
                    }
                } else if (param.minMax?.products && param.minMax?.products.length > 0) {
                    for (const element of param.minMax?.products) {
                        if (element.checked) {
                            totalPrice.current = totalPrice.current + ((element.quantity ?? 0) * (element.price ?? 0))
                        }
                    }
                }
            }
        })
    }

    const renderHistoryItem = (item: ElementHistoryDto) => (
        <div>
            <Text className={styles.dateTime}>
                <DateFormat value={item.date} format={DATE_TIME_FORMAT_DEFAULT} /> {' | ' + item.userName}
            </Text>
            <Text className={styles.price}>
                {t('elements.price_no_tax') + ': '}<MoneyFormat value={item.price} suffix={' €' + (item.unit ? `/${item.unit}` : '')} />
            </Text>
        </div>
    );
    const onItemChange = (item: AtLeast<FlattenedItem<ElementParameterDto>, 'key'>,reason: 'change' | 'remove' | 'change-self-and-children') => {
        if (reason === 'change') {
            setParameters(parameters.map((section) => {
                if (section.key === item.key) {
                    return { ...section, ...item };
                }
                return section;
            }));
        }
    }

    const onMoveItem = (key: string, type: 'up' | 'down') => {
        const newLines = onMove(parameters,key,type)

        setParameters([...newLines])
    }

    useEffect(() => {
        totalPrice.current = form.getValues('price') ?? 0;
        calculateTotalPrice(parameters)

        setTotalRefreshKey(x => x + 1)
    }, [parameters]);

    const onClickBody = (ev: MouseEvent) => {
        if (ev && ev.target) {
            const target = ev.target as HTMLElement;
            const targetId = target.id;
            const targetParent = target.parentElement;

            if (
                targetId.includes('react-select-') ||
                (target.className && target.className.includes && target.className.includes('LoadingProvider')) ||
                (targetParent && targetParent.className && targetParent.className.includes && targetParent.className.includes('OptionTitleSubTitle'))
            ) {
                return;
            }
        }

        if (selectedSectionKey) {
            setSelectedSectionKey('');
        }
    }

    useEffect(() => {
        document.body.addEventListener('click', onClickBody);

        return function cleanup() {
            window.removeEventListener('click', onClickBody);
        }
    }, [selectedSectionKey, setSelectedSectionKey]);

    const unitsOptions = units.map((x: UnitDto) => {
        return {
            label: x.name,
            value: x.id,
        }
    })

    const taxesOptions = taxes.map((x: TaxDto) => {
        return {
            label: x.description,
            value: x.id
        }
    })

    const elementTypesOptions = elementTypes.map((x: ElementTypeDto) => {
        return {
            label: x.name,
            value: x.id
        }
    })

    const familiesOptions: FamilySelectInputOption[] = families.map((x: FamilyDto) => {
        return {
            label: x.name,
            value: x.id,
            isComponent: x.isComponent,
            isElement: x.isElement,
            subFamilies: x.subFamilies?.map((y: FamilyDto) => {
                return {
                    label: y.name,
                    value: y.id,
                    subFamilies: [],
                    isComponent: x.isComponent,
                    isElement: x.isElement,
                }
            }) ?? []
        }
    }) ?? [];

    if (!canRead) {
        return null;
    }
    const screenTitleLabel = isElement ? t('elements.title_elements') : t('elements.title_components');
    return (<ScreenTitle title={screenTitleLabel}>
        <PageBreadcrumbsPortal
            breadcrumbs={[
                { name: t('home.title'), url: '/' },
                { name: screenTitleLabel, url },
                { name: title, url: currentUrl },
            ]}
        />

        <PageHeader title={title} informationText={t('common.go_back')} onGoBack={() => navigateTo()}>
            <Button variant='forth' fw className={styles.totalPriceButton}>
                <span className={styles.totalPriceLabel}>{t('elements.total_price')}</span>
                <span key={totalRefreshKey} className={styles.totalPrice}>
                    <MoneyFormat value={totalPrice.current} suffix={'€'} />
                </span>
                <InfoIcon className={styles.infoIcon} onClick={() => setShowTotalPriceInformationModal(true)} />
            </Button>

            { type !== 'create' && <Button fw variant='secondary' onClick={() => setShowHistoryModal(true)}> <FaHistory /> </Button> }

            {canWrite && !isDetails && <Button type='submit' form='elementForm'>{t('common.save')}</Button>}
            {canWrite && isDetails && <Button fw onClick={() => { navigateTo('duplicate', id); }}>{t('common.duplicate')}</Button>}
            {canWrite && isDetails && <Button fw onClick={() => { navigateTo('edit', id); }}>{t('common.edit')}</Button>}
            {canWrite && isDetails && <Button variant='secondary' onClick={() => setShowConfirmDeleteModal(true)}> <FaTrashAlt /> </Button>}
        </PageHeader>
        <PageContainer addBottomSpace>
            <Form handleSubmit={form.handleSubmit} onSubmit={onSubmit} onInvalid={onInvalid} noValidate id="elementForm" ref={formRef}>
                <Row className="mb-3">
                    <Col md={12} lg={6} className={styles.marginBottomMd}>
                        <Label space>{isElement ? t('elements.element_type') : t('elements.ouvrage_type')}</Label>
                        <SelectInputController
                            control={form.control}
                            name='elementTypeId'
                            options={elementTypesOptions || []}
                            rules={{ required: false }}
                            disabled={isDetails}
                            hasError={Boolean(errors.elementTypeId)}
                        />
                        <InputError error={errors.elementTypeId} />
                    </Col>
                    <Col md={12} lg={6}>
                        <Label space>{t('elements.work_type')}</Label>
                        <SelectInputController
                            control={form.control}
                            name='workTypeId'
                            options={workTypes || []}
                            rules={{ required: false }}
                            disabled={isDetails}
                            onChange={onWorkTypeChange}
                            hasError={Boolean(errors.workTypeId)}
                        />
                        <InputError error={errors.workTypeId} />
                    </Col>
                </Row>
                {(!isDetails || form.getValues('families')?.length) ?
                    <Row className="mb-3">
                        <Col>
                            <MultiLineDynamicListController
                                control={form.control}
                                name='families'
                                onChange={(e) => onChangeFamiliesList(e)}
                                disabled={isDetails}
                                renderItem={renderFamilyItem}
                                labelAdd={t('elements.add_new_family')}
                                askBeforeDelete={true}
                                deleteModalItemName={t('elements.modals.the_family')}
                                deleteModalMessage={t('elements.modals.will_delete_parameters')} />
                        </Col>
                    </Row>
                    : undefined
                }
                <Row className="mb-3">
                    <Col>
                        <Label space>{t('elements.name')}{!isDetails ? '*' : ''}</Label>
                        <TextInputController
                            name='name' control={form.control}
                            placeholder={t('elements.name')} disabled={isDetails}
                            rules={{ required: true, maxLength: 250 }}
                            hasError={Boolean(errors.name)} />
                        <InputError error={errors.name} maxLength={250} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Label space>{t('elements.image')}</Label>
                        <FilesUploader
                            files={medias}
                            type='images'
                            onChange={setMedias}
                            containerName={(f) => f.key ?? ''}
                            canSetMain
                            disabled={isDetails}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={6} lg={3} className={styles.marginBottomMd}>
                        <Label space>{t('elements.reference')}</Label>
                        <TextInputController
                            name='reference' control={form.control}
                            placeholder={t('elements.reference')} disabled={isDetails}
                            rules={{ required: false, maxLength: 250 }}
                            hasError={Boolean(errors.reference)} />
                        <InputError error={errors.reference} maxLength={250} />
                    </Col>
                    <Col md={6} lg={3} className={styles.marginBottomMd}>
                        <Label space>{t('elements.unit')}{!isDetails ? '*' : ''}</Label>
                        <SelectInputController
                            control={form.control}
                            name='unitId'
                            options={unitsOptions || []}
                            rules={{ required: true }}
                            disabled={isDetails}
                            onChange={(x) => definePriceTagLabel(x)}
                            hasError={Boolean(errors.unitId)}
                        />
                        <InputError error={errors.unitId} />
                    </Col>
                    <Col md={6} lg={3} className={styles.marginBottomSm}>
                        <Label space>{t('elements.price_no_tax')}</Label>
                        <MoneyInputController
                            key={refreshKey}
                            name='price' control={form.control}
                            unitPrefix={priceTagLabel}
                            onChange={(x) => onChangePrice(x ?? 0)}
                            disabled={isDetails}
                            hasError={Boolean(errors.price)} />
                        <InputError error={errors.price} />
                    </Col>
                    <Col md={6} lg={3}>
                        <Label space>{t('elements.tax')}</Label>
                        <SelectInputController
                            control={form.control}
                            name='taxId'
                            options={taxesOptions || []}
                            rules={{ required: false }}
                            disabled={isDetails}
                            hasError={Boolean(errors.taxId)}
                        />
                        <InputError error={errors.taxId} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Label space>{t('elements.technical_file')}</Label>
                        <FileInputController
                            name='attachment'
                            value={file}
                            control={form.control}
                            accept={{
                                'application/pdf': ['.pdf'],
                            }}
                            onChange={setFile}
                            disabled={isDetails}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Label space>{t('elements.description')}</Label>
                        <RichInputController
                            name='description' control={form.control}
                            placeholder={t('elements.description')} disabled={isDetails}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label space>{t('elements.budget_pdf_visibility')}{!isDetails ? '*' : ''}</Label>
                        <SelectInputController
                            control={form.control}
                            name='budgetPdfVisibility'
                            options={[
                                {
                                    label: t('elements.budget_pdf_visibility_options.element_and_price_visible'),
                                    value: BudgetPDFVisibility.ELEMENT_AND_PRICE_VISIBLE
                                },
                                {
                                    label: t('elements.budget_pdf_visibility_options.price_invisible'),
                                    value: BudgetPDFVisibility.PRICE_INVISIBLE
                                },
                                {
                                    label: t('elements.budget_pdf_visibility_options.element_invisible'),
                                    value: BudgetPDFVisibility.ELEMENT_INVISIBLE
                                },
                            ]}
                            rules={{ required: true }}
                            disabled={isDetails}
                            hasError={Boolean(errors.budgetPdfVisibility)}
                        />
                        <InputError error={errors.budgetPdfVisibility} />
                    </Col>
                </Row>

                <div className={styles.sectionsContainer}>
                    <ElementParameterDataProvider.Provider value={{
                        selectedSectionKey,
                        parameters,
                        isSubmited: form.formState.isSubmitted,
                        hideBaseCheckBox: isComponent
                    }}>
                        <SortableTree
                            items={parameters}
                            onItemsChanged={(newItems) => setParameters(newItems as ElementParameterDto[])}
                            onItemChange={onItemChange}
                            onMoveItem={onMoveItem}
                            onSelectItem={setSelectedSectionKey}
                            TreeItemComponent={ElementParameterLine}
                            canHaveChildren={(item) => (item.type === ParameterSectionType.SECTION)}
                            canRootHaveChildren={true}
                            dropAnimation={null}
                            indentationWidth={20}
                            disableSorting={isDetails}
                        />
                    </ElementParameterDataProvider.Provider>
                </div>
            </Form>
        </PageContainer>

        <ConfirmDeleteModal
            itemName={form.getValues('name')}
            isOpen={showConfirmDeleteModal}
            onClose={(result) => onDelete(result)}
        />
        <YesNoModal
            title={t('elements.modals.keep_or_delete_old_parameters_title')}
            message={t('elements.modals.keep_or_delete_old_parameters_message')}
            isOpen={showConfirmParametersModal}
            onClose={(x) => onCloseConfirmParametersModal(x)}
        />

        <ConfirmDeleteModal
            itemName={t('elements.modals.the_family')}
            secundaryMessage={t('elements.modals.will_delete_parameters')}
            isOpen={showConfirmDeleteFamilyModal}
            onClose={(isToDeleteFamily) => onCloseRemoveFamilyModal(isToDeleteFamily)}
        />

        <InformationModal
            message={t('elements.modals.total_price_info')}
            isOpen={showTotalPriceInformationModal}
            onClose={() => setShowTotalPriceInformationModal(false)}
        />

        <HistoryModal
            title={t('elements.modals.price_history')}
            isOpen={showHistoryModal}
            items={elementHistory}
            renderItem={renderHistoryItem}
            onClose={() => setShowHistoryModal(false)}
        />
    </ScreenTitle>)
}

export default ElementScreen;
