import MoneyInput from 'common/components/moneyInput/MoneyInput';
import { Col, Row } from 'react-bootstrap';
import { BudgetLineParameterDto } from 'api/budgets/models/BudgetLineParameterDto';
import { useContext } from 'react';
import { LinesDataProvider } from '../../../../LinesDataProvider';

interface Props {
    parameter: BudgetLineParameterDto;
    disabled: boolean;
    onChange: (parameter: Partial<BudgetLineParameterDto>, calculateTotals: boolean) => void;
}

function ParameterNumber({ parameter, disabled, onChange }: Props): JSX.Element | null {
    const { units } = useContext(LinesDataProvider);
    const unitPrefix = units.find(x => x.id === parameter.number?.unitId)?.symbol;

    return (
        <div>
            <Row>
                <Col xs={12} sm={4}>
                    <MoneyInput
                        value={parameter.number?.number ?? null}
                        onBlur={(v) => {
                            onChange({ number: { number: v ?? 0 } }, false);
                        }}
                        disabled={disabled}
                        hideUnitsDrop={!unitPrefix}
                        unitPrefix={unitPrefix}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default ParameterNumber;
