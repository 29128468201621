import Http from 'common/services/Http';
import IdVersion from 'api/common/types/IdVersion';
import { Paged } from 'api/common/types/Page';
import { BudgetsFieldsSearchCriteria } from './models/BudgetsFieldsSearchCriteria';
import { BudgetFieldDto } from './models/BudgetFieldDto';

class BudgetsFieldsService {
    public getList(criteria: BudgetsFieldsSearchCriteria) {
        return Http.get<Paged<BudgetFieldDto>>('budgetsFields', criteria);
    }

    public getById(id: string) {
        return Http.get<BudgetFieldDto>('budgetsFields/' + id);
    }

    public create(model: BudgetFieldDto): Promise<string> {
        return Http.post('budgetsFields', model);
    }

    public update(model: BudgetFieldDto) {
        return Http.put('budgetsFields', model);
    }

    public remove(model: IdVersion) {
        return Http.put('budgetsFields/deactivate', model);
    }

    public getCatalog(budgetId?: string | null, budgetHistoryId?: string | null) {
        return Http.get<BudgetFieldDto[]>('budgetsFields/catalog', { budgetId, budgetHistoryId });
    }
}

export default new BudgetsFieldsService();
