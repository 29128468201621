import { DEFAULT_DECIMAL_PLACES } from 'Config';
import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import colors from 'styles/export/colors.module.scss';

const MoneyFormat: React.FC<NumericFormatProps> = ({ value, suffix, decimalScale = DEFAULT_DECIMAL_PLACES, ...props }: NumericFormatProps) => {
    return (
        <NumericFormat
            value={value}
            displayType={props.displayType ?? 'text'}
            thousandSeparator={' '}
            decimalSeparator={','}
            decimalScale={decimalScale}
            fixedDecimalScale={true}
            suffix={suffix ?? ' €'}
            style={props.disabled ? { backgroundColor: colors.light3 } : undefined}
            {...props}
        />
    );
};

export default MoneyFormat;
