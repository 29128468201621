import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { UnitDto } from './models/UnitDto';
import { UnitSearchCriteria } from './models/UnitSearchCriteria';

class UnitsService {
    public getList (criteria: UnitSearchCriteria) {
        return Http.get<Paged<UnitDto>>('units', criteria);
    }

    public getCatalog () {
        return Http.get<UnitDto[]>('units/catalog');
    }

    public getFormattedCatalog () {
        return Http.get<UnitDto[]>('units/get-formatted-catalog');
    }

    public getById (id: string) {
        return Http.get<UnitDto>('units/' + id);
    }

    public create (model: UnitDto): Promise<string> {
        return Http.post('units', model);
    }

    public update (model: UnitDto) {
        return Http.put('units', model);
    }

    public remove (model: UnitDto) {
        return Http.put('units/deactivate', model);
    }
}

export default new UnitsService();
