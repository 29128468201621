import styles from './DateInput.module.scss';
import DatePicker, { ReactDatePicker, registerLocale } from 'react-datepicker';
import { fr } from 'date-fns/locale';
import { DATEPICKER_FORMAT_DEFAULT } from 'Config';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';
import { useRef } from 'react';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';

registerLocale('fr', fr)

export interface DateInputProps {
    value: Date | null | undefined;
    disabled?: boolean;
    isGroupStart?: boolean;
    isGroupEnd?: boolean;
    minDate?: Date;
    maxDate?: Date;
    onChange: (value: Date | null | undefined) => void;
}

const DateInput = ({ value, disabled, isGroupStart, isGroupEnd, minDate, maxDate, onChange }: DateInputProps) => {
    const { t } = useTranslation();
    const datepickerRef = useRef<ReactDatePicker>(null);

    return (
        <div className={styles.container}>
            <DatePicker
                ref={datepickerRef}
                dateFormat={DATEPICKER_FORMAT_DEFAULT}
                customInput={<input className={`${styles.input} ${isGroupEnd ? styles.inputGroupEnd : ''}`} />}
                locale="fr"
                timeInputLabel={t('common.datetime_picker_time')}
                timeCaption={t('common.datetime_picker_time')}
                timeFormat="HH:mm"
                onChange={onChange}
                selected={value}
                placeholderText='--/--/----'
                wrapperClassName={styles.datepicker}
                disabled={disabled}
                portalId='root-portal'
                minDate={minDate} // Adiciona a data mínima
                maxDate={maxDate} // Adiciona a data máxima
            />
            <div className={`${styles.sideButton} ${isGroupStart ? styles.sideButtonGroupStart : ''}`} onClick={() => datepickerRef?.current?.setOpen(true)}>
                <FaCalendarAlt />
            </div>
        </div>
    );
};

type DateInputControllerProps<T extends FieldValues> = { onChange?: (value: Date | null | undefined) => void; } & Omit<DateInputProps, 'value' | 'onChange'> & UseControllerProps<T>;

export const DateInputController = <T extends FieldValues>(props: DateInputControllerProps<T>) => {
    const { field } = useController({ ...props, disabled: false });

    return (
        <DateInput
            {...props}
            value={field.value}
            disabled={props.disabled}
            onChange={(e) => {
                field.onChange(e);
                if (props.onChange) {
                    props.onChange(e);
                }
            }}
        />
    );
};

export default DateInput;
