import { useEffect, useRef, useState } from 'react'

interface StickyData {
    topIsSticky: boolean;
    rightIsSticky: boolean;
}

function onSticky(element: HTMLElement, callback: (data: StickyData) => void) {
    if (!element) {
        return
    }

    const observer = new IntersectionObserver(
        ([event]) => {
            callback({
                topIsSticky: event.boundingClientRect.top !== event.intersectionRect.top,
                rightIsSticky: event.boundingClientRect.right !== event.intersectionRect.right,
            });
            // callback(event.intersectionRatio < 1)
        },
        { threshold: [1] } // , rootMargin: '-1px 0px 0px 0px'
    )
    observer.observe(element)

    return { observer, element }
}

function useSticky<Target extends HTMLElement>() {
    const ref = useRef<Target>(null)
    const [stickyData, setStickyData] = useState<StickyData>({ rightIsSticky: false, topIsSticky: false })

    useEffect(() => {
        if (!ref.current) {
            return
        }
        const sticky = onSticky(ref.current, setStickyData)

        return () => sticky?.observer.unobserve(sticky?.element)
    }, [])

    return { stickyData, ref }
}
export default useSticky;
