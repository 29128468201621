import React from 'react';
import { IconProps } from './types';
import { calculateAspectRatioFit } from 'common/utils/calculateAspectRatioFit';

export const ExternalParts: React.FC<IconProps> = ({ size = 24, ...rest }) => {
    const newSize = calculateAspectRatioFit(24, 20.713, size, size);
    return (
        <svg id="icon-clients" xmlns="http://www.w3.org/2000/svg" width={newSize.width} height={newSize.height} viewBox="0 0 24 20.713">
            <defs>
                <clipPath id="clip-path-clients">
                    <rect id="Retângulo_889" data-name="Retângulo 889" width={newSize.width} height={newSize.height} fill="#27449c"/>
                </clipPath>
            </defs>
            <g id="Grupo_455" data-name="Grupo 455" clipPath="url(#clip-path-clients)">
                <path id="Caminho_1267" data-name="Caminho 1267" d="M23.834,15.939a2.884,2.884,0,0,0-1.423-2.533,4.8,4.8,0,0,0-3.328-.365,2.786,2.786,0,0,0-2.094,1.622l-.112.251-.2-.184c-.061-.054-.119-.108-.176-.161-.125-.115-.243-.224-.367-.323a6.054,6.054,0,0,0-.592-.434c-1.851-1.157-3.874-.989-5.784-.636a4.824,4.824,0,0,0-2.563,1.55l-.2.235-.13-.282a2.933,2.933,0,0,0-2.136-1.658,10.1,10.1,0,0,0-1.764-.056A2.974,2.974,0,0,0,0,15.931C0,17.627,0,19.347,0,21.042v.014a1.161,1.161,0,0,0,1.081,1.235,1.2,1.2,0,0,0,.148,0c.871,0,1.729,0,2.588,0l1.287,0h.548l.04.149c.24.914.712,1.359,1.44,1.36,3.178.008,6.4.008,9.579,0a1.4,1.4,0,0,0,1.293-.911c.037-.1.067-.193.1-.3.016-.053.033-.108.051-.164l.045-.138h4.34a1.217,1.217,0,0,0,1.3-1.129,1.158,1.158,0,0,0,0-.17v-.014q0-.839,0-1.68c0-1.1.005-2.237,0-3.355l.162,0Zm-17.726.426a1.964,1.964,0,0,1-.081.307h0a6.784,6.784,0,0,0-.3,2.276v2.327H1.074l-.011-.189c0-.043-.005-.084-.008-.125-.007-.083-.013-.169-.013-.255v-4.68a1.9,1.9,0,0,1,1.742-2.019,6.3,6.3,0,0,1,1.7.024,2.007,2.007,0,0,1,1.626,2.334M17.1,20.918c0,.395,0,.789,0,1.183h-.2v0h.2c0,.559-.144.7-.7.7H7.466a.788.788,0,0,1-.585-.144.764.764,0,0,1-.14-.574c0-.4,0-.809,0-1.2-.009-.938-.018-1.907.065-2.858a4.4,4.4,0,0,1,4.356-4.054,7.349,7.349,0,0,1,3.024.294l.013,0a4.591,4.591,0,0,1,2.9,4.276c.008.789.005,1.593,0,2.371m5.694.015a1.571,1.571,0,0,1-.027.179l-.033.159h-4.6l-.017-.3c-.007-.1-.013-.2-.013-.3q0-.325,0-.649c.005-.566.011-1.151-.023-1.721a7.092,7.092,0,0,0-.266-1.576,2.048,2.048,0,0,1,1.228-2.617A2.012,2.012,0,0,1,19.5,14a6.355,6.355,0,0,1,1.669.022A1.872,1.872,0,0,1,22.8,15.97v4.943h-.2l0,0Z" transform="translate(0 -3.094)" fill="#27449c"/>
                <path id="Caminho_1268" data-name="Caminho 1268" d="M18.36,3.941A3.926,3.926,0,0,0,14.461,0h-.024a3.922,3.922,0,0,0-3.921,3.9,3.921,3.921,0,1,0,7.843.043m-6.827-.006a2.934,2.934,0,0,1,2.9-2.907h.006a2.941,2.941,0,0,1,2.906,2.9,2.906,2.906,0,0,1-2.9,2.914h0a2.91,2.91,0,0,1-2.907-2.9" transform="translate(-2.52 0)" fill="#27449c"/>
                <path id="Caminho_1269" data-name="Caminho 1269" d="M15.615,10.425l0,.125h0Z" transform="translate(-3.741 -2.498)" fill="#27449c"/>
                <path id="Caminho_1270" data-name="Caminho 1270" d="M3.98,4.019H3.968A2.391,2.391,0,0,0,3.98,8.8h.011A2.391,2.391,0,0,0,3.98,4.019M4.942,7.4a1.379,1.379,0,0,1-.971.4H3.955A1.39,1.39,0,0,1,3.97,5.02h.015A1.39,1.39,0,0,1,4.942,7.4" transform="translate(-0.381 -0.963)" fill="#27449c"/>
                <path id="Caminho_1271" data-name="Caminho 1271" d="M25.879,4.021h-.006a2.4,2.4,0,0,0-2.385,2.37,2.359,2.359,0,0,0,2.3,2.41h.1a2.39,2.39,0,0,0-.006-4.781m0,3.78h-.011a1.391,1.391,0,0,1-1.379-1.4,1.436,1.436,0,0,1,1.4-1.379l0,.176,0-.176A1.39,1.39,0,0,1,25.875,7.8" transform="translate(-5.627 -0.963)" fill="#27449c"/>
            </g>
        </svg>
    );
}
