import { UnitDto } from 'api/units/models/UnitDto';
import BudgetTotals from './budgetTotals/BudgetTotals';
import { TaxDto } from 'api/taxes/models/TaxDto';
import { LinesDataProvider } from '../lines/LinesDataProvider';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Panel from 'common/components/panel/Panel';
import { useFormContext } from 'react-hook-form';
import { BudgetDto } from 'api/budgets/models/BudgetDto';
import { RichInputController } from 'common/components/richTextInput/RichTextInput';

interface Props {
    isDetails: boolean;
    units: UnitDto[];
    taxes: TaxDto[];
}

function BudgetPrices({ isDetails, units, taxes }: Props): JSX.Element | null {
    const { t } = useTranslation();
    const form = useFormContext<BudgetDto>();

    return (
        <div>
            <LinesDataProvider.Provider value={{ taxes, units, hiddenColumns: [] }}>
                <Row>
                    <Col xs={12} md={6}>
                        <Panel title={t('budgets.budget.totals')}>
                            <BudgetTotals
                                disabled={isDetails}
                            />
                        </Panel>
                    </Col>
                    <Col xs={12} md={6}>
                        <Panel title={t('budgets.budget.payment_terms')} style={{ overflow: 'unset' }}>
                            <RichInputController
                                name="paymentTerms"
                                control={form.control}
                                disabled={isDetails}
                            />
                        </Panel>
                    </Col>
                </Row>
            </LinesDataProvider.Provider>
        </div>
    );
}

export default BudgetPrices;
