import { FaCaretDown } from 'react-icons/fa';
import TextInput from '../textInput/TextInput';
import styles from './PhoneInput.module.scss';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';

// const NumericFormatCustom = <BaseType extends any = InputAttributes>(props: NumericFormatProps<BaseType>) => {
//     const numericFormatProps: any = useNumericFormat(props);

//     return (
//         <NumberFormatBase
//             {...numericFormatProps}
//             format={numStr =>
//                 numericFormatProps.format((props as any).hasCurrentPhone ? (' ' + numStr) : numStr)
//             }
//         />
//     );
// };

export interface PhoneCodeOption {
    value: string;
    name: string;
    phoneCode: string;
}

export interface PhoneInputProps {
    value: string | null | undefined;
    phoneCodeValue: string | null | undefined;
    phoneCodes: PhoneCodeOption[];
    hasError?: boolean;
    disabled?: boolean;
    onChange: (value: string | null | undefined) => void;
    onChangePhoneCode?: (phoneCodeValue: string | null | undefined) => void;
}

const PhoneInput = ({ value, disabled, phoneCodeValue, phoneCodes, hasError, onChange, onChangePhoneCode }: PhoneInputProps) => {
    const currentPhoneCode = phoneCodes ? phoneCodes.find(x => x.value === phoneCodeValue) : null;
    const { t } = useTranslation();

    // eslint-disable-next-line react/display-name
    const CustomToggle = React.forwardRef(({ ...p }, ref: any) => (
        <div
            {...p}
            className={`${styles.sideButton} ${disabled ? styles.disabled : ''}`}
            ref={ref}
        />
    ));

    // eslint-disable-next-line react/display-name
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
            const [value, setValue] = useState('');
            const { t } = useTranslation();

            return (
                <div
                    ref={ref}
                    style={style}
                    className={`${className} ${styles.dropdown}`}
                    aria-labelledby={labeledBy}
                >
                    <TextInput
                        placeholder={t('common.write_here')}
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        className="mx-3 my-2 w-auto"
                    />
                    <ul className={`list-unstyled ${styles.list}`}>
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || (child as any).props.children.toLowerCase().startsWith(value),
                        )}
                    </ul>
                </div>
            );
        },
    );

    return (
        <div className={styles.container}>
            <Dropdown drop='down' className={styles.dropdownContainer}>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" disabled={disabled}>
                    {currentPhoneCode ? currentPhoneCode.name : '-'}
                    <FaCaretDown />
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                    <Dropdown.Item
                        key={'none'}
                        eventKey={'none'}
                        active={!phoneCodeValue}
                        onClick={() => onChangePhoneCode && onChangePhoneCode(null)}
                    >
                        -
                    </Dropdown.Item>
                    {(phoneCodes ?? []).map((phoneCode, i) => (
                        <Dropdown.Item
                            key={i}
                            eventKey={i}
                            active={Boolean(phoneCode.value === phoneCodeValue)}
                            onClick={() => onChangePhoneCode && onChangePhoneCode(phoneCode.value)}
                        >
                            {phoneCode.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>

            <NumericFormat
                value={value}
                displayType={'input'}
                customInput={TextInput}
                className={`${styles.input} ${hasError ? styles.hasError : ''}`}
                disabled={disabled}
                onValueChange={e =>
                    onChange(e.floatValue?.toString())
                }
                // format={currentPhoneCode ? `+${currentPhoneCode.phoneCode} ### ### ### ### ###` : ''}
                // allowEmptyFormatting
                prefix={currentPhoneCode ? `+${currentPhoneCode.phoneCode} ` : ''}
                placeholder={t('common.write_here')}
                // @ts-expect-error
                hasCurrentPhone={!!currentPhoneCode}
                decimalScale={0}
                maxLength={25}
            />
        </div>
    )
};

type PhoneInputControllerProps<T extends FieldValues> = Omit<PhoneInputProps, 'phoneCodeValue' | 'value'> & UseControllerProps<T> & {
    phoneCodeValueName: string;
};

export const PhoneInputController = <T extends FieldValues>(props: PhoneInputControllerProps<T>) => {
    const { field: valueField } = useController({ ...props, disabled: false });
    const { field: phoneCodeValueField } = useController({ ...props, name: props.phoneCodeValueName as any, disabled: false });

    return (
        <PhoneInput
            {...props}
            value={valueField.value}
            phoneCodeValue={phoneCodeValueField.value}
            disabled={props.disabled}
            onChange={(e) => {
                valueField.onChange(e);
                if (props.onChange) {
                    props.onChange(e);
                }
            }}
            onChangePhoneCode={(e) => {
                phoneCodeValueField.onChange(e);
                if (props.onChangePhoneCode) {
                    props.onChangePhoneCode(e);
                }
            }}
        />
    );
};

export default PhoneInput;
