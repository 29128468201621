import { NavigateFunction } from 'react-router-dom';

class NavigationService {
    private navigate: NavigateFunction | null = null;

    setNavigate(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    goTo404() {
        if (this.navigate) {
            this.navigate('/404');
        } else {
            console.error('Navigate function is not set');
        }
    }
}

export default new NavigationService();
