import { forwardRef, Ref, useContext } from 'react';
import { TreeItemComponentProps } from 'common/components/sortableTree/types';
import clsx from 'clsx';
import useIsMobileOrTablet from 'common/hooks/useDeviceType';
import { ParameterSectionType } from 'common/components/parametersSections/models';
import { getLevelFromParentKeys, lineInnerActiveStyle, lineInnerLevelStyle, lineLevelStyle, lineOutterLineStyle } from 'screens/business/budgets/budget/lines/utils';
import { canMoveDown, canMoveUp, isLastInLevel } from '../utils';
import styles from './ElementParameterLine.module.scss'
import SectionMenu from 'common/components/collapsableSection/sectionMenu/SectionMenu';
import Button from 'common/components/button/Button';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import { ElementParameterDto } from 'api/elements/Models/ElementDto';
import ElementParameter from 'common/components/elementParameters/parameter/ElementParameter';
import { ElementParameterDataProvider } from './ElementParameterDataProvider';

export const ELEMENT_PARAMETER_SECTION_ID_PREFIX = 'ElementParameterSection_';
const MAX_LEVEL_TO_LIGHTEN = 3;

function ElementParameterLine({
    item: section,
    depth: level,
    handleProps,
    clone,
    ghost,
    disableInteraction,
    disableSelection,
    style,
    parentsKeys,
    childCount,
    onItemChange,
    onItemCreate,
    onMoveItem,
    onSelectItem,
    disabled,
    wrapperRef
}: TreeItemComponentProps<ElementParameterDto>, ref: Ref<HTMLDivElement>): JSX.Element | null {
    const { selectedSectionKey,parameters,isSubmited } = useContext(ElementParameterDataProvider);
    const isMobileOrTablet = useIsMobileOrTablet();
    const isLastInSelectedLevel = selectedSectionKey != null ? isLastInLevel(parameters, selectedSectionKey, section.key) : false;

    const disableMoveUp = !canMoveUp(parameters, selectedSectionKey);
    const disableMoveDown = !canMoveDown(parameters, selectedSectionKey);

    const onMoveUp = () => {
        if (onMoveItem) {
            onMoveItem(section.key,'up')
        }
    }

    const onMoveDown = () => {
        if (onMoveItem) {
            onMoveItem(section.key,'down')
        }
    }

    const onToggleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if (onItemChange) {
            onItemChange({
                key: section.key,
                isOpen: !section.isOpen
            }, 'change');
        }
    }

    const _onClickSection = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (!disabled && onSelectItem) { onSelectItem(section.key); };
    }

    const _onClickParameterProductList = (key: string) => {
        if (!disabled && onSelectItem) { onSelectItem(key); };
    }
    const parentIsSelected = selectedSectionKey != null ? parentsKeys.includes(selectedSectionKey) : false;
    const levelsUntilParent = selectedSectionKey != null && parentIsSelected ? getLevelFromParentKeys(selectedSectionKey, parentsKeys) : 0;
    const isActive = selectedSectionKey === section.key;

    if (clone || section.visibleOnlyInBudgets || !section.active) {
        return null;
    }

    return (
        <div
            className={clsx(
                styles.line,
                clone && styles.lineClone,
                ghost && styles.lineGhost,
                ghost && styles.indicator,
                disableSelection && styles.lineDisableSelection,
                disableInteraction && styles.lineDisableIteraction,
            )}
            style={{
                ...style,
                ...lineLevelStyle(level, clone ?? false, levelsUntilParent),
                ...lineOutterLineStyle(isActive)
            }}
            id={ELEMENT_PARAMETER_SECTION_ID_PREFIX + section.key}
            ref={wrapperRef as any}
            onClick={_onClickSection}
        >
            {isActive && !disabled && <SectionMenu
                isEditing={false}
                level={level}
                handleProps={handleProps}
                showMove={!isMobileOrTablet}
                showMoveUp={isMobileOrTablet}
                showMoveDown={isMobileOrTablet}
                onMoveUp={onMoveUp}
                onMoveDown={onMoveDown}
                disabledMoveDown={disableMoveDown}
                disabledMoveUp={disableMoveUp}
            />}
            <div
                className={styles.contentContainer}
                style={{
                    ...(lineInnerLevelStyle(parentIsSelected, levelsUntilParent)),
                    ...(lineInnerActiveStyle(isActive, parentIsSelected, isLastInSelectedLevel, (childCount ?? 0) > 0, section.isOpen)),
                }}
            >
                <div className={styles.section} ref={ref}>
                    {section.type === ParameterSectionType.SECTION || section.title || section.parameterName
                        ? <div className={`${styles.header} ${styles['level-' + (level >= MAX_LEVEL_TO_LIGHTEN ? MAX_LEVEL_TO_LIGHTEN : level)]} ${isActive ? styles.active : ''}`}>
                            <div className={styles.title}>
                                {section.type === ParameterSectionType.SECTION && <Button size='small' onClick={onToggleOpen} className={styles.toggleOpenButton}>
                                    {!section.isOpen && <FaAngleRight />}
                                    {section.isOpen && <FaAngleDown />}
                                </Button>}
                                {`${(section.title ?? section.parameterName ?? '')} ${!disabled && section.parameterIsRequired ? '*' : ''}`}
                            </div>
                        </div>
                        : null
                    }
                    {section.type === ParameterSectionType.PARAMETER && <div>
                        <ElementParameter
                            parameter={section}
                            onChange={(item) => onItemChange && onItemChange({ ...item },'change')}
                            disabled={disabled}
                            hasSubmited={isSubmited}
                            activeParameterKey={selectedSectionKey}
                            onClick={_onClickParameterProductList}
                        />
                    </div>}
                </div>

            </div>
        </div>)
}

export default forwardRef<HTMLDivElement, TreeItemComponentProps<ElementParameterDto>>(ElementParameterLine);
