import { ParameterSectionParameterType, ParameterSectionType } from 'common/components/parametersSections/models';
import ParameterMinMax from '../parameterMinMax/ParameterMinMax';
import ParameterProducts from '../parameterProducts/ParameterProducts';
import ParameterSection from '../parameterSection/ParameterSection';
import styles from './Parameter.module.scss';
import { BudgetLineParameterDto } from 'api/budgets/models/BudgetLineParameterDto';
import ParameterImages from '../parameterImages/ParameterImages';
import ParameterNumber from '../parameterNumber/ParameterNumber';
import ParameterText from '../parameterText/ParameterText';
import ParameterQuantity from '../parameterQuantity/ParameterQuantity';
import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import { useDispatch } from 'react-redux';
import { calculateLineTotals, updateLineParameter } from 'store/budget/action';

interface Props {
    parameter: BudgetLineParameterDto;
    level: number;
    line: BudgetLineDto;
    disabled: boolean;
}

function Parameter({ parameter, level, line, disabled }: Props): JSX.Element | null {
    const dispatch = useDispatch();

    const onChangeParameter = (newParameter: Partial<BudgetLineParameterDto>, calculateTotals: boolean) => {
        dispatch(updateLineParameter(line.key, {
            key: parameter.key,
            ...newParameter
        }));

        if (calculateTotals) {
            dispatch(calculateLineTotals(line.key));
        }
    }

    if (parameter.type === ParameterSectionType.PARAMETER) {
        let content: JSX.Element | null;

        switch (parameter.parameterType) {
            case ParameterSectionParameterType.NUMBER_MIN_MAX:
                content = <ParameterMinMax parameter={parameter} line={line} disabled={disabled} onChange={onChangeParameter} />
                break;

            case ParameterSectionParameterType.PRODUCTS:
                content = <ParameterProducts parameter={parameter} line={line} disabled={disabled} onChange={onChangeParameter} />
                break;

            case ParameterSectionParameterType.IMAGES:
                content = <ParameterImages parameter={parameter} disabled={disabled} />
                break;

            case ParameterSectionParameterType.TEXT:
                content = <ParameterText parameter={parameter} disabled={disabled} onChange={onChangeParameter} />
                break;

            case ParameterSectionParameterType.NUMBER:
                content = <ParameterNumber parameter={parameter} disabled={disabled} onChange={onChangeParameter} />
                break;

            case ParameterSectionParameterType.QUANTITY:
                content = <ParameterQuantity parameter={parameter} disabled={disabled} line={line} onChange={onChangeParameter} />
                break;

            default:
                content = null;
        }

        if (!content && !parameter.parameterName) {
            return null;
        }

        return (
            <div className={styles.parameter}>
                {parameter.parameterName ? <div className={styles.parameterName}>{parameter.parameterName}</div> : ''}
                {content}
            </div>
        );
    }

    return <ParameterSection parameter={parameter} level={level} line={line} disabled={disabled} />
}

export default Parameter;
