import React from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './InputError.module.scss';

interface Props {
    error: FieldError | undefined;
    maxLength?: number;
    isWarning?: boolean;
}

const InputError: React.FC<Props> = ({ error, maxLength, isWarning }: Props) => {
    const { t } = useTranslation();

    const renderMessage = () => {
        switch (error?.type) {
            case 'required':
            case 'validate':
                return t('common.errors.required');
            case 'email_not_available':
                return t('common.errors.email_not_available');
            case 'pattern':
                if (error?.message === 'email') {
                    return t('common.errors.email_not_valid');
                }
                return t(error?.message as any);
            case 'maxLength':
                if (maxLength) { return t('common.errors.max_length_detail', { maxLength }); } else { return t('common.errors.max_length_no_detail'); }
            default:
                return error?.message;
        }
    }

    return error && error.type
        ? (
            <div className={`InputError ${styles.message} ${isWarning ? styles.messageWarning : ''}`}>
                {renderMessage()}
            </div>
        )
        : null;
}

export default InputError;
