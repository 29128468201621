import { FamilyDto } from 'api/families/models/FamilyDto';
import ListingTable from 'common/components/listingTable/ListingTable';
import ListingTableCache, { ListingTableData } from 'common/components/listingTable/ListingTableCache';
import TableActionButton from 'common/components/listingTable/actionButton/TableActionButton';
import { FilterData, ListingTableColumn, OrderData } from 'common/components/listingTable/models';
import ListingTableSidebar from 'common/components/listingTable/sidebar/ListingTableSidebar';
import { orderColumns } from 'common/components/listingTable/tableUtils';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Logger from 'common/services/Logger';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCopy, FaEdit, FaTrash } from 'react-icons/fa';
import { DATE_TIME_FORMAT_DEFAULT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import FamiliesService from 'api/families/FamiliesService';
import Button from 'common/components/button/Button';
import { useNavigate } from 'react-router-dom';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import Loading from 'common/services/Loading';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import Toast from 'common/services/Toast';
import DateFormat from 'common/components/dateFormat/dateFormat';
import WorkTypesService from 'api/workTypes/WorkTypesService';
import { SelectInputOption } from 'common/components/selectInput/SelectInput';
import YesNoModal from 'common/components/modal/yesNoModal/YesNoModal';

interface Filters {
    name?: string | null | undefined;
    parentId?: string | null | undefined;
    workTypeId?: string | null | undefined;

    updatedDate?: { start: Date | null; end: Date | null };
    updatedDateStart?: Date | null;
    updatedDateEnd?: Date | null;
    userUpdatedName?: string;
}

export type FamiliesListType = 'elements' | 'components';

interface Props {
    listType: FamiliesListType;
    isSubFamilies?: boolean;
}

function FamiliesListScreen({ listType, isSubFamilies }: Props): JSX.Element | null {
    const PAGE_ID = `LIBRARY_FAMILIES_LIST_${listType}_${isSubFamilies}`;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState<FamilyDto[]>([]);
    const [tableData, setTableData] = useState<ListingTableData<FamilyDto, Filters>>(ListingTableCache.get(PAGE_ID, {
        order: { field: 'name', isOrderAsc: true },
        hiddenColumns: [],
        columnsOrder: [],
        columnsWidth: [],
        page: 1,
        filters: {},
        lastRowId: null,
    }));
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const baseUrl = '/library/' + listType + (isSubFamilies ? '/sub-families' : '/families');
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<FamilyDto | null>(null);
    const [showConfirmDeleteRelationsModal, setShowConfirmDeleteRelationsModal] = useState<FamilyDto | null>(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['FAMILIES_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['FAMILIES_READ'])
    const [totalItems, setTotalItems] = useState(0);
    const [scrollToRowId, setScrollToRowId] = useState<string | null>(null);
    const firstLoad = useRef(true);

    const [workTypes, setWorkTypes] = useState<SelectInputOption[]>()
    const [families, setFamilies] = useState<SelectInputOption[]>()

    const columns: ListingTableColumn<FamilyDto>[] = orderColumns([
        {
            name: t('families.list.name'),
            field: 'name',
            filter: { type: 'text', value: tableData.filters.name ?? '', placeholder: t('families.list.name') },
            minWidth: '18rem',
        },
        ...((isSubFamilies
            ? [{
                name: t('families.list.family'),
                field: 'parentId',
                orderColumn: 'fp.name',
                filter: { type: 'dropdown', value: tableData.filters.parentId ?? '', options: families || [] },
                minWidth: '18rem',
                renderCell: (row) => row.parentName ?? ''
            }]
            : []) as ListingTableColumn<FamilyDto>[]),
        {
            name: t('families.list.work_type'),
            field: 'workTypeName',
            filter: { type: 'dropdown', value: tableData.filters.workTypeId ?? '', options: workTypes || [] },
            minWidth: '18rem',
            orderColumn: 'wt.name',
            renderCell: (row) => row.workTypeName ?? ''
        },
        {
            name: t('common.updated_date'),
            field: 'updatedDate',
            filter: {
                type: 'date-range',
                value: {
                    start: tableData.filters.updatedDate?.start ? new Date(tableData.filters.updatedDate.start) : null,
                    end: tableData.filters.updatedDate?.end ? new Date(tableData.filters.updatedDate.end) : null
                }
            },
            minWidth: '22rem',
            orderColumn: 'updated_date',
            renderCell: (row) => {
                return row.updatedDate ? <DateFormat value={row.updatedDate} format={DATE_TIME_FORMAT_DEFAULT} /> : '';
            }
        },
        {
            name: t('common.user_updated'),
            field: 'userUpdatedName',
            filter: { type: 'text', value: tableData.filters.userUpdatedName ?? '', placeholder: t('common.user_updated') },
            minWidth: '21rem',
            orderColumn: 'user_updated_name',
        },
    ], tableData.columnsOrder);

    const onFilter = (col: ListingTableColumn<FamilyDto>, filterData: FilterData) => {
        setTableData(d => {
            const data = ({
                ...d, page: 1
            })
            if (col.field === 'name') {
                data.filters = {
                    ...data.filters,
                    name: filterData.value as string,
                };
            }
            if (col.field === 'parentId') {
                data.filters = {
                    ...data.filters,
                    parentId: filterData.value as string,
                };
            }

            if (col.field === 'workTypeName') {
                data.filters = {
                    ...data.filters,
                    workTypeId: filterData.value as string,
                };
            }

            if (col.field === 'updatedDate') {
                data.filters = {
                    ...data.filters,
                    updatedDate: filterData.value,
                    updatedDateStart: filterData.value?.start as Date,
                    updatedDateEnd: filterData.value?.end as Date
                };
            }

            if (col.field === 'userUpdatedName') {
                data.filters = {
                    ...data.filters,
                    userUpdatedName: filterData.value as string
                };
            }
            return data;
        });
        setRefreshKey(k => k + 1);
    }

    const onOrder = (orderData: OrderData<FamilyDto>) => {
        setRefreshKey(k => k + 1);
        setTableData(d => ({ ...d, order: orderData, page: 1 }));
    }

    const onClearFilters = () => {
        setTableData(d => ({ ...d, page: 1, filters: {}, lastRowId: null }));
        setRefreshKey(k => k + 1);
    }

    const renderAction = (row: FamilyDto) => (
        <TableActionButton
            options={[
                { label: <span><FaEdit /> {t('common.edit')}</span>, onClick: () => onClickEdit(row.id) },
                { label: <span><FaCopy /> {t('common.duplicate')}</span>, onClick: () => onClickDuplicate(row.id) },
                { label: <span><FaTrash /> {t('common.delete')}</span>, onClick: () => setShowConfirmDeleteModal(row) },
            ]}
        />
    )

    const getData = async () => {
        if (isLoading) {
            return;
        }

        try {
            setIsLoading(true);

            const familiesData = await FamiliesService.getList({
                ...tableData.filters,
                isComponent: listType === 'components',
                isElement: listType === 'elements',
                page: firstLoad.current && tableData.page > 1 ? 1 : tableData.page,
                itemsPerPage: firstLoad.current && tableData.page > 1 ? DEFAULT_PAGINATION_ITEMS_PER_PAGE * tableData.page : DEFAULT_PAGINATION_ITEMS_PER_PAGE,
                name: tableData.filters.name,
                parentId: tableData.filters.parentId,
                hasParentId: Boolean(isSubFamilies),
                orderColumn: tableData.order.orderColumn,
                orderBy: tableData.order.isOrderAsc ? 'asc' : 'desc'
            });

            const newListElements = familiesData.currentPage === 1 ? familiesData.items : [...data, ...familiesData.items]
            setHasMore(familiesData.totalItems > newListElements.length);
            setData(newListElements);
            setTotalItems(familiesData.totalItems)

            if (firstLoad.current) {
                const [
                    workTypesData,
                    familiesData
                ] = await Promise.all([
                    WorkTypesService.getCatalog(),
                    FamiliesService.getElementsCatalog({ isComponent: listType === 'components',isElement: listType === 'elements' }),
                ]);
                setWorkTypes(workTypesData)

                setFamilies(familiesData.map((x: FamilyDto) => {
                    return {
                        label: x.name,
                        value: x.id
                    }
                }))

                setScrollToRowId(tableData.lastRowId);
            }
            firstLoad.current = false;
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get families', error);
            Toast.error(t('messages.error_load_info'));
        }

        setIsLoading(false);
    }

    const onDelete = async (result: boolean) => {
        if (!showConfirmDeleteModal) {
            return;
        }

        const itemToDelete = showConfirmDeleteModal;
        setShowConfirmDeleteModal(null);

        if (result) {
            setShowConfirmDeleteRelationsModal(itemToDelete);
        }
    }

    const onDeleteRelations = async (result: boolean) => {
        Loading.show();
        try {
            await FamiliesService.delete(showConfirmDeleteRelationsModal?.id ?? '', result)
            Toast.success(t('messages.record_delete_success'));

            setTableData(d => ({ ...d, page: 1 }));
            setRefreshKey(k => k + 1);
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete family', error);
            Toast.error(t('messages.record_delete_error'));
        } finally {
            Loading.hide();
            setShowConfirmDeleteRelationsModal(null)
        }
    }

    const onLoadMore = () => {
        setTableData(d => ({ ...d, page: d.page + 1 }));
    }

    const onClickEdit = (id: string) => {
        ListingTableCache.saveLastRowId(PAGE_ID, id);
        navigate(baseUrl + '/edit/' + id)
    }

    const onClickDuplicate = (id: string) => {
        ListingTableCache.saveLastRowId(PAGE_ID, id);
        navigate(baseUrl + '/duplicate/' + id)
    }

    const onClickNew = () => {
        navigate(baseUrl + '/create')
    }

    const onRowClick = (id: string) => {
        ListingTableCache.saveLastRowId(PAGE_ID, id);
        navigate(baseUrl + '/details/' + id)
    }

    useEffect(() => {
        void getData();
    }, [tableData.page, tableData.filters, tableData.order, refreshKey]);

    useEffect(() => {
        ListingTableCache.save(PAGE_ID, tableData);
    }, [tableData]);

    let title = '';
    let url = '/library';
    switch (listType) {
        case 'elements':
            title = isSubFamilies ? t('families.list.elements_sub_family_title') : t('families.list.elements_family_title');
            url += isSubFamilies ? '/elements/sub-families' : '/elements/families';
            break;
        case 'components':
            title = isSubFamilies ? t('families.list.components_sub_family_title') : t('families.list.components_family_title');
            url += isSubFamilies ? '/components/sub-families' : '/components/families';
            break;
        default:
            break;
    }

    if (!canRead) {
        return null;
    }

    return (
        <ScreenTitle title={title}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: title, url },
                ]}
            />
            <PageHeader title={title} subTitle={`${totalItems} ${t('common.total_results')}`} informationText={t('common.list')} showGoBack={false} addSidebarSpacing>
                {canWrite && <Button onClick={onClickNew}>{t('common.new')}</Button>}
            </PageHeader>
            <PageContainer>
                <ListingTable<FamilyDto>
                    columns={columns}
                    rows={data}
                    order={tableData.order}
                    hiddenColumns={tableData.hiddenColumns}
                    columnsWidths={tableData.columnsWidth}
                    onOrder={onOrder}
                    onFilter={onFilter}
                    onClearFilters={onClearFilters}
                    onRenderAction={canWrite ? renderAction : undefined}
                    infiniteScroll={{
                        isLoading,
                        hasMore,
                        onLoadMore,
                    }}
                    onRowClick={(r) => onRowClick(r.id)}
                    onRowId={r => r.id}
                    scrollToRowId={scrollToRowId}
                    onColResize={(w) => setTableData(d => ({ ...d, columnsWidth: w }))}
                />
            </PageContainer>

            <ListingTableSidebar
                columns={columns}
                hiddenColumns={tableData.hiddenColumns}
                onChangeHiddenColumns={(hc) => setTableData(d => ({ ...d, hiddenColumns: hc }))}
                onChangeColumnsOrder={(oc) => setTableData(d => ({ ...d, columnsOrder: oc }))}
            />

            <ConfirmDeleteModal
                itemName={showConfirmDeleteModal?.name}
                isOpen={!!showConfirmDeleteModal}
                onClose={onDelete}
            />

            <YesNoModal
                title={t('common.question')}
                message={listType === 'components' ? t('families.delete_components_relations') : t('families.delete_elements_relations')}
                onClose={onDeleteRelations}
                isOpen={!!showConfirmDeleteRelationsModal}
            />
        </ScreenTitle>
    );
}

export default FamiliesListScreen;
