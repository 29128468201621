import { DATE_TIME_FORMAT_DEFAULT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import TaxesService from 'api/taxes/TaxesService';
import { TaxDto } from 'api/taxes/models/TaxDto';
import { TaxSearchCriteria } from 'api/taxes/models/TaxSearchCriteria';
import ListingTableCache, { ListingTableData } from 'common/components/listingTable/ListingTableCache';
import TableActionButton from 'common/components/listingTable/actionButton/TableActionButton';
import { FilterData, ListingTableColumn, OrderData } from 'common/components/listingTable/models';
import { orderColumns } from 'common/components/listingTable/tableUtils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Logger from 'common/services/Logger'
import toast from 'react-hot-toast';
import ListingTable from 'common/components/listingTable/ListingTable';
import ListingTableSidebar from 'common/components/listingTable/sidebar/ListingTableSidebar';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Loading from 'common/services/Loading';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import Button from 'common/components/button/Button';
import DateFormat from 'common/components/dateFormat/dateFormat';

const PAGE_ID = 'BACKOFFICE_TAXES_LIST';

function TaxListScreen(): JSX.Element | null {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<ListingTableData<TaxDto, Partial<TaxSearchCriteria>>>(ListingTableCache.get(PAGE_ID, {
        order: { field: 'description', orderColumn: 't.description', isOrderAsc: true },
        hiddenColumns: [],
        columnsOrder: [],
        columnsWidth: [],
        page: 1,
        filters: {},
        lastRowId: null,
    }));
    const [taxes, setTaxes] = useState<TaxDto[]>([]);
    const [taxToDelete, setTaxToDelete] = useState<TaxDto | null>(null);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['TAXES_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['TAXES_READ'])
    const [refreshKey, setRefreshKey] = useState(0);
    const [scrollToRowId, setScrollToRowId] = useState<string | null>(null);
    const firstLoad = useRef(true);

    const columns: ListingTableColumn<TaxDto>[] = orderColumns([
        {
            name: t('taxes.list.description'),
            field: 'description',
            filter: { type: 'text', value: tableData.filters.description ?? '', placeholder: t('taxes.list.description') },
            minWidth: '18rem',
        },
        {
            name: t('taxes.list.value'),
            field: 'value',
            cellFormat: 'money',
            suffix: ' %',
            cellAlignment: 'right',
            filter: { type: 'money', value: tableData.filters.value,unitPrefix: '%' },
            minWidth: '18rem',
        },
        {
            name: t('common.updated_date'),
            field: 'updatedDate',
            filter: {
                type: 'date-range',
                value: {
                    start: tableData.filters.updatedDate?.start ? new Date(tableData.filters.updatedDate.start) : null,
                    end: tableData.filters.updatedDate?.end ? new Date(tableData.filters.updatedDate.end) : null
                }
            },
            minWidth: '22rem',
            orderColumn: 'updated_date',
            renderCell: (row) => {
                return row.updatedDate ? <DateFormat value={row.updatedDate} format={DATE_TIME_FORMAT_DEFAULT} /> : '';
            }
        },
        {
            name: t('common.user_updated'),
            field: 'userUpdatedName',
            filter: { type: 'text', value: tableData.filters.userUpdatedName ?? '', placeholder: t('common.user_updated') },
            minWidth: '21rem',
            orderColumn: 'user_updated_name',
        },
    ], tableData.columnsOrder);

    const renderAction = (row: TaxDto) => (
        <TableActionButton
            options={[
                { label: <span><FaTrash /> {t('common.delete')}</span>, onClick: () => onOpenModalToDelete(row) },
            ]}
        />
    )

    useEffect(() => {
        void getData();
    }, [tableData.page, tableData.filters, tableData.order, refreshKey]);

    useEffect(() => {
        ListingTableCache.save(PAGE_ID, tableData);
    }, [tableData]);

    const getData = async () => {
        if (isLoading) {
            return;
        }

        try {
            setIsLoading(true);
            const taxesData = await TaxesService.getList({
                ...tableData.filters,
                page: firstLoad.current && tableData.page > 1 ? 1 : tableData.page,
                itemsPerPage: firstLoad.current && tableData.page > 1 ? DEFAULT_PAGINATION_ITEMS_PER_PAGE * tableData.page : DEFAULT_PAGINATION_ITEMS_PER_PAGE,
                orderColumn: tableData.order.orderColumn,
                orderBy: tableData.order.isOrderAsc ? 'asc' : 'desc',
                active: true,
            });

            const newListElements = taxesData.currentPage === 1 ? taxesData.items : [...taxes, ...taxesData.items]
            setHasMore(taxesData.totalItems > newListElements.length);
            setTaxes(newListElements);
            setTotalItems(taxesData.totalItems)

            if (firstLoad.current) {
                setScrollToRowId(tableData.lastRowId);
            }
            firstLoad.current = false;
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get families', error);
            toast.error(t('messages.error_load_info'));
        }

        setIsLoading(false);
    }

    const navigateTo = (type: string, id?: string) => {
        if (type === 'create') {
            navigate(`/backoffice/taxes/${type}`);
        } else if (id) {
            ListingTableCache.saveLastRowId(PAGE_ID, id);
            navigate(`/backoffice/taxes/${type}/${id}`);
        }
    }

    const onOpenModalToDelete = (row: TaxDto) => {
        setTaxToDelete(row);
        setShowConfirmDeleteModal(true)
    }

    const onDelete = async (result: boolean) => {
        if (!result) {
            setShowConfirmDeleteModal(false)
            return;
        }
        try {
            await TaxesService.remove(taxToDelete as TaxDto)
            setTableData(d => ({ ...d, page: 1 }));
            setRefreshKey(k => k + 1);
            Loading.hide();
            toast.success(t('messages.record_delete_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete tax', error);
            toast.error(t('messages.record_delete_error'));
        }
        setShowConfirmDeleteModal(false)
    }

    const onLoadMore = () => {
        setTableData(d => ({ ...d, page: d.page + 1 }));
    }

    const onFilter = (col: ListingTableColumn<TaxDto>, filterData: FilterData) => {
        setTableData(d => {
            const data = ({
                ...d, page: 1
            })
            if (col.field === 'description') {
                data.filters = {
                    ...data.filters,
                    description: filterData.value as string,
                };
            }
            if (col.field === 'value') {
                data.filters = {
                    ...data.filters,
                    value: filterData.value as number,
                };
            }
            if (col.field === 'updatedDate') {
                data.filters = {
                    ...data.filters,
                    updatedDate: filterData.value,
                    updatedDateStart: filterData.value?.start as Date,
                    updatedDateEnd: filterData.value?.end as Date
                };
            }
            if (col.field === 'userUpdatedName') {
                data.filters = {
                    ...data.filters,
                    userUpdatedName: filterData.value as string
                };
            }
            return data;
        });
        setRefreshKey(k => k + 1);
    }

    const onOrder = (orderData: OrderData<TaxDto>) => {
        setRefreshKey(k => k + 1)
        setTableData(d => ({ ...d, order: orderData, page: 1 }));
    }

    const onClearFilters = () => {
        setTableData(d => ({ ...d, page: 1, filters: {}, lastRowId: null }));
        setRefreshKey(k => k + 1)
    }

    if (!canRead) {
        return null;
    }

    return (<ScreenTitle title={t('taxes.title')}>
        <PageBreadcrumbsPortal
            breadcrumbs={[
                { name: t('home.title'), url: '/' },
                { name: t('taxes.title'), url: '/backoffice/taxes' },
            ]}
        />
        <PageHeader title={t('taxes.title')} subTitle={`${totalItems} ${t('common.total_results')}`} informationText={t('taxes.list.sub_title')} showGoBack={false} addSidebarSpacing >
            {canWrite && <Button onClick={() => navigateTo('create')}>{t('common.new')}</Button>}
        </PageHeader>
        <PageContainer>
            <ListingTable<TaxDto>
                columns={columns}
                rows={taxes}
                order={tableData.order}
                hiddenColumns={tableData.hiddenColumns}
                columnsWidths={tableData.columnsWidth}
                onOrder={onOrder}
                onFilter={onFilter}
                onClearFilters={onClearFilters}
                onRenderAction={canWrite ? renderAction : undefined}
                onRowClick={(row) => navigateTo('details', row.id)}
                infiniteScroll={{
                    isLoading,
                    hasMore,
                    onLoadMore,
                }}
                onRowId={r => r.id}
                scrollToRowId={scrollToRowId}
                onColResize={(w) => setTableData(d => ({ ...d, columnsWidth: w }))}
            />
        </PageContainer>
        <ListingTableSidebar
            columns={columns}
            hiddenColumns={tableData.hiddenColumns}
            onChangeHiddenColumns={(hc) => setTableData(d => ({ ...d, hiddenColumns: hc }))}
            onChangeColumnsOrder={(oc) => setTableData(d => ({ ...d, columnsOrder: oc }))}
        />
        <ConfirmDeleteModal
            itemName={taxToDelete?.description}
            isOpen={showConfirmDeleteModal}
            onClose={(result) => onDelete(result)}
        />
    </ScreenTitle>)
}

export default TaxListScreen;
