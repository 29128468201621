import React, { ReactElement } from 'react';
import ReactModal from 'react-modal';
import styles from './Modal.module.scss';

type ModalHeaderProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const ModalHeader = (props: ModalHeaderProps) => (
    <div {...props} className={`${styles.modalHeader} ${props.className ?? ''}`} />
);

type ModalBodyProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const ModalBody = (props: ModalBodyProps) => (
    <div {...props} className={`${styles.modalBody} ${props.className ?? ''}`} />
);

type ModalFooterProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const ModalFooter = (props: ModalFooterProps) => (
    <div {...props} className={`${styles.modalFooter} ${props.className ?? ''}`} />
);

type Props = {
    children: ReactElement | ReactElement[];
} & ReactModal.Props;

const Modal: React.FC<Props> = ({ children, ...props }: Props) => {
    const customStyles = {
        content: {
            ...(props.style?.content ? props.style.content : {}),
        },
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            zIndex: 200,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            ...(props.style?.overlay ? props.style.overlay : {})
        },
    };

    return (
        <ReactModal
            {...props}
            style={{
                ...(props.style ? props.style : {}),
                ...customStyles,

            }}
            className={`${styles.content} ${props.className}`}
        >
            {children}
        </ReactModal >
    );
};

export default Modal;
