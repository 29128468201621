import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { Header, Label } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/button/Button';
import Modal, { ModalHeader, ModalBody, ModalFooter } from 'common/components/modal/Modal';
import SearchableSelectInput from 'common/components/searchableSelectInput/SearchableSelectInput';
import { OptionTitleSubTitle, SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';
import { Row } from 'react-bootstrap';
import { WorkExternalPartDto } from 'api/works/models/WorkDto';
import SelectInput, { SelectInputOption } from 'common/components/selectInput/SelectInput';

type Props = {
    onLoadItems: (inputValue: string, callback: (options: SearchableSelectTitleSubTitleOption[], hasMore: boolean) => void) => void;
    onClose: (newContact?: string) => void;
    selectedExternalParts: (WorkExternalPartDto)[];
} & ReactModal.Props;

const ContactModal: React.FC<Props> = ({ onLoadItems, onClose, selectedExternalParts, ...props }: Props) => {
    const { t } = useTranslation();

    const [contact, setContact] = useState<string | undefined>(undefined);
    const [externalPartSelected, setExternalPartSelected] = useState<string | undefined>(undefined);
    const [showExternalPartDrop, setShowExternalPartDrop] = useState<boolean>(false);

    const onAddNewContact = () => {
        const externalPart = selectedExternalParts.find(x => x.externalPartId === externalPartSelected);
        window.open('/external-parts/' + externalPart?.externalPartCategoryId + '/edit/' + externalPart?.externalPartId);
        setShowExternalPartDrop(false);
        setExternalPartSelected(undefined);
    }

    const onClickRefreshAfterNew = () => {
        setShowExternalPartDrop(false);
        setExternalPartSelected(undefined);
    }

    const closeModal = (result?: string | undefined) => {
        setShowExternalPartDrop(false);
        setExternalPartSelected(undefined);
        setContact(undefined);
        onClose(result);
    }

    return (
        <Modal {...props} style={{ content: { minWidth: 600 } }}>
            <div>
                <ModalHeader>
                    <Header>{t('works.work.contacts').toUpperCase()}</Header>
                </ModalHeader>
                <ModalBody>
                    <Row className='mb-3'>
                        <Label>{t('works.work.select_contact')}</Label>
                        <SearchableSelectInput
                            value={contact}
                            loadOptions={onLoadItems}
                            onChange={(item) => {
                                setContact(item ?? undefined);
                                setShowExternalPartDrop(false);
                            }}
                            formatOptionLabel={OptionTitleSubTitle}
                            onClickAddNew={contact ? undefined : () => setShowExternalPartDrop(true)}
                            onClickRefreshAfterNew={onClickRefreshAfterNew}
                        />
                    </Row>
                    {showExternalPartDrop && <Row className='mb-3'>
                        <Label>{t('works.work.select_external_part')}</Label>
                        <SelectInput
                            value={externalPartSelected}
                            options={selectedExternalParts.map(item => ({
                                label: item.externalPartName ?? '',
                                value: item.externalPartId!,
                            } satisfies SelectInputOption))}
                            onChange={(item) => {
                                setExternalPartSelected(item ?? undefined);
                            }}
                            formatOptionLabel={OptionTitleSubTitle}
                            onClickAddNew={externalPartSelected ? () => onAddNewContact() : undefined}
                            showAddWhenSelected={true}
                        />
                    </Row>}
                </ModalBody>
                <ModalFooter className="text-right">
                    <Button fw variant='secondary' onClick={() => { closeModal(); }}>{t('common.cancel')}</Button>
                    <Button onClick={() => { closeModal(contact); }}>{t('common.save')}</Button>
                </ModalFooter>
            </div>
        </Modal >
    );
};

export default ContactModal;
