import Button from 'common/components/button/Button';
import { ParameterSectionBaseModel, ParameterSectionParameterType } from '../models';
import styles from './Parameter.module.scss';
import { IoMoveOutline, IoTrash } from 'react-icons/io5';
import TextInput from 'common/components/textInput/TextInput';
import { Label } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import SelectInput from 'common/components/selectInput/SelectInput';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSSProperties } from 'react';
import { CSS } from '@dnd-kit/utilities';
import CheckInput from 'common/components/checkInput/CheckInput';
import { OptionTitleSubTitle, SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';
import { Col, Row } from 'react-bootstrap';

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
    !(isSorting || wasDragging);

export interface ParameterSectionProps {
    parameter: ParameterSectionBaseModel;
    disabled?: boolean;
    unitOptions?: SearchableSelectTitleSubTitleOption[];
    selectInputOptions?: SearchableSelectTitleSubTitleOption[];
    onChange: (parameter: ParameterSectionBaseModel) => void;
    onRemove: () => void;
}

const Parameter = ({ parameter, disabled, unitOptions, selectInputOptions, onChange, onRemove }: ParameterSectionProps) => {
    const { t } = useTranslation();

    const options = Object.keys(ParameterSectionParameterType).map(key => ({
        label: t('common.parameters_sections.parameters_types.' + key),
        value: key,
    }));

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: parameter.key,
        animateLayoutChanges,
        disabled,
    });

    const style: CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition,
        opacity: isDragging ? 0.3 : 1,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={styles.container}
        >
            {!disabled &&
                <div className={styles.buttonContainer}>
                    <Button variant="third" className={`${styles.button} ${styles.dragHandle}`} {...attributes} {...listeners}>
                        <IoMoveOutline />
                    </Button>
                </div>
            }
            <Row className={styles.row}>
                <Col xs={12} md={12} lg={6} xl={4} xxl={3} xxxl={2}>
                    <div className={styles.control}>
                        <Label space>{t('common.parameters_sections.parameter_name')}</Label>
                        <TextInput
                            value={parameter.parameterName ?? ''}
                            onChange={(e) => onChange({ ...parameter, parameterName: e.target.value })}
                            placeholder={t('common.write_here')}
                            disabled={disabled}
                        />
                    </div>
                </Col>
                <Col xs={12} md={12} lg={6} xl={4} xxl={3} xxxl={2}>
                    <div className={styles.control}>
                        <Label space>{t('common.parameters_sections.parameter_type')}</Label>
                        <SelectInput
                            value={parameter.parameterType}
                            options={options}
                            disabled={disabled}
                            onChange={(v) => onChange({ ...parameter, parameterType: v as ParameterSectionParameterType })}
                        />
                    </div>
                </Col>
                {parameter.parameterType !== 'NUMBER' && parameter.parameterType !== 'NUMBER_MIN_MAX'
                    ? null :
                    <Col xs={12} md={12} lg={6} xl={4} xxl={3} xxxl={1}>
                        <div className={styles.control}>
                            <Label space>{t('common.parameters_sections.unit')}</Label>
                            <SelectInput
                                value={parameter.unitId}
                                options={unitOptions || []}
                                onChange={(v) => onChange({ ...parameter, unitId: v })}
                                disabled={disabled}
                            />
                        </div>
                    </Col>
                }
                {parameter.parameterType !== 'PRODUCTS' && parameter.parameterType !== 'NUMBER_MIN_MAX'
                    ? null :
                    <Col xs={12} md={12} lg={6} xl={4} xxl={3} xxxl={2}>
                        <div className={styles.control}>
                            <Label space>{t('common.parameters_sections.parameter_family')}</Label>
                            <SelectInput
                                value={parameter.parameterFamilyId}
                                options={selectInputOptions || []}
                                formatOptionLabel={OptionTitleSubTitle}
                                onChange={(v) => onChange({ ...parameter, parameterFamilyId: v })}
                                disabled={disabled}
                            />
                        </div>
                    </Col>
                }
                <Col xs={12} md={12} lg={6} xl={4} xxl={3} xxxl={1}>
                    <div className={styles.checkbox}>
                        <CheckInput
                            checked={parameter.parameterIsRequired ?? false}
                            disabled={disabled}
                            onChange={(e) => onChange({ ...parameter, parameterIsRequired: e.target.checked })}
                        >
                            {t('common.parameters_sections.parameter_required')}
                        </CheckInput>
                    </div>
                </Col>
            </Row>
            {!disabled &&
                <div className={`${styles.buttonContainer}`}>
                    <Button variant="third" className={styles.button} onClick={onRemove}><IoTrash /></Button>
                </div>
            }
        </div>
    );
};

export default Parameter;
