import { Label } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { ExternalPartContactDto, ExternalPartDto } from 'api/externalPart/models/ExternalPartDto';
import InputError from '../inputError/InputError';
import { TextInputController } from '../textInput/TextInput';
import { FieldErrors, UseFormReturn } from 'react-hook-form';
import { PhoneCodeOption, PhoneInputController } from '../phoneInput/PhoneInput';
import { useEffect } from 'react';

export interface ContactsSelectorProps {
    form: UseFormReturn<ExternalPartDto>;
    errors: FieldErrors<ExternalPartDto>;
    phoneCodesOptions: PhoneCodeOption[];
    item: ExternalPartContactDto;
    disabled?: boolean;
    onChange: (items: ExternalPartContactDto[]) => void;
    index: number;
}

export interface ContactsSelectInputOption {
    label: string;
    value: string;
}

const ContactsSelector = ({ form, errors, item, disabled, phoneCodesOptions, onChange, index }: ContactsSelectorProps) => {
    const { t } = useTranslation();

    return (
        <Row >
            <Col sm={12} md={6} className="mb-3">
                <Label space>{t('external_parts.contact.name_last_name')}{!disabled ? '*' : ''}</Label>
                <TextInputController
                    key={index}
                    name={`contacts.${index}.name`}
                    control={form.control}
                    placeholder={t('external_parts.contact.name_last_name')} disabled={disabled}
                    rules={{ required: true, maxLength: 250 }}
                    hasError={Boolean(errors.companyName)}
                />
                <InputError error={errors?.contacts?.[index]?.name} maxLength={250} />
            </Col>
            <Col sm={12} md={6} className="mb-3">
                <Label space>{t('external_parts.contact.function')}</Label>
                <TextInputController
                    key={index}
                    name={`contacts.${index}.function`}
                    control={form.control}
                    placeholder={t('external_parts.contact.function')} disabled={disabled}
                    rules={{ maxLength: 250 }}
                    hasError={Boolean(errors.companyName)} />
                <InputError error={errors?.contacts?.[index]?.function} maxLength={250} />
            </Col>
            <Col sm={12} md={6} className="mb-3">
                <Label space>{t('external_parts.contact.phone_number')}</Label>
                <PhoneInputController
                    key={index}
                    name={`contacts.${index}.phoneNumber`}
                    control={form.control}
                    onChange={(number) => {
                        form.setValue(`contacts.${index}.phoneNumber`, number ?? '');
                        if (number) {
                            form.setValue(`contacts.${index}.alreadyChangePhone`, true);
                        }
                    }}
                    onChangePhoneCode={() => {
                        form.setValue(`contacts.${index}.alreadyChangePhone`, true);
                    }}
                    disabled={disabled}
                    phoneCodes={phoneCodesOptions}
                    phoneCodeValueName={`contacts.${index}.phoneCodeId`}
                />
                <InputError error={errors?.contacts?.[index]?.phoneNumber} />
            </Col>
            <Col sm={12} md={6} className="mb-3">
                <Label space>{t('external_parts.contact.email')}</Label>
                <TextInputController
                    key={index}
                    name={`contacts.${index}.email`}
                    control={form.control}
                    type='email'
                    placeholder={t('external_parts.contact.email')}
                    disabled={disabled}
                    rules={{
                        maxLength: 250,
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'email',
                        }
                    }}
                    hasError={Boolean(errors?.contacts?.[index]?.email)} />

                <InputError error={errors?.contacts?.[index]?.email} maxLength={250} />
            </Col>

        </Row>
    );
};

export default ContactsSelector;
