import { DATE_TIME_FORMAT_DEFAULT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';

import ListingTable from 'common/components/listingTable/ListingTable';
import ListingTableCache, { ListingTableData } from 'common/components/listingTable/ListingTableCache';
import TableActionButton from 'common/components/listingTable/actionButton/TableActionButton';
import { OrderData, ListingTableColumn, FilterData } from 'common/components/listingTable/models';
import ListingTableSidebar from 'common/components/listingTable/sidebar/ListingTableSidebar';
import { orderColumns } from 'common/components/listingTable/tableUtils';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Loading from 'common/services/Loading';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Logger from 'common/services/Logger';
import Toast from 'common/services/Toast';
import Button from 'common/components/button/Button';

import { ExternalPartDto } from 'api/externalPart/models/ExternalPartDto';
import { ExternalPartSearchCriteria } from 'api/externalPart/models/ExternalPartSearchCriteria';
import ExternalPartsService from 'api/externalPart/ExternalPartsService';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ExternalPartCategoriesService from 'api/externalPartCategory/ExternalPartCategoriesService';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import { SelectInputOption } from 'common/components/selectInput/SelectInput';
import { TaxDto } from 'api/taxes/models/TaxDto';
import TaxesService from 'api/taxes/TaxesService';
import CountriesService from 'api/countries/CountriesService';
import { CountryDto } from 'api/countries/models/CountryDto';
import DateFormat from 'common/components/dateFormat/dateFormat';

const PAGE_ID = 'EXTERNAL_PARTS_LIST';

function ExternalPartsListScreen(): JSX.Element | null {
    const { t } = useTranslation();
    const { categoryId } = useParams<{ categoryId: string }>();
    const [categoryName, setCategoryName] = useState<string>('');

    const navigate = useNavigate();
    const [tableData, setTableData] = useState<ListingTableData<ExternalPartDto, Partial<ExternalPartSearchCriteria>>>(ListingTableCache.get(PAGE_ID, {
        order: { field: 'companyName', orderColumn: 'ep.company_name', isOrderAsc: true },
        hiddenColumns: [],
        columnsOrder: [],
        columnsWidth: [],
        page: 1,
        filters: {},
        lastRowId: null,
    }));

    const [externalParts, setExternalParts] = useState<ExternalPartDto[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0)
    const [externalPartToDelete, setExternalPartToDelete] = useState<ExternalPartDto | null>(null);

    const [taxes, setTaxes] = useState<SelectInputOption[]>()
    const [countries, setCountries] = useState<SelectInputOption[]>()

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['EXTERNAL_PARTS_READ'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['EXTERNAL_PARTS_WRITE'])

    const [scrollToRowId, setScrollToRowId] = useState<string | null>(null);
    const firstLoad = useRef(true);
    const [refreshKey, setRefreshKey] = useState(0);

    const columns: ListingTableColumn<ExternalPartDto>[] = orderColumns([
        {
            name: t('external_parts.list.company_name'),
            field: 'companyName',
            filter: { type: 'text', value: tableData.filters.companyName ?? '', placeholder: t('external_parts.list.company_name') },
            minWidth: '18rem',
            orderColumn: 'company_name'
        },
        {
            name: t('external_parts.list.address'),
            field: 'address',
            filter: { type: 'text', value: tableData.filters.address ?? '', placeholder: t('external_parts.list.address') },
            minWidth: '18rem',
        },
        {
            name: t('external_parts.list.zip_code'),
            field: 'zipCode',
            filter: { type: 'text', value: tableData.filters.zipCode ?? '', placeholder: t('external_parts.list.zip_code') },
            minWidth: '18rem',
            orderColumn: 'zip_code'
        },
        {
            name: t('external_parts.list.city'),
            field: 'city',
            filter: { type: 'text', value: tableData.filters.city ?? '', placeholder: t('external_parts.list.city') },
            minWidth: '18rem',
        },
        {
            name: t('external_parts.list.country'),
            field: 'countryId',
            filter: { type: 'dropdown', value: tableData.filters.countryId ?? '', options: countries || [] },
            minWidth: '18rem',
            orderColumn: 'country_name',
            renderCell: (row) => <div>{row.countryName}</div>
        },
        {
            name: t('external_parts.list.tax'),
            field: 'taxId',
            filter: { type: 'dropdown', value: tableData.filters.taxId ?? '', options: taxes || [] },
            minWidth: '18rem',
            orderColumn: 'tax_name',
            renderCell: (row) => renderTax(row)

        },
        {
            name: t('external_parts.list.default_discount'),
            field: 'defaultDiscount',
            filter: { type: 'text', value: tableData.filters.defaultDiscount ?? '', placeholder: t('external_parts.list.default_discount') },
            minWidth: '18rem',
            orderColumn: 'default_discount',
            cellAlignment: 'right',
            renderCell: (row) => {
                return row.defaultDiscount ? <MoneyFormat value={row.defaultDiscount} suffix={'%'} /> : '';
            }
        },
        {
            name: t('common.updated_date'),
            field: 'updatedDate',
            filter: {
                type: 'date-range',
                value: {
                    start: tableData.filters.updatedDate?.start ? new Date(tableData.filters.updatedDate.start) : null,
                    end: tableData.filters.updatedDate?.end ? new Date(tableData.filters.updatedDate.end) : null
                }
            },
            minWidth: '22rem',
            orderColumn: 'updated_date',
            renderCell: (row) => {
                return row.updatedDate ? <DateFormat value={row.updatedDate} format={DATE_TIME_FORMAT_DEFAULT} /> : '';
            }
        },
        {
            name: t('common.user_updated'),
            field: 'userUpdatedName',
            filter: { type: 'text', value: tableData.filters.userUpdatedName ?? '', placeholder: t('common.user_updated') },
            minWidth: '21rem',
            orderColumn: 'user_updated_name',
        },
    ], tableData.columnsOrder);

    const renderTax = (row: ExternalPartDto) => {
        return <div>
            {row.taxId && (<span>{(row.taxValue + '% - ' + row.taxName)}</span>)}
        </div>
    }

    const renderAction = (row: ExternalPartDto) => (
        <TableActionButton
            options={[
                { label: <span><FaEdit /> {t('common.edit')}</span>, onClick: () => navigateTo('edit', row.id) },
                { label: <span><FaTrash /> {t('common.delete')}</span>, onClick: () => onOpenModalToDelete(row) },
            ]}
        />
    )

    const onFilter = (col: ListingTableColumn<ExternalPartDto>, filterData: FilterData) => {
        setTableData(d => {
            const data = ({
                ...d, page: 1
            })
            data.filters = {
                ...data.filters
            };

            if (col.field === 'companyName') {
                data.filters = {
                    ...data.filters,
                    companyName: filterData.value as string
                };
            }
            if (col.field === 'address') {
                data.filters = {
                    ...data.filters,
                    address: filterData.value as string
                };
            }

            if (col.field === 'zipCode') {
                data.filters = {
                    ...data.filters,
                    zipCode: filterData.value as string
                };
            }

            if (col.field === 'city') {
                data.filters = {
                    ...data.filters,
                    city: filterData.value as string
                };
            }

            if (col.field === 'countryId') {
                data.filters = {
                    ...data.filters,
                    countryId: filterData.value as string
                };
            }

            if (col.field === 'taxId') {
                data.filters = {
                    ...data.filters,
                    taxId: filterData.value as string
                };
            }

            if (col.field === 'defaultDiscount') {
                data.filters = {
                    ...data.filters,
                    defaultDiscount: filterData.value as string
                };
            }

            if (col.field === 'updatedDate') {
                data.filters = {
                    ...data.filters,
                    updatedDate: filterData.value,
                    updatedDateStart: filterData.value?.start as Date,
                    updatedDateEnd: filterData.value?.end as Date
                };
            }

            if (col.field === 'userUpdatedName') {
                data.filters = {
                    ...data.filters,
                    userUpdatedName: filterData.value as string
                };
            }

            return data;
        });
        setRefreshKey(k => k + 1);
    }

    const onClearFilters = () => {
        setTableData(d => ({ ...d, page: 1, filters: {} }));
        setRefreshKey(k => k + 1)
    }

    const onOrder = (orderData: OrderData<ExternalPartDto>) => {
        setRefreshKey(k => k + 1)
        setTableData(d => ({ ...d, order: orderData, page: 1 }));
    }

    useEffect(() => {
        void getData();
    }, [tableData.page, tableData.filters, tableData.order, refreshKey, categoryId]);

    useEffect(() => {
        ListingTableCache.save(PAGE_ID, tableData);
    }, [tableData]);

    const getData = async () => {
        try {
            if (isLoading) { return; }

            Loading.show();
            setIsLoading(true);
            const data = await ExternalPartsService.getList({
                ...tableData.filters,
                externalPartCategoryId: categoryId,
                page: firstLoad.current && tableData.page > 1 ? 1 : tableData.page,
                itemsPerPage: firstLoad.current && tableData.page > 1 ? DEFAULT_PAGINATION_ITEMS_PER_PAGE * tableData.page : DEFAULT_PAGINATION_ITEMS_PER_PAGE,
                orderColumn: tableData.order.orderColumn,
                orderBy: tableData.order.isOrderAsc ? 'asc' : 'desc'
            });

            const newListElements = data.currentPage === 1 ? data.items : [...externalParts, ...data.items]
            setHasMore(data.totalItems > newListElements.length);
            setExternalParts(newListElements);
            setTotalItems(data.totalItems)

            if (firstLoad.current) {
                const [taxesData, countriesData] = await Promise.all([
                    TaxesService.getFormattedCatalog(),
                    CountriesService.getCatalog()
                ]);

                setTaxes(taxesData.map((x: TaxDto) => {
                    return {
                        label: x.description,
                        value: x.id
                    }
                }))

                setCountries(countriesData.map((x: CountryDto) => {
                    return {
                        label: x.name,
                        value: x.id
                    }
                }))

                setScrollToRowId(tableData.lastRowId);
            }
            firstLoad.current = false;
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get external parts list', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
            setIsLoading(false);
        }
    }

    const navigateTo = (type: string, id?: string) => {
        if (type === 'create') {
            navigate(`/external-parts/${categoryId}/${type}`);
        } else if (id) {
            ListingTableCache.saveLastRowId(PAGE_ID, id);
            navigate(`/external-parts/${categoryId}/${type}/${id}`);
        }
    }

    const onLoadMore = () => {
        setTableData(d => ({ ...d, page: d.page + 1 }));
    }

    const onOpenModalToDelete = (row: ExternalPartDto) => {
        setExternalPartToDelete(row);
        setShowConfirmDeleteModal(true)
    }

    const onDelete = async (result: boolean) => {
        if (!result) {
            setShowConfirmDeleteModal(false)
            return;
        }
        try {
            await ExternalPartsService.remove(externalPartToDelete as ExternalPartDto)

            setTableData(d => ({ ...d, page: 1 }));
            setRefreshKey(k => k + 1);

            Loading.hide();
            Toast.success(t('messages.record_delete_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete external part', error);
            Toast.error(t('messages.record_delete_error'));
        }
        setShowConfirmDeleteModal(false)
    }

    useEffect(() => {
        void getExternalPartCategory();
    }, [categoryId]);

    const getExternalPartCategory = async () => {
        try {
            if (isLoading || !categoryId) { return; }

            Loading.show();
            setIsLoading(true);

            const result = await ExternalPartCategoriesService.getById(categoryId);

            setCategoryName(result.name);
            setIsLoading(true);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get external part category', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
            setIsLoading(false);
        }
    }

    if (!canRead) {
        return null;
    }

    return (
        <ScreenTitle title={categoryName}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: categoryName, url: `/external-parts/${categoryId}` },
                ]}
            />
            <PageHeader title={categoryName} subTitle={`${totalItems} ${t('common.total_results')}`} showGoBack={false} addSidebarSpacing informationText={t('common.list')} >
                {canWrite && <Button onClick={() => navigateTo('create')}>{t('common.new')}</Button>}
            </PageHeader>
            <PageContainer>
                <ListingTable<ExternalPartDto>
                    columns={columns}
                    rows={externalParts}
                    order={tableData.order}
                    hiddenColumns={tableData.hiddenColumns}
                    columnsWidths={tableData.columnsWidth}
                    onOrder={onOrder}
                    onFilter={onFilter}
                    onClearFilters={onClearFilters}
                    onRenderAction={canWrite ? renderAction : undefined}
                    onRowClick={(row) => navigateTo('details', row.id)}
                    infiniteScroll={{
                        isLoading,
                        hasMore,
                        onLoadMore,
                    }}
                    onRowId={r => r.id}
                    scrollToRowId={scrollToRowId}
                    onColResize={(w) => setTableData(d => ({ ...d, columnsWidth: w }))}
                />
            </PageContainer>
            <ListingTableSidebar
                columns={columns}
                hiddenColumns={tableData.hiddenColumns}
                onChangeHiddenColumns={(hc) => setTableData(d => ({ ...d, hiddenColumns: hc }))}
                onChangeColumnsOrder={(oc) => setTableData(d => ({ ...d, columnsOrder: oc }))}
            />
            <ConfirmDeleteModal
                itemName={externalPartToDelete?.companyName}
                isOpen={showConfirmDeleteModal}
                onClose={(result) => onDelete(result)}
            />
        </ScreenTitle>
    );
}

export default ExternalPartsListScreen;
