import styles from './FilesUploaderImagesList.module.scss';
import { useTranslation } from 'react-i18next';
import { AttachmentDto } from 'api/common/models/AttachmentDto';
import { FaTrash } from 'react-icons/fa';
import Button from 'common/components/button/Button';
import RadioInput from 'common/components/radioInput/RadioInput';
import { Image } from 'common/components/image/Image';

interface Props {
    files: AttachmentDto[];
    disabled?: boolean;
    canSetIsMain?: boolean;
    onRemove: (file: AttachmentDto, newMainFile?: AttachmentDto) => void;
    onSetIsMain: (file: AttachmentDto) => void;
}

const FilesUploaderImagesList = ({ files, disabled, canSetIsMain, onRemove, onSetIsMain }: Props) => {
    const { t } = useTranslation();

    const filteredFiles = files.filter(f => !f.remove);

    const onRemoveFile = (file: AttachmentDto) => {
        let newMainFile;

        if (canSetIsMain && file.isMain) {
            if (filteredFiles[0] === file) { newMainFile = filteredFiles[1] } else { newMainFile = filteredFiles[0] }
        }
        onRemove(file, newMainFile)
    }

    return (
        <div className={styles.container}>
            {filteredFiles.map((file, i) => (
                <div key={i} className={styles.fileContainer}>
                    <Image src={file.url} containerClassName={styles.imageContainer} />
                    {/* <div className={styles.imageContainer}>
                    </div> */}
                    {!disabled && <Button variant="none" size='small' className={styles.fileRemoveIcon} onClick={() => onRemoveFile(file)}>
                        <FaTrash />
                    </Button>}
                    {canSetIsMain && (
                        <div className={styles.isMainContainer}>
                            <RadioInput
                                checked={file.isMain ?? false}
                                onChange={() => onSetIsMain(file)}
                                disabled={disabled}
                            >
                                {t('common.main_image')}
                            </RadioInput>
                        </div>
                    )}
                </div>
            ))}
            {filteredFiles.length === 0 && (
                <div className={styles.noItemsContainer}>
                    {t('common.without_images')}
                </div>
            )}
        </div>
    );
}

export default FilesUploaderImagesList;
