import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import styles from './PricesTab.module.scss';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { Label } from 'common/components/texts/Texts';
import MoneyInput from 'common/components/moneyInput/MoneyInput';
import { useDispatch, useSelector } from 'react-redux';
import { calculateLineTotals, updateLine } from 'store/budget/action';
import SelectInput from 'common/components/selectInput/SelectInput';
import { useContext } from 'react';
import { LinesDataProvider } from '../../../LinesDataProvider';
import { DEFAULT_DECIMAL_PLACES } from 'Config';
import { Reducers } from 'store/types';
import { BudgetPDFVisibility } from 'api/elements/Models/ElementDto';

interface Props {
    line: BudgetLineDto;
    disabled: boolean;
}

function PricesTab({ line, disabled }: Props): JSX.Element | null {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { taxes } = useContext(LinesDataProvider);
    const decimalPlaces = useSelector<Reducers, number>(x => x.budget.budget.decimalPlaces ?? DEFAULT_DECIMAL_PLACES);

    const taxesOptions = taxes.map(t => ({ selectedLabel: t.value?.toString() + ' %', label: t.description, value: t.id }));

    const onChangeValue = (newLine: Partial<BudgetLineDto>) => {
        dispatch(updateLine({ key: line.key, ...newLine }));
        dispatch(calculateLineTotals(line.key));
    }

    return (
        <div className={styles.container}>
            <Row className='mb-3'>
                <Col>
                    <Label space>{t('elements.budget_pdf_visibility')}</Label>
                    <SelectInput
                        value={line.pdfVisibility}
                        options={[
                            {
                                label: t('elements.budget_pdf_visibility_options.element_and_price_visible'),
                                value: BudgetPDFVisibility.ELEMENT_AND_PRICE_VISIBLE
                            },
                            {
                                label: t('elements.budget_pdf_visibility_options.price_invisible'),
                                value: BudgetPDFVisibility.PRICE_INVISIBLE
                            },
                            {
                                label: t('elements.budget_pdf_visibility_options.element_invisible'),
                                value: BudgetPDFVisibility.ELEMENT_INVISIBLE
                            },
                        ]}
                        disabled={disabled}
                        onChange={(v) => onChangeValue({ pdfVisibility: v ?? null })}
                        removeRemoveButton
                    />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col xs={4}>
                    <Label space>{t('budgets.budget.lines.header.gross_unit_price')}</Label>
                    <MoneyInput
                        value={line.grossUnitPrice}
                        onBlur={(v) => onChangeValue({ grossUnitPrice: v ?? 0 })}
                        unitPrefix="€"
                        decimalScale={decimalPlaces}
                        disabled={disabled}
                    />
                </Col>
                <Col xs={4}>
                    <Label space>{t('budgets.budget.lines.header.margin')}</Label>
                    <MoneyInput
                        value={line.marginPercentage}
                        onBlur={(v) => onChangeValue({ marginPercentage: v ?? 0 })}
                        unitPrefix="%"
                        disabled={disabled}
                    />
                </Col>
                <Col xs={4}>
                    <Label space>{t('budgets.budget.lines.header.discount')}</Label>
                    <MoneyInput
                        value={line.discountPercentage}
                        onBlur={(v) => onChangeValue({ discountPercentage: v ?? 0 })}
                        unitPrefix="%"
                        disabled={disabled}
                    />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col xs={4}>
                    <Label space>{t('budgets.budget.lines.header.price_per_unity')}</Label>
                    <MoneyInput
                        value={line.unitPrice}
                        unitPrefix="€"
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </Col>
                <Col xs={4}>
                    <Label space>{t('budgets.budget.lines.header.total_unit_without_tax')}</Label>
                    <MoneyInput
                        value={line.totalUnitExcludingTax}
                        onBlur={(v) => onChangeValue({ totalUnitExcludingTax: v ?? 0 })}
                        unitPrefix="€"
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </Col>
                <Col xs={4}>
                    <Label space>{t('budgets.budget.lines.header.unit_price_with_children')}</Label>
                    <MoneyInput
                        value={line.unitPriceWithChildren}
                        unitPrefix="€"
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={4}>
                    <Label space>{t('budgets.budget.lines.header.total_without_tax')}</Label>
                    <MoneyInput
                        value={line.totalExcludingTax}
                        onBlur={(v) => onChangeValue({ totalExcludingTax: v ?? 0 })}
                        unitPrefix="€"
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </Col>
                <Col xs={4}>
                    <Label space>{t('budgets.budget.lines.header.tax')}</Label>
                    <SelectInput
                        value={line.taxId}
                        options={taxesOptions}
                        onChange={v => onChangeValue({ taxId: v, taxValue: taxes.find(x => x.id === v)?.value ?? 0 })}
                        disabled={disabled}
                    />
                </Col>
                <Col xs={4}>
                    <Label space>{t('budgets.budget.lines.header.total_with_tax')}</Label>
                    <MoneyInput
                        value={line.totalIncludingTax}
                        unitPrefix="€"
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </Col>
            </Row>
        </div>
    );
}

export default PricesTab;
