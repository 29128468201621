import { useTranslation } from 'react-i18next';
import styles from './ProductCard.module.scss';
import MoneyInput from '../moneyInput/MoneyInput';
import { FaEye, FaFilePdf, FaRegFilePdf } from 'react-icons/fa';
import { FiMaximize } from 'react-icons/fi';
import colors from 'styles/export/colors.module.scss';
import CarouselModal from '../carouselModal/CarouselModal';
import { useState } from 'react';
import { SubHeader } from '../texts/Texts';
import { UnitOption } from '../dimensionInput/DimensionInput';
import { Image } from '../image/Image';
import { LoadingSpinnerCenter } from '../loading/LoadingSpinner';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { DEFAULT_DECIMAL_PLACES } from 'Config';

export interface ProductCardProps {
    mainImage: string;
    images: string[];
    name: string;
    family: string;
    subfamily: string;
    quantity: number;
    enableDataSheet: boolean;
    unit: string;
    unitOptions: UnitOption[];
    price?: number;
    priceIncluded?: boolean;
    selected?: boolean;
    className?: string;
    showBottomArrow?: boolean;
    showOpenElement?: boolean;
    isLoading?: boolean;
    disabled?: boolean;
    onSelect: () => void;
    onChangePrice?: (value: number | null | undefined) => void;
    onChangeQuantity?: (value: number | null | undefined) => void;
    onChangeUnit?: (unitId: string | null | undefined) => void;
    onClickOpenElement?: () => void;
    onChangeEnableDataSheet?: (enableDataSheet: boolean) => void;
}

const ProductCard = ({
    mainImage, images, name, family, subfamily, enableDataSheet, price, quantity, unit, unitOptions,
    priceIncluded, selected, className, isLoading, showBottomArrow, showOpenElement, disabled, onChangeEnableDataSheet,
    onClickOpenElement, onSelect, onChangePrice, onChangeQuantity, onChangeUnit
}: ProductCardProps) => {
    const { t } = useTranslation();
    const [isCarouselModalOpen, setIsCarouselModalOpen] = useState<boolean>(false);

    const decimalPlaces = useSelector<Reducers, number>(x => x.budget.budget.decimalPlaces ?? DEFAULT_DECIMAL_PLACES);

    const handleClickOpenElement = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        if (onClickOpenElement) {
            onClickOpenElement();
        }
    }

    const handleChangeEnableDataSheet = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        if (onChangeEnableDataSheet) {
            onChangeEnableDataSheet(!enableDataSheet)
        }
    }

    return (
        <div className={styles.className}>
            <div className={`${styles.cardContainer} ${selected ? styles.selected : ''}`} onClick={onSelect}>
                <div className={styles.cardHeader}>
                    <div className={styles.cardHeaderImage}>
                        <Image src={mainImage} containerClassName={`${styles.image} ${mainImage ? '' : styles.noImage} `} />
                    </div>
                    <div className={styles.cardHeaderTop}>
                        <div className={styles.button} onClick={disabled ? undefined : handleChangeEnableDataSheet}>
                            {!enableDataSheet && <FaRegFilePdf size={20} color={colors.primary} />}
                            {enableDataSheet && <FaFilePdf size={20} color={colors.primary} />}
                        </div>
                    </div>
                    <div className={styles.cardHeaderBottom}>
                        {showOpenElement && <div className={styles.button} onClick={handleClickOpenElement}>
                            <FaEye size={20} color={colors.primary} />
                        </div>}
                        <div className={styles.button} onClick={(e) => { e.stopPropagation(); setIsCarouselModalOpen(true); }}>
                            <FiMaximize size={20} color={colors.primary} />
                        </div>
                    </div>
                    {isLoading && <div className={styles.loadingContainer}><LoadingSpinnerCenter /></div>}
                </div>
                <div className={styles.cardBody}>
                    <SubHeader className={styles.cardName}>{name}</SubHeader>
                    <span className={styles.cardFamily}>
                        {family}
                        {subfamily && <span> · </span>}
                        {subfamily}
                    </span>
                </div>
                <div className={styles.cardFooter}>
                    <div className={styles.cardPrice} onClick={(e) => { e.stopPropagation() }}>
                        {priceIncluded
                            ? t('product_card.included')
                            : <MoneyInput
                                value={price}
                                unitPrefix='€'
                                onBlur={onChangePrice}
                                disabled={disabled}
                                decimalScale={decimalPlaces}
                            />
                        }
                    </div>
                    <div className={styles.cardQuantity} onClick={(e) => { e.stopPropagation() }}>
                        <MoneyInput
                            value={quantity}
                            unit={unit}
                            unitsOptions={unitOptions}
                            onBlur={onChangeQuantity}
                            onChangeUnit={onChangeUnit}
                            popperFixed
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
            {showBottomArrow && <div className={styles.bottomArrow}></div>}

            <CarouselModal
                images={images}
                isOpen={isCarouselModalOpen}
                onClose={() => setIsCarouselModalOpen(false)}/>
        </div>
    );
}

export default ProductCard;
