import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import './App.css';
import store from './store/store';
import i18n from './common/services/I18n';
import Setup from './Setup';
import DefaultRouter from './Router';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import LoadingProvider from 'common/components/loading/LoadingProvider';
import { FaTimes } from 'react-icons/fa';
import { Label } from 'common/components/texts/Texts';
import { Tooltip } from 'react-tooltip'
import { BODY_TOOLTIP } from 'Config';

const App: React.FunctionComponent = () => {
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <Toaster
                    position='top-right'
                    toastOptions={{
                        duration: 5000,
                    }}
                >
                    {(t) => (
                        <ToastBar toast={t}>
                            {({ icon, message }) => (
                                <>
                                    {icon}
                                    {message}
                                    {t.type !== 'loading' && (
                                        <Label onClick={() => toast.dismiss(t.id)} className='cursor-pointer'><FaTimes /></Label>
                                    )}
                                </>
                            )}
                        </ToastBar>
                    )}
                </Toaster>
                <Setup>
                    <DefaultRouter />
                </Setup>
                <LoadingProvider />
                <Tooltip id={BODY_TOOLTIP} style={{ zIndex: 500 }} />
            </I18nextProvider>
        </Provider>
    );
};

export default App;
