import toast from 'react-hot-toast';
import { FaExclamationTriangle } from 'react-icons/fa';
import colors from 'styles/export/colors.module.scss';

class Toast {
    public error(message: string) {
        toast.error(message, {
            style: {
                color: colors.danger,
            }
        });
    }

    public success(message: string) {
        toast.success(message, {
            style: {
                color: colors.success,
            }
        });
    }

    public warning(message: string, duration?: number) {
        toast(message,
            {
                icon: <FaExclamationTriangle fontSize={19} color={colors.warning} />,
                style: {
                    color: colors.warning,
                },
                duration,
            }
        );
    }
}

export default new Toast();
