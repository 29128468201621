import { SelectInputOption } from 'common/components/selectInput/SelectInput';
import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { WorkTypeDto } from './models/WorkTypeDto';
import { WorkTypeCatalogSearchCriteria, WorkTypeSearchCriteria } from './models/WorkTypeSearchCriteria';

class WorkTypesService {
    public getList (criteria: WorkTypeSearchCriteria) {
        return Http.get<Paged<WorkTypeDto>>('workTypes', criteria);
    }

    public getCatalog (criteria?: WorkTypeCatalogSearchCriteria) {
        return Http.get<SelectInputOption[]>('workTypes/catalog', criteria);
    }

    public getById (id: string) {
        return Http.get<WorkTypeDto>('workTypes/' + id);
    }

    public create (model: WorkTypeDto): Promise<string> {
        return Http.post('workTypes', model);
    }

    public update (model: WorkTypeDto) {
        return Http.put('workTypes', model);
    }

    public delete (id: string, deleteRelations: boolean) {
        return Http.delete('workTypes/' + id + '/' + deleteRelations);
    }
}

export default new WorkTypesService();
