/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { LOGGER_LOG_TYPE } from 'Config';
import { UserProfile } from 'api/account/models/UserProfile';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Reducers } from 'store/types';
import styles from './AccountScreen.module.scss';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import AccountService from 'api/account/AccountService';
import { setProfile } from 'store/authentication/action';
import Toast from 'common/services/Toast';

const AccountScreen: React.FC = () => {
    const { type } = useParams<{ type: string }>();
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [validated, setValidated] = useState(false);
    const [item, setItem] = useState(userProfile);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const initStatus = {
        id: '',
        realName: '',
        email: '',
        password: '',
        confirm: '',
    };
    const [formData, setFormData] = useState(initStatus);

    const navigateTo = (type: string) => {
        navigate(`/account/${type}`);
        setIsDetails(type === 'details');
    }

    const getData = async () => {
        try {
            setItem(userProfile);
            setFormData({ ...initStatus, ...userProfile });
            setIsDetails(type === 'details');
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get account information', error);
            Toast.error(t('messages.error_load_info'));
        }
    };

    useEffect(() => {
        getData().catch(console.error);
    }, [type, userProfile]);

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [key]: value });
    }

    const onSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (type === 'password' && formData.password !== formData.confirm) {
            Toast.error(t('messages.password_and_confirmation_doesnt_match_error'))
            return;
        }
        setValidated(true);
        if (form.checkValidity() === false) {
            return;
        }
        try {
            Loading.show();
            if (type === 'password') {
                await AccountService.changePassword(formData.password);
            } else {
                await AccountService.changeAccount(formData);
                const profile = await AccountService.profile();
                await dispatch(setProfile(profile) as any);
            }
            navigateTo('edit');
            Loading.hide();
            Toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the account with id: ${form.id}`, error);
            Toast.error(t('messages.error_load_info'));
            Loading.hide();
        }
    };

    return (
        <ScreenTitle title={t('account.title')}>
            {/* <Form onSubmit={onSubmit} noValidate validated={validated} key={`form_${type}`}> */}
            {(type === 'edit' || type === 'details') && <Row>
                <Col xs={10} >
                    <Col xs={12} >
                        <Form.Group className="mb-3" controlId="realName">
                            <Form.Label>{t('account.name')}{!isDetails ? '*' : ''}</Form.Label>
                            <Form.Control required name="realName" type="text" defaultValue={item?.realName} readOnly={isDetails} onChange={handleChange} placeholder={t('account.name')} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} >
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>{t('account.email')}</Form.Label>
                            <Form.Control name="email" type="text" defaultValue={item?.email} readOnly={true} onChange={handleChange} placeholder={t('account.email')} />
                        </Form.Group>
                    </Col>
                </Col>
            </Row>}
            {type === 'password' && <Row>
                <Col xs={10} >
                    <Col xs={12} >
                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>{t('account.password')}{!isDetails ? '*' : ''}</Form.Label>
                            <Form.Control required name="password" type="password" onChange={handleChange} placeholder={t('account.password')} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} >
                        <Form.Group className="mb-3" controlId="confirm">
                            <Form.Label>{t('account.password_confirm')}</Form.Label>
                            <Form.Control required name="confirm" type="password" onChange={handleChange} placeholder={t('account.password_confirm')} />
                        </Form.Group>
                    </Col>
                </Col>
            </Row>}

            <div className={styles.buttonsContainer}>
                <Button variant="secondary" type="button" className={styles.button} onClick={() => { navigateTo('details'); }}>
                    {t('common.cancel')}
                </Button>
                {isDetails &&
                        <Button variant="danger" type="button" className={styles.button} onClick={() => { navigateTo('password'); }}>
                            {t('common.change_password')}
                        </Button>
                }
                {isDetails &&
                        <Button variant="warning" type="button" className={styles.button} onClick={() => { navigateTo('edit'); }}>
                            {t('common.edit')}
                        </Button>
                }
                {!isDetails &&
                        <Button variant="primary" type="submit" className={styles.button}>
                            {t('common.save')}
                        </Button>
                }
            </div>
            {/* </Form> */}
        </ScreenTitle>
    );
};

export default AccountScreen;
