import { UserManager, WebStorageStateStore, User } from 'oidc-client';
import { ApplicationPaths, ApplicationName } from '../../Config';
import { setToken } from 'store/authentication/action';
import store from 'store/store';

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};

export class AuthorizeService {
    private userManager: UserManager | undefined = undefined;
    private readonly _callbacks: any = [];
    private readonly _nextSubscriptionId = 0;
    private _user: User | undefined | null = null;
    private _isAuthenticated = false;
    private readonly _popUpDisabled = true;
    private refreshTokenPromise: Promise<User | null> | null = null;

    public createArguments = (state: any) => ({ useReplaceToNavigate: true, data: state });
    public error = (message: any) => ({ status: AuthenticationResultStatus.Fail, message });
    public success = (state: any, user: User | undefined) => ({ status: AuthenticationResultStatus.Success, state, user });
    public redirect = () => ({ status: AuthenticationResultStatus.Redirect });

    public async isAuthenticated() {
        const user = await this.getUser();
        return user != null;
    }

    public async getUser() {
        if ((this._user?.profile) != null) {
            return this._user.profile;
        }
        await this.ensureUserManagerInitialized();
        const user = await this.userManager?.getUser();
        return user?.profile;
    }

    public async getAccessToken() {
        await this.ensureUserManagerInitialized();
        const user = await this.userManager?.getUser();
        return user?.access_token;
    }

    public async signIn(state: any) {
        await this.ensureUserManagerInitialized();
        try {
            await this.userManager?.signinRedirect(this.createArguments(state));
            return this.redirect();
        } catch (redirectError) {
            return this.error(redirectError);
        }
    }

    public async completeSignIn(url: any) {
        try {
            await this.ensureUserManagerInitialized();
            const user = await this.userManager?.signinCallback(url);
            this.updateState(user);
            return this.success(user?.state, user);
        } catch (error) {
            return this.error('There was an error signing in.');
        }
    }

    async signOut(state: any) {
        await this.ensureUserManagerInitialized();
        try {
            await this.userManager?.signoutRedirect(this.createArguments(state));
            return this.redirect();
        } catch (redirectSignOutError) {
            return this.error(redirectSignOutError);
        }
    }

    async completeSignOut(url: any) {
        await this.ensureUserManagerInitialized();
        try {
            const response = await this.userManager?.signoutCallback(url);
            this.updateState(null);
            return this.success(response?.state, undefined);
        } catch (error) {
            return this.error(error);
        }
    }

    updateState(user: User | undefined | null) {
        this._user = user;
        this._isAuthenticated = this._user != null;
    }

    async ensureUserManagerInitialized() {
        if (this.userManager !== undefined) {
            return;
        }
        const response = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl);
        if (!response.ok) {
            throw new Error(`Could not load settings for '${ApplicationName}'`);
        }
        const settings = await response.json();
        settings.response_type = 'code';
        settings.scope = 'openid profile email api_scope offline_access';
        settings.automaticSilentRenew = true;
        settings.includeIdTokenInSilentRenew = true;
        settings.silent_redirect_uri = window.location.origin + '/silent-renew.html';
        settings.userStore = new WebStorageStateStore({
            prefix: ApplicationName
        });

        this.userManager = new UserManager(settings);

        this.userManager.events.addUserLoaded((user) => {
            this.updateState(user);
        });

        this.userManager.events.addUserSignedOut(() => {
            this.userManager?.removeUser().then(() => this.updateState(undefined)).catch(() => { });
        });

        // this.userManager.events.addAccessTokenExpired(() => {
        //     console.log('Access token expired. Attempting silent renew...');
        //     this.userManager?.signinSilent().catch((error) => {
        //         console.error('Silent renew error:', error);
        //         if (error.message.includes('invalid_grant')) {
        //             console.error('Refresh token is invalid or expired. Redirecting to sign-in...');
        //             // void this.signIn(); // Force a full sign-in if refresh token is invalid
        //         } else {
        //             this.updateState(null); // Log the user out on other errors
        //         }
        //     });
        // });

        this.userManager.events.addSilentRenewError((error) => {
            console.error('Silent renew error:', error);
            // Handle token renewal failure
            this.updateState(null);
        });
    }

    public async handleTokenRefresh() {
        if (this.refreshTokenPromise) {
            // If a refresh is already in progress, return the same promise
            // eslint-disable-next-line @typescript-eslint/return-await
            return this.refreshTokenPromise;
        }

        // Create a new refresh token promise
        // eslint-disable-next-line no-async-promise-executor
        this.refreshTokenPromise = new Promise(async (resolve, reject) => {
            try {
                const user = await this.userManager?.signinSilent();
                this.updateState(user);

                if (user && user.access_token) {
                    const token = { token: user.access_token, tokenType: user.token_type, expires: 0 };
                    store.dispatch(setToken(token) as any);
                }

                resolve(user as any);
            } catch (error) {
                console.error('Silent renew error:', error);
                this.updateState(null);
                reject(error);
            } finally {
                // Clear the promise once the refresh is done
                this.refreshTokenPromise = null;
            }
        });

        // eslint-disable-next-line @typescript-eslint/return-await
        return this.refreshTokenPromise;
    }

    getUserManager() {
        return this.userManager;
    }

    static get instance() { return authService; }
}

const authService = new AuthorizeService();

export default authService;
