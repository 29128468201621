import { FamilyDto } from 'api/families/models/FamilyDto';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Logger from 'common/services/Logger';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOGGER_LOG_TYPE } from 'Config';
import FamiliesService from 'api/families/FamiliesService';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'common/services/Loading';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { Label } from 'common/components/texts/Texts';
import { TextInputController } from 'common/components/textInput/TextInput';
import { TextAreaInputController } from 'common/components/textAreaInput/TextAreaInput';
import Button from 'common/components/button/Button';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import { FaTrashAlt } from 'react-icons/fa';
import { CheckInputController } from 'common/components/checkInput/CheckInput';
import InputError from 'common/components/inputError/InputError';
import { SearchableSelectInputController } from 'common/components/searchableSelectInput/SearchableSelectInputController';
import { Reducers } from 'store/types';
import { useSelector } from 'react-redux';
import UsersService from 'api/users/UsersService';
import { UserProfile } from 'api/account/models/UserProfile';
import Toast from 'common/services/Toast';
import InfoIcon from 'common/components/infoIcon/InfoIcon';
import InformationModal from 'common/components/modal/informationModal/InformationModal';
import { ParameterSectionBaseModel, ParameterSectionParameterType, ParameterSectionType } from 'common/components/parametersSections/models';
import { SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';
import { SelectInputController, SelectInputOption } from 'common/components/selectInput/SelectInput';
import WorkTypesService from 'api/workTypes/WorkTypesService';
import UnitsService from 'api/units/UnitsService';
import { Form } from 'common/components/form/Form';
import { IoAddSharp } from 'react-icons/io5';
import styles from './FamilyScreen.module.scss';
import { newSection, onMove } from './parameters/utils';
import { SortableTree } from 'common/components/sortableTree/SortableTree';
import { FlattenedItem } from 'common/components/sortableTree/types';
import ParameterLine from './parameters/ParameterLine';
import { AtLeast } from 'common/types/Atleast';
import { FamilyParameterDataProvider } from './parameters/FamilyParameterDataProvider';
import YesNoModal from 'common/components/modal/yesNoModal/YesNoModal';

type FamiliesListType = 'elements' | 'components';

interface Props {
    listType: FamiliesListType;
    isSubFamilies?: boolean;
}

function FamilyScreen({ listType, isSubFamilies }: Props): JSX.Element | null {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id, type } = useParams<{ id: string, type: 'create' | 'details' | 'edit' | 'duplicate' }>();
    const form = useForm<FamilyDto>({ shouldUnregister: false });
    const errors = form.formState.errors;
    const url = '/library/' + listType + (isSubFamilies ? '/sub-families' : '/families');
    const currentUrl = url + '/' + type + (id ? ('/' + id) : '');
    const isDetails = type === 'details';
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmDeleteRelationsModal, setShowConfirmDeleteRelationsModal] = useState(false);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['FAMILIES_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['FAMILIES_READ'])
    const [showTypesInformationModal, setShowTypesInformationModal] = useState(false);
    const isStart = useRef<boolean>(true);
    const [familiesInputOptions, setFamiliesInputOptions] = useState<SearchableSelectTitleSubTitleOption[]>([])
    const [sections, setSections] = useState<ParameterSectionBaseModel[]>([])
    const [workTypes, setWorkTypes] = useState<SelectInputOption[]>([]);
    const [units, setUnits] = useState<SearchableSelectTitleSubTitleOption[]>([]);
    const [selectedSectionKey, setSelectedSectionKey] = useState<string>('');
    const [family, setFamily] = useState<Partial<FamilyDto>>();

    const getData = async () => {
        try {
            let result: Partial<FamilyDto> = {
                id,
                isComponent: listType === 'components',
                isElement: listType === 'elements',
                name: '',
                description: '',
                parentId: null,
            }

            Loading.show();

            if (id) {
                result = await FamiliesService.getById(id);
                if (result?.parameters?.length) {
                    result.parameters.forEach((x) => {
                        x.isOpen = true;
                    })
                    setSections(result.parameters);
                }

                if (result && type === 'duplicate') {
                    result.name += ' - ' + t('common.copy');
                }
            }

            const famResult = await FamiliesService.catalog();
            setFamiliesInputOptions(famResult);

            const workTypesResult = await WorkTypesService.getCatalog({ workTypeId: result.workTypeId ?? undefined });
            setWorkTypes(workTypesResult);

            const unitsResult = await UnitsService.getFormattedCatalog();
            setUnits(unitsResult.map(x => {
                return {
                    value: x.id,
                    label: x.name,
                    subTitle: ''
                }
            }))

            form.reset(result);
            setFamily(result);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get families', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const onSubmit = async (model: FamilyDto) => {
        try {
            Loading.show();

            if (sections && model.isComponent && sections.find(x => x.type === ParameterSectionType.PARAMETER && x.parameterType !== ParameterSectionParameterType.PRODUCTS)) {
                Toast.warning(t('families.family.family_component_error_save'));
                Loading.hide();
                return;
            }

            model.parameters = sections;

            if (model && model.id && type !== 'duplicate') {
                await FamiliesService.update(model)
                // navigateTo('details', model.id);
                void getData();
            } else if (model) {
                const id = await FamiliesService.create(model)
                navigateTo('edit', id);
            }
            Toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t update save the family', error);
            Toast.error(t('messages.record_save_error'));
        }
        Loading.hide();
    }

    const onInvalid = () => {
        Toast.warning(t('messages.required_fields_empty'));
    }

    const onDelete = async (result: boolean) => {
        setShowConfirmDeleteModal(false);

        if (result) {
            setShowConfirmDeleteRelationsModal(true);
        }
    }

    const onDeleteRelations = async (result: boolean) => {
        Loading.show();
        try {
            await FamiliesService.delete(id!, result)
            Toast.success(t('messages.record_delete_success'));
            onGoBack();
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete family', error);
            Toast.error(t('messages.record_delete_error'));
        } finally {
            Loading.hide();
            setShowConfirmDeleteRelationsModal(false)
        }
    }

    const onGoBack = () => {
        navigate(url);
    }

    const navigateTo = (type: string, id?: string) => {
        navigate(url + '/' + type + (id ? ('/' + id) : ''));
    }

    const onLoadFamiliesParentsOptions = (
        inputValue: string,
        callback: (options: any[], hasMore: boolean) => void,
        _isStart: boolean,
        page: number
    ) => {
        FamiliesService.getList({
            hasParentId: false,
            isComponent: listType === 'components',
            isElement: listType === 'elements',
            itemsPerPage: 10,
            page,
            name: isStart.current ? form.getValues('parentName') ?? inputValue : inputValue,
        }).then(result => {
            isStart.current = false;

            const options: FamilyDto[] = result.items.map(item => ({ ...item, label: item.name, value: item.id, }))
            callback(options, result.currentPage < result.totalPages);
        }).catch((error) => {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get families', error);
            Toast.error(t('messages.error_load_info'));
        });
    };

    const onClickAddNewSection = () => {
        const section = newSection(null);
        setSections([...sections, ...[section]]);
    }

    const onItemChange = (item: AtLeast<FlattenedItem<ParameterSectionBaseModel>, 'key'>, reason: 'change' | 'remove' | 'change-self-and-children') => {
        const removeDescendants = (lines: ParameterSectionBaseModel[], parentKey: string): ParameterSectionBaseModel[] => {
            const directChildren = lines.filter(line => line.parentKey === parentKey);

            directChildren.forEach(child => {
                lines = removeDescendants(lines, child.key);
            });

            return lines.filter(line => line.parentKey !== parentKey);
        };

        const alterDescendants = (lines: ParameterSectionBaseModel[], key: string, value: boolean): ParameterSectionBaseModel[] => {
            lines.forEach(x => {
                if (x.key === key) {
                    x.visibleOnlyInBudgets = value
                }
            })

            const children = lines.filter(x => x.parentKey === key);

            children.forEach(child => {
                child.visibleOnlyInBudgets = value;
                lines = alterDescendants(lines, child.key, value);
            });

            return lines
        };

        if (reason === 'remove') {
            let updatedLines = sections.filter(line => line.key !== item.key);
            updatedLines = removeDescendants(updatedLines, item.key);
            setSections([...updatedLines])
        }

        if (reason === 'change') {
            setSections(sections.map((section) => {
                if (section.key === item.key) {
                    return { ...section, ...item };
                }
                return section;
            }));
        }

        if (reason === 'change-self-and-children') {
            const newLines = alterDescendants(sections, item.key, item.visibleOnlyInBudgets ?? false)
            setSections([...newLines])
        }
    }

    const onItemCreate = (item: ParameterSectionBaseModel) => {
        if (item.parentKey === null) {
            let newSections = sections;
            const index = sections.findIndex(l => l.key === item.relativeKey);
            if (index < 0) {
                newSections = [...newSections, item]
            } else {
                const totalChildren = getTotalLinesInside(sections, item.relativeKey!);
                newSections = [
                    ...newSections.slice(0, index + 1 + totalChildren),
                    item,
                    ...newSections.slice(index + 1 + totalChildren),
                ];
            }
            setSections(newSections)
        } else {
            let newLines = [...sections];
            const index = sections.findIndex(l => l.key === (item.relativeKey ?? item.parentKey));
            if (index > -1) {
                const relativeItem = sections[index];
                const totalChildren = relativeItem.key !== item.parentKey && relativeItem.type === ParameterSectionType.SECTION && item.type === ParameterSectionType.SECTION
                    ? getTotalLinesInside(sections, item.relativeKey!)
                    : 0;
                newLines = [
                    ...newLines.slice(0, index + 1 + totalChildren),
                    item,
                    ...newLines.slice(index + 1 + totalChildren),
                ];
            }
            newLines.map(x => {
                if (x.key === item.parentKey) {
                    x.isOpen = true;
                }
                return x;
            })
            setSections([...newLines])
        }
    }

    const onMoveItem = (key: string, type: 'up' | 'down') => {
        const newLines = onMove(sections, key, type)

        setSections([...newLines])
    }

    function getTotalLinesInside(lines: ParameterSectionBaseModel[], lineKey: string | null) {
        const children = lines.filter(x => x.parentKey === lineKey);

        let totalCount = children.length;

        for (const child of children) {
            totalCount += getTotalLinesInside(lines, child.key);
        }

        return totalCount;
    }

    const onClickBody = (ev: MouseEvent) => {
        if (ev && ev.target) {
            const target = ev.target as HTMLElement;
            const targetId = target.id;

            if (targetId.includes('react-select-')) {
                return;
            }
        }

        if (selectedSectionKey) {
            setSelectedSectionKey('');
        }
    }

    const onChangeFamilyType = () => {
        setFamily({ ...family, isComponent: form.getValues('isComponent'), isElement: form.getValues('isElement') });
    }

    useEffect(() => {
        document.body.addEventListener('click', onClickBody);

        return function cleanup() {
            window.removeEventListener('click', onClickBody);
        }
    }, [selectedSectionKey, setSelectedSectionKey]);

    useEffect(() => {
        void getData();
    }, [id, type]);

    let title = type === 'create' ? t('common.new') : (type === 'edit' ? t('common.edit') : t('common.details'));
    let listTitle = '';
    switch (listType) {
        case 'elements':
            listTitle = isSubFamilies ? t('families.list.elements_sub_family_title') : t('families.list.elements_family_title');
            title += ' ' + (isSubFamilies ? t('families.family.elements_sub_family_title') : t('families.family.elements_family_title'));
            break;
        case 'components':
            listTitle = isSubFamilies ? t('families.list.components_sub_family_title') : t('families.list.components_family_title');
            title += ' ' + (isSubFamilies ? t('families.family.components_sub_family_title') : t('families.family.components_family_title'));
            break;
        default:
            break;
    }

    if (!canRead) {
        return null;
    }

    return (
        <ScreenTitle title={title}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: listTitle, url },
                    { name: title, url: currentUrl },
                ]}
            />
            <PageHeader title={title} informationText={t('common.go_back')} showGoBack onGoBack={onGoBack}>
                {canWrite && !isDetails && <Button form='formfamily' type='submit'>{t('common.save')}</Button>}
                {canWrite && isDetails && <Button fw onClick={() => { navigateTo('duplicate', id); }}>{t('common.duplicate')}</Button>}
                {canWrite && isDetails && <Button fw onClick={() => { navigateTo('edit', id); }}>{t('common.edit')}</Button>}
                {canWrite && isDetails && (
                    <Button variant='danger' onClick={() => setShowConfirmDeleteModal(true)}>
                        <FaTrashAlt />
                    </Button>
                )}
            </PageHeader>
            <PageContainer addBottomSpace>
                <Form id='formfamily' handleSubmit={form.handleSubmit} onSubmit={onSubmit} onInvalid={onInvalid} noValidate className={styles.form}>
                    <Row className="mb-3">
                        {isSubFamilies && (
                            <Col xs={6}>
                                <Label space>{t('families.family.family')}{!isDetails ? '*' : ''}</Label>
                                <SearchableSelectInputController
                                    name='parentId'
                                    control={form.control}
                                    objectValue={form.getValues('selectObject') as any}
                                    loadOptions={onLoadFamiliesParentsOptions}
                                    disabled={isDetails}
                                    rules={{ required: true }}
                                    hasError={Boolean(errors.parentId)}
                                    onChange={(_, option) => {
                                        if (option) {
                                            form.setValue('isComponent', option.isComponent);
                                            form.setValue('isElement', option.isElement);
                                            form.setValue('workTypeId', option.workTypeId)
                                        }
                                    }}
                                />
                                <InputError error={errors.parentId} />
                            </Col>
                        )}
                        <Col xs={6}>
                            <Label space>{t('families.family.name')}{!isDetails ? '*' : ''}</Label>
                            <TextInputController
                                name='name'
                                control={form.control}
                                placeholder={t('families.family.name')}
                                disabled={isDetails}
                                rules={{ required: true, maxLength: 500 }}
                                hasError={Boolean(errors.name)}
                            />
                            <InputError error={errors.name} maxLength={500} />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12}>
                            <Label space>{t('families.family.type')}
                                {!isDetails ? '*' : ''}
                                {isSubFamilies && <InfoIcon onClick={() => setShowTypesInformationModal(true)} />}
                            </Label>

                            <CheckInputController
                                name='isComponent'
                                control={form.control}
                                placeholder={t('families.family.component')}
                                disabled={isDetails || isSubFamilies}
                                onChange={() => {
                                    void form.trigger('isElement');
                                    onChangeFamilyType();
                                }}
                            >
                                {t('families.family.component')}
                            </CheckInputController>
                            <CheckInputController
                                name='isElement'
                                control={form.control}
                                placeholder={t('families.family.element')}
                                disabled={isDetails || isSubFamilies}
                                rules={{
                                    validate: () => {
                                        return !!(form.watch('isComponent') || form.watch('isElement')) || 'error';
                                    }
                                }}
                                onChange={() => {
                                    onChangeFamilyType();
                                }}
                            >
                                {t('families.family.element')}
                            </CheckInputController>
                            <InputError error={errors.isComponent || errors.isElement} />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12}>
                            <Label space>{t('families.family.work_type')}</Label>
                            <SelectInputController
                                control={form.control}
                                name='workTypeId'
                                options={workTypes || []}
                                disabled={isDetails || isSubFamilies}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12}>
                            <Label space>{t('families.family.description')}</Label>
                            <TextAreaInputController
                                name='description'
                                control={form.control}
                                placeholder={t('families.family.description')}
                                disabled={isDetails}
                                rules={{ maxLength: 2000 }}
                                hasError={Boolean(errors.description)}
                            />
                            <InputError error={errors.description} maxLength={2000} />
                        </Col>
                    </Row>

                    {sections?.length
                        ? <FamilyParameterDataProvider.Provider value={{ family, familiesInputOptions, units, selectedSectionKey, sections }}>
                            <SortableTree
                                items={sections}
                                onItemsChanged={(newItems) => setSections(newItems as ParameterSectionBaseModel[])}
                                onItemChange={onItemChange}
                                onItemCreate={onItemCreate}
                                onMoveItem={onMoveItem}
                                onSelectItem={setSelectedSectionKey}
                                TreeItemComponent={ParameterLine}
                                canHaveChildren={(item) => (item.type === ParameterSectionType.SECTION)}
                                canRootHaveChildren={true}
                                dropAnimation={null}
                                indentationWidth={20}
                                disableSorting={isDetails}
                            />
                        </FamilyParameterDataProvider.Provider>
                        : isDetails
                            ? null
                            : <div className="text-center">
                                <Button variant={'third'} className={`${styles.addButton}`} onClick={onClickAddNewSection}>
                                    <IoAddSharp fontSize={18} />
                                    <span className='v-align-middle'>
                                        {t('common.parameters_sections.click_add_section')}
                                    </span>
                                </Button>
                            </div>
                    }
                </Form>
            </PageContainer>

            <ConfirmDeleteModal
                itemName={form.getValues('name')}
                isOpen={showConfirmDeleteModal}
                onClose={onDelete}
            />

            <YesNoModal
                title={t('common.question')}
                message={listType === 'components' ? t('families.delete_components_relations') : t('families.delete_elements_relations')}
                onClose={onDeleteRelations}
                isOpen={showConfirmDeleteRelationsModal}
            />

            <InformationModal
                message={t('families.family.types_information')}
                isOpen={showTypesInformationModal}
                onClose={() => setShowTypesInformationModal(false)}
            />
        </ScreenTitle>
    );
}

export default FamilyScreen;
