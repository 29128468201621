import { useTranslation } from 'react-i18next';
import styles from './SelectInput.module.scss';
import LibSelect, { FormatOptionLabelMeta, GroupBase, StylesConfig, createFilter } from 'react-select';
import colors from './../../../styles/export/colors.module.scss';
import { FaPlus, FaSync, FaTrashAlt, FaEye } from 'react-icons/fa';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import { ReactNode, useState } from 'react';

export const defaultSelectInputStyles = (disabled: boolean, addDropdownIndicatorPadding: boolean, hasError: boolean, smallerValueContainer: boolean): StylesConfig<any, false, GroupBase<any>> => {
    return {
        container: base => ({
            ...base,
            flex: 1,
        }),
        menuPortal: base => ({
            ...base,
            zIndex: 200,
        }),
        menu: base => ({
            ...base,
            zIndex: 3,
            border: '1px solid ' + colors.light,
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '8px',
        }),
        control: (base, state) => ({
            ...base,
            backgroundColor: disabled ? colors.light3 : '#FFFFFF',
            borderRadius: 8,
            border: hasError ? ('1px solid ' + colors.danger) : (state.isFocused ? '1px solid ' + colors.light2 : '1px solid ' + colors.light),
            boxShadow: 'none',
            ':hover': {
                borderColor: hasError ? colors.danger : (state.isFocused ? colors.light2 : colors.light),
            },
            width: '100%',
        }),
        placeholder: base => ({
            ...base,
            color: colors.light2,
            opacity: 1,
            fontWeight: '300',
            fontSize: '14px',
            fontFamily: 'Alexandria'
        }),
        valueContainer: (base, state) => ({
            ...base,
            padding: smallerValueContainer && state.hasValue ? '5px 0.6rem 4px' : '0.6rem',
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? colors.light : colors.white,
            ':hover': {
                backgroundColor: colors.light,
            },
            color: colors.primaryDark,
            fontSize: '14px',
            padding: '12px',
        }),
        singleValue: (base, state) => ({
            ...base,
            color: disabled ? colors.light2 : colors.primaryDark,
            whiteSpace: 'nowrap',
            fontWeight: 'normal',
            fontSize: '14px',
            fontFamily: 'Alexandria',
            paddingLeft: '0.25rem',
            paddingRight: '0.25rem'
        }),
        indicatorSeparator: base => ({
            ...base,
            display: 'none',
        }),
        clearIndicator: base => ({
            ...base,
            paddingLeft: 0,
            paddingRight: 0,
        }),
        dropdownIndicator: base => ({
            ...base,
            paddingLeft: 0,
            paddingRight: addDropdownIndicatorPadding ? 20 : undefined,
        })
    };
}

export interface SelectInputOption {
    label: string;
    value: string;
}

export interface SelectProps<T> {
    value: string | null | undefined;
    options: SelectInputOption[];
    disabled?: boolean;
    hasError?: boolean;
    showAddWhenSelected?: boolean;
    smallerValueContainer?: boolean;
    removeRemoveButton?: boolean;
    onClickGoToDetails?: (id: string) => void;
    onClickAddNew?: () => void;
    onClickRefreshAfterNew?: () => void;
    formatOptionLabel?: ((data: T, formatOptionLabelMeta: FormatOptionLabelMeta<T>) => ReactNode) | undefined;
    onChange: (value: string | null | undefined, oldValue: string | null | undefined) => void;
}

const SelectInput = <T extends SelectInputOption>({ value, disabled, options, hasError, showAddWhenSelected, smallerValueContainer, removeRemoveButton, onChange, formatOptionLabel, onClickAddNew, onClickRefreshAfterNew,onClickGoToDetails }: SelectProps<T>) => {
    const { t } = useTranslation();
    const [needsRefresh, setNeedsRefresh] = useState(false);

    return (
        <div className={styles.container}>
            <LibSelect
                hideSelectedOptions={false}
                noOptionsMessage={() => t('common.no_options')}
                options={options as any}
                value={value ? options.find(x => x.value === value) as T : null}
                onChange={v => {
                    onChange(v ? v.value : null, value)
                }}
                filterOption={createFilter({
                    ignoreCase: true,
                    ignoreAccents: true,
                    trim: true,
                    matchFrom: ('any' as const),
                    stringify: (option) => option.label + ' ' + (option as any).data.subTitle
                })}
                menuPortalTarget={document.body}
                isDisabled={disabled}
                styles={defaultSelectInputStyles(disabled ?? false, Boolean((value && !disabled) || onClickAddNew), !!hasError, !!smallerValueContainer)}
                placeholder={t('common.select_option')}
                formatOptionLabel={formatOptionLabel}
            />
            {Boolean(value && !disabled && !removeRemoveButton) && (
                <div className={`${styles.sideButton} ${onClickAddNew ? styles.sideButtonExtraLeft : ''}`} onClick={() => onChange(null, value)}>
                    <FaTrashAlt />
                </div>
            )}
            {Boolean(value) && Boolean(onClickGoToDetails) && (
                <div className={`${styles.sideButton} ${value && !disabled ? styles.sideButtonExtraRight : ''}`} onClick={() => {
                    if (onClickGoToDetails) {
                        onClickGoToDetails(value ?? '');
                    }
                }}>
                    <FaEye />
                </div>
            )}
            {(Boolean(!value) || Boolean(showAddWhenSelected)) && Boolean(onClickAddNew) && (
                <div
                    className={`${styles.sideButton} ${value && !disabled ? styles.sideButtonExtraRight : ''}`}
                    onClick={() => {
                        if (needsRefresh) {
                            setNeedsRefresh(false);
                            if (onClickRefreshAfterNew) {
                                onClickRefreshAfterNew();
                            }
                        } else {
                            setNeedsRefresh(true);
                            if (onClickAddNew) {
                                onClickAddNew();
                            }
                        }
                    }}
                >
                    {needsRefresh ? <FaSync /> : <FaPlus />}
                </div>
            )}
        </div>
    );
};

type SelectInputControllerProps<T extends FieldValues, T2 extends SelectInputOption> = Omit<Partial<SelectProps<T2>>, 'value'> & Pick<SelectProps<T2>, 'options'> & UseControllerProps<T>;

export const SelectInputController = <T extends FieldValues, T2 extends SelectInputOption>(props: SelectInputControllerProps<T, T2>) => {
    const { field: { ref, ...field } } = useController({ ...props, disabled: false });

    return (
        <SelectInput
            {...props}
            {...field}
            disabled={props.disabled}
            onChange={(e) => {
                const oldVal = field.value;
                field.onChange(e);
                if (props.onChange) {
                    props.onChange(e, oldVal);
                }
            }}
        />
    );
};

export default SelectInput;
