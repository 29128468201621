import { WorkDto } from 'api/works/models/WorkDto';
import ListingTable from 'common/components/listingTable/ListingTable';
import ListingTableCache, { ListingTableData } from 'common/components/listingTable/ListingTableCache';
import TableActionButton from 'common/components/listingTable/actionButton/TableActionButton';
import { FilterData, ListingTableColumn, OrderData } from 'common/components/listingTable/models';
import ListingTableSidebar from 'common/components/listingTable/sidebar/ListingTableSidebar';
import { orderColumns } from 'common/components/listingTable/tableUtils';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Logger from 'common/services/Logger';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { DATE_TIME_FORMAT_DEFAULT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import WorksService from 'api/works/WorksService';
import Button from 'common/components/button/Button';
import { useNavigate } from 'react-router-dom';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import Loading from 'common/services/Loading';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import Toast from 'common/services/Toast';
import { WorkExternalPartsSearchCriteria, WorksSearchCriteria } from 'api/works/models/WorksSearchCriteria';
import { SelectInputOption } from 'common/components/selectInput/SelectInput';
import CountriesService from 'api/countries/CountriesService';
import DateFormat from 'common/components/dateFormat/dateFormat';
import ExternalPartCategoriesService from 'api/externalPartCategory/ExternalPartCategoriesService';
import { ExternalPartCategoryDto } from 'api/externalPartCategory/models/ExternalPartCategoryDto';
import YesNoModal from 'common/components/modal/yesNoModal/YesNoModal';

const PAGE_ID = 'BUSINESS_WORKS_LIST';
const baseUrl = '/business/works';

function WorksListScreen(): JSX.Element | null {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState<WorkDto[]>([]);
    const [tableData, setTableData] = useState<ListingTableData<WorkDto, Partial<WorksSearchCriteria>>>(ListingTableCache.get(PAGE_ID, {
        order: { field: 'name', isOrderAsc: true },
        hiddenColumns: [],
        columnsOrder: [],
        columnsWidth: [],
        page: 1,
        filters: {},
        lastRowId: null,
    }));
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<WorkDto | null>(null);
    const [showConfirmDeleteRelationsModal, setShowConfirmDeleteRelationsModal] = useState<WorkDto | null>(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['BUDGETS_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['BUDGETS_READ'])
    const [totalItems, setTotalItems] = useState(0);
    const [scrollToRowId, setScrollToRowId] = useState<string | null>(null);
    const firstLoad = useRef(true);
    const [countries, setCountries] = useState<SelectInputOption[]>()
    const [categoriesData,setCategoriesData] = useState<ExternalPartCategoryDto[]>([])

    const columns: ListingTableColumn<WorkDto>[] = orderColumns([
        {
            name: t('works.list.name'),
            field: 'name',
            filter: { type: 'text', value: tableData.filters.name ?? '', placeholder: t('works.list.name') },
            minWidth: '18rem',
        },
        {
            name: t('works.list.address'),
            field: 'address',
            filter: { type: 'text', value: tableData.filters.address ?? '', placeholder: t('works.list.address') },
            minWidth: '18rem',
        },
        {
            name: t('works.list.zip_code'),
            field: 'zipCode',
            orderColumn: 'zip_code',
            filter: { type: 'text', value: tableData.filters.zipCode ?? '', placeholder: t('works.list.zip_code') },
            minWidth: '18rem',
        },
        {
            name: t('works.list.country'),
            field: 'countryId',
            filter: { type: 'dropdown', value: tableData.filters.countryId ?? '', options: countries || [] },
            minWidth: '18rem',
            orderColumn: 'c.name',
            renderCell: (row) => <div>{row.countryName}</div>
        },
        {
            name: t('common.updated_date'),
            field: 'updatedDate',
            filter: {
                type: 'date-range',
                value: {
                    start: tableData.filters.updatedDate?.start ? new Date(tableData.filters.updatedDate.start) : null,
                    end: tableData.filters.updatedDate?.end ? new Date(tableData.filters.updatedDate.end) : null
                }
            },
            minWidth: '22rem',
            orderColumn: 'updated_date',
            renderCell: (row) => {
                return row.updatedDate ? <DateFormat value={row.updatedDate} format={DATE_TIME_FORMAT_DEFAULT} /> : '';
            }
        },
        {
            name: t('common.user_updated'),
            field: 'userUpdatedName',
            filter: { type: 'text', value: tableData.filters.userUpdatedName ?? '', placeholder: t('common.user_updated') },
            minWidth: '21rem',
            orderColumn: 'user_updated_name',
        },
        ...(categoriesData.map((x: ExternalPartCategoryDto) => {
            const f: ListingTableColumn<WorkDto> = {
                name: x.name,
                field: x.id as any,
                filter: { type: 'dropdown', value: tableData.filters.externalParts?.find(y => y.id === x.id)?.externalPartOptionId ?? '', options: (x.externalParts ?? []).map(op => ({ label: op.companyName, value: op.id! })) },
                minWidth: '18rem',
                renderCell: (row: WorkDto) => {
                    return <div>{row.externalParts.find(y => y.externalPartCategoryId === x.id)?.externalPartName}</div>
                },
                orderColumn: `${x.id}`
            }

            return f
        }))
    ], tableData.columnsOrder);

    const onFilter = (col: ListingTableColumn<WorkDto>, filterData: FilterData) => {
        let hasFound = false;
        setTableData(d => {
            const data = ({
                ...d, page: 1
            })
            if (col.field === 'name') {
                data.filters = {
                    ...data.filters,
                    name: filterData.value as string,
                };
                hasFound = true;
            }
            if (col.field === 'address') {
                data.filters = {
                    ...data.filters,
                    address: filterData.value as string,
                };
                hasFound = true;
            }
            if (col.field === 'zipCode') {
                data.filters = {
                    ...data.filters,
                    zipCode: filterData.value as string,
                };
                hasFound = true;
            }
            if (col.field === 'countryId') {
                data.filters = {
                    ...data.filters,
                    countryId: filterData.value as string,
                };
                hasFound = true;
            }
            if (col.field === 'updatedDate') {
                data.filters = {
                    ...data.filters,
                    updatedDate: filterData.value,
                    updatedDateStart: filterData.value?.start as Date,
                    updatedDateEnd: filterData.value?.end as Date
                };
                hasFound = true;
            }
            if (col.field === 'userUpdatedName') {
                data.filters = {
                    ...data.filters,
                    userUpdatedName: filterData.value as string
                };
                hasFound = true;
            }

            if (!hasFound) {
                data.filters.externalParts = data.filters?.externalParts?.map(element => {
                    if (element.id === col.field) {
                        return { ...element,externalPartOptionId: filterData.value }
                    }

                    return element
                });
            }
            return data;
        });
        setRefreshKey(k => k + 1);
    }

    const onOrder = (orderData: OrderData<WorkDto>) => {
        setRefreshKey(k => k + 1);
        setTableData(d => ({ ...d, order: orderData, page: 1 }));
    }

    const onClearFilters = () => {
        setTableData(d => ({ ...d, page: 1, filters: { externalParts: buildExternalPartsFilter(categoriesData) }, lastRowId: null }));
        setRefreshKey(k => k + 1);
    }

    const renderAction = (row: WorkDto) => (
        <TableActionButton
            options={[
                { label: <span><FaEdit /> {t('common.edit')}</span>, onClick: () => onClickEdit(row.id) },
                { label: <span><FaTrash /> {t('common.delete')}</span>, onClick: () => setShowConfirmDeleteModal(row) },
            ]}
        />
    )

    const getData = async () => {
        if (isLoading) {
            return;
        }

        try {
            setIsLoading(true);
            const externalParts: WorkExternalPartsSearchCriteria[] = tableData.filters.externalParts?.filter(x => x.externalPartOptionId !== '' && x.externalPartOptionId !== null) ?? []
            let orderColumnHelper = tableData.order.orderColumn
            let orderColumnIsInFilters = null;
            let orderColumnExternalPartId = null;

            // if order column is one of the dynamic columns
            if (tableData.filters.externalParts?.some(x => tableData.order.orderColumn?.includes(x.id))) {
                orderColumnIsInFilters = externalParts?.some(x => tableData.order.orderColumn?.includes(x.id)) ?? false;
                orderColumnExternalPartId = orderColumnIsInFilters ? null : tableData.order.orderColumn
                if (!orderColumnIsInFilters) {
                    orderColumnHelper = 'OrderCategory.company_name'
                } else {
                    orderColumnHelper = `ep${externalParts?.findIndex(x => x.id === tableData.order.orderColumn)}.company_name`
                }
            }

            const worksData = await WorksService.getList({
                ...tableData.filters,
                page: firstLoad.current && tableData.page > 1 ? 1 : tableData.page,
                itemsPerPage: firstLoad.current && tableData.page > 1 ? DEFAULT_PAGINATION_ITEMS_PER_PAGE * tableData.page : DEFAULT_PAGINATION_ITEMS_PER_PAGE,
                orderColumn: orderColumnIsInFilters === null ? tableData.order.orderColumn : orderColumnHelper,
                orderBy: tableData.order.isOrderAsc ? 'asc' : 'desc',
                getAllExternalParts: true,
                externalParts,
                orderColumnExternalPartId
            });

            const newListElements = worksData.currentPage === 1 ? worksData.items : [...data, ...worksData.items]
            setHasMore(worksData.totalItems > newListElements.length);
            setData(newListElements);
            setTotalItems(worksData.totalItems)

            if (firstLoad.current) {
                const countriesData = await CountriesService.getCatalog();

                setCountries(countriesData.map(x => ({
                    label: x.name,
                    value: x.id,
                })));

                setScrollToRowId(tableData.lastRowId);
            }
            firstLoad.current = false;
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get works', error);
            Toast.error(t('messages.error_load_info'));
        }

        setIsLoading(false);
    }

    const getAvailableForWorkCreationExternalPartCategories = async () => {
        try {
            const categories = await ExternalPartCategoriesService.GetAvailableForWorkCreation(true);
            setCategoriesData(categories)

            setTableData(d => ({ ...d, filters: { ...d.filters, externalParts: buildExternalPartsFilter(categories) } }))
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get the external part categories available for work creation on works list', error);
            Toast.error(t('messages.error_load_info'));
        }
        setIsLoading(false);
    }

    const buildExternalPartsFilter = (data: ExternalPartCategoryDto[]) => {
        const filterHelper: any[] = [];
        data.forEach((x) => {
            filterHelper.push({
                id: x.id,
                externalPartOptionId: ''
            });
        })

        return filterHelper
    }

    const onDelete = async (result: boolean) => {
        if (!showConfirmDeleteModal) {
            return;
        }

        const itemToDelete = showConfirmDeleteModal;
        setShowConfirmDeleteModal(null);

        if (result) {
            setShowConfirmDeleteRelationsModal(itemToDelete);
        }
    }

    const onDeleteRelations = async (result: boolean) => {
        Loading.show();
        try {
            await WorksService.delete(showConfirmDeleteRelationsModal?.id ?? '', result)
            Toast.success(t('messages.record_delete_success'));

            setTableData(d => ({ ...d, page: 1 }));
            setRefreshKey(k => k + 1);
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete work', error);
            Toast.error(t('messages.record_delete_error'));
        } finally {
            Loading.hide();
            setShowConfirmDeleteRelationsModal(null)
        }
    }

    const onLoadMore = () => {
        setTableData(d => ({ ...d, page: d.page + 1 }));
    }

    const onClickEdit = (id: string) => {
        ListingTableCache.saveLastRowId(PAGE_ID, id);
        navigate(baseUrl + '/edit/' + id)
    }

    const onClickNew = () => {
        navigate(baseUrl + '/create')
    }

    const onRowClick = (id: string) => {
        ListingTableCache.saveLastRowId(PAGE_ID, id);
        navigate(baseUrl + '/details/' + id)
    }

    useEffect(() => {
        void getData();
    }, [tableData.page, tableData.filters, tableData.order, refreshKey]);

    useEffect(() => {
        ListingTableCache.save(PAGE_ID, tableData);
    }, [tableData]);

    useEffect(() => {
        void getAvailableForWorkCreationExternalPartCategories();
    }, []);

    if (!canRead) {
        return null;
    }

    return (
        <ScreenTitle title={t('works.list.title')}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: t('works.list.title'), url: baseUrl },
                ]}
            />
            <PageHeader title={t('works.list.title')} subTitle={`${totalItems} ${t('common.total_results')}`} informationText={t('common.list')} showGoBack={false} addSidebarSpacing>
                {canWrite && <Button onClick={onClickNew}>{t('common.new')}</Button>}
            </PageHeader>
            <PageContainer>
                <ListingTable<WorkDto>
                    columns={columns}
                    rows={data}
                    order={tableData.order}
                    hiddenColumns={tableData.hiddenColumns}
                    columnsWidths={tableData.columnsWidth}
                    onOrder={onOrder}
                    onFilter={onFilter}
                    onClearFilters={onClearFilters}
                    onRenderAction={canWrite ? renderAction : undefined}
                    infiniteScroll={{
                        isLoading,
                        hasMore,
                        onLoadMore,
                    }}
                    onRowClick={(r) => onRowClick(r.id)}
                    onRowId={r => r.id}
                    scrollToRowId={scrollToRowId}
                    onColResize={(w) => setTableData(d => ({ ...d, columnsWidth: w }))}
                />
            </PageContainer>

            <ListingTableSidebar
                columns={columns}
                hiddenColumns={tableData.hiddenColumns}
                onChangeHiddenColumns={(hc) => setTableData(d => ({ ...d, hiddenColumns: hc }))}
                onChangeColumnsOrder={(oc) => setTableData(d => ({ ...d, columnsOrder: oc }))}
            />

            <ConfirmDeleteModal
                itemName={showConfirmDeleteModal?.name}
                isOpen={!!showConfirmDeleteModal}
                onClose={onDelete}
            />

            <YesNoModal
                title={t('common.question')}
                message={t('works.delete_relations')}
                onClose={onDeleteRelations}
                isOpen={!!showConfirmDeleteRelationsModal}
            />
        </ScreenTitle>
    );
}

export default WorksListScreen;
