import { BudgetFieldValueDto } from 'api/budgets/models/BudgetDto';
import { BudgetFieldDto } from 'api/budgetsFields/models/BudgetFieldDto';

export const mapFieldValues = (budgetsFields: BudgetFieldDto[], fieldsValues: BudgetFieldValueDto[]): BudgetFieldValueDto[] => {
    return budgetsFields.map(bf => {
        const fv = fieldsValues.find(x => x.budgetFieldId === bf.id);
        return ({
            budgetFieldId: bf.id,
            value: fv?.value ?? null,
            budgetFieldOptionId: fv?.budgetFieldOptionId ?? null,
            name: bf.name,
            isRequired: bf.isRequired,
            fieldType: bf.fieldType,
            options: bf.options,
        });
    });
}
