import { LOGGER_LOG_TYPE } from 'Config';
import Button from 'common/components/button/Button';
import InputError from 'common/components/inputError/InputError';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { TextInputController } from 'common/components/textInput/TextInput';
import { Label } from 'common/components/texts/Texts';
import Loading from 'common/services/Loading';
import Toast from 'common/services/Toast';
import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import Logger from 'common/services/Logger';
import { ExternalPartCategoryDto } from 'api/externalPartCategory/models/ExternalPartCategoryDto';
import ExternalPartCategoriesService from 'api/externalPartCategory/ExternalPartCategoriesService';
import { useSelector } from 'react-redux';
import UsersService from 'api/users/UsersService';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import { CheckInputController } from 'common/components/checkInput/CheckInput';
import Events from 'common/services/Events';
import { Form } from 'common/components/form/Form';

function ExternalPartCategoryScreen(): JSX.Element | null {
    const { id, type } = useParams<{ id: string, type: string }>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const form = useForm<ExternalPartCategoryDto>({ shouldUnregister: false });
    const errors = form.formState.errors;
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['EXTERNAL_PARTS_READ'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['EXTERNAL_PARTS_WRITE'])

    const url = '/external-parts/categories';
    const currentUrl = url + '/' + type + (id ? ('/' + id) : '');

    useEffect(() => {
        void getData()
    }, [id, type]);

    const getData = async () => {
        try {
            let result: Partial<ExternalPartCategoryDto> = { id, name: '' };
            if (id) {
                Loading.show();
                result = await ExternalPartCategoriesService.getById(id);
            }
            form.reset(result);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get external part category information', error);
            Toast.error(t('messages.error_load_info'));
        }
    };

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/external-parts/categories/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate('/external-parts/categories');
        }
    }

    const onSubmit = async (model: ExternalPartCategoryDto) => {
        try {
            Loading.show();

            if (model && model.id) {
                await ExternalPartCategoriesService.update(model)
                // navigateTo('details', id);
                void getData();
            } else if (model) {
                const id = await ExternalPartCategoriesService.create(model)
                navigateTo('edit', id);
            }
            Events.emit('side-bar-update');
            Loading.hide();
            Toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the external part category with id: ${id}`, error);
            Toast.error(t('messages.record_save_error'));

            Loading.hide();
        }
    };

    const onInvalid = () => {
        Toast.warning(t('messages.required_fields_empty'));
    }

    const onDelete = async (result: boolean) => {
        if (!result) {
            setShowConfirmDeleteModal(false)
            return;
        }

        Loading.show();
        try {
            await ExternalPartCategoriesService.remove(form.getValues())
            Toast.success(t('messages.record_delete_success'));
            navigateTo();
            Events.emit('side-bar-update');
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete external part category', error);
            Toast.error(t('messages.record_delete_error'));
        } finally {
            Loading.hide();
            setShowConfirmDeleteModal(false)
        }
    }

    const title = (type === 'create' ? t('common.new') : (type === 'edit' ? t('common.edit') : t('common.details'))) + ' ' + t('external_part_categories.category');

    if (!canRead) {
        return null;
    }

    return (
        <ScreenTitle title={t('external_part_categories.title')}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: t('external_part_categories.title'), url },
                    { name: title, url: currentUrl },
                ]}
            />
            <Form handleSubmit={form.handleSubmit} onSubmit={onSubmit} onInvalid={onInvalid} noValidate>
                <PageHeader title={title} onGoBack={() => navigateTo()} informationText={t('common.go_back')}>
                    {canWrite && !isDetails && <Button type='submit'>{t('common.save')}</Button>}
                    {canWrite && isDetails && <Button fw onClick={() => { navigateTo('edit', id); }}>{t('common.edit')}</Button>}
                    {canWrite && isDetails && (
                        <Button variant='danger' onClick={() => setShowConfirmDeleteModal(true)}>
                            <FaTrashAlt />
                        </Button>
                    )}
                </PageHeader>
                <PageContainer>
                    <Row className="mb-3">
                        <Col xs={12}>
                            <Label space>{t('external_part_categories.list.name')}{!isDetails ? '*' : ''}</Label>
                            <TextInputController
                                name='name' control={form.control}
                                placeholder={t('external_part_categories.list.name')} disabled={isDetails}
                                rules={{ required: true, maxLength: 250 }}
                                hasError={Boolean(errors.name)} />
                            <InputError error={errors.name} maxLength={250} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Label style={{ marginRight: '1rem' }}>{t('external_part_categories.available_for_work_creation')}</Label>
                            <CheckInputController
                                name='availableForWorkCreation'
                                control={form.control}
                                disabled={isDetails}
                            />
                        </Col>
                    </Row>
                </PageContainer>
            </Form>
            <ConfirmDeleteModal
                itemName={form.getValues('name')}
                secundaryMessage={t('external_part_categories.remove_with_external_parts')}
                isOpen={showConfirmDeleteModal}
                onClose={(result) => onDelete(result)}
            />
        </ScreenTitle >
    )
}

export default ExternalPartCategoryScreen;
