import styles from './ParameterChildrenContainer.module.scss';
import InfoMessage from 'common/components/infoMessage/InfoMessage';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

interface Props {
    className?: string;
    showInfo: boolean;
    children: ReactNode;
}

function ParameterChildrenContainer({ className, showInfo, children }: Props): JSX.Element | null {
    const { t } = useTranslation();

    if (!children) {
        return null;
    }

    return (
        <div className={`${styles.container} ${className || ''}`}>
            {showInfo && <div className={styles.info}>
                <InfoMessage message={t('budgets.budget.lines.info_select_parameters_products_related')} type="information" />
            </div>}
            <div className={styles.childrenContainer} style={showInfo ? { borderTop: 0 } : {}}>
                {children}
            </div>
        </div>
    );
}

export default ParameterChildrenContainer;
