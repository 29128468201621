import React from 'react';
import { SpinnerCircular } from 'spinners-react';

interface Props {
    size?: number;
}

export const LoadingSpinnerCenter: React.FunctionComponent<Props> = (props: Props) => {
    return <div className="text-center"><LoadingSpinner {...props} /></div>;
};

const LoadingSpinner: React.FunctionComponent<Props> = ({ size }: Props) => {
    return <SpinnerCircular speed={150} secondaryColor="rgba(0,0,0,0.20)" color="#000000" size={size} />;
};

export default LoadingSpinner;
