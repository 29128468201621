import React, { DetailedHTMLProps, InputHTMLAttributes, ReactElement, Ref } from 'react';
import styles from './RadioInput.module.scss';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';

type RadioProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const RadioInputPlain = ({ children, ...props }: RadioProps, ref: Ref<HTMLInputElement> | undefined) => (
    <label className={styles.container}>
        {Boolean(children) && <span className={styles.labelText}>{children}</span>}
        <input
            type="radio"
            {...props}
            ref={ref}
            className={`${props.className ?? ''}`}
        />
        <span className={styles.checkmark}></span>
    </label>
);

const RadioInput = React.forwardRef(RadioInputPlain);

export default RadioInput;

type RadioControllerProps<T extends FieldValues> = RadioProps & UseControllerProps<T>;

const RadioInputControllerPlain = <T extends FieldValues>(props: RadioControllerProps<T>, ref: Ref<HTMLInputElement> | undefined) => {
    const { field } = useController({ ...props, disabled: false });

    return (
        <RadioInput
            {...props}
            ref={ref}
            defaultValue={undefined}
            checked={!!field.value}
            name={field.name}
            onChange={(e) => {
                field.onChange(e);
                if (props.onChange) {
                    props.onChange(e);
                }
            }}
        />
    );
};

export const RadioInputController = React.forwardRef(RadioInputControllerPlain) as
    <T extends FieldValues>(p: RadioControllerProps<T> & { ref?: Ref<HTMLInputElement> }) => ReactElement;
