import { TaxDto } from 'api/taxes/models/TaxDto';
import InputError from 'common/components/inputError/InputError';
import Button from 'common/components/button/Button';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { TextInputController } from 'common/components/textInput/TextInput';
import { Label } from 'common/components/texts/Texts';
import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import TaxesService from 'api/taxes/TaxesService';
import Loading from 'common/services/Loading';
import toast from 'react-hot-toast';
import { LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import { MoneyInputController } from 'common/components/moneyInput/MoneyInput';
import UsersService from 'api/users/UsersService';
import { UserProfile } from 'api/account/models/UserProfile';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import Toast from 'common/services/Toast';
import { Form } from 'common/components/form/Form';
import styles from './TaxScreen.module.scss'

function TaxScreen(): JSX.Element | null {
    const { id, type } = useParams<{ id: string, type: 'create' | 'details' }>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const url = '/backoffice/taxes';
    const currentUrl = url + '/' + type + (id ? ('/' + id) : '');

    const form = useForm<TaxDto>({ shouldUnregister: false });
    const errors = form.formState.errors;
    const [isDetails, setIsDetails] = useState<boolean>(type !== 'create');
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['TAXES_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['TAXES_READ'])

    useEffect(() => {
        void getData();
    }, [id, type]);

    const getData = async () => {
        try {
            let result: Partial<TaxDto> = {
                id,
                description: ''
            }
            if (id) {
                Loading.show();
                result = await TaxesService.getById(id);
            }

            form.reset(result);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get families', error);
            toast.error(t('messages.error_load_info'));
        }
    }

    const onSubmit = async (model: TaxDto) => {
        try {
            Loading.show();

            if (model && model.id) {
                await TaxesService.update(model)
                // navigateTo('details', model.id);
            } else if (model) {
                const id = await TaxesService.create(model)
                navigateTo('edit', id);
            }
            Loading.hide();
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the tax with id: ${id}`, error);
            toast.error(t('messages.record_save_error'));
            Loading.hide();
        }
    };

    const onInvalid = () => {
        Toast.warning(t('messages.required_fields_empty'));
    }

    const onDelete = async (item: boolean) => {
        if (!item) {
            setShowConfirmDeleteModal(false)
            return;
        }
        try {
            Loading.show();

            await TaxesService.remove(form.getValues())
            navigateTo();

            Loading.hide();
            toast.success(t('messages.record_delete_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete tax', error);
            toast.error(t('messages.record_delete_error'));
        }
        setShowConfirmDeleteModal(false)
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/backoffice/taxes/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate('/backoffice/taxes');
        }
    }

    const title = (type === 'create' ? t('common.new') : t('common.details')) + ' ' + t('taxes.tax');

    if (!canRead) {
        return null;
    }

    return (<ScreenTitle title={t('taxes.title')}>
        <PageBreadcrumbsPortal
            breadcrumbs={[
                { name: t('home.title'), url: '/' },
                { name: t('taxes.title'), url },
                { name: title, url: currentUrl },
            ]}
        />
        <Form handleSubmit={form.handleSubmit} onSubmit={onSubmit} onInvalid={onInvalid} noValidate>
            <PageHeader title={title} informationText={t('common.go_back')} onGoBack={() => navigateTo()}>
                {canWrite && !isDetails && <Button type='submit'>{t('common.save')}</Button>}
                {canWrite && isDetails && <Button variant='secondary' onClick={() => setShowConfirmDeleteModal(true)}> <FaTrashAlt /> </Button>}
            </PageHeader>
            <PageContainer>
                <Row>
                    <Col sm={12} md={6} className={styles.marginBottomMd}>
                        <Label space>{t('taxes.list.description')}{!isDetails ? '*' : ''}</Label>
                        <TextInputController
                            name='description' control={form.control}
                            placeholder={t('taxes.list.description')} disabled={isDetails}
                            rules={{ required: true, maxLength: 250 }}
                            hasError={Boolean(errors.description)} />
                        <InputError error={errors.description} maxLength={250} />
                    </Col>
                    <Col sm={12} md={6}>
                        <Label space>{t('taxes.list.value')}{!isDetails ? '*' : ''}</Label>
                        <MoneyInputController
                            name='value' control={form.control}
                            unitPrefix='%'
                            rules={{ required: true }}
                            disabled={isDetails}
                            hasError={Boolean(errors.value)}/>
                        <InputError error={errors.value} />
                    </Col>
                </Row>
            </PageContainer>
        </Form>
        <ConfirmDeleteModal
            itemName={form.getValues('description')}
            isOpen={showConfirmDeleteModal}
            onClose={(result) => onDelete(result)}
        />
    </ScreenTitle>)
}

export default TaxScreen;
