import { ColumnOrder } from './ListingTableCache';
import { ListingTableColumn } from './models';

export const columnsReorder = <TRow>(list: ListingTableColumn<TRow>[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const orderColumns = <TRow>(columns: ListingTableColumn<TRow>[], columnsOrder: ColumnOrder<TRow>[], fixedColumns: { field: string, position: number }[] = []) => {
    // Filter fixed columns
    const fixedCols = columns.filter(col => fixedColumns.some(fixed => fixed.field === col.field));

    // Remove fixed columns before sorting
    let cols = columns.filter(col => !fixedColumns.some(fixed => fixed.field === col.field));

    // Apply sorting
    for (const columnOrder of columnsOrder) {
        const sourceIndex = cols.findIndex(x => x.field === columnOrder.field);
        if (sourceIndex > -1) {
            cols = columnsReorder(
                [...cols],
                sourceIndex,
                columnOrder.i
            );
        }
    }

    // put the fixed columns back in their positions
    for (const fixedCol of fixedColumns) {
        cols.splice(fixedCol.position, 0, ...fixedCols.filter(col => col.field === fixedCol.field));
    }

    return cols;
}
