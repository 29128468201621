import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import styles from './Button.module.scss';

export type ButtonProps = {
    variant?: 'primary' | 'secondary' | 'third' | 'forth' | 'danger' | 'empty' | 'none';
    size?: 'normal' | 'medium' | 'small';
    fw?: boolean;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button = ({ variant, size, fw, ...props }: ButtonProps, ref: React.LegacyRef<HTMLButtonElement>) => (
    <button
        type="button"
        {...props}
        ref={ref}
        className={`${styles.button} ${styles[variant ?? 'primary']} ${styles[size ?? 'normal']} ${fw ? styles.fw : ''} ${props.className ?? ''}`}
    />
);

export default React.forwardRef(Button);
