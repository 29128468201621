import Button from 'common/components/button/Button';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import PageHeader from 'common/components/pageHeader/PageHeader';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import Loading from 'common/services/Loading';
import { LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import Toast from 'common/services/Toast';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { useFieldArray, useForm } from 'react-hook-form';
import { BudgetFieldDto } from 'api/budgetsFields/models/BudgetFieldDto';
import BudgetsFieldsService from 'api/budgetsFields/BudgetsFieldsService';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import { Form } from 'common/components/form/Form';
import { FaPlus, FaTrash, FaTrashAlt } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';
import { Header, Label } from 'common/components/texts/Texts';
import { TextInputController } from 'common/components/textInput/TextInput';
import InputError from 'common/components/inputError/InputError';
import { MoneyInputController } from 'common/components/moneyInput/MoneyInput';
import { SelectInputController } from 'common/components/selectInput/SelectInput';
import { CheckInputController } from 'common/components/checkInput/CheckInput';
import styles from './BudgetFieldScreen.module.scss';
import { BudgetFieldType } from 'api/budgetsFields/enums/BudgetFieldType';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'common/components/strictModeDroppable/StrictModeDroppable';
import { IoMoveOutline } from 'react-icons/io5';

function BudgetFieldScreen(): JSX.Element | null {
    const { id, type } = useParams<{ id: string, type: string }>();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['BUDGETS_FIELDS_WRITE']);
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['BUDGETS_FIELDS_READ']);
    const fieldTypesOptions = useMemo(() => Object.keys(BudgetFieldType).map(key => ({
        label: t('budgets_fields.field_types.' + key),
        value: key,
    })), [i18n.language]);
    const form = useForm<BudgetFieldDto>({ shouldUnregister: false });
    const errors = form.formState.errors;
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const { fields: options, move: moveOptions, remove: removeOption, append: appendOption } = useFieldArray({
        control: form.control,
        name: 'options',
        keyName: 'key',
    });

    const url = '/backoffice/budgets-fields';
    const currentUrl = url + '/' + type + '/' + id;

    useEffect(() => {
        void getData()
    }, [id, type]);

    const getData = async () => {
        if (!id) {
            return;
        }

        try {
            Loading.show();

            let result: Partial<BudgetFieldDto> = { id, name: '', options: [] };
            if (id) {
                Loading.show();
                result = await BudgetsFieldsService.getById(id);
            }

            form.reset(result);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get budget field', error);
            Toast.error(t('messages.error_load_info'));
        }
    };

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/backoffice/budgets-fields/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate('/backoffice/budgets-fields');
        }
    }

    const onSubmit = async (model: BudgetFieldDto) => {
        try {
            Loading.show();

            const tempModel = { ...model };
            if (tempModel.fieldType !== BudgetFieldType.SELECT) {
                tempModel.options = [];
            }

            let updated = false;
            if (tempModel && tempModel.id) {
                await BudgetsFieldsService.update(tempModel)
                updated = true;
            } else if (tempModel) {
                const id = await BudgetsFieldsService.create(tempModel)
                navigateTo('edit', id);
            }
            Loading.hide();
            Toast.success(t('messages.record_save_success'));
            if (updated) {
                await getData();
            }
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the budget field with id: ${id}`, error);
            Toast.error(t('messages.record_save_error'));
            Loading.hide();
        }
    };

    const onInvalid = () => {
        Toast.warning(t('messages.required_fields_empty'));
    }

    const onDelete = async (result: boolean) => {
        if (!result) {
            setShowConfirmDeleteModal(false)
            return;
        }

        Loading.show();
        try {
            await BudgetsFieldsService.remove(form.getValues())
            Toast.success(t('messages.record_delete_success'));
            navigateTo();
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete budget field', error);
            Toast.error(t('messages.record_delete_error'));
        } finally {
            Loading.hide();
            setShowConfirmDeleteModal(false)
        }
    }

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        moveOptions(result.source.index, result.destination.index);
    }

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        userSelect: 'none',
        ...draggableStyle
    });

    const addNewOption = () => {
        appendOption({
            id: null,
            label: '',
        });
    }

    const onChangeFieldType = () => {
        form.setValue('options', []);

        if (form.getValues('fieldType') === BudgetFieldType.SELECT) {
            addNewOption();
        }
    }

    const title = (type === 'create' ? t('common.new') : (type === 'edit' ? t('common.edit') : t('common.details'))) + ' ' + t('budgets_fields.title');

    if (!canRead) {
        return null;
    }

    return (
        <ScreenTitle title={title}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: t('budgets_fields.list.title'), url },
                    { name: title, url: currentUrl },
                ]}
            />

            <PageHeader title={title} informationText={t('common.go_back')} onGoBack={() => navigateTo()}>
                {canWrite && !isDetails && <Button type='submit' form="submitForm">{t('common.save')}</Button>}
                {canWrite && isDetails && <Button fw onClick={() => { navigateTo('edit', id); }}>{t('common.edit')}</Button>}
                {canWrite && isDetails && <Button variant='secondary' onClick={() => setShowConfirmDeleteModal(true)}> <FaTrashAlt /> </Button>}
            </PageHeader>
            <PageContainer addBottomSpace>
                <Form handleSubmit={form.handleSubmit} onSubmit={onSubmit} onInvalid={onInvalid} noValidate id="submitForm">
                    <Row className='mb-3'>
                        <Col>
                            <Label space>{t('budgets_fields.budget_field.name')}{!isDetails ? '*' : ''}</Label>
                            <TextInputController
                                name='name'
                                control={form.control}
                                placeholder={t('budgets_fields.budget_field.name')}
                                disabled={isDetails}
                                rules={{ required: true, maxLength: 400 }}
                                hasError={Boolean(errors.name)} />
                            <InputError error={errors.name} maxLength={400} />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <Label space>{t('budgets_fields.budget_field.position')}{!isDetails ? '*' : ''}</Label>
                            <MoneyInputController
                                name='position'
                                control={form.control}
                                disabled={isDetails}
                                rules={{ required: true }}
                                hasError={Boolean(errors.position)}
                                decimalScale={2}
                                hideUnitsDrop
                            />
                            <InputError error={errors.position} />
                        </Col>
                        <Col>
                            <Label space>{t('budgets_fields.budget_field.field_type')}{!isDetails ? '*' : ''}</Label>
                            <SelectInputController
                                name='fieldType'
                                control={form.control}
                                disabled={isDetails}
                                rules={{ required: true }}
                                hasError={Boolean(errors.fieldType)}
                                options={fieldTypesOptions}
                                onChange={onChangeFieldType}
                            />
                            <InputError error={errors.fieldType} />
                        </Col>
                        <Col>
                            <Label space>{t('budgets_fields.budget_field.is_required')}</Label>
                            <div className={styles.requiredCheckbox}>
                                <CheckInputController
                                    name='isRequired'
                                    control={form.control}
                                    disabled={isDetails}
                                />
                                <InputError error={errors.isRequired} />
                            </div>
                        </Col>
                    </Row>

                    {form.watch('fieldType') === BudgetFieldType.SELECT && (
                        <div>
                            <Header className='mb-3'>{t('budgets_fields.budget_field.options')}</Header>
                            <Row>
                                <Row className={`mb-2 ${styles.row}`}>
                                    {!isDetails && (
                                        <Col xs='auto' style={{ width: 60 }}></Col>
                                    )}
                                    <Col>
                                        <Label>{t('budgets_fields.budget_field.label_option')}</Label>
                                    </Col>
                                    {!isDetails && (
                                        <Col xs={1}></Col>
                                    )}
                                </Row>
                            </Row>
                            {options.length === 0 && (
                                <Row>
                                    <Col className='text-center mb-3'>
                                        {t('budgets_fields.budget_field.without_information')}
                                    </Col>
                                </Row>
                            )}
                            <DragDropContext onDragEnd={onDragEnd}>
                                <StrictModeDroppable droppableId="droppable" isDropDisabled={isDetails}>
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {options.map((option, index) => {
                                                return (
                                                    <Draggable key={option.key} draggableId={option.key} index={index} isDragDisabled={isDetails}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <Row className={`mb-2 ${styles.row}`}>
                                                                    {!isDetails && (
                                                                        <Col xs='auto' style={{ width: 60 }}>
                                                                            <span {...provided.dragHandleProps} className={styles.dragIconContainer}>
                                                                                <IoMoveOutline className={styles.dragIcon} />
                                                                            </span>
                                                                        </Col>
                                                                    )}
                                                                    <Col>
                                                                        <TextInputController
                                                                            name={`options.${index}.label`}
                                                                            disabled={isDetails}
                                                                            rules={{ required: true, maxLength: 400 }}
                                                                            hasError={Boolean(errors.options ? errors.options[index]?.label : undefined)}
                                                                            control={form.control}
                                                                            placeholder={t('budgets_fields.budget_field.label_option')}
                                                                        />
                                                                        <InputError error={(errors.options ? errors.options[index]?.label : undefined)} maxLength={400} />
                                                                    </Col>
                                                                    {!isDetails && (
                                                                        <Col xs={1}>
                                                                            <Button onClick={() => removeOption(index)}><FaTrash /></Button>
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </StrictModeDroppable>
                            </DragDropContext>
                            {!isDetails && (
                                <div className='text-right mt-3'>
                                    <Button onClick={addNewOption}><FaPlus /> {t('budgets_fields.budget_field.add_option')}</Button>
                                </div>
                            )}
                        </div>
                    )}
                </Form>
            </PageContainer>
            <ConfirmDeleteModal
                itemName={form.getValues('name')}
                isOpen={showConfirmDeleteModal}
                onClose={(result) => onDelete(result)}
            />
        </ScreenTitle>
    )
}

export default BudgetFieldScreen;
