import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import Toast from 'common/services/Toast';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit } from 'react-icons/fa';
import Logger from 'common/services/Logger';
import ListingTable from 'common/components/listingTable/ListingTable';
import { WorkDto } from 'api/works/models/WorkDto';
import { orderColumns } from 'common/components/listingTable/tableUtils';
import ListingTableCache, { ListingTableData } from 'common/components/listingTable/ListingTableCache';
import { WorksSearchCriteria } from 'api/works/models/WorksSearchCriteria';
import { FilterData, ListingTableColumn, OrderData } from 'common/components/listingTable/models';
import WorksService from 'api/works/WorksService';
import TableActionButton from 'common/components/listingTable/actionButton/TableActionButton';

export interface WorksListProps {
    externalPartId: string;
}

const PAGE_ID = 'EXTERNAL_PART_CONTACTS_LIST';

const WorksList = ({ externalPartId }: WorksListProps) => {
    const { t } = useTranslation();

    const [scrollToRowId, setScrollToRowId] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const firstLoad = useRef(true);

    const [works, setWorks] = useState<WorkDto[]>([]);

    const [tableData, setTableData] = useState<ListingTableData<WorkDto, Partial<WorksSearchCriteria>>>(ListingTableCache.get(PAGE_ID, {
        order: { field: 'name', orderColumn: 'w.name', isOrderAsc: true },
        hiddenColumns: [],
        columnsOrder: [],
        columnsWidth: [],
        page: 1,
        filters: {},
        lastRowId: null,
    }));

    const worksColumns: ListingTableColumn<WorkDto>[] = orderColumns([
        {
            name: t('works.list.name'),
            field: 'name',
            filter: { type: 'text', value: tableData.filters.name ?? '', placeholder: t('works.list.name') },
            minWidth: '18rem',
        },
        {
            name: t('works.list.zip_code'),
            field: 'zipCode',
            orderColumn: 'zip_code',
            filter: { type: 'text', value: tableData.filters.zipCode ?? '', placeholder: t('works.list.zip_code') },
            minWidth: '18rem',
        },
        {
            name: t('works.list.address'),
            field: 'address',
            filter: { type: 'text', value: tableData.filters.address ?? '', placeholder: t('works.list.address') },
            minWidth: '18rem',
        },
    ], tableData.columnsOrder);

    const onFilter = (col: ListingTableColumn<WorkDto>, filterData: FilterData) => {
        setTableData(d => {
            const data = ({
                ...d, page: 1
            })
            data.filters = {
                ...data.filters
            };
            if (col.field === 'name') {
                data.filters = {
                    ...data.filters,
                    name: filterData.value as string,
                };
            }
            if (col.field === 'address') {
                data.filters = {
                    ...data.filters,
                    address: filterData.value as string,
                };
            }
            if (col.field === 'zipCode') {
                data.filters = {
                    ...data.filters,
                    zipCode: filterData.value as string,
                };
            }
            return data;
        });
        setRefreshKey(k => k + 1);
    }

    const onClearFilters = () => {
        setTableData(d => ({ ...d, page: 1, filters: {} }));
        setRefreshKey(k => k + 1)
    }

    const onOrder = (orderData: OrderData<WorkDto>) => {
        setRefreshKey(k => k + 1)
        setTableData(d => ({ ...d, order: orderData, page: 1 }));
    }
    useEffect(() => {
        void getWorks();
    }, [tableData.page, tableData.filters, tableData.order, refreshKey]);

    useEffect(() => {
        ListingTableCache.save(PAGE_ID, tableData);
    }, [tableData]);

    const getWorks = async () => {
        setIsLoading(true);
        try {
            const worksData = await WorksService.getList({
                externalPartId,
                ...tableData.filters,
                page: firstLoad.current && tableData.page > 1 ? 1 : tableData.page,
                itemsPerPage: firstLoad.current && tableData.page > 1 ? DEFAULT_PAGINATION_ITEMS_PER_PAGE * tableData.page : DEFAULT_PAGINATION_ITEMS_PER_PAGE,
                orderColumn: tableData.order.orderColumn,
                orderBy: tableData.order.isOrderAsc ? 'asc' : 'desc',
                getAllExternalParts: false
            });

            const newListElements = worksData.currentPage === 1 ? worksData.items : [...works, ...worksData.items]
            setHasMore(worksData.totalItems > newListElements.length);
            setWorks(newListElements);

            if (firstLoad.current) {
                setScrollToRowId(tableData.lastRowId);
            }
            firstLoad.current = false;
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get external part works', error);
            Toast.error(t('messages.error_load_info'));
        }
        setIsLoading(false);
    };

    const onLoadMore = () => {
        setTableData(d => ({ ...d, page: d.page + 1 }));
    }

    const navigateToWork = (typeUrl: string, id: string) => {
        ListingTableCache.saveLastRowId(PAGE_ID, id);
        window.open(`/business/works/${typeUrl}/${id}`, '_blank');
    }

    const renderAction = (row: WorkDto) => (
        <TableActionButton
            options={[
                { label: <span><FaEdit /> {t('common.edit')}</span>, onClick: () => navigateToWork('edit', row.id) },
            ]}
        />
    )

    return (
        <ListingTable<WorkDto>
            columns={worksColumns}
            rows={works}
            order={tableData.order}
            hiddenColumns={tableData.hiddenColumns}
            columnsWidths={tableData.columnsWidth}
            onOrder={onOrder}
            onFilter={onFilter}
            onClearFilters={onClearFilters}
            onRenderAction={renderAction}
            onRowClick={(row) => navigateToWork('details', row.id)}
            infiniteScroll={{
                isLoading,
                hasMore,
                onLoadMore,
            }}
            onRowId={r => r.id}
            scrollToRowId={scrollToRowId}
            onColResize={(w) => setTableData(d => ({ ...d, columnsWidth: w }))}
        />
    )
}

export default WorksList;
