import React from 'react';
import ReactModal from 'react-modal';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../Modal';
import { Header, Text } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/button/Button';

type Props = {
    message: string;
    onClose: () => void;
} & ReactModal.Props;

const ConfirmModal: React.FC<Props> = ({ message, onClose, ...props }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal {...props} style={{ content: { minWidth: 400 } }}>
            <div>
                <ModalHeader>
                    <Header>{t('common.confirmation').toUpperCase()}</Header>
                </ModalHeader>
                <ModalBody>
                    <Text>{message}</Text>
                </ModalBody>
                <ModalFooter className="text-right">
                    <Button onClick={onClose}>{t('common.ok')}</Button>
                </ModalFooter>
            </div>
        </Modal >
    );
};

export default ConfirmModal;
