import Storage from 'common/services/Storage';
import { OrderData } from './models';

const tableSuffix = '_TABLE';

export interface ColumnOrder<TRow> {
    i: number;
    field: keyof TRow;
}

export interface ColumnWidth<TRow> {
    width: number;
    field: keyof TRow;
}

export interface ListingTableData<TRow, TFilters> {
    hiddenColumns: (keyof TRow)[];
    columnsOrder: ColumnOrder<TRow>[];
    columnsWidth: ColumnWidth<TRow>[];
    order: OrderData<TRow>;
    page: number;
    filters: TFilters;
    lastRowId: string | null;
}

export const DEFAULT_TABLE_DATA = {
    order: { field: 'name', isOrderAsc: true },
    hiddenColumns: [],
    columnsOrder: [],
    columnsWidth: [],
    page: 1,
    filters: {},
    lastRowId: null,
};

class ListingTableCache {
    public save<TRow, TFilters>(pageId: string, data: ListingTableData<TRow, TFilters>) {
        Storage.setObject(pageId + tableSuffix, data);
    }

    public get<TRow, TFilters>(pageId: string, defaultData?: ListingTableData<TRow, TFilters>): ListingTableData<TRow, TFilters> {
        const result: ListingTableData<TRow, TFilters> = Storage.getObject(pageId + tableSuffix) ?? defaultData;
        if (!result.filters) {
            (result as any).filters = {};
        }
        return result;
    }

    public saveLastRowId(pageId: string, lastRowId: string) {
        const data = this.get(pageId, DEFAULT_TABLE_DATA);
        data.lastRowId = lastRowId;
        this.save(pageId, data);
    }
}

export default new ListingTableCache();
