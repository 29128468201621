import Button from 'common/components/button/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FaTrashAlt } from 'react-icons/fa';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import PageHeader from 'common/components/pageHeader/PageHeader';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import { Col, Row } from 'react-bootstrap';
import { TextInputController } from 'common/components/textInput/TextInput';
import { Label } from 'common/components/texts/Texts';
import Loading from 'common/services/Loading';

import { RoleDto } from 'api/roles/models/RoleDto';
import { LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import RolesService from 'api/roles/RolesService';
import { useForm } from 'react-hook-form';
import InputError from 'common/components/inputError/InputError';
import Toast from 'common/services/Toast';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { Form } from 'common/components/form/Form';

function RoleScreen(): JSX.Element | null {
    const { id, type } = useParams<{ id: string, type: string }>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const form = useForm<RoleDto>({ shouldUnregister: false });
    const errors = form.formState.errors;
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['ROLES_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['ROLES_READ'])

    const url = '/backoffice/roles';
    const currentUrl = url + '/' + type + (id ? ('/' + id) : '');

    useEffect(() => {
        void getData()
    }, [id, type]);

    const getData = async () => {
        try {
            let result: Partial<RoleDto> = { id, name: '' };
            if (id) {
                Loading.show();
                result = await RolesService.getById(id);
            }
            form.reset(result);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get role information', error);
            Toast.error(t('messages.error_load_info'));
        }
    };

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/backoffice/roles/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate('/backoffice/roles');
        }
    }

    const onSubmit = async (model: RoleDto) => {
        try {
            Loading.show();

            if (model && model.id) {
                await RolesService.update(model)
                // navigateTo('details', id);
            } else if (model) {
                const id = await RolesService.create(model)
                navigateTo('edit', id);
            }
            Loading.hide();
            Toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            if (error?.response?.status === 409) {
                Toast.warning(t('messages.role_already_exists'));
            } else {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the role with id: ${id}`, error);
                Toast.error(t('messages.record_save_error'));
            }
            Loading.hide();
        }
    };

    const onInvalid = () => {
        Toast.warning(t('messages.required_fields_empty'));
    }

    const onDelete = async (result: boolean) => {
        if (!result) {
            setShowConfirmDeleteModal(false)
            return;
        }

        Loading.show();
        try {
            await RolesService.remove(form.getValues())
            Toast.success(t('messages.record_delete_success'));
            navigateTo();
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete role', error);
            Toast.error(t('messages.record_delete_error'));
        } finally {
            Loading.hide();
            setShowConfirmDeleteModal(false)
        }
    }

    const title = (type === 'create' ? t('common.new') : (type === 'edit' ? t('common.edit') : t('common.details'))) + ' ' + t('roles.title');

    if (!canRead) {
        return null;
    }

    return (
        <ScreenTitle title={t('roles.title')}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: t('roles.list.title'), url },
                    { name: title, url: currentUrl },
                ]}
            />
            <Form handleSubmit={form.handleSubmit} onSubmit={onSubmit} onInvalid={onInvalid} noValidate>
                <PageHeader title={title} informationText={t('common.go_back')} onGoBack={() => navigateTo()}>
                    {canWrite && !isDetails && <Button type='submit'>{t('common.save')}</Button>}
                    {canWrite && isDetails && <Button fw onClick={() => { navigateTo('edit', id); }}>{t('common.edit')}</Button>}
                    {canWrite && isDetails && <Button variant='secondary' onClick={() => setShowConfirmDeleteModal(true)}> <FaTrashAlt /> </Button>}
                </PageHeader>
                <PageContainer>
                    <Row>
                        <Col>
                            <Label space>{t('roles.list.name')}{!isDetails ? '*' : ''}</Label>
                            <TextInputController
                                name='name' control={form.control}
                                placeholder={t('roles.list.name')} disabled={isDetails}
                                rules={{ required: true, maxLength: 250 }}
                                hasError={Boolean(errors.name)} />
                            <InputError error={errors.name} maxLength={250} />
                        </Col>
                    </Row>
                </PageContainer>
            </Form>
            <ConfirmDeleteModal
                itemName={form.getValues('name')}
                isOpen={showConfirmDeleteModal}
                onClose={(result) => onDelete(result)}
            />
        </ScreenTitle>
    )
}

export default RoleScreen;
