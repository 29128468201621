import { hexToRgba } from 'common/utils/hexToRgba';
import { MenuItemStyles } from 'react-pro-sidebar';
import colors from 'styles/export/colors.module.scss';

const menuClasses = {
    root: 'ps-menu-root',
    menuItemRoot: 'ps-menuitem-root',
    subMenuRoot: 'ps-submenu-root',
    button: 'ps-menu-button',
    prefix: 'ps-menu-prefix',
    suffix: 'ps-menu-suffix',
    label: 'ps-menu-label',
    icon: 'ps-menu-icon',
    subMenuContent: 'ps-submenu-content',
    SubMenuExpandIcon: 'ps-submenu-expand-icon',
    disabled: 'ps-disabled',
    active: 'ps-active',
    open: 'ps-open',
};

const themes = {
    light: {
        sidebar: {
            backgroundColor: colors.white,
            color: colors.primary,
        },
        menu: {
            menuContent: colors.light3,
            icon: colors.primary,
            color: colors.primaryDark,
            hover: {
                backgroundColor: '#E9ECF3',
            },
            disabled: {
                color: '#9fb6cf',
            },
        },
    }
};

export const menuItemStyles: MenuItemStyles = {
    root: {
        fontSize: '13px',
        fontWeight: 400,
    },
    icon: {
        color: themes.light.menu.icon,
        [`&.${menuClasses.disabled}`]: {
            color: themes.light.menu.disabled.color,
        },
    },
    SubMenuExpandIcon: {
        color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
        backgroundColor:
        level === 0
            ? hexToRgba(themes.light.menu.menuContent, 1)
            : 'transparent',
    }),
    button: {
        [`&.${menuClasses.disabled}`]: {
            color: themes.light.menu.disabled.color,
        },
        '&:hover': {
            backgroundColor: hexToRgba(themes.light.menu.hover.backgroundColor, 1),
        },
        '&.ps-active': {
            backgroundColor: '#E9ECF3',
        },
    },
    label: ({ open }) => ({
        fontWeight: open ? 600 : undefined,
        color: themes.light.menu.color
    }),
};
