import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { Header, Text, Label } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/button/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'common/components/modal/Modal';
import MoneyInput from 'common/components/moneyInput/MoneyInput';
import styles from './UpdatePricesModal.module.scss';
import { Row, Col } from 'react-bootstrap';
import InputError from 'common/components/inputError/InputError';

type Props = {
    total?: number;
    onClose: (value: number | null | undefined) => void;
} & ReactModal.Props;

const UpdatePricesModal: React.FC<Props> = ({ total, onClose, ...props }: Props) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<number | null | undefined>(null);
    const [valueHasError, setValueHasError] = useState<boolean>(false);
    const [hasSubmited, setHasSubmited] = useState<boolean>(false);

    const clickOnSave = () => {
        setHasSubmited(true);
        if (value) {
            onClose(value);
            setValue(null);
        } else {
            setValueHasError(true);
        }
    }

    return (
        <Modal {...props} style={{ content: { minWidth: 400, maxWidth: 550 } }}>
            <div>
                <ModalHeader>
                    <Header >{t('elements.prices_update.update_price')}</Header>
                </ModalHeader>
                <ModalBody>
                    <Text className={styles.message}> {t('elements.prices_update.update_price_message', { total })}</Text>

                    <Row className={styles.center}>
                        <Col md={6}>
                            <Label space>{t('elements.prices_update.price_variation')}</Label>
                            <MoneyInput
                                value={value}
                                unitPrefix={'%'}
                                onChange={(val) => {
                                    setValue(val);
                                    if (val) {
                                        setValueHasError(false);
                                    }
                                }}
                                hasError={hasSubmited && valueHasError}
                            />
                            {hasSubmited && valueHasError && <InputError error={{ type: 'required' }} />}
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter className={styles.footerContainer}>
                    <Button variant='secondary' onClick={() => {
                        onClose(undefined);
                        setValue(null);
                    }} className={styles.cancelButton}>{t('common.cancel')}</Button>
                    <Button onClick={() => clickOnSave()}>{t('elements.prices_update.confirm_update_prices')}</Button>
                </ModalFooter>
            </div>
        </Modal >
    );
};

export default UpdatePricesModal;
