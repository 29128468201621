import { FamilyDto } from 'api/families/models/FamilyDto';
import { ParameterSectionBaseModel } from 'common/components/parametersSections/models';
import { SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';
import { createContext } from 'react';

interface FamilyParameterDataProviderModel {
    family?: Partial<FamilyDto>;
    units: SearchableSelectTitleSubTitleOption[];
    familiesInputOptions: SearchableSelectTitleSubTitleOption[];
    selectedSectionKey: string;
    sections: ParameterSectionBaseModel[];
}

const defaultValue: FamilyParameterDataProviderModel = {
    familiesInputOptions: [],
    units: [],
    selectedSectionKey: '',
    sections: []
}

export const FamilyParameterDataProvider = createContext(defaultValue);
