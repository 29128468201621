import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AuthenticatedLayout from './common/layouts/authenticatedLayout/AuthenticatedLayout';
import AuthorizeRoute from './common/routes/AuthorizeRoute';
import { ApplicationPaths, LoginActions, LogoutActions } from './Config';
import ForbiddenScreen from './screens/errors/ForbiddenScreen';
import NotFoundScreen from './screens/errors/NotFoundScreen';
import LoginScreen from './screens/auth/LoginScreen';
import LogoutScreen from './screens/auth/LogoutScreen';
import HomeScreen from 'screens/home/HomeScreen';
import AccountScreen from 'screens/account/AccountScreen';
import TestScreen from 'screens/test/TestScreen';
import RoleScreen from 'screens/backoffice/roles/RoleScreen';
import UsersListScreen from 'screens/backoffice/users/list/UsersListScreen';
import FamiliesListScreen from 'screens/library/families/list/FamiliesListScreen';
import RolesList from 'screens/backoffice/roles/list/RolesList';
import UserScreen from 'screens/backoffice/users/user/UserScreen';
import RulesScreen from 'screens/backoffice/rules/RulesScreen';
import FamilyScreen from 'screens/library/families/family/FamilyScreen';
import TaxScreen from 'screens/backoffice/taxes/tax/TaxScreen';
import TaxListScreen from 'screens/backoffice/taxes/taxList/TaxListScreen';
import WorkTypesListScreen from 'screens/library/workTypes/list/WorkTypeList';
import WorkTypeScreen from 'screens/library/workTypes/workType/WorkTypeScreen';
import UnitsListScreen from 'screens/library/units/list/UnitsListScreen';
import UnitScreen from 'screens/library/units/unit/UnitScreen';
import ElementScreen from 'screens/library/elements/element/ElementScreen';
import ElementsListScreen from 'screens/library/elements/list/ElementsListScreen';
import React, { ReactNode, useEffect } from 'react';
import PolicyRoute from 'common/routes/PolicyRoute';
import ExternalPartCategoriesListScreen from 'screens/externalPartCategories/list/ExternalPartCategoriesListScreen';
import ExternalPartCategoryScreen from 'screens/externalPartCategories/externalPartCategory/ExternalPartCategoryScreen';
import ExternalPartsListScreen from 'screens/externalParts/list/ExternalPartsListScreen';
import ExternalPartScreen from 'screens/externalParts/externalPart/ExternalPartScreen';
import WorkScreen from 'screens/business/works/work/WorkScreen';
import WorksListScreen from 'screens/business/works/list/WorksListScreen';
import BudgetScreen from 'screens/business/budgets/budget/BudgetScreen';
import BudgetsListScreen from 'screens/business/budgets/list/BudgetsListScreen';
import BudgetFileModelScreen from 'screens/backoffice/budgetFileModels/budgetFileModel/BudgetFileModelScreen';
import BudgetFileModelsListScreen from 'screens/backoffice/budgetFileModels/budgetFileModelList/BudgetFileModelListScreen';
import NumerationScreen from 'screens/backoffice/numerations/numeration/NumerationScreen';
import NumerationsList from 'screens/backoffice/numerations/list/NumerationsList';
import NavigationService from 'common/services/NavigationService';
import DashboardScreen from 'screens/dashboard/DashboardScreen';
import BudgetsFieldsList from 'screens/backoffice/budgetFields/list/BudgetsFieldsList';
import BudgetFieldScreen from 'screens/backoffice/budgetFields/budgetField/BudgetFieldScreen';

interface ForceReloadOnNavigateProps { children: ReactNode; }

const ForceReloadOnNavigate = (props: ForceReloadOnNavigateProps) => {
    const location = useLocation();
    return <React.Fragment key={location.key}>{props.children}</React.Fragment>;
}

const HomeRedirection = () => {
    return <Navigate replace={true} to={ApplicationPaths.Home} />;
};

const NavigationSetup: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        NavigationService.setNavigate(navigate);
    }, [navigate]);

    return null; // This component does not render anything
};

const Router = () => {
    return (
        <BrowserRouter>
            <NavigationSetup />
            <Routes>
                <Route path="/404" element={<NotFoundScreen />} />
                <Route path="/403" element={<ForbiddenScreen />} />
                <Route path={ApplicationPaths.Login} element={<LoginScreen action={LoginActions.Login} />} />
                <Route path={ApplicationPaths.Reset} element={<LoginScreen action={LoginActions.Reset} />} />
                <Route path={ApplicationPaths.LoginFailed} element={<LoginScreen action={LoginActions.LoginFailed} />} />
                <Route path={ApplicationPaths.LoginCallback} element={<LoginScreen action={LoginActions.LoginCallback} />} />
                <Route path={ApplicationPaths.LogOut} element={<LogoutScreen action={LogoutActions.Logout} />} />
                <Route path={ApplicationPaths.LogOutCallback} element={<LogoutScreen action={LogoutActions.LogoutCallback} />} />
                <Route path='/*' element={
                    <AuthorizeRoute>
                        <AuthenticatedLayout>
                            <Routes>
                                <Route path="/" element={<HomeRedirection />} />

                                <Route path="/dashboard" element={
                                    <ForceReloadOnNavigate><DashboardScreen /></ForceReloadOnNavigate>
                                } />

                                <Route path="/library/elements/families" element={
                                    <PolicyRoute policies={['FAMILIES_READ', 'FAMILIES_WRITE']} type='OR'>
                                        <ForceReloadOnNavigate><FamiliesListScreen listType="elements" /></ForceReloadOnNavigate>
                                    </PolicyRoute>
                                } />
                                <Route path="/library/elements/sub-families" element={
                                    <PolicyRoute policies={['FAMILIES_READ', 'FAMILIES_WRITE']} type='OR'>
                                        <ForceReloadOnNavigate><FamiliesListScreen listType="elements" isSubFamilies /></ForceReloadOnNavigate>
                                    </PolicyRoute>
                                } />
                                <Route path="/library/components/families" element={
                                    <PolicyRoute policies={['FAMILIES_READ', 'FAMILIES_WRITE']} type='OR'>
                                        <ForceReloadOnNavigate><FamiliesListScreen listType="components" /></ForceReloadOnNavigate>
                                    </PolicyRoute>
                                } />
                                <Route path="/library/components/sub-families" element={
                                    <PolicyRoute policies={['FAMILIES_READ', 'FAMILIES_WRITE']} type='OR'>
                                        <ForceReloadOnNavigate><FamiliesListScreen listType="components" isSubFamilies /></ForceReloadOnNavigate>
                                    </PolicyRoute>
                                } />
                                <Route path="/library/elements/families/:type/:id?" element={
                                    <PolicyRoute policies={['FAMILIES_READ', 'FAMILIES_WRITE']} type='OR'>
                                        <ForceReloadOnNavigate><FamilyScreen listType="elements" /></ForceReloadOnNavigate>
                                    </PolicyRoute>
                                } />
                                <Route path="/library/elements/sub-families/:type/:id?" element={
                                    <PolicyRoute policies={['FAMILIES_READ', 'FAMILIES_WRITE']} type='OR'>
                                        <ForceReloadOnNavigate><FamilyScreen listType="elements" isSubFamilies /></ForceReloadOnNavigate>
                                    </PolicyRoute>
                                } />
                                <Route path="/library/components/families/:type/:id?" element={
                                    <PolicyRoute policies={['FAMILIES_READ', 'FAMILIES_WRITE']} type='OR'>
                                        <ForceReloadOnNavigate><FamilyScreen listType="components" /></ForceReloadOnNavigate>
                                    </PolicyRoute>
                                } />
                                <Route path="/library/components/sub-families/:type/:id?" element={
                                    <PolicyRoute policies={['FAMILIES_READ', 'FAMILIES_WRITE']} type='OR'>
                                        <ForceReloadOnNavigate><FamilyScreen listType="components" isSubFamilies /></ForceReloadOnNavigate>
                                    </PolicyRoute>
                                } />

                                <Route path="/library/work-types" element={
                                    <PolicyRoute policies={['WORK_TYPES_READ', 'WORK_TYPES_WRITE']} type='OR'>
                                        <WorkTypesListScreen />
                                    </PolicyRoute>
                                } />
                                <Route path="/library/work-types/:type/:id?" element={
                                    <PolicyRoute policies={['WORK_TYPES_READ', 'WORK_TYPES_WRITE']} type='OR'>
                                        <WorkTypeScreen />
                                    </PolicyRoute>
                                } />

                                <Route path="/library/units" element={
                                    <PolicyRoute policies={['UNITS_READ', 'UNITS_WRITE']} type='OR'>
                                        <UnitsListScreen />
                                    </PolicyRoute>
                                } />
                                <Route path="/library/units/:type/:id?" element={
                                    <PolicyRoute policies={['UNITS_READ', 'UNITS_WRITE']} type='OR'>
                                        <UnitScreen />
                                    </PolicyRoute>
                                } />

                                <Route path="/library/elements-items" element={
                                    <PolicyRoute policies={['ELEMENTS_READ', 'ELEMENTS_WRITE']} type='OR'>
                                        <ForceReloadOnNavigate><ElementsListScreen listType="elements"/></ForceReloadOnNavigate>
                                    </PolicyRoute>
                                } />
                                <Route path="/library/components-items" element={
                                    <PolicyRoute policies={['ELEMENTS_READ', 'ELEMENTS_WRITE']} type='OR'>
                                        <ForceReloadOnNavigate><ElementsListScreen listType="components"/></ForceReloadOnNavigate>
                                    </PolicyRoute>
                                } />
                                <Route path="/library/elements-items/:type/:id?" element={
                                    <PolicyRoute policies={['ELEMENTS_READ', 'ELEMENTS_WRITE']} type='OR'>
                                        <ForceReloadOnNavigate><ElementScreen listType="elements"/></ForceReloadOnNavigate>
                                    </PolicyRoute>
                                } />

                                <Route path="/library/components-items/:type/:id?" element={
                                    <PolicyRoute policies={['ELEMENTS_READ', 'ELEMENTS_WRITE']} type='OR'>
                                        <ForceReloadOnNavigate><ElementScreen listType="components"/></ForceReloadOnNavigate>
                                    </PolicyRoute>
                                } />

                                <Route path="/external-parts/categories" element={
                                    <PolicyRoute policies={['EXTERNAL_PARTS_READ', 'EXTERNAL_PARTS_WRITE']} type='OR'>
                                        <ExternalPartCategoriesListScreen />
                                    </PolicyRoute>
                                } />

                                <Route path="/external-parts/categories/:type/:id?" element={
                                    <PolicyRoute policies={['EXTERNAL_PARTS_READ', 'EXTERNAL_PARTS_WRITE']} type='OR'>
                                        <ExternalPartCategoryScreen />
                                    </PolicyRoute>
                                } />

                                <Route path="/external-parts/:categoryId?" element={
                                    <PolicyRoute policies={['EXTERNAL_PARTS_READ', 'EXTERNAL_PARTS_WRITE']} type='OR'>
                                        <ExternalPartsListScreen />
                                    </PolicyRoute>
                                } />
                                <Route path="/external-parts/:categoryId?/:type/:id?" element={
                                    <PolicyRoute policies={['EXTERNAL_PARTS_READ', 'EXTERNAL_PARTS_WRITE']} type='OR'>
                                        <ExternalPartScreen />
                                    </PolicyRoute>
                                } />

                                <Route path="/test" element={<TestScreen />} />
                                <Route path="/home/:menu?" element={<HomeScreen />} />
                                <Route path="/account/:type" element={<AccountScreen />} />
                                <Route path="/backoffice/users" element={
                                    <PolicyRoute policies={['USERS_READ', 'USERS_WRITE']} type='OR'>
                                        <UsersListScreen />
                                    </PolicyRoute>
                                } />
                                <Route path="/backoffice/users/:type/:id?" element={
                                    <PolicyRoute policies={['USERS_READ', 'USERS_WRITE']} type='OR'>
                                        <UserScreen />
                                    </PolicyRoute>
                                } />
                                <Route path="/backoffice/roles" element={
                                    <PolicyRoute policies={['ROLES_READ', 'ROLES_WRITE']} type='OR'>
                                        <RolesList />
                                    </PolicyRoute>
                                } />
                                <Route path="/backoffice/roles/:type/:id?" element={
                                    <PolicyRoute policies={['ROLES_READ', 'ROLES_WRITE']} type='OR'>
                                        <RoleScreen />
                                    </PolicyRoute>
                                } />
                                <Route path="/backoffice/budgets-fields" element={
                                    <PolicyRoute policies={['BUDGETS_FIELDS_READ', 'BUDGETS_FIELDS_WRITE']} type='OR'>
                                        <BudgetsFieldsList />
                                    </PolicyRoute>
                                } />
                                <Route path="/backoffice/budgets-fields/:type/:id?" element={
                                    <PolicyRoute policies={['BUDGETS_FIELDS_READ', 'BUDGETS_FIELDS_WRITE']} type='OR'>
                                        <BudgetFieldScreen />
                                    </PolicyRoute>
                                } />
                                <Route path="/backoffice/numerations" element={
                                    <PolicyRoute policies={['NUMERATIONS_READ', 'NUMERATIONS_WRITE']} type='OR'>
                                        <NumerationsList />
                                    </PolicyRoute>
                                } />
                                <Route path="/backoffice/numerations/:type/:id?" element={
                                    <PolicyRoute policies={['NUMERATIONS_READ', 'NUMERATIONS_WRITE']} type='OR'>
                                        <NumerationScreen />
                                    </PolicyRoute>
                                } />
                                <Route path="/backoffice/rules" element={
                                    <PolicyRoute policies={['RULES_READ', 'RULES_WRITE']} type='OR'>
                                        <RulesScreen />
                                    </PolicyRoute>
                                } />
                                <Route path="/backoffice/taxes/:type/:id?" element={
                                    <PolicyRoute policies={['TAXES_READ', 'TAXES_WRITE']} type='OR'>
                                        <TaxScreen />
                                    </PolicyRoute>
                                } />
                                <Route path="/backoffice/taxes" element={
                                    <PolicyRoute policies={['TAXES_READ', 'TAXES_WRITE']} type='OR'>
                                        <TaxListScreen />
                                    </PolicyRoute>
                                } />

                                <Route path="/backoffice/budget-file-models/:type/:id?" element={
                                    <PolicyRoute policies={['BUDGETS_FILE_MODELS_READ', 'BUDGETS_FILE_MODELS_WRITE']} type='OR'>
                                        <BudgetFileModelScreen />
                                    </PolicyRoute>
                                } />
                                <Route path="/backoffice/budget-file-models" element={
                                    <PolicyRoute policies={['BUDGETS_FILE_MODELS_READ', 'BUDGETS_FILE_MODELS_WRITE']} type='OR'>
                                        <BudgetFileModelsListScreen />
                                    </PolicyRoute>
                                } />

                                <Route path="/business/works/:type/:id?" element={
                                    <PolicyRoute policies={['WORKS_READ', 'WORKS_WRITE']} type='OR'>
                                        <WorkScreen />
                                    </PolicyRoute>
                                } />
                                <Route path="/business/works" element={
                                    <PolicyRoute policies={['WORKS_READ', 'WORKS_WRITE']} type='OR'>
                                        <WorksListScreen />
                                    </PolicyRoute>
                                } />

                                <Route path="/business/budgets/:type/:id?/:version?" element={
                                    <PolicyRoute policies={['BUDGETS_READ', 'BUDGETS_WRITE']} type='OR'>
                                        <BudgetScreen />
                                    </PolicyRoute>
                                } />
                                <Route path="/business/budgets" element={
                                    <PolicyRoute policies={['BUDGETS_READ', 'BUDGETS_WRITE']} type='OR'>
                                        <BudgetsListScreen />
                                    </PolicyRoute>
                                } />

                                <Route path="*" element={<Navigate replace={true} to="/404" />} />
                            </Routes>
                        </AuthenticatedLayout>
                    </AuthorizeRoute>
                } />

                <Route path="*" element={<Navigate replace={true} to="/404" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
