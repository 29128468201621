import React from 'react';
import ReactModal from 'react-modal';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../Modal';
import { Header, Text } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/button/Button';

type Props = {
    title: string;
    message: string;
    onClose: (res: boolean) => void;
} & ReactModal.Props;

const YesNoModal: React.FC<Props> = ({ title, message, onClose, ...props }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal {...props} style={{ content: { minWidth: 400 } }}>
            <div>
                <ModalHeader>
                    <Header>{title.toUpperCase()}</Header>
                </ModalHeader>
                <ModalBody>
                    <Text dangerouslySetInnerHTML={{ __html: message }}></Text>
                </ModalBody>
                <ModalFooter className="text-right">
                    <Button fw variant="secondary" onClick={() => onClose(false)}>{t('common.no')}</Button>
                    <Button onClick={() => onClose(true)}>{t('common.yes')}</Button>
                </ModalFooter>
            </div>
        </Modal >
    );
};

export default YesNoModal;
