import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import BudgetLine from '../line/BudgetLine';
import { BudgetLineType } from 'api/budgets/enums/BudgetLineType';
import { SortableTree } from 'common/components/sortableTree/SortableTree';
import { useDispatch } from 'react-redux';
import { calculateAllLinesTotals, setLines } from 'store/budget/action';

interface Props {
    lines: BudgetLineDto[];
    disabled: boolean;
}

function BudgetLinesList({ lines, disabled }: Props): JSX.Element | null {
    const dispatch = useDispatch();

    const onItemsChanged = (lines: BudgetLineDto[]) => {
        dispatch(setLines(lines));
        dispatch(calculateAllLinesTotals());
    }

    return (
        <SortableTree
            items={lines}
            onItemsChanged={(newItems) => onItemsChanged(newItems as BudgetLineDto[])}
            TreeItemComponent={BudgetLine}
            canHaveChildren={(item) => (item.lineType === BudgetLineType.SECTION || item.lineType === BudgetLineType.ELEMENT || item.lineType === BudgetLineType.COMPONENT_ELEMENT)}
            canRootHaveChildren={(item) => item.lineType === BudgetLineType.SECTION}
            dropAnimation={null}
            indentationWidth={20}
            disableSorting={disabled}
        />
    );
}

export default BudgetLinesList;
