import { Paged } from 'api/common/types/Page';
import Http from 'common/services/Http';
import { POLICIES } from '../../Config';
import { UserDto } from './models/UserDto';
import { UsersSelectItemDto } from './models/UsersSelectItemDto';
import { UserSearchCriteria } from './models/UserSearchCriteria';

class UsersService {
    public getList (criteria: UserSearchCriteria) {
        return Http.get<Paged<UserDto>>('users', criteria);
    }

    public getAllForSelectItem () {
        return Http.get<UsersSelectItemDto[]>('users/get-All-for-select-item');
    }

    public getById (id: string) {
        return Http.get<UserDto>('users/' + id);
    }

    public create (model: UserDto): Promise<string> {
        return Http.post('users', model);
    }

    public update (model: UserDto) {
        return Http.put('users', model);
    }

    public remove (model: UserDto) {
        return Http.put('users/deactivate', model);
    }

    public hasPolicies (userPolicies: string[], policies: (keyof typeof POLICIES)[], type: 'OR' | 'AND' = 'AND') {
        return type === 'AND'
            ? (policies || []).map(p => !!(userPolicies).find(up => up === POLICIES[p])).filter(p => !p).length === 0
            : (policies || []).map(p => !!(userPolicies).find(up => up === POLICIES[p])).filter(p => p).length > 0;
    }
}

export default new UsersService();
