import PageContainer from 'common/components/pageContainer/PageContainer';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useTranslation } from 'react-i18next';
import styles from './HomeScreen.module.scss';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import { Header } from 'common/components/texts/Texts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SideBarItem } from 'common/layouts/sidebar/Sidebar';
import { Menus } from 'common/layouts/sidebar/components/Menus';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import i18n from 'common/services/I18n';
import UsersService from 'api/users/UsersService';

function HomeScreen(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { menu } = useParams<{ menu: string }>();

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const validateItem = useCallback((item: SideBarItem) => {
        const type = item.type ?? 'AND';
        const policies = item.policies ?? [];
        const userPolicies = loggedUser?.policies ?? [];
        return item.policies.length > 0 ? UsersService.hasPolicies(userPolicies, policies, type) : true;
    }, [loggedUser]);

    const [currentItems, setCurrentItems] = useState<SideBarItem[]>(Menus);

    useEffect(() => {
        const loadItems = async () => {
            let items = Menus;
            if (menu) {
                const parentMenu = Menus.find(x => x.url?.includes(menu));
                if (parentMenu) {
                    items = parentMenu.items || items;
                    if (parentMenu.itemsLoader) {
                        const loadedItems = await parentMenu.itemsLoader();
                        items = [...loadedItems, ...items];
                    }
                }
            }

            const sidebarItems = items.filter(item => item.url !== location.pathname && validateItem(item))
                .map(item => {
                    item.items = item?.items ? item.items.filter(i => validateItem(i)) : undefined;
                    return item;
                });

            setCurrentItems(sidebarItems);
        };

        void loadItems();
    }, [menu, loggedUser, validateItem, location.pathname]);

    const breadcrumbs = [
        { name: t('home.title'), url: '/' },
    ]

    if (menu) {
        const parentMenu = Menus.find(x => x.url?.includes(menu));
        if (parentMenu) {
            breadcrumbs.push({ name: parentMenu.label, url: parentMenu.url! })
        }
    }

    const onClickItem = (menuItem: SideBarItem) => {
        if (menuItem.items?.length) {
            setCurrentItems(menuItem.items);
        }
        if (menuItem.url) {
            navigate(menuItem.url);
        }
    }

    return (
        <ScreenTitle title={t('home.title')}>
            <PageBreadcrumbsPortal
                breadcrumbs={breadcrumbs}
            />
            <PageContainer>
                <Link to="/" className={styles.headerLink}><Header>{t('home.title')}</Header></Link>
                <div className={`row ${styles.list}`}>
                    {currentItems.map((menuItem, index) => (
                        <div key={`item_${menuItem.url}_${index}`} className={'col-xs-12 col-md-6 col-lg-3'}>
                            <div className={styles.menuItem} onClick={() => onClickItem(menuItem)}>
                                {menuItem.Icon && <div className={styles.menuItemIcon}>
                                    {<menuItem.Icon size={60} />}
                                </div>}
                                <div className={styles.menuItemTitle}>
                                    {menuItem.label}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </PageContainer>
        </ScreenTitle>
    );
}

export default HomeScreen;
