import { InformationText, Label } from 'common/components/texts/Texts';
import { FormatOptionLabelMeta } from 'react-select';
import styles from './OptionTitleSubTitle.module.scss';
import { SearchableSelectInputOption } from '../SearchableSelectInput';
import { Image } from 'common/components/image/Image';

export interface SearchableSelectTitleSubTitleOption extends SearchableSelectInputOption {
    subTitle: string;
    reference?: string;
    imageUrl?: string;
    canShowNoImage?: boolean;
}

export const OptionTitleSubTitle = (data: SearchableSelectTitleSubTitleOption, formatOptionLabelMeta: FormatOptionLabelMeta<SearchableSelectTitleSubTitleOption>) => {
    return (
        <div className={styles.container}>
            {(data.imageUrl || data.canShowNoImage) &&
                <Image src={data.imageUrl} containerClassName={styles.image} />
            }
            <div className={styles.labelContainer}>
                <div className={styles.title}>
                    {data.reference && <InformationText small>{data.reference} -&nbsp;</InformationText>}
                    <Label>{data.label}</Label>
                </div>
                <InformationText small>{data.subTitle}</InformationText>
            </div>
        </div>
    );
}
