import { t } from 'i18next';
import { SideBarItem } from '../Sidebar';
import * as Icons from './icons/index';
import ExternalPartCategoriesService from 'api/externalPartCategory/ExternalPartCategoriesService';

export const Menus: SideBarItem[] = [
    {
        label: t('menu.home'),
        url: '/home',
        Icon: Icons.Home,
        policies: [],
        type: 'OR'
    },
    {
        label: t('menu.dashboard'),
        url: '/dashboard',
        Icon: Icons.Dashboard,
        policies: [],
        type: 'OR'
    },
    {
        label: t('menu.business.business'),
        url: '/home/business',
        policies: [
            'WORKS_READ',
            'BUDGETS_READ',
        ],
        Icon: Icons.Budgets,
        type: 'OR',
        items: [
            {
                label: t('menu.business.works'),
                url: '/business/works',
                policies: [
                    'WORKS_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.business.budgets'),
                url: '/business/budgets',
                policies: [
                    'BUDGETS_READ',
                ],
                type: 'OR',
                items: []
            },
        ]
    },
    {
        label: t('menu.library.library'),
        url: '/home/library',
        policies: [
            'FAMILIES_READ',
            'WORK_TYPES_READ',
            'UNITS_READ',
            'ELEMENTS_READ',
        ],
        Icon: Icons.Products,
        type: 'OR',
        items: [
            {
                label: t('menu.library.elements'),
                url: '/library/elements-items',
                policies: [
                    'ELEMENTS_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.library.components'),
                url: '/library/components-items',
                policies: [
                    'ELEMENTS_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.library.families_elements'),
                url: '/library/elements/families',
                policies: [
                    'FAMILIES_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.library.sub_families_elements'),
                url: '/library/elements/sub-families',
                policies: [
                    'FAMILIES_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.library.families_components'),
                url: '/library/components/families',
                policies: [
                    'FAMILIES_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.library.sub_families_components'),
                url: '/library/components/sub-families',
                policies: [
                    'FAMILIES_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.library.work_types'),
                url: '/library/work-types',
                policies: [
                    'WORK_TYPES_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.library.units'),
                url: '/library/units',
                policies: [
                    'UNITS_READ',
                ],
                type: 'OR',
                items: []
            },
        ]
    },
    {
        label: t('menu.external_parts.external_parts'),
        url: '/home/external-parts',
        Icon: Icons.ExternalParts,
        policies: [
            'EXTERNAL_PARTS_READ',
        ],
        type: 'OR',
        items: [{
            label: t('menu.external_parts.external_parts_categories'),
            url: '/external-parts/categories',
            policies: [
                'EXTERNAL_PARTS_READ',
            ],
            type: 'OR',
        }],
        itemsLoader: async () => {
            const results = await ExternalPartCategoriesService.getCatalog();
            if (results?.length) {
                return results.map((res) => ({
                    label: res.name,
                    url: '/external-parts/' + res.id,
                    policies: [
                        'EXTERNAL_PARTS_READ',
                        'EXTERNAL_PARTS_WRITE',
                    ],
                    type: 'OR'
                }));
            }
            return []
        }
    },
    {
        label: t('menu.backoffice.backoffice'),
        url: '/home/backoffice',
        policies: [
            'USERS_READ',
            'ROLES_READ',
            'RULES_READ',
            'TAXES_READ',
            'BUDGETS_FILE_MODELS_READ',
            'NUMERATIONS_READ'
        ],
        Icon: Icons.Backoffice,
        type: 'OR',
        items: [
            {
                label: t('menu.backoffice.budgets_fields'),
                url: '/backoffice/budgets-fields',
                policies: [
                    'BUDGETS_FIELDS_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.backoffice.numerations'),
                url: '/backoffice/numerations',
                policies: [
                    'NUMERATIONS_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.backoffice.budget_file_models'),
                url: '/backoffice/budget-file-models',
                policies: [
                    'BUDGETS_FILE_MODELS_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.backoffice.users'),
                url: '/backoffice/users',
                policies: [
                    'USERS_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.backoffice.roles'),
                url: '/backoffice/roles',
                policies: [
                    'ROLES_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.backoffice.rules'),
                url: '/backoffice/rules',
                policies: [
                    'RULES_READ',
                ],
                type: 'OR',
                items: []
            },
            {
                label: t('menu.backoffice.tax'),
                url: '/backoffice/taxes',
                policies: [
                    'TAXES_READ',
                ],
                type: 'OR',
                items: []
            },
        ]
    }
];
