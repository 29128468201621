import styles from './ParameterImages.module.scss';
import { BudgetLineParameterDto } from 'api/budgets/models/BudgetLineParameterDto';
import { useTranslation } from 'react-i18next';
import { Text } from 'common/components/texts/Texts';
import { Image } from 'common/components/image/Image';

interface Props {
    parameter: BudgetLineParameterDto;
    disabled: boolean;
}

function ParameterImages({ parameter, disabled }: Props): JSX.Element | null {
    const { t } = useTranslation();

    if (!parameter.images?.length) {
        return <Text>{t('messages.no_info_to_show')}</Text>;
    }

    return (
        <div className={styles.list}>
            {(parameter.images ?? []).map((image, i) => (
                <Image
                    key={i}
                    src={image.url}
                    containerClassName={styles.image}
                />
            ))}
        </div>
    );
}

export default ParameterImages;
