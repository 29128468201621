import { ParameterProductLine } from 'api/elements/Models/ElementDto'
import Utils from 'common/services/Utils'

export const newProductLine = (): ParameterProductLine => {
    return {
        id: Utils.newGuid(),
        key: Utils.newGuid(),
        isOpen: true,
        parentKey: null
    }
}

export function onMove(lines: ParameterProductLine[],lineKey: string, direction: 'up' | 'down') {
    const lineIndex = lines.findIndex(x => x.key === lineKey);

    if (lineIndex === -1) {
        return lines;
    }

    const newIndex: number | null = lineIndex;
    const newLines: ParameterProductLine[] = lines;
    if (direction === 'up') {
        if (lineIndex === 0) {
            return lines; // Already at the top
        }
        const item = lines.find(x => x.key === lineKey)!;
        const oldItemAtPosition = newLines[lineIndex - 1];
        newLines[lineIndex - 1] = item;
        newLines[lineIndex] = oldItemAtPosition;
    } else if (direction === 'down') {
        if (lineIndex === lines.length - 1) {
            return lines; // Already at the bottom
        }
        const item = lines.find(x => x.key === lineKey)!;
        const oldItemAtPosition = newLines[lineIndex + 1];
        newLines[lineIndex + 1] = item;
        newLines[lineIndex] = oldItemAtPosition;
    } else {
        return lines; // Invalid direction
    }

    if (newIndex === null) {
        return lines;
    }

    return newLines;
}
