import React from 'react';
import { IconProps } from './types';
import { calculateAspectRatioFit } from 'common/utils/calculateAspectRatioFit';

export const Dashboard: React.FC<IconProps> = ({ size = 25, ...rest }) => {
    const newSize = calculateAspectRatioFit(21.544, 24, size, size);
    return (
        <svg id="icon-dashboard" xmlns="http://www.w3.org/2000/svg" width={newSize.width} height={newSize.height} viewBox="0 0 21.544 24">
            <defs>
                <clipPath id="clip-path-dashboard">
                    <rect id="Retângulo_490" data-name="Retângulo 490" width={newSize.width} height={newSize.height} fill="#27449c"/>
                </clipPath>
            </defs>
            <g id="Grupo_297" data-name="Grupo 297" transform="translate(0 0)" clipPath="url(#clip-path-dashboard)">
                <path id="Caminho_1212" data-name="Caminho 1212" d="M10.107,6.968a.516.516,0,0,1,.418-.083c.753.2,1.494.434,2.25.621a.477.477,0,0,1,.415.416A1.517,1.517,0,1,0,16.1,7.105a.435.435,0,0,1,.117-.528C17.2,5.559,18.173,4.54,19.129,3.5a1.088,1.088,0,0,1,1.193-.477,1.3,1.3,0,0,0,1.433-.735A1.473,1.473,0,0,0,21.632.6,1.437,1.437,0,0,0,20.1.046a1.547,1.547,0,0,0-1,2.288c-1.185,1.251-2.351,2.488-3.529,3.711a.5.5,0,0,1-.408.1,1.61,1.61,0,0,0-1.68.483.444.444,0,0,1-.366.093c-.793-.205-1.583-.423-2.365-.664a.567.567,0,0,1-.33-.332A1.542,1.542,0,0,0,8.505,4.513a1.516,1.516,0,0,0-1.051,1.9.446.446,0,0,1-.178.519c-.994.9-1.988,1.807-2.967,2.726a.589.589,0,0,1-.706.167,1.521,1.521,0,1,0-.179,2.949,1.493,1.493,0,0,0,1.193-1.906.472.472,0,0,1,.188-.554c.413-.36.81-.738,1.215-1.108L8.1,7.309a1.558,1.558,0,0,0,2.011-.34M20.421.828a.726.726,0,0,1,.709.707.7.7,0,0,1-1.4.016.736.736,0,0,1,.7-.723M14.672,6.9a.736.736,0,0,1,.7.677.7.7,0,1,1-1.4.038.734.734,0,0,1,.7-.715M8.213,5.948a.7.7,0,0,1,1.4.076.7.7,0,1,1-1.4-.076M3.144,11.972a.718.718,0,0,1-.691-.723.7.7,0,0,1,.732-.672.71.71,0,0,1,.673.693.728.728,0,0,1-.715.7" transform="translate(-1.019 0)" fill="#27449c"/>
                <path id="Caminho_1213" data-name="Caminho 1213" d="M50.8,23.222a1.533,1.533,0,0,0-1.59-1.573c-.315,0-.63,0-.946,0a1.556,1.556,0,0,0-1.722,1.732q0,3.125,0,6.25c0,2.11,0,4.221,0,6.332a1.513,1.513,0,0,0,1.307,1.591,7.019,7.019,0,0,0,1.147.042A1.608,1.608,0,0,0,50.8,35.8q0-4.4,0-8.8c0-1.261.009-2.522,0-3.783m-.826,5.526v.863q0,3.082,0,6.164c0,.736-.251.986-.984.991-.233,0-.466,0-.7,0-.651-.009-.919-.277-.919-.93q0-5.609,0-11.218c0-.438-.005-.877,0-1.315a.736.736,0,0,1,.837-.828c.288,0,.575,0,.863,0,.645,0,.9.249.9.891q0,2.692,0,5.383" transform="translate(-29.259 -13.61)" fill="#27449c"/>
                <path id="Caminho_1214" data-name="Caminho 1214" d="M18.242,33.534c-.342-.01-.685,0-1.027,0a1.545,1.545,0,0,0-1.7,1.709q0,2.014,0,4.027c0,1.383,0,2.767,0,4.15a1.522,1.522,0,0,0,1.374,1.617,6.4,6.4,0,0,0,1.515,0,1.485,1.485,0,0,0,1.364-1.5q.022-4.253,0-8.506a1.491,1.491,0,0,0-1.53-1.5m.707,9.781c0,.647-.273.913-.93.92q-.37,0-.74,0c-.671-.008-.93-.274-.931-.961q0-1.994,0-3.988,0-2.015,0-4.029c0-.649.241-.887.889-.893.288,0,.576,0,.864,0,.594.008.846.255.847.851,0,1.727,0,3.454,0,5.181,0,.973,0,1.946,0,2.919" transform="translate(-9.756 -21.08)" fill="#27449c"/>
                <path id="Caminho_1215" data-name="Caminho 1215" d="M35.283,39.891a1.549,1.549,0,0,0-1.606-1.608c-.356,0-.713-.006-1.069,0a1.537,1.537,0,0,0-1.58,1.549q-.019,3.331,0,6.662a1.472,1.472,0,0,0,1.26,1.5,7.429,7.429,0,0,0,1.309.055,1.577,1.577,0,0,0,1.687-1.7c0-1.056,0-2.111,0-3.166,0-1.1.007-2.193,0-3.29m-.828,6.395c0,.658-.265.927-.912.936-.3,0-.6.012-.9,0a.741.741,0,0,1-.782-.814q-.009-3.245,0-6.49a.725.725,0,0,1,.786-.8c.329-.01.658-.008.986,0a.742.742,0,0,1,.825.847c.006,1.068,0,2.136,0,3.2,0,1.041,0,2.081,0,3.122" transform="translate(-19.502 -24.067)" fill="#27449c"/>
                <path id="Caminho_1216" data-name="Caminho 1216" d="M2.723,46.213c-.37-.007-.74,0-1.11,0a1.549,1.549,0,0,0-1.608,1.6c-.008.6,0,1.206,0,1.81s-.011,1.234,0,1.851a1.459,1.459,0,0,0,1.258,1.5,6.379,6.379,0,0,0,1.754,0,1.439,1.439,0,0,0,1.24-1.47c.017-1.261.021-2.522,0-3.783a1.524,1.524,0,0,0-1.535-1.5m.705,5.251a.713.713,0,0,1-.73.735,9.433,9.433,0,0,1-1.108,0,.735.735,0,0,1-.754-.8c-.012-.588,0-1.177,0-1.766s-.007-1.177,0-1.766a.741.741,0,0,1,.838-.831c.328,0,.657-.006.985,0a.707.707,0,0,1,.77.727q.022,1.848,0,3.7" transform="translate(0 -29.052)" fill="#27449c"/>
            </g>
        </svg>
    );
}
