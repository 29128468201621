import { AttachmentDto } from 'api/common/models/AttachmentDto';
import { ParameterSectionParameterType, ParameterSectionType } from 'common/components/parametersSections/models';

export interface ElementDto {
    id: string;
    version: string;
    name: string;
    description?: string;
    reference?: string;
    unitId?: string;
    price?: number;
    taxId?: string;
    workTypeId?: string;
    elementTypeId?: string;
    images?: AttachmentDto[];
    imagesIds?: string [];
    attachment?: AttachmentDto;
    attachmentsToCopy?: string [];
    families?: FamilySelectModel[];
    budgetPdfVisibility: string;
    imagesToRemove: string[];
    mainImageKey?: string;
    deleteAttachment: boolean

    isElement: boolean;
    isComponent: boolean;
    parameters?: ElementParameterDto[]
}

export interface FamilySelectModel {
    id: string;
    key?: string;
    newRow?: boolean;
    familyId?: string | null;
    subFamilyId?: string | null;
    isComponent?: boolean;
    isElement?: boolean;
}

export interface ElementListDto {
    id: string;
    version: string;
    name: string;
    description?: string;
    reference?: string;
    unitName?: string;
    unitSymbol?: string;
    unitId?: string;
    price?: number;
    taxId?: string;
    taxName?: string;
    taxValue?: number
    workTypeId?: string;
    workTypeName?: string;
    elementTypeId?: string;
    elementTypeName?: string;
    mediaUrl?: string;
    familyId?: string;
    familiesNames: string[];
    subFamilyId?: string;
    subFamiliesNames: string[];

    updatedDate?: Date | null;
    userUpdatedName?: string;

    priceToUpdate?: boolean;
}

export interface ElementParameterDto {
    id?: string;
    active?: boolean;
    familyParameterId?: string | null ;
    elementFamilyKey?: string;

    products?: ParameterProductLine[];
    minMax?: ParameterMinMax;
    images?: AttachmentDto[];
    text?: string | null;
    number?: number | null;
    quantity?: number | null;

    elementFamilyId?: string;

    title?: string | null;
    type?: ParameterSectionType;
    unitId?: string | null;
    unitSymbol?: string | null
    familyId?: string;
    parameterType?: ParameterSectionParameterType | null;
    parameterName?: string | null;
    parameterFamilyId?: string | null;
    parameterIsRequired?: boolean;
    visibleOnlyInBudgets?: boolean;
    position?: number;

    closed: boolean;

    key: string;
    parentKey: string | null;
    isOpen: boolean;
    isInCycle?: boolean;
}

export interface ElementParameterTextDto {
    text?: string
}

export interface ElementParameterNumberDto {
    number?: number | null
}

export interface ParameterMinMax {
    minValue?: number | null;
    maxValue?: number | null;
    unitId?: string | null;
    products?: ParameterProductLine[]
}

export interface ParameterProductLine {
    id: string;
    checked?: boolean
    elementId?: string | null;
    quantity?: number | null;
    price?: number | null;
    unitId?: string | null;
    unitSymbol?: string | null;
    selectObject?: ProductSelectObject

    key: string;
    parentKey: string | null;
    isOpen: boolean;
}

export interface ProductSelectObject {
    label: string
    subTitle: string
    imageUrl: string;
    elementImageUrl: boolean;

}

export interface ParameterImage {
    attachmentId?: string;
    key?: string;
    isMain: boolean ;
    active: boolean;
}

export interface ElementHistoryDto {
    date: Date;
    userName: string;
    price: number;
    unit?: string;
}

export interface ElementUpdatePricesDto {
    elementsIds: string[];
    priceVariationPercentage: number;
}

export enum BudgetPDFVisibility {
    ELEMENT_AND_PRICE_VISIBLE = 'ELEMENT_AND_PRICE_VISIBLE',
    PRICE_INVISIBLE = 'PRICE_INVISIBLE',
    ELEMENT_INVISIBLE = 'ELEMENT_INVISIBLE',
}
