import Utils from 'common/services/Utils';
import { ParameterSectionBaseModel, ParameterSectionType } from './models';
import { arrayMove } from '@dnd-kit/sortable';

export const duplicateItem = (item: ParameterSectionBaseModel, clean: boolean = false): ParameterSectionBaseModel => {
    return {
        key: Utils.newGuid(),
        type: item.type,
        parameterName: !clean ? item.parameterName : null,
        parameterType: item.parameterType,
        title: !clean ? item.title : null,
        closed: false,
        isOpen: true,
        parentKey: Utils.newGuid(),
        // children: item.children.map(c => duplicateItem(c, clean)),
    };
}

export const newSection = (): ParameterSectionBaseModel => {
    return {
        key: Utils.newGuid(),
        type: ParameterSectionType.SECTION,
        title: null,
        closed: false,
        isOpen: true,
        parentKey: Utils.newGuid(),
        // children: [
        //     newSectionParameter(),
        // ],
    }
}

export const newSectionParameter = (): ParameterSectionBaseModel => {
    return {
        key: Utils.newGuid(),
        type: ParameterSectionType.PARAMETER,
        parameterType: null,
        parameterName: null,
        closed: false,
        isOpen: true,
        parentKey: Utils.newGuid(),
        // children: [],
    }
}

export const getParentSectionKey = (key: string, items: ParameterSectionBaseModel[], parentKey = 'root'): string | null => {
    for (const item of items) {
        if (item.key === key) {
            return parentKey;
        }
        // if (item.children && item.type === ParameterSectionType.SECTION) {
        //     const foundKey = getParentSectionKey(key, item.children, item.key);
        //     if (foundKey) {
        //         return foundKey;
        //     }
        // }
    }
    return null;
}

export const getItem = (key: string, items: ParameterSectionBaseModel[]): ParameterSectionBaseModel | null => {
    for (const item of items) {
        if (item.key === key) {
            return item;
        }
        // if (item.children && item.type === ParameterSectionType.SECTION) {
        //     const foundItem = getItem(key, item.children);
        //     if (foundItem) {
        //         return foundItem;
        //     }
        // }
    }
    return null;
}

export const removeSection = (key: string, items: ParameterSectionBaseModel[]): ParameterSectionBaseModel[] => {
    return items.map(item => { return { ...item } }).filter(item => {
        if ('children' in item) {
            // item.children = removeSection(key, item.children);
        }
        return item.key !== key;
    });
}

export const addSectionToParent = (section: ParameterSectionBaseModel, toParentKey: string, items: ParameterSectionBaseModel[], currentParent = 'root'): ParameterSectionBaseModel[] => {
    if (currentParent === toParentKey) {
        return [
            ...items,
            section,
        ];
    }
    return items.map(item => {
        return {
            ...item
            // children: addSectionToParent(section, toParentKey, item.children, item.key),
        };
    });
}

export const moveSectionFromContainerToContainer = (key: string, toParentKey: string, items: ParameterSectionBaseModel[]): ParameterSectionBaseModel[] => {
    const section = getItem(key, items);
    if (!section) {
        return items;
    }

    // let newItems = removeSection(key, items);

    const newItems = addSectionToParent(section, toParentKey, items);

    return newItems;
}

export const moveItem = (items: ParameterSectionBaseModel[], fromKey: string, toKey: string): ParameterSectionBaseModel[] => {
    const fromIndex = items.findIndex(x => x.key === fromKey);
    const toIndex = items.findIndex(x => x.key === toKey);

    if (fromIndex > -1 && toIndex > -1) {
        return arrayMove(items, fromIndex, toIndex);
    }

    return items.map(item => {
        return {
            ...item
            // children: moveItem(item.children, fromKey, toKey),
        };
    });
}
