import { ElementParameterDto } from 'api/elements/Models/ElementDto';
import { createContext } from 'react';

interface ElementParameterDataProviderModel {
    selectedSectionKey: string;
    parameters: ElementParameterDto[];
    isSubmited: boolean;
    hideBaseCheckBox: boolean;
}

const defaultValue: ElementParameterDataProviderModel = {
    selectedSectionKey: '',
    parameters: [],
    isSubmited: false,
    hideBaseCheckBox: false
}

export const ElementParameterDataProvider = createContext(defaultValue);
