import Http from '../../common/services/Http';
import { CountryDto } from './models/CountryDto';

class CountriesService {
    public getCatalog () {
        return Http.get<CountryDto[]>('countries/catalog');
    }
}

export default new CountriesService();
