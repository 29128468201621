import styles from './Parameter.module.scss';
import TextInput from 'common/components/textInput/TextInput';
import { Label } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import SelectInput from 'common/components/selectInput/SelectInput';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSSProperties, forwardRef, Ref, useEffect, useState } from 'react';
import { CSS } from '@dnd-kit/utilities';
import CheckInput from 'common/components/checkInput/CheckInput';
import { OptionTitleSubTitle, SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';
import { Col, Row } from 'react-bootstrap';
import { ParameterSectionBaseModel, ParameterSectionParameterType } from 'common/components/parametersSections/models';
import { FamilyDto } from 'api/families/models/FamilyDto';
import { BODY_TOOLTIP } from 'Config';
import InputError from 'common/components/inputError/InputError';

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
    !(isSorting || wasDragging);

export interface ParameterSectionProps {
    parameter: ParameterSectionBaseModel;
    disabled?: boolean;
    unitOptions?: SearchableSelectTitleSubTitleOption[];
    selectInputOptions?: SearchableSelectTitleSubTitleOption[];
    onChange: (parameter: ParameterSectionBaseModel) => void;
    family?: Partial<FamilyDto>;
}

const Parameter = ({ parameter, disabled, unitOptions, selectInputOptions, onChange, family }: ParameterSectionProps, ref: Ref<HTMLDivElement>): JSX.Element | null => {
    const { t } = useTranslation();

    const options = Object.keys(ParameterSectionParameterType).map(key => ({
        label: t('common.parameters_sections.parameters_types.' + key),
        value: key,
    }));

    const [valueHasError, setValueHasError] = useState<boolean>(false);

    // const {
    //     setNodeRef,
    //     transform,
    //     transition,
    //     isDragging,
    // } = useSortable({
    //     id: parameter.key,
    //     animateLayoutChanges,
    //     disabled,
    // });

    // const style: CSSProperties = {
    //     transform: CSS.Translate.toString(transform),
    //     transition,
    //     opacity: isDragging ? 0.3 : 1,
    // };

    useEffect(() => {
        if (family?.isComponent && !parameter.parameterType) {
            const newParameterType = ParameterSectionParameterType.PRODUCTS;
            onChange({ ...parameter, parameterType: newParameterType });
        }

        if (family?.isComponent && parameter.parameterType && parameter.parameterType !== ParameterSectionParameterType.PRODUCTS) {
            setValueHasError(true);
        } else {
            setValueHasError(false);
        }
    }, [family?.isComponent, family?.isElement, parameter.parameterType]);

    return (
        <div
            ref={ref}
            // style={style}
            className={styles.container}
        >
            <Row className={styles.row}>
                {/* commented for task #51635 */}
                <Col xs={12} md={12} lg={6} xl={4} xxl={3} xxxl={2}>
                    <div className={styles.control} data-tooltip-id={BODY_TOOLTIP}
                        data-tooltip-content={family?.isComponent ? t('families.family.family_type_disabled') : null}>
                        <Label space>{t('common.parameters_sections.parameter_type')}</Label>
                        <SelectInput
                            value={family?.isComponent && (!parameter.parameterType || parameter.parameterType === ParameterSectionParameterType.PRODUCTS.toString()) ? ParameterSectionParameterType.PRODUCTS : parameter.parameterType}
                            options={options}
                            disabled={(family?.isComponent && !valueHasError) ? true : disabled}
                            onChange={(v) => {
                                onChange({ ...parameter, parameterType: v as ParameterSectionParameterType })
                                if (valueHasError && v === ParameterSectionParameterType.PRODUCTS.toString()) {
                                    setValueHasError(false);
                                }
                            }}
                            hasError={valueHasError}
                        />
                        {valueHasError && <InputError error={{ type: 'pattern' }} />}
                    </div>
                </Col>
                <Col xs={12} md={12} lg={6} xl={4} xxl={3} xxxl={2}>
                    <div className={styles.control}>
                        <Label space>{t('common.parameters_sections.parameter_name')}</Label>
                        <TextInput
                            value={parameter.parameterName ?? ''}
                            onChange={(e) => onChange({ ...parameter, parameterName: e.target.value })}
                            placeholder={t('common.write_here')}
                            disabled={disabled}
                        />
                    </div>
                </Col>
                {parameter.parameterType !== 'NUMBER' && parameter.parameterType !== 'NUMBER_MIN_MAX'
                    ? null :
                    <Col xs={12} md={12} lg={6} xl={4} xxl={3} xxxl={1}>
                        <div className={styles.control}>
                            <Label space>{t('common.parameters_sections.unit')}</Label>
                            <SelectInput
                                value={parameter.unitId}
                                options={unitOptions || []}
                                onChange={(v) => onChange({ ...parameter, unitId: v })}
                                disabled={disabled}
                            />
                        </div>
                    </Col>
                }
                {parameter.parameterType !== 'PRODUCTS' && parameter.parameterType !== 'NUMBER_MIN_MAX'
                    ? null :
                    <Col xs={12} md={12} lg={6} xl={4} xxl={3} xxxl={2}>
                        <div className={styles.control}>
                            <Label space>{t('common.parameters_sections.parameter_family')}</Label>
                            <SelectInput
                                value={parameter.parameterFamilyId}
                                options={selectInputOptions || []}
                                formatOptionLabel={OptionTitleSubTitle}
                                onChange={(v) => onChange({ ...parameter, parameterFamilyId: v })}
                                disabled={disabled}
                            />
                        </div>
                    </Col>
                }
                {/* <Col xs={12} md={12} lg={6} xl={4} xxl={3} xxxl={2}>
                    <div className={styles.control}>
                        <Label space>{t('common.parameters_sections.parameter_family')}</Label>
                        <SelectInput
                            value={parameter.parameterFamilyId}
                            options={selectInputOptions || []}
                            formatOptionLabel={OptionTitleSubTitle}
                            onChange={(v) => onChange({ ...parameter, parameterFamilyId: v })}
                            disabled={disabled}
                        />
                    </div>
                </Col> */}
                <Col xs={12} md={12} lg={6} xl={4} xxl={3} xxxl={1}>
                    <div className={styles.checkbox}>
                        <CheckInput
                            checked={parameter.parameterIsRequired ?? false}
                            disabled={disabled}
                            onChange={(e) => onChange({ ...parameter, parameterIsRequired: e.target.checked })}
                        >
                            {t('common.parameters_sections.parameter_required')}
                        </CheckInput>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default forwardRef<HTMLDivElement, ParameterSectionProps>(Parameter);
