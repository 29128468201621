import { Dropdown } from 'react-bootstrap';
import Button, { ButtonProps } from '../button/Button';
import styles from './ButtonDropdown.module.scss';
import React, { ReactNode, useState } from 'react';

export interface ButtonDropdownOption {
    label: string | ReactNode;
    active?: boolean;
    onClick: () => void;
}

type Props = {
    options: ButtonDropdownOption[];
} & ButtonProps;

const ButtonDropdown = ({ options, ...props }: Props) => {
    // eslint-disable-next-line react/display-name
    const CustomToggle = React.forwardRef(({ ...p }, ref: any) => (
        <Button {...props} {...p} ref={ref} {...p} />
    ));

    // eslint-disable-next-line react/display-name
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={`${className} ${styles.dropdown}`}
                    aria-labelledby={labeledBy}
                >
                    {/* <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    /> */}
                    <ul className={`list-unstyled ${styles.list}`}>
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || (child as any).props.children.toLowerCase().startsWith(value),
                        )}
                    </ul>
                </div>
            );
        },
    );

    return (
        <Dropdown drop='down' className={styles.dropdownContainer}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                {props.children}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu}>
                {options.map((option, i) => (
                    <Dropdown.Item key={i} eventKey={i} active={option.active} onClick={option.onClick}>{option.label}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
};

export default ButtonDropdown;
