import styles from './FilesUploaderFilesList.module.scss';
import { useTranslation } from 'react-i18next';
import { AttachmentDto } from 'api/common/models/AttachmentDto';
import { FaTrash } from 'react-icons/fa';
import { FiFileText } from 'react-icons/fi';
import Button from 'common/components/button/Button';

interface Props {
    files: AttachmentDto[];
    disabled?: boolean;
    onRemove: (file: AttachmentDto) => void;
}

const FilesUploaderFilesList = ({ files, disabled, onRemove }: Props) => {
    const { t } = useTranslation();

    const filteredFiles = files.filter(f => !f.remove);

    return (
        <div className={styles.container}>
            {filteredFiles.map((file, i) => (
                <div key={i} className={styles.fileContainer}>
                    <FiFileText className={styles.fileIcon} />
                    <span className={styles.fileText}>{file.name}</span>
                    {!disabled && <Button variant="none" size="small" className={styles.fileRemoveIcon} onClick={() => onRemove(file)}>
                        <FaTrash />
                    </Button>}
                </div>
            ))}
            {filteredFiles.length === 0 && (
                <div className={styles.noItemsContainer}>
                    {t('common.without_files')}
                </div>
            )}
        </div>
    );
}

export default FilesUploaderFilesList;
