import { TaxDto } from 'api/taxes/models/TaxDto';
import { UnitDto } from 'api/units/models/UnitDto';
import { createContext } from 'react';
import { LinesTableColumn } from './utils';

interface LinesDataProviderModel {
    units: UnitDto[];
    taxes: TaxDto[];
    hiddenColumns: LinesTableColumn[];
}

const defaultValue: LinesDataProviderModel = {
    taxes: [],
    units: [],
    hiddenColumns: [],
}

export const LinesDataProvider = createContext(defaultValue);
