import { FilterData, ListingTableColumn, OrderData, TableRow } from './models';
import styles from './ListingTable.module.scss';
import { FaEraser, FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import useSticky from 'common/hooks/useSticky';
import { isTablet } from 'Config';
import { ColumnFilter } from './ColumnFilter';
import ColumnResizer from 'react-table-column-resizer';

const getCellWidthStyle = <TRow extends TableRow>(col: ListingTableColumn<TRow>) => {
    return {
        width: col.width ?? undefined,
        minWidth: col.minWidth ?? undefined,
    };
}

const getColumnCellStyle = <TRow extends TableRow>(col: ListingTableColumn<TRow>) => {
    return {
        textAlign: col.columnCellAlignment || 'left',
        ...getCellWidthStyle(col),
    }
}

interface DefaultTableColumnProps<TRow> {
    col: ListingTableColumn<TRow>;
    order?: OrderData<TRow> | null;
    hasFilters: boolean;
    colIndex?: number;
    onClickOrder: () => void;
    onFilter?: (col: ListingTableColumn<TRow>, filterData: FilterData) => void;
    onOrder?: (orderData: OrderData<TRow>) => void;
    onColResize?: (width: number) => void;
}

export const DefaultTableColumn = <TRow extends TableRow>({ col, colIndex, order, onClickOrder, hasFilters, onFilter, onOrder, onColResize }: DefaultTableColumnProps<TRow>) => {
    const { ref, stickyData } = useSticky<HTMLTableCellElement>();

    const renderActiveFilterIcon = () => {
        return <div>
            {order && !order.isOrderAsc && <FaSortAmountUp className={`${styles.headerSortIcon} ${styles.active}`} />}
            {order && order.isOrderAsc && <FaSortAmountDown className={`${styles.headerSortIcon} ${styles.active}`} />}
        </div>
    }

    const renderHasFilterIcon = (col: ListingTableColumn<TRow>) => {
        return <div>
            {onOrder && <FaSortAmountUp className={`${styles.headerSortIcon}`} />}
        </div>
    }

    return <>
        <th
            ref={ref}
            className={
                `${!col.hideOn
                    ? '' :
                    col.hideOn?.includes('xxl')
                        ? styles.xxl
                        : col.hideOn?.includes('xl')
                            ? styles.xl
                            : col.hideOn?.includes('lg')
                                ? styles.lg
                                : col.hideOn?.includes('md') ? styles.md : col.hideOn?.includes('sm') ? styles.sm : ''}
                                    ${styles.columnHeader}
                                    ${col.columnCellClassName || ''}
                                    ${stickyData.topIsSticky ? styles.isSticky : ''}
                                    `
            }
            style={getColumnCellStyle(col)}
        >
            <div
                onClick={() => col.hideOrderButton ? null : onClickOrder()}
                className={`${styles.headerInfo} ${col.columnCellAlignment === 'right' ? styles.headerInfoRight : ''} ${onOrder && styles.clicakble}`}
            >
                {!col.hideOrderButton && (order && order.field === col.field ? renderActiveFilterIcon() : renderHasFilterIcon(col))}
                {col.name}
            </div>
            {col.filter && hasFilters && onFilter && (
                <ColumnFilter
                    filter={col.filter}
                    onFilter={(d) => onFilter(col, d)}
                />
            )}
        </th>
        <ColumnResizer
            id={colIndex ?? 0}
            className={`${styles.columnResizer} ${stickyData.topIsSticky ? styles.isSticky : ''}`}
            defaultWidth={col.width}
            minWidth={0}
            maxWidth={null}
            disabled={false}
            resizeStart={() => {}}
            resizeEnd={(width: number) => onColResize && onColResize(width)}
        />
    </>;
}

interface ActionClearFiltersTableColumnProps {
    totalFilters: number;
    onClearFilters?: () => void;
}

export const ActionClearFiltersTableColumn = ({ totalFilters, onClearFilters }: ActionClearFiltersTableColumnProps) => {
    const { t } = useTranslation();
    const { ref, stickyData } = useSticky<HTMLTableCellElement>();

    return (
        <th
            ref={ref}
            className={`${styles.columnHeader} ${styles.actionColumnHeader} ${stickyData.topIsSticky ? styles.isSticky : ''} ${stickyData.rightIsSticky ? styles.rightIsSticky : ''}`}
        >
            {Boolean(onClearFilters) && (
                <>
                    <div className={`${styles.headerInfo}`}>&nbsp;</div>
                    <div className={styles.clearFiltersContainer}>
                        <div className={styles.clearFiltersButton} onClick={onClearFilters}>
                            <FaEraser />
                        </div>
                        <span className={styles.clearFiltersText}>
                            {totalFilters} {totalFilters === 1 ? t('common.filter_amount') : t('common.filters_amount')}
                        </span>
                    </div>
                </>
            )}
        </th>
    );
}

interface ActionMenuFiltersTableColumnProps<TRow> {
    row: TRow;
    rowIndex: number;
    striped: boolean;
    onRenderAction?: (row: TRow) => JSX.Element | undefined;
}

export const ActionMenuFiltersTableColumn = <TRow extends TableRow>({ row, rowIndex, striped, onRenderAction }: ActionMenuFiltersTableColumnProps<TRow>) => {
    const { ref, stickyData } = useSticky<HTMLTableCellElement>();
    const isMobile = isTablet(window.innerWidth);

    return (
        <td
            ref={ref}
            className={`${styles.column} ${striped && rowIndex % 2 === 0 ? styles.striped : ''} ${isMobile ? styles.mobile : ''} ${styles.actionColumn} ${stickyData.rightIsSticky ? styles.rightIsSticky : ''} global-action-column`}
        >
            {onRenderAction && (
                onRenderAction(row)
            )}
        </td>
    );
}
