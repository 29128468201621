import { WorkDto, WorkExternalPartDto } from 'api/works/models/WorkDto';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Header, InformationText, Label } from 'common/components/texts/Texts';
import { SelectInputController } from 'common/components/selectInput/SelectInput';
import { Col, Row } from 'react-bootstrap';

interface Props {
    type: 'create' | 'details' | 'edit' | undefined;
    onClickRefreshExternalParts: (externalPartCategoryId: string, index: number) => void;
    onExternalPartsChange: (externalParts: WorkDto['externalParts'],externalPartCategory: WorkExternalPartDto,newValue: string | null | undefined,oldValue: string | null | undefined) => void;
}

function WorkExternalParts({ type, onClickRefreshExternalParts, onExternalPartsChange }: Props): JSX.Element | null {
    const { t } = useTranslation();
    const form = useFormContext<WorkDto>();

    const { fields } = useFieldArray({
        control: form.control,
        name: 'externalParts',
        keyName: 'key',
    });
    const isDetails = type === 'details';

    const onClickNewExternalPart = (categoryId: string) => {
        window.open('/external-parts/' + categoryId + '/create');
    }

    const onClickDetailsExternalPart = (categoryId: string,itemId: string) => {
        window.open('/external-parts/' + categoryId + '/details/' + itemId);
    }

    const externalPartChange = (externalPartCategory: WorkExternalPartDto,newValue: string | null | undefined,oldValue: string | null | undefined) => {
        const updatedExternalParts = form.getValues('externalParts');
        const externalPart = updatedExternalParts.find(x => x.externalPartCategoryId === externalPartCategory.externalPartCategoryId);
        if (externalPart) {
            externalPart.externalPartId = newValue;
            externalPart.externalPartName = externalPartCategory.externalPartsOptions.find(x => x.value === newValue)?.label;
            externalPartCategory.externalPartId = newValue;
            externalPartCategory.externalPartName = externalPartCategory.externalPartsOptions.find(x => x.value === newValue)?.label;
        }
        onExternalPartsChange(updatedExternalParts,externalPartCategory,newValue,oldValue);
    };

    return (
        <div>
            <Header space className='mb-3'>{t('works.work.external_parts')}</Header>

            {fields.map((externalPart, i) => (
                <div key={externalPart.key}>
                    <Row className="mb-3">
                        <Col xs={12}>
                            <Label space>{externalPart.externalPartCategoryName}</Label>
                            <SelectInputController
                                name={`externalParts.${i}.externalPartId` as 'externalParts.0.externalPartId'}
                                options={form.watch(`externalParts.${i}.externalPartsOptions`)}
                                control={form.control}
                                disabled={isDetails}
                                onClickAddNew={() => onClickNewExternalPart(externalPart.externalPartCategoryId)}
                                onClickRefreshAfterNew={() => onClickRefreshExternalParts(externalPart.externalPartCategoryId, i)}
                                onChange={(value,oldValue) => externalPartChange(form.watch(`externalParts.${i}`),value,oldValue)}
                                onClickGoToDetails={() => onClickDetailsExternalPart(externalPart.externalPartCategoryId,form.getValues(`externalParts.${i}.externalPartId`) ?? '')}
                            />
                        </Col>
                    </Row>
                </div>
            ))}

            {fields.length === 0 && <InformationText space className='text-center p-2'>{t('works.work.without_external_parts')}</InformationText>}
        </div>
    );
}

export default WorkExternalParts;
