import styles from './MultiLineDynamicList.module.scss';
import { IoAddSharp, IoTrash } from 'react-icons/io5';
import Button from 'common/components/button/Button';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import Utils from 'common/services/Utils';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ConfirmDeleteModal from '../modal/confirmDeleteModal/ConfirmDeleteModal';

interface MultiLineDynamicListItem {
    id: string;
    newRow: boolean;
}

interface MultiLineDynamicListProps<TItem> {
    items: TItem[];
    renderItem: (item: TItem, index: number) => JSX.Element;
    onChange?: (items: TItem[]) => void;
    labelAdd: string;
    labelEmptyList?: string;
    disabled?: boolean;
    askBeforeDelete?: boolean;
    deleteModalItemName?: string;
    deleteModalMessage?: string;
}
const MultiLineDynamicList = <TItem extends MultiLineDynamicListItem>({ items, renderItem, onChange, labelAdd, labelEmptyList, disabled ,askBeforeDelete,deleteModalItemName,deleteModalMessage }: MultiLineDynamicListProps<TItem>) => {
    const { t } = useTranslation();
    const [itemToRemove, setItemToRemove] = useState<TItem | null >(null);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const onClickRemove = (item: any) => {
        setShowConfirmDeleteModal(false);
        if (onChange) {
            onChange(items.filter(x => x.id !== item.id));
        }
    }

    const onClickAddNew = () => {
        if (onChange) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const obj: TItem = { id: Utils.newGuid(),newRow: true } as TItem;
            onChange([...items, obj]);
        }
    }

    const openConfirmDeleteModal = (item: TItem) => {
        setShowConfirmDeleteModal(true);
        setItemToRemove(item)
    }

    const cancelDeleteRow = () => {
        setShowConfirmDeleteModal(false);
        setItemToRemove(null)
    }
    return (
        <div>
            {items.map((item, i) => (
                <div className={styles.container} key={'item_' + i}>
                    <div>
                        {!disabled && <div className={styles.header}>
                            <div className={styles.headerRight}>
                                <div className={styles.headerButton} onClick={() => { askBeforeDelete ? openConfirmDeleteModal(item) : onClickRemove(item); }}>
                                    <IoTrash />
                                </div>
                            </div>
                        </div>}

                        <div className={`${styles.innerContainer} ${disabled ? styles.disabled : ''}`}>
                            <div>
                                {renderItem(item, i)}
                            </div>
                        </div>
                    </div>
                </div>))}
            {!items.length && disabled && <div className='text-right'>
                <Button variant="third" className={styles.addButton} >
                    <span className='v-align-middle'>{labelEmptyList ?? t('messages.without_data')}</span>
                </Button>
            </div>}
            {!disabled && <div className='text-right'>
                <Button variant="third" className={styles.addButton} onClick={onClickAddNew}>
                    <IoAddSharp fontSize={18} /> <span className='v-align-middle'>{labelAdd}</span>
                </Button>
            </div>}

            <ConfirmDeleteModal
                itemName={deleteModalItemName}
                secundaryMessage={deleteModalMessage}
                isOpen={showConfirmDeleteModal}
                onClose={(isToDelete) => isToDelete ? onClickRemove(itemToRemove) : cancelDeleteRow()}
            />

        </div>

    );
};

export default MultiLineDynamicList;

type MultiLineDynamicListControllerProps<T extends FieldValues, TItem extends MultiLineDynamicListItem> = Omit<MultiLineDynamicListProps<TItem>, 'items'> & UseControllerProps<T>;

export const MultiLineDynamicListController = <T extends FieldValues, TItem extends MultiLineDynamicListItem>(props: MultiLineDynamicListControllerProps<T, TItem>) => {
    const { field: { ref, ...field } } = useController({ ...props, disabled: false });

    return (
        <MultiLineDynamicList
            items={field.value ?? []}
            disabled={props.disabled}
            labelEmptyList={props.labelEmptyList}
            {...props}
            onChange={(e: any) => {
                field.onChange(e);
                if (props.onChange) {
                    props.onChange(e);
                }
            }}
        />
    );
};
