import { FaCaretDown } from 'react-icons/fa';
import MoneyFormat from '../moneyFormat/MoneyFormat';
import TextInput from '../textInput/TextInput';
import styles from './MoneyInput.module.scss';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import useNoInitialEffect from 'common/hooks/useNoInitialEffect';
import { NumberFormatValues } from 'react-number-format';
import { DEFAULT_DECIMAL_PLACES } from 'Config';

export interface UnitOption {
    label: string;
    value: string;
    symbol?: string;
}

export interface MoneyInputProps {
    value: number | null | undefined;
    disabled?: boolean;
    unitPrefix?: string;
    unit?: string | null;
    unitsOptions?: UnitOption[];
    isGroupStart?: boolean;
    isGroupEnd?: boolean;
    decimalScale?: number;
    hasError?: boolean;
    hideUnitsDrop?: boolean;
    popperFixed?: boolean;
    onChange?: (value: number | null | undefined) => void;
    onBlur?: (value: number | null | undefined) => void;
    onChangeUnit?: (unit: string | null | undefined) => void;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
    isAllowed?: ((values: NumberFormatValues) => boolean) | undefined;
}

const MoneyInput = ({
    hasError, value, disabled, unitPrefix = '', unit = '', unitsOptions, isGroupStart, isGroupEnd, decimalScale = DEFAULT_DECIMAL_PLACES,
    popperFixed, hideUnitsDrop, onKeyDown, onChange, onChangeUnit, isAllowed, onBlur
}: MoneyInputProps) => {
    const unitOption = (unitsOptions || []).find(x => x.value === unit);
    const currentUnit = unitOption ? (unitOption.symbol ?? unitOption.label) : unit;
    const [tempValue, setTempValue] = useState(value);

    const onBlurHandle = () => {
        if (onBlur) {
            onBlur(tempValue);
        }
    }

    // eslint-disable-next-line react/display-name
    const CustomToggle = React.forwardRef(({ ...p }, ref: any) => (
        <div
            {...p}
            className={`${styles.sideButton} ${disabled ? styles.disabled : ''} ${unitsOptions ? styles.clickable : ''} ${isGroupStart ? styles.sideButtonGroupStart : ''}`}
            ref={ref}
        />
    ));

    // eslint-disable-next-line react/display-name
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
            const [value] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={`${className} ${styles.dropdown}`}
                    aria-labelledby={labeledBy}
                >
                    {/* <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    /> */}
                    <ul className={`list-unstyled ${styles.list}`}>
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || (child as any).props.children.toLowerCase().startsWith(value),
                        )}
                    </ul>
                </div>
            );
        },
    );

    useNoInitialEffect(() => {
        if (tempValue !== value) {
            setTempValue(value);
        }
    }, [value]);

    return (
        <div className={styles.container}>
            <MoneyFormat
                customInput={TextInput}
                displayType={'input'}
                value={tempValue ?? ''}
                className={`${styles.input} ${isGroupEnd ? styles.inputGroupEnd : ''} ${isGroupStart && hideUnitsDrop ? styles.inputGroupStartEmpty : ''} ${hasError ? styles.hasError : ''}`}
                suffix=''
                disabled={disabled}
                onValueChange={e => {
                    setTempValue(e.floatValue);
                    if (onChange) {
                        onChange(e.floatValue);
                    }
                }}
                isAllowed={isAllowed}
                placeholder='0,00'
                onKeyDown={onKeyDown}
                onBlur={onBlurHandle}
                decimalScale={decimalScale}
            />
            {!hideUnitsDrop && <Dropdown
                drop='down'
                className={styles.dropdownContainer}
                show={disabled ? false : undefined}
            >
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" disabled={disabled}>
                    {unitPrefix ?? ''}
                    {currentUnit && unitPrefix ? '/' : ''}
                    {currentUnit}
                    {unitsOptions && <FaCaretDown />}
                </Dropdown.Toggle>

                {unitsOptions && <Dropdown.Menu as={CustomMenu} popperConfig={popperFixed ? { strategy: 'fixed' } : undefined} renderOnMount={popperFixed ? true : undefined}>
                    {(unitsOptions ?? []).map((option, i) => (
                        <Dropdown.Item
                            key={i}
                            eventKey={i}
                            active={Boolean(option.value === unit)}
                            onClick={() => onChangeUnit && onChangeUnit(option.value)}
                        >
                            {option.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>}
            </Dropdown>}
        </div>
    )
};

type MoneyInputControllerProps<T extends FieldValues> = Omit<MoneyInputProps, 'value'> & UseControllerProps<T>;

export const MoneyInputController = <T extends FieldValues>(props: MoneyInputControllerProps<T>) => {
    const { field } = useController({ ...props, disabled: false });

    return (
        <MoneyInput
            {...props}
            disabled={props.disabled}
            value={field.value}
            onBlur={(value: number | null | undefined) => {
                field.onBlur();
                if (props.onBlur) {
                    props.onBlur(value);
                }
            }}
            onChange={(e) => {
                field.onChange(e);
                if (props.onChange) {
                    props.onChange(e);
                }
            }}
        />
    );
};

export default MoneyInput;
