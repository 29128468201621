import Button from 'common/components/button/Button';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import PageHeader from 'common/components/pageHeader/PageHeader';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import { Col, Row } from 'react-bootstrap';
import Loading from 'common/services/Loading';
import { LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import Toast from 'common/services/Toast';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { NumerationDto } from 'api/numerations/models/NumerationDto';
import NumerationsService from 'api/numerations/NumerationsService';
import { NumerationSchemeType } from 'api/numerations/enums/NumerationSchemeType';
import NumerationSchemeParser, { SchemeToken } from 'api/numerations/NumerationSchemeParser';
import SelectInput from 'common/components/selectInput/SelectInput';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { Header, Label, SubHeader, Text } from 'common/components/texts/Texts';
import TextInput from 'common/components/textInput/TextInput';
import MoneyInput from 'common/components/moneyInput/MoneyInput';
import InputError from 'common/components/inputError/InputError';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'common/components/strictModeDroppable/StrictModeDroppable';
import Utils from 'common/services/Utils';
import { IoMoveOutline } from 'react-icons/io5';
import styles from './NumerationScreen.module.scss';
import WorkTypesService from 'api/workTypes/WorkTypesService';

export function reorder(list: SchemeToken[], startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const MAX_RESULT_LENGTH = 100;

function getInitials(name: string): string {
    const words = name.trim().split(' ');
    const initials = words.map(word => word.charAt(0).toUpperCase()).join('');
    return initials;
}

const padZero = function (s: string, len: number) {
    const c = '0';
    while (s.length < len) s = c + s;
    return s;
}

const geSchemeTokenExample = (token: SchemeToken, numberSize: number, userName: string, workTypeName: string) => {
    switch (token.type) {
        case NumerationSchemeType.WORK_TYPE:
            return getInitials(workTypeName);
        case NumerationSchemeType.USER_INITIALS:
            return getInitials(userName);
        case NumerationSchemeType.SEQUENTIAL_NUMBER:
            return padZero('1', numberSize);
        case NumerationSchemeType.CURRENT_MONTH:
            return padZero((new Date().getMonth() + 1).toString(), 2);
        case NumerationSchemeType.CURRENT_YEAR:
            return new Date().getFullYear();
        case NumerationSchemeType.CURRENT_YEAR_SMALL:
            return new Date().getFullYear().toString().substring(2);
        case NumerationSchemeType.TEXT:
            return token.value ?? '';
        default:
            return '';
    }
}

function NumerationScreen(): JSX.Element | null {
    const { id, type } = useParams<{ id: string, type: string }>();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['NUMERATIONS_WRITE']);
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['NUMERATIONS_READ']);
    const schemeTypesOptions = useMemo(() => Object.keys(NumerationSchemeType).map(key => ({
        label: t('numerations.scheme_types.' + key),
        value: key,
    })), [i18n.language]);
    const [model, setModel] = useState<NumerationDto>();
    const [schemeTokens, setSchemeTokens] = useState<SchemeToken[]>([]);
    const userName = loggedUser!.realName
    const [workTypeName, setWorkTypeName] = useState<string>('')
    const finalResultExample = schemeTokens.map((t) => geSchemeTokenExample(t, model?.numberSize ?? 0, userName, workTypeName)).join('') ?? '';
    const [submitted, setSubmitted] = useState(false);

    const url = '/backoffice/numerations';
    const currentUrl = url + '/' + type + '/' + id;

    useEffect(() => {
        void getData()
    }, [id, type]);

    const getData = async () => {
        if (!id) {
            return;
        }

        try {
            Loading.show();
            const result = await NumerationsService.getById(id);
            const workTypes = await WorkTypesService.getCatalog();

            setModel(result);
            setSchemeTokens(NumerationSchemeParser.parse(result.scheme));
            setWorkTypeName(workTypes.length > 0 ? workTypes[0].label : 'A B');

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get numeration', error);
            Toast.error(t('messages.error_load_info'));
        }
    };

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/backoffice/numerations/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate('/backoffice/numerations');
        }
    }

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!model) {
            return;
        }

        setSubmitted(true);

        if (schemeTokens.find(x => !x.type)) {
            Toast.warning(t('messages.required_fields_empty'));
            return;
        }

        if (finalResultExample.length > MAX_RESULT_LENGTH) {
            Toast.warning(t('numerations.numeration.too_big_message'));
            return;
        }

        try {
            Loading.show();

            await NumerationsService.update({ ...model, scheme: NumerationSchemeParser.compose(schemeTokens) })

            Loading.hide();
            Toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the numeration with id: ${id}`, error);
            Toast.error(t('messages.record_save_error'));
            Loading.hide();
        }
    };

    const onChangeSchemeToken = (value: Partial<SchemeToken>, key: string) => {
        setSchemeTokens(tokens => tokens.map((t) => {
            if (t.key === key) {
                return {
                    ...t,
                    ...value,
                }
            }
            return t;
        }));
    }

    const removeSchemeToken = (key: string) => {
        setSchemeTokens(tokens => tokens.filter((t) => t.key !== key));
    }

    const addSchemeToken = () => {
        setSchemeTokens(tokens => [...tokens, { type: null, value: undefined, key: Utils.newGuid() }]);
    }

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            [...schemeTokens],
            result.source.index,
            result.destination.index
        );
        setSchemeTokens(items);
    }

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        userSelect: 'none',
        ...draggableStyle
    });

    const title = (type === 'edit' ? t('common.edit') : t('common.details')) + ' ' + t('numerations.numeration.title');

    if (!canRead) {
        return null;
    }

    return (
        <ScreenTitle title={title}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: t('numerations.list.title'), url },
                    { name: title, url: currentUrl },
                ]}
            />

            <PageHeader title={title} informationText={t('common.go_back')} onGoBack={() => navigateTo()}>
                {canWrite && !isDetails && <Button type='submit' form='form'>{t('common.save')}</Button>}
                {canWrite && isDetails && <Button fw onClick={() => { navigateTo('edit', id); }}>{t('common.edit')}</Button>}
            </PageHeader>
            <PageContainer addBottomSpace>
                <form noValidate onSubmit={onSubmit} id="form">
                    <Header>{t('numerations.types.' + model?.numerationType)}</Header>
                    <br />
                    <Row>
                        {!isDetails && <Col xs='auto' style={{ width: 60 }}></Col>}
                        <Col>
                            <Label>{t('numerations.numeration.type')}</Label>
                        </Col>
                        <Col xs={2}>
                            <Label>{t('numerations.numeration.value')}</Label>
                        </Col>
                        <Col xs={2}>
                            <Label>{t('numerations.numeration.example')}</Label>
                        </Col>
                        {!isDetails && <Col xs={1}></Col>}
                    </Row>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <StrictModeDroppable droppableId="droppable" isDropDisabled={isDetails}>
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {schemeTokens.map((token, index) => {
                                        return (
                                            <Draggable key={token.key} draggableId={token.key} index={index} isDragDisabled={isDetails}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <Row className={`mb-2 ${styles.row}`}>
                                                            {!isDetails && (
                                                                <Col xs='auto' style={{ width: 60 }}>
                                                                    <span {...provided.dragHandleProps}>
                                                                        <IoMoveOutline />
                                                                    </span>
                                                                </Col>
                                                            )}
                                                            <Col>
                                                                <SelectInput
                                                                    options={schemeTypesOptions}
                                                                    value={token.type}
                                                                    onChange={v => onChangeSchemeToken({ type: v as NumerationSchemeType, value: '' }, token.key)}
                                                                    disabled={isDetails}
                                                                    hasError={!token.type && submitted}
                                                                />
                                                                {!token.type && submitted && <InputError error={{ type: 'required' }} />}
                                                            </Col>
                                                            <Col xs={2}>
                                                                <TextInput
                                                                    value={token.value ?? ''}
                                                                    onChange={e => onChangeSchemeToken({ value: e.target.value }, token.key)}
                                                                    disabled={token.type !== NumerationSchemeType.TEXT || isDetails}
                                                                />
                                                            </Col>
                                                            <Col xs={2} className={styles.alignCenter}>
                                                                <Text>{geSchemeTokenExample(token, model?.numberSize ?? 0, userName, workTypeName)}</Text>
                                                            </Col>
                                                            {!isDetails && (
                                                                <Col xs={1}>
                                                                    <Button onClick={() => removeSchemeToken(token.key)}><FaTrash /></Button>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>
                    {!isDetails && (
                        <Row className={styles.row}>
                            <Col></Col>
                            <Col xs={1}>
                                <Button onClick={addSchemeToken}><FaPlus /></Button>
                            </Col>
                        </Row>
                    )}

                    <br />

                    {schemeTokens.find(x => x.type === NumerationSchemeType.SEQUENTIAL_NUMBER) && (
                        <div className='mb-3'>
                            <SubHeader space>{t('numerations.numeration.options')}</SubHeader>

                            <Label space>{t('numerations.numeration.number_size')}</Label>
                            <Row>
                                <Col xs={12} md={4}>
                                    <MoneyInput
                                        value={model?.numberSize}
                                        onChange={v => setModel(m => ({ ...m!, numberSize: v ?? 1 }))}
                                        hideUnitsDrop
                                        decimalScale={0}
                                        isAllowed={(values) => {
                                            const { floatValue } = values;
                                            return (floatValue ?? 0) >= 1 && (floatValue ?? 0) <= 20;
                                        }}
                                        disabled={isDetails}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}

                    <SubHeader>{t('numerations.numeration.final_result_example')}:</SubHeader>
                    <Text>{finalResultExample}</Text>
                    {finalResultExample.length > MAX_RESULT_LENGTH && <InputError error={{ type: 'value', message: t('numerations.numeration.too_big_message') }} isWarning />}
                </form>
            </PageContainer>
        </ScreenTitle>
    )
}

export default NumerationScreen;
