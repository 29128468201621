import Utils from 'common/services/Utils'
import { ParameterSectionBaseModel, ParameterSectionType } from 'common/components/parametersSections/models'

export const newSection = (parentId: string | null): ParameterSectionBaseModel => {
    return {
        key: Utils.newGuid(),
        type: ParameterSectionType.SECTION,
        title: null,
        isOpen: true,
        closed: false,
        parentKey: parentId
    }
}

export const newSectionParameter = (parentId: string | null): ParameterSectionBaseModel => {
    return {
        key: Utils.newGuid(),
        type: ParameterSectionType.PARAMETER,
        parameterType: null,
        parameterName: null,
        closed: false,
        isOpen: true,
        parentKey: parentId
    }
}

export function isLastInLevel(allLines: ParameterSectionBaseModel[], parentKey: string, lineKey: string): boolean {
    if (allLines.length === 0) {
        return false;
    }

    function isLast(lines: ParameterSectionBaseModel[]): boolean {
        const lastLine = lines[lines.length - 1];
        if (!lastLine) {
            return false;
        }

        const lastLineChildren = allLines.filter(x => x.parentKey === lastLine.key)

        if ((lastLine.type !== ParameterSectionType.SECTION || (lastLine.type === ParameterSectionType.SECTION && lastLine.isOpen)) && lastLineChildren && lastLineChildren.length > 0) {
            return isLast(lastLineChildren);
        }

        if (lastLine.key === lineKey) {
            return true;
        }

        return false;
    }

    const parentChildren = allLines.filter(x => x.parentKey === parentKey)
    return isLast(parentChildren);
}

export function onMove(lines: ParameterSectionBaseModel[],lineKey: string, direction: 'up' | 'down') {
    const lineIndex = lines.findIndex(x => x.key === lineKey);
    if (lineIndex === -1) {
        return lines;
    }

    let newIndex: number | null = lineIndex;

    if (direction === 'up') {
        if (lineIndex === 0) {
            return lines; // Already at the top
        }
        newIndex = getPreviousIndexWithSameParentKey(lines, lineIndex);
    } else if (direction === 'down') {
        if (lineIndex === lines.length - 1) {
            return lines; // Already at the bottom
        }
        newIndex = getNextIndexWithSameParentKey(lines, lineIndex);
    } else {
        return lines; // Invalid direction
    }

    if (newIndex === null) {
        return lines;
    }

    // Get the subtree that needs to be moved
    const subtree = getSubtreeToMove(lines, lineIndex);
    const newLines = lines.filter(line => !subtree.includes(line));

    // Calculate the new position for the subtree
    let targetIndex = newIndex;
    if (direction === 'down') {
        targetIndex += subtree.length;
    }

    // Insert the subtree at the new position
    newLines.splice(targetIndex, 0, ...subtree);

    return newLines;
}

function getPreviousIndexWithSameParentKey(array: ParameterSectionBaseModel[], currentIndex: number): number | null {
    if (currentIndex < 0 || currentIndex >= array.length) {
        return null;
    }

    const currentParentKey = array[currentIndex].parentKey;

    for (let i = currentIndex - 1; i >= 0; i--) {
        if (array[i].parentKey === currentParentKey) {
            return i;
        }
    }

    return null;
}

function getNextIndexWithSameParentKey(lines: ParameterSectionBaseModel[], currentIndex: number): number | null {
    if (currentIndex < 0 || currentIndex >= lines.length) {
        return null;
    }

    const currentParentKey = lines[currentIndex].parentKey;

    for (let i = currentIndex + 1; i < lines.length; i++) {
        if (lines[i].parentKey === currentParentKey) {
            return i;
        }
    }

    return null;
}

function getSubtreeToMove(lines: ParameterSectionBaseModel[], startIndex: number): ParameterSectionBaseModel[] {
    const startKey = lines[startIndex].key;
    const subtree = [lines[startIndex]];

    for (let i = startIndex + 1; i < lines.length; i++) {
        if (lines[i].parentKey === startKey) {
            subtree.push(...getSubtreeToMove(lines, i));
        }
    }

    return subtree;
}

export function canMoveUp(lines: ParameterSectionBaseModel[], lineKey: string | null): boolean {
    if (lineKey === null) {
        return false;
    }

    const lineIndex = lines.findIndex(x => x.key === lineKey);
    if (lineIndex <= -1) {
        return false;
    }

    const moveIndex = getPreviousIndexWithSameParentKey(lines, lineIndex);

    return moveIndex != null && moveIndex !== lineIndex;
}

export function canMoveDown(lines: ParameterSectionBaseModel[], lineKey: string | null): boolean {
    if (lineKey === null) {
        return false;
    }

    const lineIndex = lines.findIndex(x => x.key === lineKey);
    if (lineIndex <= -1) {
        return false;
    }

    const moveIndex = getNextIndexWithSameParentKey(lines, lineIndex);

    return moveIndex != null && moveIndex !== lineIndex;
}
