import BudgetLinesTable from './table/BudgetLinesTable';
import { TaxDto } from 'api/taxes/models/TaxDto';
import { UnitDto } from 'api/units/models/UnitDto';
import { LinesDataProvider } from './LinesDataProvider';
import { BudgetsCacheData } from '../utils/BudgetsCache';

interface Props {
    type: 'create' | 'details' | 'edit' | 'version' | undefined;
    units: UnitDto[];
    taxes: TaxDto[];
    budgetData: BudgetsCacheData;
}

function BudgetLines({ type, units, taxes, budgetData }: Props): JSX.Element | null {
    const isDetails = type === 'details' || type === 'version';

    return (
        <LinesDataProvider.Provider value={{ taxes, units, hiddenColumns: budgetData.hiddenColumns }}>
            <BudgetLinesTable
                disabled={isDetails}
            />
        </LinesDataProvider.Provider>
    );
}

export default BudgetLines;
