
import InputError from 'common/components/inputError/InputError';
import Button from 'common/components/button/Button';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { TextInputController } from 'common/components/textInput/TextInput';
import { Label } from 'common/components/texts/Texts';
import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle, FaTrashAlt } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from 'common/services/Loading';
import toast from 'react-hot-toast';
import { LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import UsersService from 'api/users/UsersService';
import { UserProfile } from 'api/account/models/UserProfile';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import Toast from 'common/services/Toast';
import { Form } from 'common/components/form/Form';
import styles from './BudgetFileModelScreen.module.scss'
import { BudgetFileModelDto } from 'api/budgetFileModels/models/BudgetFileModelDto';
import BudgetFileModelsService from 'api/budgetFileModels/BudgetFileModelsService';
import { FileInputController } from 'common/components/fileInput/FileInput';
import { AttachmentDto } from 'api/common/models/AttachmentDto';
import CustomFile from 'common/models/CustomFile';
import { SelectInputController, SelectInputOption } from 'common/components/selectInput/SelectInput';
import InformationModal from 'common/components/modal/informationModal/InformationModal';
import { RichInputController } from 'common/components/richTextInput/RichTextInput';

function BudgetFileModelScreen(): JSX.Element | null {
    const { id, type } = useParams<{ id: string, type: 'create' | 'edit' | 'details' }>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const url = '/backoffice/budget-file-models';
    const currentUrl = url + '/' + type + (id ? ('/' + id) : '');

    const form = useForm<BudgetFileModelDto>({ shouldUnregister: false });
    const errors = form.formState.errors;
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['BUDGETS_FILE_MODELS_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['BUDGETS_FILE_MODELS_READ'])
    const [headerfile, setHeaderFile] = useState<AttachmentDto | null>();
    const [footerfile, setFooterFile] = useState<AttachmentDto | null>();
    const [conditionsFile, setConditionsFile] = useState<AttachmentDto | null>();
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

    const [modelsCatalog, setModelsCatalog] = useState<SelectInputOption[]>([]);

    useEffect(() => {
        void getData();
    }, [id, type]);

    const getData = async () => {
        try {
            let result: Partial<BudgetFileModelDto> = {
                id,
                name: ''
            }
            void getModelsCatalog();
            if (id) {
                Loading.show();
                result = await BudgetFileModelsService.getById(id);
                setHeaderFile(result.headerAttachment)
                setFooterFile(result.footerAttachment)
                setConditionsFile(result.conditionsAttachment)
            }
            form.reset(result);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get budget file models', error);
            toast.error(t('messages.error_load_info'));
        }
    }

    const getModelsCatalog = async () => {
        try {
            Loading.show();
            const result = await BudgetFileModelsService.getModelsCatalog();

            const items = result.map(item => {
                return {
                    value: item,
                    label: item
                };
            });

            setModelsCatalog(items);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get models catalog', error);
            toast.error(t('messages.error_load_info'));
        }
    }

    const onSubmit = async (model: BudgetFileModelDto) => {
        try {
            Loading.show();
            const mediasToSend: CustomFile[] = []

            if (headerfile !== null && headerfile?.tempFile) {
                model.headerAttachmentId = headerfile?.key
                headerfile.tempFile.containerName = headerfile?.key
                mediasToSend.push(headerfile?.tempFile)
            } else if (headerfile == null) {
                model.removeHeaderAttachment = true;
            }

            if (footerfile !== null && footerfile?.tempFile) {
                model.footerAttachmentId = footerfile?.key
                footerfile.tempFile.containerName = footerfile?.key
                mediasToSend.push(footerfile?.tempFile)
            } else if (footerfile == null) {
                model.removeFooterAttachment = true;
            }

            if (conditionsFile !== null && conditionsFile?.tempFile) {
                model.conditionsAttachmentId = conditionsFile?.key
                conditionsFile.tempFile.containerName = conditionsFile?.key
                mediasToSend.push(conditionsFile?.tempFile)
            } else if (conditionsFile == null) {
                model.removeConditionsAttachment = true;
            }

            if (model && model.id) {
                await BudgetFileModelsService.update(model, mediasToSend)
                // navigateTo('details', model.id);~
                void getData();
            } else if (model) {
                const id = await BudgetFileModelsService.create(model, mediasToSend)
                navigateTo('edit', id);
            }
            setFooterFile(null)
            setHeaderFile(null)
            setConditionsFile(null)
            Loading.hide();
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the budget file model with id: ${id}`, error);
            toast.error(t('messages.record_save_error'));
            Loading.hide();
        }
    };

    const onInvalid = () => {
        Toast.warning(t('messages.required_fields_empty'));
    }

    const onDelete = async (item: boolean) => {
        if (!item) {
            setShowConfirmDeleteModal(false)
            return;
        }
        try {
            Loading.show();

            await BudgetFileModelsService.remove(form.getValues())
            navigateTo();

            Loading.hide();
            toast.success(t('messages.record_delete_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete budget file model', error);
            toast.error(t('messages.record_delete_error'));
        }
        setShowConfirmDeleteModal(false)
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`${url}/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate(url);
        }
    }

    const title = (type === 'create' ? t('common.new') : t('common.details')) + ' ' + t('budget_file_models.model');

    if (!canRead) {
        return null;
    }

    return (<ScreenTitle title={t('budget_file_models.title')}>
        <PageBreadcrumbsPortal
            breadcrumbs={[
                { name: t('home.title'), url: '/' },
                { name: t('budget_file_models.title'), url },
                { name: title, url: currentUrl },
            ]}
        />

        <PageHeader title={title} informationText={t('common.go_back')} onGoBack={() => navigateTo()}>
            {canWrite && !isDetails && <Button type='submit' form='modelForm'>{t('common.save')}</Button>}
            {canWrite && isDetails && <Button fw onClick={() => { navigateTo('edit', id); }}>{t('common.edit')}</Button>}
            {canWrite && isDetails && <Button variant='secondary' onClick={() => setShowConfirmDeleteModal(true)}> <FaTrashAlt /> </Button>}
        </PageHeader>
        <PageContainer addBottomSpace>
            <Form handleSubmit={form.handleSubmit} onSubmit={onSubmit} onInvalid={onInvalid} noValidate id="modelForm">
                <Row className="mb-3">
                    <Col sm={12} md={12} >
                        <Label space>{t('budget_file_models.name')}{!isDetails ? '*' : ''}</Label>
                        <TextInputController
                            name='name' control={form.control}
                            placeholder={t('budget_file_models.name')} disabled={isDetails}
                            rules={{ required: true, maxLength: 250 }}
                            hasError={Boolean(errors.name)} />
                        <InputError error={errors.name} maxLength={250} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Label space>{t('budget_file_models.html_file')}{!isDetails ? '*' : ''}</Label>
                        <SelectInputController
                            name='htmlModel'
                            control={form.control}
                            options={modelsCatalog}
                            disabled={isDetails}
                            rules={{ required: true }}
                            hasError={Boolean(errors.htmlModel)}
                        />
                        <InputError error={errors.htmlModel} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Label space>
                            {t('budget_file_models.header_file')}&nbsp;
                            <FaInfoCircle className={styles.infoCircle} onClick={() => setShowInfoModal(true)} />
                        </Label>
                        <FileInputController
                            name='headerAttachment'
                            value={headerfile}
                            control={form.control}
                            accept={{
                                'image/png': ['.png'],
                                'image/jpeg': ['.jpg', '.jpeg'],
                            }}
                            onChange={setHeaderFile}
                            disabled={isDetails}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Label space>
                            {t('budget_file_models.footer_file')}&nbsp;
                            <FaInfoCircle className={styles.infoCircle} onClick={() => setShowInfoModal(true)} />
                        </Label>
                        <FileInputController
                            name='footerAttachment'
                            value={footerfile}
                            control={form.control}
                            accept={{
                                'image/png': ['.png'],
                                'image/jpeg': ['.jpg', '.jpeg'],
                            }}
                            onChange={setFooterFile}
                            disabled={isDetails}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Label space>{t('budget_file_models.terms_and_conditions_file')}</Label>
                        <FileInputController
                            name='conditionsAttachment'
                            value={conditionsFile}
                            control={form.control}
                            accept={{
                                'application/pdf': ['.pdf'],
                            }}
                            onChange={setConditionsFile}
                            disabled={isDetails}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Label space>{t('budget_file_models.payment_conditions')}</Label>
                        <RichInputController
                            name='paymentConditions' control={form.control}
                            placeholder={t('elements.description')} disabled={isDetails}
                            rules={{ maxLength: 2000 }}
                            hasError={Boolean(errors.paymentConditions)}
                        />
                        <InputError error={errors.paymentConditions} maxLength={2000} />
                    </Col>
                </Row>
            </Form>
        </PageContainer>

        <ConfirmDeleteModal
            itemName={form.getValues('name')}
            isOpen={showConfirmDeleteModal}
            onClose={(result) => onDelete(result)}
        />
        <InformationModal
            message={t('budget_file_models.banner_sizes')}
            isOpen={showInfoModal}
            onClose={() => setShowInfoModal(false)}
        />
    </ScreenTitle>)
}

export default BudgetFileModelScreen;
