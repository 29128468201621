import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'common/components/texts/Texts';
import styles from './NotFoundScreen.module.scss';
import Button from 'common/components/button/Button';
import { useNavigate } from 'react-router-dom';
import { FaAngleLeft } from 'react-icons/fa';

const NotFoundScreen: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <Header className={styles.header}>404 - {t('messages.page_not_found')}</Header>
            <Button onClick={() => navigate('/')} size='small'><FaAngleLeft /> {t('common.go_back')}</Button>
        </div>
    );
};

export default NotFoundScreen;
