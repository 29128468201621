import Utils from 'common/services/Utils';
import { useState, useEffect } from 'react';

const useIsMobileOrTablet = () => {
    const [isMobileOrTablet, setIsMobileOrTablet] = useState<boolean>(false);

    const checkDeviceType = () => {
        setIsMobileOrTablet(Utils.isMobileOrTablet());
    };

    useEffect(() => {
        checkDeviceType();

        window.addEventListener('resize', checkDeviceType);

        return () => window.removeEventListener('resize', checkDeviceType);
    }, []);

    return isMobileOrTablet;
};

export default useIsMobileOrTablet;
