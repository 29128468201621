import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import { AtLeast } from 'common/types/Atleast';
import styles from './BudgetPageBreakLine.module.scss';
import { useTranslation } from 'react-i18next';
import { lineActiveStyle } from '../../utils';
import { forwardRef, Ref } from 'react';

interface Props {
    line: BudgetLineDto;
    disabled: boolean;
    level: number;
    isActive: boolean;
    onChange: (line: AtLeast<BudgetLineDto, 'key'>, calculateTotals: boolean) => void;
}

function BudgetPageBreakLine({ line, disabled, isActive, level, onChange }: Props, ref: Ref<HTMLDivElement>): JSX.Element | null {
    const { t } = useTranslation();

    return (
        <div
            className={styles.pageBreak}
            style={lineActiveStyle(isActive)}
            ref={ref}
        >
            {t('budgets.budget.lines.page_break')}
        </div>
    );
}

export default forwardRef<HTMLDivElement, Props>(BudgetPageBreakLine);
