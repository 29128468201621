import { useContext, useEffect, useRef, useState } from 'react';
import { LinesDataProvider } from '../../../../LinesDataProvider';
import { BudgetLineParameterDto, BudgetLineParameterElementDto } from 'api/budgets/models/BudgetLineParameterDto';
import ProductCard from 'common/components/productCard/ProductCard';
import { UnitOption } from 'common/components/dimensionInput/DimensionInput';
import styles from './ElementsList.module.scss';
import { AtLeast } from 'common/types/Atleast';
import ElementParameters from '../elementParameters/ElementParameters';
import { BudgetLineDto } from 'api/budgets/models/BudgetDto';

interface Props {
    parameter: BudgetLineParameterDto;
    elements: BudgetLineParameterElementDto[];
    line: BudgetLineDto;
    disabled: boolean;
    onChangeElement: (element: AtLeast<BudgetLineParameterElementDto, 'key'>) => void;
}

function ElementsList({ parameter, elements, line, disabled, onChangeElement }: Props): JSX.Element | null {
    const { units } = useContext(LinesDataProvider);
    const unitsOptions: UnitOption[] = units.map(u => ({ selectedLabel: u.symbol, label: u.symbol, value: u.id }));
    const listRef = useRef<HTMLDivElement | null>(null);
    const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const [lastSelectedProductKey, setLastSelectedProductKey] = useState<string | null>(elements?.find(x => x.checked)?.key ?? null);
    const [isLoading, setIsLoading] = useState(false);

    const scrollToElement = (elementKey: string) => {
        const item = itemRefs.current[elementKey];
        const list = listRef.current;

        if (list && item) {
            const itemRect = item.getBoundingClientRect();
            const listRect = list.getBoundingClientRect();

            const scrollLeft = itemRect.left - listRect.left + list.scrollLeft;

            list.scrollTo({
                left: scrollLeft,
                behavior: 'smooth'
            });
        }
    };

    const onSelectElement = (element: BudgetLineParameterElementDto) => {
        if (disabled) {
            return;
        }

        if (lastSelectedProductKey) {
            if (lastSelectedProductKey === element.key && !element.parameters?.length) {
                onChangeElement({
                    key: element.key,
                    checked: false,
                    parameters: [],
                    checkedForParameters: false,
                });
            }
            setLastSelectedProductKey(null);
            return;
        }

        if (!element.checked) {
            setLastSelectedProductKey(element.key);
        }

        const checked = !element.checked;
        onChangeElement({
            key: element.key,
            checked,
            ...(!checked
                ? {
                    parameters: [],
                    checkedForParameters: false,
                }
                : {})
        });
    }

    const onChangeParameters = (product: BudgetLineParameterElementDto, parameters: BudgetLineParameterDto[], checkedForParameters: boolean) => {
        if (!parameters?.length && lastSelectedProductKey && lastSelectedProductKey === product.key) {
            setLastSelectedProductKey(null);
        }

        onChangeElement({
            key: product.key,
            parameters,
            checkedForParameters,
        });
    }

    useEffect(() => {
        if (lastSelectedProductKey) {
            scrollToElement(lastSelectedProductKey);
        }
    }, [lastSelectedProductKey])

    const currentProduct = lastSelectedProductKey ? elements.find(x => x.key === lastSelectedProductKey) : null

    return (
        <div>
            <div className={styles.list} ref={listRef}>
                {(elements ?? []).map((element, i) => (
                    <div key={i} ref={(el) => { itemRefs.current[element.key] = el; }}>
                        <ProductCard
                            key={i}
                            disabled={disabled}
                            mainImage={element.elementImages?.find(x => x.isMain)?.url ?? ''}
                            images={element.elementImages?.filter(x => x.url).map(x => x.url) ?? []}
                            name={element.elementName}
                            family={parameter.familyName ?? ''}
                            subfamily={parameter.subFamilyName ?? ''}
                            enableDataSheet={element.enableDataSheet ?? false}
                            quantity={element.quantity ?? 0}
                            unit={element.unitId ?? ''}
                            unitOptions={unitsOptions}
                            price={element.price ?? undefined}
                            priceIncluded={false}
                            selected={element.checked}
                            onSelect={() => onSelectElement(element)}
                            className={styles.productItem}
                            showBottomArrow={!isLoading && lastSelectedProductKey === element.key && (element.parameters || []).length > 0}
                            showOpenElement={true}
                            isLoading={isLoading && lastSelectedProductKey === element.key}
                            onClickOpenElement={() => {
                                window.open('/library/elements-items/details/' + element.elementId, '_blank');
                            }}
                            onChangePrice={(price) => {
                                onChangeElement({
                                    key: element.key,
                                    price,
                                });
                            }}
                            onChangeUnit={(unitId) => {
                                onChangeElement({
                                    key: element.key,
                                    unitId,
                                });
                            }}
                            onChangeQuantity={(quantity) => {
                                onChangeElement({
                                    key: element.key,
                                    quantity,
                                });
                            }}
                            onChangeEnableDataSheet={(enableDataSheet) => {
                                onChangeElement({
                                    key: element.key,
                                    enableDataSheet,
                                });
                            }}
                        />
                    </div>
                ))}
            </div>
            {currentProduct && (
                <ElementParameters
                    parameters={currentProduct.parameters ?? []}
                    elementId={currentProduct.elementId}
                    checkedForParameters={currentProduct.checkedForParameters}
                    line={line}
                    onChange={(params, checkedForParameters) => onChangeParameters(currentProduct, params, checkedForParameters)}
                    className={styles.elementsContainer} // TODO: só no minmax
                    onLoading={setIsLoading}
                    disabled={disabled}
                />
            )}
        </div>
    );
}

export default ElementsList;
