import MoneyInput from 'common/components/moneyInput/MoneyInput';
import { Col, Row } from 'react-bootstrap';
import { BudgetLineParameterDto } from 'api/budgets/models/BudgetLineParameterDto';
import { useDispatch } from 'react-redux';
import { updateLineQuantity } from 'store/budget/action';
import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import { useContext } from 'react';
import { LinesDataProvider } from '../../../../LinesDataProvider';

interface Props {
    parameter: BudgetLineParameterDto;
    line: BudgetLineDto;
    disabled: boolean;
    onChange: (parameter: Partial<BudgetLineParameterDto>, calculateTotals: boolean) => void;
}

function ParameterQuantity({ parameter, line, disabled, onChange }: Props): JSX.Element | null {
    const dispatch = useDispatch();
    const { units } = useContext(LinesDataProvider);
    const unitsOptions = units.map(u => ({ selectedLabel: u.symbol, label: u.name, value: u.id }));

    const handleOnChange = (value: number | null | undefined) => {
        onChange({ quantity: { quantity: value ?? 0 } }, false); // false because updateLineQuantity takes care of that
        dispatch(updateLineQuantity(line.key));
    }

    return (
        <div>
            <Row>
                <Col xs={12} sm={4}>
                    <MoneyInput
                        value={parameter.quantity?.quantity ?? null}
                        onBlur={handleOnChange}
                        unitPrefix={unitsOptions.find(x => x.value === line.unitId)?.selectedLabel}
                        disabled={disabled}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default ParameterQuantity;
