import CustomFile from 'common/models/CustomFile';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './FilesUploader.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../button/Button';
import FileIcon from 'assets/svg/upload-icon.svg';
import MediaIcon from 'assets/svg/image-default-icon.svg';
import { AttachmentDto } from 'api/common/models/AttachmentDto';
import FilesUploaderFilesList from './filesList/FilesUploaderFilesList';
import FilesUploaderImagesList from './imagesList/FilesUploaderImagesList';
import Utils from 'common/services/Utils';

interface Props {
    files: AttachmentDto[];
    type: 'files' | 'images';
    disabled?: boolean;
    hasError?: boolean;
    containerName?: (f: CustomFile) => string;
    canSetMain?: boolean;
    onChange: (files: AttachmentDto[]) => void;
}

const FilesUploader = ({ files, type, disabled, hasError, canSetMain, containerName, onChange }: Props) => {
    const { t } = useTranslation();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const newFiles: AttachmentDto[] = acceptedFiles.map(f => {
            const customFile: CustomFile = f as CustomFile;
            if (containerName) {
                customFile.containerName = containerName(customFile);
            }

            return {
                id: null,
                key: Utils.newGuid(),
                tempFile: customFile,
                contentType: customFile.type,
                name: customFile.name,
                size: customFile.size,
                url: URL.createObjectURL(customFile),
            };
        });

        if (!files?.filter(f => !f.remove)?.length && newFiles?.length) {
            newFiles[0].isMain = true;
        }

        onChange([
            ...files,
            ...newFiles,
        ]);
    }, [files, onChange])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: type === 'images'
            ? {
                'image/png': ['.png'],
                'image/jpeg': ['.jpg', '.jpeg'],
            }
            : undefined,
        disabled,
    });

    const onRemove = (file: AttachmentDto,newMainFile?: AttachmentDto) => {
        onChange(files.map(f => {
            if (f === file) {
                return { ...f, remove: true,isMain: false };
            } else if (f === newMainFile) {
                return { ...f, isMain: true };
            }

            return f;
        }));
    }

    const onSetIsMain = (file: AttachmentDto) => {
        onChange(files.map(f => {
            if (f === file) {
                return { ...f, isMain: true };
            }
            return { ...f, isMain: false };
        }));
    }

    return (
        <div className={styles.container}>
            {!disabled && <div className={`${styles.uploaderContainer} ${isDragActive ? styles.dragActive : ''} ${hasError ? styles.hasError : ''}`} {...getRootProps()}>
                <input {...getInputProps()} />
                {type === 'files' && <img src={FileIcon} className={styles.icon} alt={t('common.drop_files_here')} />}
                {type === 'images' && <img src={MediaIcon} className={styles.icon} alt={t('common.drop_images_here')} />}
                {!disabled && <span className={styles.dropText}>{type === 'images' ? t('common.drop_images_here') : t('common.drop_files_here')}</span>}
                {!disabled && <span className={styles.orText}>{t('common.or')}</span>}
                {!disabled && <Button>{t('common.browse')}</Button>}
            </div>}
            <div className={`${styles.filesContainer} ${hasError ? styles.hasError : ''}  ${disabled ? '' : styles.borderLeftNone}`}>
                {type === 'files' && <FilesUploaderFilesList files={files} disabled={disabled} onRemove={onRemove} />}
                {type === 'images' && <FilesUploaderImagesList files={files} disabled={disabled} onRemove={onRemove} canSetIsMain={canSetMain} onSetIsMain={onSetIsMain} />}
            </div>
        </div>
    );
}

export default FilesUploader;
