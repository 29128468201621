import { LOGGER_LOG_TYPE } from 'Config';
import RolesService from 'api/roles/RolesService';
import { RoleDto } from 'api/roles/models/RoleDto';
import RulesService from 'api/rules/RulesService';
import { RuleConcatType, RuleDto } from 'api/rules/models/RuleDto';
import UsersService from 'api/users/UsersService';
import { UsersSelectItemDto } from 'api/users/models/UsersSelectItemDto';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { useEffect, useState } from 'react';
import styles from './RulesScreen.module.scss';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/button/Button';
import { useDebouncedCallback } from 'use-debounce';
import { removeAccents } from 'common/utils/removeAccents';
import TextInput from 'common/components/textInput/TextInput';
import CheckInput from 'common/components/checkInput/CheckInput';
import PageHeader from 'common/components/pageHeader/PageHeader';
import { UserProfile } from 'api/account/models/UserProfile';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import Toast from 'common/services/Toast';
import InfoMessage from 'common/components/infoMessage/InfoMessage';
import { FaInfoCircle } from 'react-icons/fa';
import InformationModal from 'common/components/modal/informationModal/InformationModal';

function RulesScreen(): JSX.Element {
    const { t } = useTranslation();
    const [usersList, setUsersList] = useState<UsersSelectItemDto[]>([]);
    const [rulesList, setRulesList] = useState<RuleDto[]>([]);
    const [rolesList, setRolesList] = useState<RoleDto[]>([]);

    const [rule, setRule] = useState<RuleDto>();

    const [filterRule, setFilterRule] = useState<string>('');
    const [filterUser, setFilterUser] = useState<string>('');
    const [filterRole, setFilterRole] = useState<string>('');

    const [allRolesChecked, setAllRolesChecked] = useState<boolean>(false);
    const [allUsersChecked, setAllUsersChecked] = useState<boolean>(false);

    const [usersListFiltered, setUsersListFiltered] = useState<UsersSelectItemDto[]>([]);
    const [rulesListFiltered, setRulesListFiltered] = useState<RuleDto[]>([]);
    const [rolesListFiltered, setRolesListFiltered] = useState<RoleDto[]>([]);

    const [showRolesInformationModal, setShowRolesInformationModal] = useState<boolean>(false);
    const [showUsersInformationModal, setShowUsersInformationModal] = useState<boolean>(false);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasRulesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['RULES_WRITE']);

    const getData = async () => {
        try {
            Loading.show();
            const users = await UsersService.getAllForSelectItem();
            setUsersList(users);
            const roles = await RolesService.getAllForSelectItem();
            setRolesList(roles);
            const rules = await RulesService.getAllForSelectItem();
            setRulesList(rules);

            if (rules.length >= 1) {
                void onRuleSelected(rules[0], users, roles);
            }
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get roles list', error);
            Toast.error(t('messages.error_load_info'));
            Loading.hide();
        }
    };

    useEffect(() => {
        void getData();
    }, []);

    const onRuleSelected = async (rule: RuleDto, users: UsersSelectItemDto[], roles: RoleDto[]) => {
        void parseRuleFromExpression(rule, users, roles);
    }

    const parseRuleFromExpression = async (rule: RuleDto, users: UsersSelectItemDto[], roles: RoleDto[]) => {
        const andOrRegex = /\((.*)\)(AND|OR)\((.*)\)/gi;
        const andOrMatch = andOrRegex.exec(rule?.expression);

        rule.concatType = RuleConcatType.OR; // To set by default
        if (andOrMatch) {
            // AND / OR
            const andOr = andOrMatch[2];
            if (andOr.toUpperCase() === RuleConcatType.AND) {
                rule.concatType = RuleConcatType.AND;
            } else if (andOr.toUpperCase() === RuleConcatType.OR) {
                rule.concatType = RuleConcatType.OR;
            }
        }

        setRule({ ...rule });

        // Users
        users.forEach(x => (x.checked = false));

        const usersExpression = andOrMatch ? andOrMatch[1] : rule.expression?.replace(/\(/g, '').replace(/\)/g, '');
        const usersRegex = /I:([^\s]+)/gi;
        const usersMatchArray: string[] = [];
        let usersMatch = usersRegex.exec(usersExpression);
        while (usersMatch != null) {
            usersMatchArray.push(usersMatch[1]);
            usersMatch = usersRegex.exec(usersExpression);
        }

        if (usersMatchArray && usersMatchArray.length > 0) {
            if (usersMatchArray.length === 1 && usersMatchArray[0] === '*') {
                setAllUsersChecked(true);
                users.forEach((u: UsersSelectItemDto) => {
                    u.disabled = true;
                    u.checked = false;
                });
            } else {
                setAllUsersChecked(false);
                users.forEach((u: UsersSelectItemDto) => {
                    u.disabled = false;
                    if (usersMatchArray.find(user => user === u.userName)) {
                        u.checked = true;
                    }
                });
            }
        }
        setUsersList([...users]);

        // Roles
        roles.forEach(x => (x.checked = false));

        const rolesExpression = andOrMatch ? andOrMatch[3] : rule.expression?.replace(/\(/g, '').replace(/\)/g, '');
        const rolesRegex = /R:([^\s]+)/gi;

        const rolesMatchArray: string[] = [];
        let rolesMatch = rolesRegex.exec(rolesExpression);
        while (rolesMatch != null) {
            rolesMatchArray.push(rolesMatch[1]);
            rolesMatch = rolesRegex.exec(rolesExpression);
        }

        if (rolesMatchArray && rolesMatchArray.length > 0) {
            if (rolesMatchArray.length === 1 && rolesMatchArray[0] === '*') {
                setAllRolesChecked(() => true);
                roles.forEach((r: RoleDto) => {
                    r.disabled = true;
                    r.checked = false;
                });
            } else {
                setAllRolesChecked(() => false);
                roles.forEach((r: RoleDto) => {
                    r.disabled = false;
                    if (rolesMatchArray.find(role => role === r.id)) {
                        r.checked = true;
                    }
                });
            }
        }

        setRolesList([...roles]);
    }

    const createExpression = (rule: RuleDto) => {
        // Users
        const usersExpression = allUsersChecked
            ? []
            : usersList.filter((e: UsersSelectItemDto) => {
                return e.checked;
            }).map((u: UsersSelectItemDto) => {
                return 'I:' + u.userName;
            });
        const usersString = allUsersChecked ? '(I:*)' : usersExpression?.length > 0 ? '(' + usersExpression.join(' OR ') + ')' : '';

        // Roles
        const rolesExpression = allRolesChecked
            ? []
            : rolesList.filter((r: RoleDto) => {
                return r.checked;
            }).map((r: RoleDto) => {
                return 'R:' + r.id;
            });

        const rolesString = allRolesChecked ? '(R:*)' : rolesExpression?.length > 0 ? '(' + rolesExpression.join(' OR ') + ')' : '';
        const andOr = rule.concatType && rule.concatType === RuleConcatType.AND ? RuleConcatType.AND : RuleConcatType.OR;

        // Result
        if (usersString && rolesString) {
            rule.expression = usersString + andOr + rolesString;
        } else if (usersString && !rolesString) {
            rule.expression = usersString;
        } else if (!usersString && rolesString) {
            rule.expression = rolesString;
        } else {
            rule.expression = '';
        }

        setRulesList([
            ...rulesList.map(r => {
                if (r.id === rule.id) {
                    r.expression = rule.expression;
                }
                return r;
            }),
        ]);
    }

    const debouncedRule = useDebouncedCallback((value: string) => {
        setFilterRule(value);
        const rules = rulesList.filter(item => {
            return removeAccents(t(('rules.policies.' + item.name) as any)).toLowerCase().includes(removeAccents(value).toLowerCase());
        });
        setRulesListFiltered(rules);
    }, 500);

    const debouncedUser = useDebouncedCallback((value: string) => {
        setFilterUser(value);
        const users = usersList.filter(item => {
            return removeAccents(item.realName).toLowerCase().includes(removeAccents(value).toLowerCase())
                || removeAccents(item.userName).toLowerCase().includes(removeAccents(value).toLowerCase())
        });
        setUsersListFiltered(users);
    }, 500);

    const debouncedRole = useDebouncedCallback((value: string) => {
        setFilterRole(value);
        const roles = rolesList.filter(item => {
            return removeAccents(item.name).toLowerCase().includes(removeAccents(value).toLowerCase())
        });
        setRolesListFiltered(roles);
    }, 500);

    useEffect(() => {
        if (rule) {
            createExpression(rule);
        }
    }, [allRolesChecked, allUsersChecked]);

    const onAllRolesChecked = (checked: boolean) => {
        rolesList.forEach((r: RoleDto) => {
            r.disabled = checked;
        });

        setAllRolesChecked(checked);
    }

    const onAllUsersChecked = (checked: boolean) => {
        usersList.forEach((r: UsersSelectItemDto) => {
            r.disabled = checked;
        });

        setAllUsersChecked(checked);
    }

    const setAnd = () => {
        if (rule) {
            const newRule = { ...rule, concatType: RuleConcatType.AND };

            setRule(() => newRule);
            createExpression(newRule);
        }
    }

    const setOr = () => {
        if (rule) {
            const newRule = { ...rule, concatType: RuleConcatType.OR };
            setRule(() => newRule);
            createExpression(newRule);
        }
    }

    const roleName = (role: RoleDto): string => {
        if (role.system || role.readOnly) {
            return t(('common.roles.' + role.name) as any)
        } else {
            return role.name;
        }
    }

    const onSave = async () => {
        try {
            Loading.show();

            if (rulesList != null) {
                await RulesService.update(rulesList);
            }
            Loading.hide();
            Toast.success(t('messages.record_save_success'));
        } catch (error) {
            Toast.error(t('messages.record_save_error'));
            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                'Couldn\'t create or update rule',
                error
            );
            Loading.hide();
        }
    };

    return (
        <ScreenTitle title={t('rules.title')}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: t('rules.title'), url: '/backoffice/rules' },
                ]}
            />
            <PageHeader title={t('rules.title')} informationText={t('rules.list.sub_title')} showGoBack={false} >
                {hasRulesWritePolicy && <Button type='button' onClick={() => onSave()}>{t('common.save')}</Button>}
            </PageHeader>

            <PageContainer className={styles.container}>
                <InfoMessage message={t('rules.list.logout_login')} type='information'/>

                <div className={styles.configContainer}>
                    <div className={styles.rulesContainer}>
                        <div className={styles.searchContainer}>
                            <TextInput onChange={(e) => debouncedRule(e.target.value)} placeholder={t('common.search')}></TextInput>
                        </div>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <td className={styles.tableHeader}>
                                        <span className={styles.tableHeaderTitle}>{t('rules.list.rules')}</span>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {(filterRule !== '' ? rulesListFiltered : rulesList).map((row, rowIndex) => (
                                    <tr key={`row-r-${rowIndex}`} className={styles.rowHover} onClick={() => onRuleSelected(row, usersList, rolesList)}>
                                        <td className={`${styles.bodyColumn} ${row.id === rule?.id ? styles.striped : ''}`}>
                                            <div className={styles.ruleContainer}>
                                                <div className={styles.ruleInfo}>{t(('rules.policies.' + row.name) as any)}</div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    { /* ##################  Roles ################## */}
                    <div className={styles.rolesContainer}>

                        <div className={styles.searchContainer}>
                            <TextInput onChange={(e) => debouncedRole(e.target.value)} placeholder={t('common.search')}></TextInput>
                        </div>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <td className={styles.tableHeader}>
                                        <span className={styles.tableHeaderTitle}>
                                            {t('rules.list.roles')}&nbsp;
                                            <FaInfoCircle className={styles.infoCircle} onClick={() => setShowRolesInformationModal(true)} />
                                        </span>
                                        <CheckInput checked={allRolesChecked} onChange={(e: any) => { onAllRolesChecked(e.target.checked) }} disabled={!hasRulesWritePolicy} />
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {(filterRole !== '' ? rolesListFiltered : rolesList).map((row, rowIndex) => (
                                    <tr key={`row-u-${rowIndex}`} className={hasRulesWritePolicy ? styles.rowHover : undefined}>
                                        <td className={styles.bodyColumn}>
                                            <div className={styles.roleContainer}>
                                                <div className={styles.roleInfo}>
                                                    {roleName(row)}
                                                </div>
                                                <div className={styles.checkbox}>
                                                    <CheckInput
                                                        onChange={(e: any) => {
                                                            setRolesList([
                                                                ...rolesList.map(r => {
                                                                    if (r.id === row.id) {
                                                                        r.checked = e.target.checked;
                                                                    }
                                                                    return r;
                                                                }),
                                                            ]);
                                                            if (rule) {
                                                                createExpression(rule);
                                                            }
                                                        }}
                                                        checked={row.checked || false}
                                                        key={row.id}
                                                        disabled={!hasRulesWritePolicy || row.disabled}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.operationsContainer}>
                        <div className={styles.multiButton}>
                            <Button
                                variant={rule == null || rule?.concatType !== 'OR' ? 'primary' : 'third'}
                                onClick={setAnd}
                                size="medium"
                                disabled={!hasRulesWritePolicy}
                            >{t('common.and')}</Button>
                            <Button
                                variant={!(rule == null || rule?.concatType !== 'OR') ? 'primary' : 'third'}
                                onClick={setOr}
                                size="medium"
                                disabled={!hasRulesWritePolicy}
                            >{t('common.or')}</Button>
                        </div>
                    </div>
                    { /* ##################  Users ################## */}
                    <div className={styles.usersContainer}>
                        {/* <p><strong>{t('users.title')}</strong></p> */}
                        <div className={styles.searchContainer}>
                            <TextInput onChange={(e) => debouncedUser(e.target.value)} placeholder={t('common.search')}></TextInput>
                        </div>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <td className={styles.tableHeader}>
                                        <span className={styles.tableHeaderTitle}>
                                            {t('rules.list.users')}&nbsp;
                                            <FaInfoCircle className={styles.infoCircle} onClick={() => setShowUsersInformationModal(true)} />
                                        </span>
                                        <CheckInput checked={allUsersChecked} onChange={e => { onAllUsersChecked(e.target.checked) }} disabled={!hasRulesWritePolicy} />
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {(filterUser !== '' ? usersListFiltered : usersList).map((row, rowIndex) => (
                                    <tr key={`row-u-${rowIndex}`} className={hasRulesWritePolicy ? styles.rowHover : undefined}>
                                        <td className={styles.bodyColumn}>
                                            <div className={styles.userContainer}>
                                                <div className={styles.userInfo}>
                                                    <span className={styles.name}>{row.realName}</span>
                                                    <span className={styles.email}>({row.userName})</span>
                                                </div>
                                                <div className={styles.checkbox}>
                                                    <CheckInput
                                                        onChange={(e: any) => {
                                                            setUsersList([
                                                                ...usersList.map(r => {
                                                                    if (r.id === row.id) {
                                                                        r.checked = e.target.checked;
                                                                    }
                                                                    return r;
                                                                }),
                                                            ]);
                                                            if (rule) {
                                                                createExpression(rule);
                                                            }
                                                        }}
                                                        checked={row.checked || false}
                                                        key={row.id}
                                                        disabled={!hasRulesWritePolicy || row.disabled}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </PageContainer>
            <InformationModal
                message={t('rules.all_roles_tooltip')}
                isOpen={showRolesInformationModal}
                onClose={() => setShowRolesInformationModal(false)}
            />
            <InformationModal
                message={t('rules.all_users_tooltip')}
                isOpen={showUsersInformationModal}
                onClose={() => setShowUsersInformationModal(false)}
            />
        </ScreenTitle >
    )
}

export default RulesScreen;
