import { BudgetLineParameterDto, BudgetLineParameterElementDto } from 'api/budgets/models/BudgetLineParameterDto';
import { useTranslation } from 'react-i18next';
import { Text } from 'common/components/texts/Texts';
import ElementsList from '../elementsList/ElementsList';
import { AtLeast } from 'common/types/Atleast';
import { BudgetLineDto } from 'api/budgets/models/BudgetDto';

interface Props {
    parameter: BudgetLineParameterDto;
    line: BudgetLineDto;
    disabled: boolean;
    onChange: (parameter: Partial<BudgetLineParameterDto>, calculateTotals: boolean) => void;
}

function ParameterProducts({ parameter, line, disabled, onChange }: Props): JSX.Element | null {
    const { t } = useTranslation();

    const onChangeElement = (newElement: AtLeast<BudgetLineParameterElementDto, 'key'>) => {
        onChange({
            elements: (parameter.elements ?? []).map(p => {
                if (p.key === newElement.key) {
                    return { ...p, ...newElement }
                }
                return p;
            }),
        }, true);
    }

    if (!parameter.elements?.length) {
        return <Text>{t('messages.no_info_to_show')}</Text>;
    }

    return (
        <div>
            <ElementsList
                parameter={parameter}
                elements={parameter.elements ?? []}
                line={line}
                onChangeElement={onChangeElement}
                disabled={disabled}
            />
        </div>
    );
}

export default ParameterProducts;
