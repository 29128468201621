import Storage from 'common/services/Storage';
import { LinesTableColumn } from '../lines/utils';

export interface BudgetsCacheData {
    hiddenColumns: LinesTableColumn[];
}

class BudgetsCache {
    public save(pageId: string, data: BudgetsCacheData) {
        Storage.setObject(pageId, data);
    }

    public get(pageId: string, defaultData?: BudgetsCacheData): BudgetsCacheData {
        const result: BudgetsCacheData = Storage.getObject(pageId) ?? defaultData;
        return result;
    }
}

export default new BudgetsCache();
