import React, { CSSProperties, useEffect } from 'react';
import styles from './ListingTable.module.scss';
import DateFormat from '../dateFormat/dateFormat';
import { useTranslation } from 'react-i18next';
import MoneyFormat from '../moneyFormat/MoneyFormat';
import { isTablet } from 'Config';
import HReafWrap from './hRefWrap/HRefWrap';
import { FilterData, ListingTableColumn, OrderData, TableRow } from './models';
import { ActionClearFiltersTableColumn, ActionMenuFiltersTableColumn, DefaultTableColumn } from './TableColumn';
import { LoadingSpinnerCenter } from '../loading/LoadingSpinner';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { ColumnWidth } from './ListingTableCache';

const TABLE_CONTAINER_ID = 'listing-table-container';

interface InfiniteScrollData {
    isLoading: boolean;
    hasMore: boolean;
    onLoadMore: () => void;
}

interface Props<TRow> {
    columns: ListingTableColumn<TRow>[];
    columnsWidths?: ColumnWidth<TRow>[];
    rows: TRow[];
    disallowHover?: boolean;
    striped?: boolean;
    selectedRow?: TRow | null;
    order?: OrderData<TRow> | null;
    showErrors?: boolean;
    errors?: any[];
    className?: string;
    hideHeaderWhenNoData?: boolean;
    colorRow?: string;
    hiddenColumns: (keyof TRow)[];
    noItemsText?: string;
    infiniteScroll?: InfiniteScrollData;
    scrollToRowId?: string | null;
    onRowClick?: (row: TRow, index: number, event: any) => void;
    onOrder?: (orderData: OrderData<TRow>) => void;
    onHref?: (row: TRow) => string;
    rowStyle?: (row: TRow, col: ListingTableColumn<TRow>, rowIndex: number, colIndex: number) => CSSProperties;
    onFilter?: (col: ListingTableColumn<TRow>, filterData: FilterData) => void;
    onClearFilters?: () => void;
    onRenderAction?: (row: TRow) => JSX.Element | undefined;
    onRowId?: (row: TRow) => string;
    onColResize: (columns: ColumnWidth<TRow>[]) => void;
}

const ListingTable = <TRow extends TableRow>({
    columns, columnsWidths, rows, selectedRow, disallowHover = false, striped = false, className,
    showErrors = false, errors = [], hideHeaderWhenNoData, hiddenColumns, infiniteScroll, scrollToRowId,
    colorRow, noItemsText, order, onOrder, onRowClick, onHref, rowStyle, onFilter, onClearFilters, onRenderAction, onRowId, onColResize
}: Props<TRow>) => {
    const { t } = useTranslation();

    const filteredColumns = columns.filter(c => !hiddenColumns.find(hc => hc === c.field));

    const getCellWidthStyle = (col: ListingTableColumn<TRow>) => {
        return {
            width: col.width ?? undefined,
            minWidth: col.minWidth ?? undefined,
        };
    }

    const getRowCellStyle = (col: ListingTableColumn<TRow>, row: TRow, rowIndex: number, colIndex: number): CSSProperties => {
        return {
            textAlign: col.cellAlignment || 'left',
            ...getCellWidthStyle(col),
            ...(col.cellStyle || {}),
            ...(rowStyle ? rowStyle(row, col, rowIndex, colIndex) : {}),
        }
    }

    const getRowCellValue = (row: TRow, col: ListingTableColumn<TRow>, index: number) => {
        if (col.renderCell) {
            return col.renderCell(row, col, index);
        }

        if (!col.field) {
            return '';
        }

        const value = row[col.field];

        if (col.cellFormat && col.cellFormat === 'money') {
            return <MoneyFormat value={value} suffix={col.suffix}/>;
        }

        if (col.cellFormat && col.cellFormat === 'date') {
            return <DateFormat value={value} />;
        }

        return value;
    }

    const onClick = (event: any, row: TRow, col: ListingTableColumn<TRow>, index: number) => {
        if (col.preventClick) {
            return;
        }

        if (onRowClick) {
            onRowClick(row, index, event);
        }
    }

    const onClickOrder = (col: ListingTableColumn<TRow>) => {
        if (onOrder) {
            resetScrollbar();

            onOrder({
                field: col.field,
                orderColumn: col.orderColumn ?? col.field as string,
                isOrderAsc: order?.field === col.field ? !order?.isOrderAsc : true,
            });
        }
    }

    const resetScrollbar = () => {
        const pageContainer = document.getElementById(TABLE_CONTAINER_ID);
        if (pageContainer) {
            pageContainer.scrollTop = 0;
        }
    }

    const onClearFiltersClicked = () => {
        if (!onClearFilters) {
            return;
        }

        resetScrollbar();

        onClearFilters();
    }

    const onCallFilter = (col: ListingTableColumn<TRow>, filterData: FilterData) => {
        if (!onFilter) {
            return;
        }

        resetScrollbar();

        onFilter(col, filterData);
    }

    const isMobile = isTablet(window.innerWidth);

    const renderRow = (row: TRow, rowIndex: number) => (
        <tr
            key={`row-${rowIndex}`}
            style={{ boxShadow: showErrors && errors[rowIndex] != null ? 'rgb(255 0 0) 0px 0px 0px 2px inset' : '', background: colorRow ?? '' }}
            className={`${styles.row} ${(rowIndex % 2 === 0 ? styles.even : styles.odd)} ${!disallowHover && styles.rowHover} ${(selectedRow && (selectedRow.id === row.id || selectedRow.index === row.index)) ? styles.selected : ''}`}
            id={onRowId ? onRowId(row) : undefined}
        >

            {filteredColumns.map((col, colIndex) => (<React.Fragment key={`col-${colIndex}`}>
                <td
                    onClick={event => onClick(event, row, col, rowIndex)}
                    className={`${col.hideOn && col.hideOn?.includes('xxl')
                        ? styles.xxl
                        : col.hideOn?.includes('xl')
                            ? styles.xl
                            : col.hideOn && col.hideOn?.includes('lg') ?
                                styles.lg
                                : col.hideOn && col.hideOn?.includes('md')
                                    ? styles.md
                                    : col.hideOn && col.hideOn?.includes('sm') ?
                                        styles.sm
                                        : ''}
                                                ${styles.column} ${striped && rowIndex % 2 === 0 ? styles.striped : ''}
                                                ${onRowClick ? styles.clickable : ''}
                                                ${isMobile ? styles.mobile : ''}`}

                    style={getRowCellStyle(col, row, rowIndex, colIndex)}
                >
                    {!col.preventClick && <HReafWrap href={onHref ? onHref(row) : null}>{getRowCellValue(row, col, rowIndex)}</HReafWrap> }
                    {col.preventClick && getRowCellValue(row, col, rowIndex) }
                </td>
                <td className={styles.columnResizerBody} />
            </React.Fragment>))}
            {Boolean(onRenderAction || onClearFilters) && (
                <ActionMenuFiltersTableColumn
                    key={'col-action'}
                    row={row}
                    rowIndex={rowIndex}
                    striped={striped}
                    onRenderAction={onRenderAction}
                />
            )}
        </tr>
    );

    // const totalFilters = filteredColumns.filter(c => ((c.filter?.type === 'money-range' || c.filter?.type === 'date-range') ? Boolean(c.filter?.value?.start || c.filter?.value?.end) : Boolean(c.filter?.value))).length;

    const totalFilters = filteredColumns.filter(c => {
        if (c.filter?.notIncludeInTotalFilters) {
            return false; // Excluir 'checkbox' do contador
        }
        if (c.filter?.type === 'money-range' || c.filter?.type === 'date-range') {
            return Boolean(c.filter?.value?.start || c.filter?.value?.end);
        }
        return Boolean(c.filter?.value);
    }).length;

    const [sentryRef] = infiniteScroll
        ? useInfiniteScroll({
            loading: infiniteScroll.isLoading,
            hasNextPage: infiniteScroll.hasMore,
            disabled: false,
            rootMargin: '0px 0px 400px 0px',
            onLoadMore: infiniteScroll.onLoadMore,
        })
        : [null];

    const scrollToRow = (rowId: string) => {
        const rowElement = document.getElementById(rowId);
        const pageContainer = document.getElementById(TABLE_CONTAINER_ID);
        if (rowElement && pageContainer) {
            // const scrollToOptions = {
            //     // block: 'nearest',
            //     // inline: 'start',
            //     behavior: 'instant'
            // };
            // rowElement.scrollIntoView(scrollToOptions as unknown as ScrollToOptions);
            // pageContainer.scrollTop = rowElement.offsetTop - pageContainer.offsetTop;
            const newTop = rowElement.offsetTop - rowElement.clientHeight - 10;
            pageContainer.scrollTo({ top: newTop > 0 ? newTop : 0, behavior: 'instant', block: 'nearest' } as unknown as ScrollToOptions);
        }
    }

    const onColResized = (col: ListingTableColumn<TRow>, width: number) => {
        if (!columnsWidths?.length) {
            columnsWidths = columns.map(c => ({
                field: c.field,
                width: c.width ?? 250
            }))
        }

        const index = columnsWidths?.findIndex(x => x.field === col.field);
        if (columnsWidths && (index >= 0)) {
            columnsWidths[index].width = width;
            onColResize && onColResize(columnsWidths);
        }
    }

    useEffect(() => {
        if (scrollToRowId) {
            scrollToRow(scrollToRowId);
        }
    }, [scrollToRowId])

    return (
        <div className={`table-responsive ${styles.tableResponsive}`} id={TABLE_CONTAINER_ID}>
            <table className={`${styles.table} ${className || ''}`}>
                {(Boolean(rows.length > 0) || (Boolean(rows.length <= 0) && !hideHeaderWhenNoData)) && <thead>
                    <tr>
                        {filteredColumns.map((col, colIndex) => {
                            col.width = columnsWidths?.find(x => x.field === col.field)?.width ?? col.width;
                            return <DefaultTableColumn
                                key={`col-${col.field.toString()}`}
                                col={col}
                                onClickOrder={() => onClickOrder(col)}
                                onFilter={onCallFilter}
                                hasFilters={Boolean(onFilter)}
                                onOrder={onOrder}
                                order={order}
                                colIndex={colIndex}
                                onColResize={(width: number) => onColResized(col, width)}
                            />
                        })}
                        {Boolean(onRenderAction || onClearFilters) && (
                            <ActionClearFiltersTableColumn
                                key={'col-action'}
                                totalFilters={totalFilters}
                                onClearFilters={onClearFiltersClicked}
                            />
                        )}
                    </tr>
                </thead>}
                <tbody>
                    {rows.map((row, rowIndex) => {
                        return (
                            (<React.Fragment key={'fragment-row-' + rowIndex}>
                                {renderRow(row, rowIndex)}
                            </React.Fragment>)
                        );
                    })}
                </tbody>
                {Boolean(rows.length <= 0) && <tfoot>
                    <tr>
                        <td colSpan={100} className={styles.noItemsText}>
                            {noItemsText ?? t('messages.no_info_to_show')}
                        </td>
                    </tr>
                </tfoot>}
            </table>
            {infiniteScroll && (infiniteScroll.isLoading || infiniteScroll.hasMore) && <div ref={sentryRef}>
                <LoadingSpinnerCenter />
            </div>}
        </div>
    );
}

export default ListingTable;
