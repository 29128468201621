import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { LogSearchCriteria } from './models/LogSearchCriteria';
import { LogDto } from './models/LogDto';

class LogsService {
    public getList(criteria: LogSearchCriteria) {
        return Http.get<Paged<LogDto>>('log', criteria);
    }

    public log (message: string): Promise<string> {
        return Http.post('logger', { error: message });
    }
}

export default new LogsService();
