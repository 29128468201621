import { BudgetLineType } from 'api/budgets/enums/BudgetLineType';
import { BudgetDto, BudgetLineDto } from 'api/budgets/models/BudgetDto';
import { BudgetLineParameterDto } from 'api/budgets/models/BudgetLineParameterDto';
import { AtLeast } from 'common/types/Atleast';

export enum AddLinePlacement {
    SAME,
    INSIDE,
    ROOT,
}

export const SET_SELECTED_LINE_KEY = 'SET_SELECTED_LINE_KEY';
export const SET_LINES = 'SET_LINES';
export const SET_BUDGET = 'SET_BUDGET';
export const ADD_LINE = 'ADD_LINE';
export const COPY_LINE = 'COPY_LINE';
export const CUT_LINE = 'CUT_LINE';
export const PASTE_LINE = 'PASTE_LINE';
export const UPDATE_LINE = 'UPDATE_LINE';
export const REMOVE_LINE = 'REMOVE_LINE';
export const REMOVE_LINE_CHILDREN = 'REMOVE_LINE_CHILDREN';
export const CLONE_LINE = 'CLONE_LINE';
export const CALCULATE_LINE_TOTALS = 'CALCULATE_LINE_TOTALS';
export const CALCULATE_ALL_LINES_TOTALS = 'CALCULATE_ALL_LINES_TOTALS';
export const CALCULATE_BUDGET_TOTALS = 'CALCULATE_BUDGET_TOTALS';
export const UPDATE_BUDGET = 'UPDATE_BUDGET';
export const UPDATE_LINE_QUANTITY = 'UPDATE_LINE_QUANTITY';
export const UPDATE_ALL_LINES = 'UPDATE_ALL_LINES';
export const UPDATE_LINE_PARAMETER = 'UPDATE_LINE_PARAMETER';
export const UPDATE_LINES_NUMBERS = 'UPDATE_LINES_NUMBERS';
export const MOVE_LINE_UP = 'MOVE_LINE_UP';
export const MOVE_LINE_DOWN = 'MOVE_LINE_DOWN';
export const SET_OPEN_LINES_INDEXES = 'SET_OPEN_LINES_INDEXES'

export type Action = SetSelectedLineKeyAction
| SetSelectedLineKeyAction
| SetLinesAction
| SetBudgetAction
| UpdateLineAction
| AddLineAction
| CloneLineAction
| CopyLineAction
| CutLineAction
| PasteLineAction
| RemoveLineAction
| CaclulateLineTotalsAction
| CaclulateAllLinesTotalsAction
| CaclulateBudgetTotalsAction
| UpdateBudgetAction
| UpdateLineQuantityAction
| UpdateAllLinesAction
| UpdateLineParameterAction
| UpdateLinesNumbersAction
| MoveLineUpAction
| MoveLineDownAction
| SetOpenLinesIndexesAction
| RemoveLineChildrenAction;

export interface SetSelectedLineKeyAction {
    type: typeof SET_SELECTED_LINE_KEY;
    selectedLineKey: string | null;
}

export interface SetLinesAction {
    type: typeof SET_LINES;
    lines: BudgetLineDto[];
}

export interface SetBudgetAction {
    type: typeof SET_BUDGET;
    budget: BudgetData;
}

export interface UpdateLineAction {
    type: typeof UPDATE_LINE;
    line: AtLeast<BudgetLineDto, 'key'>;
}

export interface AddLineAction {
    type: typeof ADD_LINE;
    lineType: BudgetLineType;
    placement: AddLinePlacement;
    lineKey: string | null;
    newLineKey: string | null;
}

export interface RemoveLineAction {
    type: typeof REMOVE_LINE;
    lineKey: string;
}

export interface RemoveLineChildrenAction {
    type: typeof REMOVE_LINE_CHILDREN;
    parentLineKey: string;
}

export interface CloneLineAction {
    type: typeof CLONE_LINE;
    lineKey: string;
}

export interface CopyLineAction {
    type: typeof COPY_LINE;
    lineKey: string | null;
}

export interface CutLineAction {
    type: typeof CUT_LINE;
    lineKey: string | null;
}

export interface PasteLineAction {
    type: typeof PASTE_LINE;
    lineKey: string;
    newLineKey: string;
    placement: AddLinePlacement;
}

export interface UpdateLineQuantityAction {
    type: typeof UPDATE_LINE_QUANTITY;
    lineKey: string;
}

export interface CaclulateLineTotalsAction {
    type: typeof CALCULATE_LINE_TOTALS;
    lineKey: string;
}

export interface CaclulateAllLinesTotalsAction {
    type: typeof CALCULATE_ALL_LINES_TOTALS;
}

export interface CaclulateBudgetTotalsAction {
    type: typeof CALCULATE_BUDGET_TOTALS;
}

export interface UpdateBudgetAction {
    type: typeof UPDATE_BUDGET;
    budget: Partial<BudgetData>;
}

export interface UpdateAllLinesAction {
    type: typeof UPDATE_ALL_LINES;
    line: Partial<BudgetLineDto>;
}

export interface UpdateLineParameterAction {
    type: typeof UPDATE_LINE_PARAMETER;
    lineKey: string;
    parameter: AtLeast<BudgetLineParameterDto, 'key'>;
}

export interface UpdateLinesNumbersAction {
    type: typeof UPDATE_LINES_NUMBERS;
}

export interface MoveLineUpAction {
    type: typeof MOVE_LINE_UP;
    lineKey: string;
}

export interface MoveLineDownAction {
    type: typeof MOVE_LINE_DOWN;
    lineKey: string;
}

export interface SetOpenLinesIndexesAction {
    type: typeof SET_OPEN_LINES_INDEXES;
    openLinesIndexes: number[];
}

export type CopyType = 'copy' | 'cut' | null;

export interface BudgetState {
    selectedLineKey: string | null;
    copiedLineKey: string | null;
    copyType: CopyType;
    lines: BudgetLineDto[];
    tempLines: BudgetLineDto[];
    budget: BudgetData;
    openLinesIndexes: number[];
}

export type BudgetData = Pick<BudgetDto,
'defaultTaxId' |
'totalNetExcludingTax' |
'totalTax' |
'totalIncludingTax' |
'totalExcludingTax' |
'discount' |
'discountTaxId' |
'discountTaxValue' |
'defaultTaxValue' |
'decimalPlaces' |
'defaultDiscountPercentage'
>
