import Utils from 'common/services/Utils';
import { NumerationSchemeType } from './enums/NumerationSchemeType';

export interface SchemeToken {
    type: NumerationSchemeType | null;
    value?: string;
    key: string;
}

const SEPARATOR_DELIMITER = '|';
const INNER_SEPARATOR_DELIMITER = ';';

class NumerationSchemeParser {
    public parse(scheme: string): SchemeToken[] {
        return scheme.split(SEPARATOR_DELIMITER).map(token => {
            const tokenSplit = token.split(INNER_SEPARATOR_DELIMITER);
            return ({
                type: NumerationSchemeType[tokenSplit[0] as NumerationSchemeType],
                value: tokenSplit.length > 1 ? tokenSplit[1] : undefined,
                key: Utils.newGuid(),
            });
        });
    }

    public compose(schemeTokens: SchemeToken[]): string {
        return schemeTokens.map(token => `${token.type}` + (token.value !== undefined ? `${INNER_SEPARATOR_DELIMITER}${token.value}` : '')).join(SEPARATOR_DELIMITER);
    }
}

export default new NumerationSchemeParser();
