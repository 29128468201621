import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import SearchableSelectInput, { SearchableSelectInputOption, SearchableSelectInputProps } from './SearchableSelectInput';

type SearchableSelectInputControllerProps<T extends FieldValues, T2 extends SearchableSelectInputOption> = Omit<Partial<SearchableSelectInputProps<T2>>, 'value'> & Pick<SearchableSelectInputProps<T2>, 'loadOptions'> & UseControllerProps<T>;

export const SearchableSelectInputController = <T extends FieldValues, T2 extends SearchableSelectInputOption>(props: SearchableSelectInputControllerProps<T, T2>) => {
    const { field } = useController({ ...props, disabled: false });
    return (
        <SearchableSelectInput
            {...props}
            value={field.value}
            onChange={(e, option) => {
                field.onChange(e);
                if (props.onChange) {
                    props.onChange(e, option);
                }
            }}
        />
    );
};
