import { BudgetDto, BudgetToPdfDto } from 'api/budgets/models/BudgetDto';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Logger from 'common/services/Logger';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_DECIMAL_PLACES, LOGGER_LOG_TYPE, BODY_TOOLTIP, DATE_TIME_FORMAT_DEFAULT } from 'Config';
import BudgetsService from 'api/budgets/BudgetsService';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'common/services/Loading';
import { FormProvider, useForm } from 'react-hook-form';
import { Tab, Tabs } from 'react-bootstrap';
import Button from 'common/components/button/Button';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import { FaEye, FaHistory, FaTrashAlt, FaUpload } from 'react-icons/fa';
import { Reducers } from 'store/types';
import { useSelector, useDispatch } from 'react-redux';
import UsersService from 'api/users/UsersService';
import { UserProfile } from 'api/account/models/UserProfile';
import Toast from 'common/services/Toast';
import { SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';
import BudgetDetails from './details/BudgetDetails';
import BudgetLines from './lines/BudgetLines';
import WorksService from 'api/works/WorksService';
import ExternalPartsService from 'api/externalPart/ExternalPartsService';
import { BudgetStatus } from 'api/budgets/enums/BudgetStatus';
import { BudgetLineType } from 'api/budgets/enums/BudgetLineType';
import UnitsService from 'api/units/UnitsService';
import TaxesService from 'api/taxes/TaxesService';
import { UnitDto } from 'api/units/models/UnitDto';
import { TaxDto } from 'api/taxes/models/TaxDto';
import styles from './BudgetScreen.module.scss';
import ColumnsOptionsSidebar, { ColumnsOptionsSidebarColumn } from 'common/components/columnsOptionsSidebar/ColumnsOptionsSidebar';
import { Form } from 'common/components/form/Form';
import { LinesTableColumn } from './lines/utils';
import BudgetsCache, { BudgetsCacheData } from './utils/BudgetsCache';
import { setBudget, setLines, setOpenLinesIndexes } from 'store/budget/action';
import { createLine } from 'store/budget/reducer';
import store from 'store/store';
import WorkTypesService from 'api/workTypes/WorkTypesService';
import { SelectInputOption } from 'common/components/selectInput/SelectInput';
import BudgetPrices from './prices/BudgetPrices';
import BudgetFileModelsService from 'api/budgetFileModels/BudgetFileModelsService';
import ButtonDropdown, { ButtonDropdownOption } from 'common/components/buttonDropdown/ButtonDropdown';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import YesNoModal from 'common/components/modal/yesNoModal/YesNoModal';
import HistoryModal from 'common/components/modal/historyModal/HistoryModal';
import { Text } from 'common/components/texts/Texts';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { BudgetHistoriesDto } from 'api/budgets/models/BudgetHistoriesDto';
import InfoMessage from 'common/components/infoMessage/InfoMessage';
import BudgetsFieldsService from 'api/budgetsFields/BudgetsFieldsService';
import { mapFieldValues } from './utils/BudgetUtils';

const PAGE_ID = 'BUDGET_LINES';

type TabKey = 'details' | 'lines' | 'prices';

type FormModel = Omit<BudgetDto, 'lines'>;

const url = '/business/budgets';

function BudgetScreen(): JSX.Element | null {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id, type, version } = useParams<{ id: string, type: 'create' | 'details' | 'edit' | 'version', version: string }>();
    const form = useForm<FormModel>({ shouldUnregister: false });
    const currentUrl = url + '/' + type + (id ? ('/' + id) : '');
    const isDetails = type === 'details' || type === 'version';
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = type !== 'version' && UsersService.hasPolicies(loggedUser?.policies ?? [], ['BUDGETS_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['BUDGETS_READ'])
    const [externalParts, setExternalParts] = useState<SearchableSelectTitleSubTitleOption[]>([]);
    const [works, setWorks] = useState<SearchableSelectTitleSubTitleOption[]>([]);
    const [workContacts, setWorkContacts] = useState<SearchableSelectTitleSubTitleOption[]>([])
    const [workTypes, setWorkTypes] = useState<SelectInputOption[]>([]);
    const [currentTabKey, setCurrentTabKey] = useState<TabKey>('details');
    const [taxes, setTaxes] = useState<TaxDto[]>([]);
    const [units, setUnits] = useState<UnitDto[]>([]);
    const [budgetFileModels, setBudgetFileModels] = useState<SelectInputOption[]>([]);
    const [budgetData, setBudgetData] = useState<BudgetsCacheData>(BudgetsCache.get(PAGE_ID, {
        hiddenColumns: [
            LinesTableColumn.GROSS_UNIT_PRICE,
            LinesTableColumn.MARGIN,
            LinesTableColumn.DISCOUNT,
            LinesTableColumn.UNIT_PRICE_WITH_CHILDREN,
            LinesTableColumn.TOTAL_INCLUDING_TAX,
            LinesTableColumn.TOTAL_UNIT_EXCLUDING_TAX,
        ],
    }));
    const [budgetHistories, setBudgetHistories] = useState<BudgetHistoriesDto[]>([]);

    const [printBudgetWithTechnicalFile,setPrintBudgetWithTechnicalFile] = useState<boolean | null>(null)
    const budget = useSelector<Reducers, Partial<BudgetDto>>(x => x.budget.budget);
    const [showInvalidReferenceModal, setShowInvalidReferenceModal] = useState(false);

    const dispatch = useDispatch();

    const linesTableColumns: ColumnsOptionsSidebarColumn<LinesTableColumn>[] = [
        {
            name: t('budgets.budget.lines.header.line_number'),
            field: LinesTableColumn.LINE_NUMBER,
        },
        {
            name: t('budgets.budget.lines.header.quantity'),
            field: LinesTableColumn.QUANTITY,
        },
        {
            name: t('budgets.budget.lines.header.unity'),
            field: LinesTableColumn.UNIT,
        },
        {
            name: t('budgets.budget.lines.header.gross_unit_price'),
            field: LinesTableColumn.GROSS_UNIT_PRICE,
        },
        {
            name: t('budgets.budget.lines.header.margin'),
            field: LinesTableColumn.MARGIN,
        },
        {
            name: t('budgets.budget.lines.header.discount'),
            field: LinesTableColumn.DISCOUNT,
        },
        {
            name: t('budgets.budget.lines.header.price_per_unity'),
            field: LinesTableColumn.UNIT_PRICE,
        },
        {
            name: t('budgets.budget.lines.header.unit_price_with_children'),
            field: LinesTableColumn.UNIT_PRICE_WITH_CHILDREN,
        },
        {
            name: t('budgets.budget.lines.header.total_without_tax'),
            field: LinesTableColumn.TOTAL_EXCLUDING_TAX,
        },
        {
            name: t('budgets.budget.lines.header.total_unit_without_tax'),
            field: LinesTableColumn.TOTAL_UNIT_EXCLUDING_TAX,
        },
        {
            name: t('budgets.budget.lines.header.tax'),
            field: LinesTableColumn.TAX,
        },
        {
            name: t('budgets.budget.lines.header.total_with_tax'),
            field: LinesTableColumn.TOTAL_INCLUDING_TAX,
        },
        {
            name: t('budgets.budget.lines.header.element_image'),
            field: LinesTableColumn.ELEMENT_IMAGE,
        },
    ];

    const generateReference = () => {
        return BudgetsService.generateReference({ workTypeId: form.getValues('workTypeId') }).then((data) => {
            form.setValue('number', data.reference);
        });
    }

    const getData = async () => {
        try {
            let result: Partial<BudgetDto> = {
                id,
                description: '',
                lines: [],
                status: BudgetStatus.IN_STUDY,
                totalExcludingTax: 0,
                totalIncludingTax: 0,
                totalNetExcludingTax: 0,
                totalTax: 0,
                budgetHistoryVersion: '1',
                decimalPlaces: DEFAULT_DECIMAL_PLACES,
                fieldsValues: [],
            }

            Loading.show();

            if (id) {
                result = type === 'version' && version
                    ? await BudgetsService.getBudgetHistoryByVersion(id, version)
                    : await BudgetsService.getById(id, isDetails);
            }

            const [externalPartsData, unitsData, taxesData, workTypesData, budgetFileModelsData, budgetsFields] = await Promise.all([
                ExternalPartsService.getCatalog(),
                UnitsService.getFormattedCatalog(),
                TaxesService.getFormattedCatalog(),
                WorkTypesService.getCatalog(),
                BudgetFileModelsService.getCatalog(),
                BudgetsFieldsService.getCatalog(type === 'version' ? null : id ?? null, type === 'version' ? id ?? null : null),
            ]);

            setExternalParts(externalPartsData);
            setUnits(unitsData);
            setTaxes(taxesData);
            setWorkTypes(workTypesData);
            setBudgetFileModels(budgetFileModelsData)

            if (!result.lines || result.lines.length === 0) {
                const rootSection = {
                    ...createLine(BudgetLineType.SECTION, null, null),
                    isOpen: true,
                };

                result.lines = [
                    rootSection,
                ];
            }

            if (type === 'create' && budgetFileModelsData.length === 1) {
                result.budgetFileModelId = budgetFileModelsData[0].value;
            }

            if (result.workId && result.workName) {
                await getWorks(result.externalPartId);

                setWorks(prevWorks => {
                    const updatedWorks = [...prevWorks];
                    if (!updatedWorks.find(x => x.value === result.workId)) {
                        updatedWorks.push({
                            label: result.workName || '',
                            value: result.workId || '',
                            subTitle: result.workSubTitle ?? '-',
                        });
                    }
                    return updatedWorks;
                });
            }

            if (result.workTypeId && result.workTypeName && !workTypesData.find(x => x.value === result.workTypeId)) {
                setWorkTypes([...workTypesData,
                    {
                        label: result.workTypeName,
                        value: result.workTypeId
                    }]);
            }

            const workContactsBuilder: SearchableSelectTitleSubTitleOption[] = []
            if (result.onSiteContactId && result.onSiteContactName && !workContactsBuilder.find(x => x.value === result.onSiteContactId)) {
                workContactsBuilder.push({
                    value: result.onSiteContactId,
                    label: result.onSiteContactName,
                    subTitle: result.onSiteContactSubTitle ?? '-'
                })
            }

            if (result.managerContactId && result.managerContactName && !workContactsBuilder.find(x => x.value === result.managerContactId)) {
                workContactsBuilder.push({
                    value: result.managerContactId,
                    label: result.managerContactName,
                    subTitle: result.managerContactSubTitle ?? '-'
                })
            }

            setWorkContacts(workContactsBuilder)

            dispatch(setBudget({
                defaultTaxId: result.defaultTaxId,
                totalNetExcludingTax: result.totalNetExcludingTax ?? 0,
                totalTax: result.totalTax ?? 0,
                totalIncludingTax: result.totalIncludingTax ?? 0,
                totalExcludingTax: result.totalExcludingTax ?? 0,
                discount: result.discount ?? 0,
                discountTaxId: result.discountTaxId || null,
                discountTaxValue: result.discountTaxValue,
                defaultTaxValue: result.defaultTaxValue,
                decimalPlaces: result.decimalPlaces ?? 0
            }));
            const openLinesArray = store.getState().budget.openLinesIndexes
            const newLines = result.lines.map((x,i) => {
                return { ...x, isOpen: x.lineType === BudgetLineType.SECTION ? openLinesArray.includes(i) : true }
            })

            dispatch(setOpenLinesIndexes([]))
            dispatch(setLines(newLines));

            if (result.workId) {
                await getWorkContacts(result.workId);
            }

            result.fieldsValues = mapFieldValues(budgetsFields, result.fieldsValues ?? []);

            result.lines = [];
            form.reset(result);

            if (type === 'create') {
                await generateReference();
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get budgets', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const onSubmit = async (model: FormModel) => {
        try {
            Loading.show();

            const tempModel: BudgetDto = {
                ...model,
                ...store.getState().budget.budget,
                lines: store.getState().budget.lines,
            }

            const tempArray: number[] = []
            tempModel.lines.forEach((element,i) => {
                if (element.isOpen) {
                    tempArray.push(i)
                }
            });
            dispatch(setOpenLinesIndexes(tempArray))

            if (tempModel && tempModel.id) {
                await BudgetsService.update(tempModel)
                // navigateTo('details', tempModel.id);
                await getData();
            } else if (tempModel) {
                const id = await BudgetsService.create(tempModel)
                navigateTo('edit', id);
            }
            Toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            if (error && error.response && error.response.status === 409 && error.response.data === 'invalid_numeration') {
                void generateReference().then(() => {
                    setShowInvalidReferenceModal(true);
                });
            } else {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t update save the budget', error);
                Toast.error(t('messages.record_save_error'));
            }
        }
        Loading.hide();
    }

    const onInvalid = () => {
        onSelectTab('details')
        Toast.warning(t('messages.required_fields_empty'));
    }

    const onDelete = async (item: boolean) => {
        if (!item) {
            setShowConfirmDeleteModal(false)
            return;
        }
        try {
            Loading.show();

            await BudgetsService.remove(form.getValues())
            onGoBack();

            Loading.hide();
            Toast.success(t('messages.record_delete_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete budget', error);
            Toast.error(t('messages.record_delete_error'));
        }
        setShowConfirmDeleteModal(false)
    }

    const onCloseConfirmPrintModal = (res: boolean) => {
        if (!res) {
            setPrintBudgetWithTechnicalFile(null)
        } else {
            void form.handleSubmit(onSubmit)().then(() => {
                void printBudget(printBudgetWithTechnicalFile!)
            });

            setPrintBudgetWithTechnicalFile(null)
        }
    }

    const getWorks = async (externalPartId: string | undefined | null) => {
        form.setValue('workId', '');
        form.setValue('workName', '');
        form.setValue('workSubTitle', '');
        form.setValue('managerContactId', '');
        form.setValue('onSiteContactId', '');

        if (!externalPartId) {
            setWorks([]);
            return;
        } else {
            form.clearErrors('externalPartId');
        }

        try {
            Loading.show();

            const works = await WorksService.getCatalogByExternalPartId(externalPartId);
            setWorks(works);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get works', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const getWorkContacts = async (workId: string | undefined | null) => {
        form.setValue('managerContactId', '');
        form.setValue('onSiteContactId', '');

        if (!workId) {
            setWorkContacts([]);
            return;
        }

        try {
            Loading.show();

            const workContacts = await WorksService.getWorkExternalPartContactsCatalog(workId);
            setWorkContacts(workContacts)

            if (workContacts.length === 1) {
                form.setValue('managerContactId', workContacts[0].value)
                form.setValue('onSiteContactId', workContacts[0].value)
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get works contacts', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const onGoBack = () => {
        if (type === 'version') {
            navigateTo('details', id);
        } else {
            navigate(url);
        }
    }

    const navigateTo = (type: string, id?: string) => {
        navigate(url + '/' + type + (id ? ('/' + id) : ''));
    }

    const onSelectTab = (k: string | null) => {
        if (!isDetails && (k === 'lines' || k === 'prices')) {
            const workId = form.watch('workId');
            const externalPartId = form.watch('externalPartId');

            if (!workId || !externalPartId) {
                onInvalid();
                if (!workId) {
                    form.setError('workId', { type: 'custom', message: t('common.errors.required') });
                }
                if (!externalPartId) {
                    form.setError('externalPartId', { type: 'custom', message: t('common.errors.required') });
                }
                return;
            }
        }

        setCurrentTabKey(k as TabKey)
    }

    const onCloseInvalidReferenceModal = (res: boolean) => {
        setShowInvalidReferenceModal(false);

        if (res) {
            void form.handleSubmit(onSubmit)();
        }
    }

    const onShowistory = async () => {
        if (id) {
            const histories = await BudgetsService.getHistoriesList(id);
            setBudgetHistories(histories);
            setShowHistoryModal(true);
        }
    }

    const renderHistoryItem = (item: BudgetHistoriesDto) => (
        <>
            <div>
                <div className={styles.historyVersion}>
                    V{item.version}
                </div>
                <button
                    className={styles.button}
                    type="button"
                    onClick={() => window.open('/business/budgets/version/' + id + '/' + item.version, '_blank')}
                    data-tooltip-id={BODY_TOOLTIP}
                    data-tooltip-content={t('budgets.budget.open_version')}
                >
                    <FaEye />
                </button>
            </div>
            <Text className={styles.dateTime}>
                <DateFormat value={item.createdDate} format={DATE_TIME_FORMAT_DEFAULT} /> {' | ' + item.createdUser}
            </Text>
        </>
    );

    useEffect(() => {
        void getData();
    }, [id, type]);

    useEffect(() => {
        BudgetsCache.save(PAGE_ID, budgetData);
    }, [budgetData]);

    const printBudget = async (includeTechnicalFiles: boolean) => {
        try {
            Loading.show();

            const model: BudgetToPdfDto = {
                budgetId: id as string,
                budgetFileModelId: form.getValues('budgetFileModelId') as string,
                includeTechnicalFiles
            }
            const result = await BudgetsService.printBudget(model);
            const url = URL.createObjectURL(result);
            window.open(url, '_blank');
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t print budget', error);
            Toast.error(t('messages.record_save_error'));
        }
        Loading.hide();
    }

    const printButtonOptions: ButtonDropdownOption[] = [
        { label: t('budgets.budget.print_without_technical_file'), onClick: () => isDetails ? printBudget(false) : setPrintBudgetWithTechnicalFile(false) },
        { label: t('budgets.budget.print_with_technical_file'), onClick: () => isDetails ? printBudget(true) : setPrintBudgetWithTechnicalFile(true) },
    ];

    if (!canRead) {
        return null;
    }

    const title = (type === 'create' ? t('common.new') : (type === 'edit' ? t('common.edit') : t('common.details'))) + ' ' + t('budgets.budget.budget');

    return (
        <ScreenTitle title={title}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: t('budgets.list.title'), url },
                    { name: title, url: currentUrl },
                ]}
            />
            <PageHeader title={title} informationText={t('common.go_back')} showGoBack addSidebarSpacing={currentTabKey === 'lines'} onGoBack={onGoBack}>
                <Button variant='forth' fw className={styles.totalPriceButton}>
                    <span className={styles.totalPriceLabel}>{t('budgets.budget.lines.totals.total_without_tax')}</span>
                    <span className={styles.totalPrice}>
                        <MoneyFormat value={budget.totalExcludingTax ?? 0} suffix={'€'} decimalScale={budget.decimalPlaces} />
                    </span>
                </Button>

                { type !== 'create' && <Button fw variant='secondary' onClick={onShowistory}> <FaHistory /> </Button> }

                {canWrite && (isDetails || type === 'edit') &&
                    <ButtonDropdown fw variant='secondary' options={printButtonOptions} disabled={!form.watch('budgetFileModelId')}
                        data-tooltip-id={BODY_TOOLTIP}
                        data-tooltip-content={!form.watch('budgetFileModelId') ? t('budgets.budget.select_file_model_to_print') : null}>
                        <FaUpload />
                    </ButtonDropdown>}
                {canWrite && !isDetails && <Button form='formBudget' type='submit'>{t('common.save')}</Button>}
                {canWrite && isDetails && <Button fw onClick={() => { navigateTo('edit', id); }}>{t('common.edit')}</Button>}
                {canWrite && isDetails && (
                    <Button variant='danger' onClick={() => setShowConfirmDeleteModal(true)}>
                        <FaTrashAlt />
                    </Button>
                )}
            </PageHeader>
            <PageContainer>
                {type === 'version' && <div className={styles.versionWarning}>
                    <InfoMessage message={t('budgets.budget.version_warning')} type='information'/>
                </div>}
                <FormProvider {...form}>
                    <Form id='formBudget' handleSubmit={form.handleSubmit} onSubmit={onSubmit} onInvalid={onInvalid} noValidate className={styles.form}>
                        <Tabs activeKey={currentTabKey} onSelect={(k) => onSelectTab(k)}>
                            <Tab eventKey={'details'} title={t('budgets.budget.tab_details')}>
                                <BudgetDetails
                                    type={type}
                                    externalParts={externalParts}
                                    works={works}
                                    workContacts={workContacts}
                                    workTypes={workTypes}
                                    budgetFileModels={budgetFileModels}
                                    taxes={taxes}
                                    onChangeExternalPart={getWorks}
                                    getWorkContacts={getWorkContacts}
                                    onChangeWorkType={generateReference}
                                />
                            </Tab>
                            <Tab eventKey={'lines'} title={t('budgets.budget.tab_lines')}>
                                <BudgetLines
                                    type={type}
                                    taxes={taxes}
                                    units={units}
                                    budgetData={budgetData}
                                />
                            </Tab>
                            <Tab eventKey={'prices'} title={t('budgets.budget.tab_prices')}>
                                <BudgetPrices
                                    isDetails={isDetails}
                                    taxes={taxes}
                                    units={units}
                                />
                            </Tab>
                        </Tabs>
                    </Form>
                </FormProvider>
            </PageContainer>
            {currentTabKey === 'lines' && (
                <ColumnsOptionsSidebar
                    columns={linesTableColumns}
                    hiddenColumns={budgetData.hiddenColumns}
                    canDrag={false}
                    onChangeHiddenColumns={(hc) => setBudgetData(d => ({ ...d, hiddenColumns: hc }))}
                    onChangeColumnsOrder={(oc) => setBudgetData(d => ({ ...d, columnsOrder: oc }))}
                />
            )}
            <ConfirmDeleteModal
                itemName={form.getValues('description')}
                isOpen={showConfirmDeleteModal}
                onClose={onDelete}
            />

            <YesNoModal
                title={t('budgets.budget.save_before_print_modal_title')}
                message={t('budgets.budget.save_before_print_modal_message')}
                isOpen={printBudgetWithTechnicalFile != null}
                onClose={onCloseConfirmPrintModal}
            />

            <YesNoModal
                title={t('budgets.budget.invalid_reference_title')}
                message={t('budgets.budget.invalid_reference_message', { newReference: form.getValues('number') })}
                isOpen={showInvalidReferenceModal}
                onClose={onCloseInvalidReferenceModal}
            />

            <HistoryModal
                title={t('budgets.budget.history_model_title')}
                isOpen={showHistoryModal}
                items={budgetHistories}
                renderItem={renderHistoryItem}
                onClose={() => setShowHistoryModal(false)}
            />
        </ScreenTitle>
    );
}

export default BudgetScreen;
