import React, { DetailedHTMLProps, InputHTMLAttributes, ReactElement, Ref } from 'react';
import styles from './CheckInput.module.scss';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';

type CheckProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const CheckInputPlain = ({ children, ...props }: CheckProps, ref: Ref<HTMLInputElement> | undefined) => {
    return (
        <label className={styles.container}>
            {Boolean(children) && <span className={styles.labelText}>{children}</span>}
            <input
                type="checkbox"
                {...props}
                ref={ref}
                className={`${props.className ?? ''}`}
            />
            <span className={styles.checkmark}></span>
        </label>
    );
};

const CheckInput = React.forwardRef(CheckInputPlain);

export default CheckInput;

type CheckControllerProps<T extends FieldValues> = CheckProps & UseControllerProps<T>;

const CheckInputControllerPlain = <T extends FieldValues>(props: CheckControllerProps<T>, ref: Ref<HTMLInputElement> | undefined) => {
    const { field } = useController({ ...props, disabled: false });

    return (
        <CheckInput
            {...props}
            ref={ref}
            defaultValue={undefined}
            checked={!!field.value}
            name={field.name}
            onChange={(e) => {
                field.onChange(e);
                if (props.onChange) {
                    props.onChange(e);
                }
            }}
        />
    );
};

export const CheckInputController = React.forwardRef(CheckInputControllerPlain) as
    <T extends FieldValues>(p: CheckControllerProps<T> & { ref?: Ref<HTMLInputElement> }) => ReactElement;
