import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../Modal';
import { Header, Text } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/button/Button';

type Props = {
    title?: string;
    message?: string;
    children?: ReactNode;
    onClose: () => void;
} & ReactModal.Props;

const InformationModal: React.FC<Props> = ({ title, message, children, onClose, ...props }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal {...props} style={{ content: { minWidth: 400 } }}>
            <div>
                <ModalHeader>
                    <Header>{title ?? t('common.information').toUpperCase()}</Header>
                </ModalHeader>
                <ModalBody>
                    {message && <Text>{message}</Text>}
                    {children}
                </ModalBody>
                <ModalFooter className="text-right">
                    <Button onClick={onClose}>{t('common.ok')}</Button>
                </ModalFooter>
            </div>
        </Modal >
    );
};

export default InformationModal;
