import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import {
    SetSelectedLineKeyAction,
    SET_SELECTED_LINE_KEY,
    SET_LINES,
    UpdateLineAction,
    UPDATE_LINE,
    AddLinePlacement,
    ADD_LINE,
    REMOVE_LINE,
    CLONE_LINE,
    CALCULATE_LINE_TOTALS,
    CaclulateAllLinesTotalsAction,
    CALCULATE_ALL_LINES_TOTALS,
    SetBudgetAction,
    SET_BUDGET,
    CALCULATE_BUDGET_TOTALS,
    CaclulateBudgetTotalsAction,
    UpdateBudgetAction,
    UPDATE_BUDGET,
    UPDATE_LINE_QUANTITY,
    UpdateAllLinesAction,
    UPDATE_ALL_LINES,
    UPDATE_LINE_PARAMETER,
    UpdateLineParameterAction,
    UPDATE_LINES_NUMBERS,
    UpdateLinesNumbersAction,
    MOVE_LINE_UP,
    MoveLineUpAction,
    MoveLineDownAction,
    MOVE_LINE_DOWN,
    BudgetData,
    SetOpenLinesIndexesAction,
    SET_OPEN_LINES_INDEXES,
    COPY_LINE,
    CUT_LINE,
    PASTE_LINE,
    REMOVE_LINE_CHILDREN
} from './type';
import { AtLeast } from 'common/types/Atleast';
import { BudgetLineType } from 'api/budgets/enums/BudgetLineType';
import { Dispatch } from 'redux';
import { BudgetLineParameterDto } from 'api/budgets/models/BudgetLineParameterDto';

export const setSelectedLineKey = (selectedLineKey: string | null): SetSelectedLineKeyAction => ({
    type: SET_SELECTED_LINE_KEY,
    selectedLineKey,
});

export const setLines = (lines: BudgetLineDto[]): any => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: SET_LINES,
            lines,
        });
        dispatch(updateLinesNumbers());
    };
};

export const setBudget = (budget: BudgetData): SetBudgetAction => ({
    type: SET_BUDGET,
    budget,
});

export const updateLine = (line: AtLeast<BudgetLineDto, 'key'>): UpdateLineAction => ({
    type: UPDATE_LINE,
    line,
});

export const addLine = (lineType: BudgetLineType, placement: AddLinePlacement, lineKey: string | null, newLineKey: string | null): any => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ADD_LINE,
            lineType,
            placement,
            lineKey,
            newLineKey,
        });
        dispatch(updateLinesNumbers());
    };
};

export const removeLine = (lineKey: string): any => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: REMOVE_LINE,
            lineKey
        });
        dispatch(updateLinesNumbers());
    };
};

export const removeLineChildren = (parentLineKey: string): any => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: REMOVE_LINE_CHILDREN,
            parentLineKey
        });
        dispatch(updateLinesNumbers());
    };
};

export const cloneLine = (lineKey: string): any => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: CLONE_LINE,
            lineKey
        });
        dispatch(updateLinesNumbers());
    };
};

export const copyLine = (lineKey: string | null): any => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: COPY_LINE,
            lineKey
        });
    };
};

export const cutLine = (lineKey: string | null): any => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: CUT_LINE,
            lineKey
        });
    };
};

export const pasteLine = (lineKey: string | null, newLineKey: string): any => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: PASTE_LINE,
            lineKey,
            newLineKey,
        });
        dispatch(updateLinesNumbers());
    };
};

export const calculateLineTotals = (lineKey: string): any => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: CALCULATE_LINE_TOTALS,
            lineKey
        });
        dispatch(calculateBudgetTotals());
    };
};

export const calculateAllLinesTotals = (): any => {
    return async (dispatch: Dispatch) => {
        dispatch(calculateAllLinesTotalsOnly());
        dispatch(calculateBudgetTotals());
    };
};

export const calculateAllLinesTotalsOnly = (): CaclulateAllLinesTotalsAction => ({
    type: CALCULATE_ALL_LINES_TOTALS,
});

export const calculateBudgetTotals = (): CaclulateBudgetTotalsAction => ({
    type: CALCULATE_BUDGET_TOTALS,
});

export const updateBudget = (budget: Partial<BudgetData>): UpdateBudgetAction => ({
    type: UPDATE_BUDGET,
    budget,
});

export const updateLineQuantity = (lineKey: string): any => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_LINE_QUANTITY,
            lineKey
        });
        dispatch(calculateLineTotals(lineKey));
    };
};

export const updateAllLines = (line: Partial<BudgetLineDto>): UpdateAllLinesAction => ({
    type: UPDATE_ALL_LINES,
    line
});

export const updateLineParameter = (lineKey: string, parameter: AtLeast<BudgetLineParameterDto, 'key'>): UpdateLineParameterAction => ({
    type: UPDATE_LINE_PARAMETER,
    lineKey,
    parameter,
});

export const updateLinesNumbers = (): UpdateLinesNumbersAction => ({
    type: UPDATE_LINES_NUMBERS,
});

export const moveLineUp = (lineKey: string): MoveLineUpAction => ({
    type: MOVE_LINE_UP,
    lineKey,
});

export const moveLineDown = (lineKey: string): MoveLineDownAction => ({
    type: MOVE_LINE_DOWN,
    lineKey,
});

export const setOpenLinesIndexes = (openLinesIndexes: number[]): SetOpenLinesIndexesAction => ({
    type: SET_OPEN_LINES_INDEXES,
    openLinesIndexes,
});
