import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { ExternalPartCategoryDto } from './models/ExternalPartCategoryDto';
import { ExternalPartCategorySearchCriteria } from './models/ExternalPartCategorySearchCriteria';

class ExternalPartCategoriesService {
    public getList (criteria: ExternalPartCategorySearchCriteria) {
        return Http.get<Paged<ExternalPartCategoryDto>>('externalPartCategories', criteria);
    }

    public getCatalog () {
        return Http.get<ExternalPartCategoryDto[]>('externalPartCategories/catalog');
    }

    public GetAvailableForWorkCreation (getOptions: boolean) {
        return Http.get<ExternalPartCategoryDto[]>('externalPartCategories/available-for-work-creation/' + getOptions);
    }

    public getById (id: string) {
        return Http.get<ExternalPartCategoryDto>('externalPartCategories/' + id);
    }

    public create (model: ExternalPartCategoryDto): Promise<string> {
        return Http.post('externalPartCategories', model);
    }

    public update (model: ExternalPartCategoryDto) {
        return Http.put('externalPartCategories', model);
    }

    public remove (model: ExternalPartCategoryDto) {
        return Http.put('externalPartCategories/deactivate', model);
    }
}

export default new ExternalPartCategoriesService();
