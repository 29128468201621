import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import { AtLeast } from 'common/types/Atleast';
import styles from './BudgetElementLine.module.scss';
import utilsStyles from '../../Utils.module.scss';
import { forwardRef, Ref, useContext, useMemo, useRef, useState } from 'react';
import { LinesDataProvider } from '../../LinesDataProvider';
import { isColumnVisible, LinesTableColumn } from '../../utils';
import InlineDropdownInput from 'common/components/inlineDropdownInput/InlineDropdownInput';
import InlineNumberInput from 'common/components/inlineNumberInput/InlineNumberInput';
import InlineTextInput from 'common/components/inlineTextInput/InlineTextInput';
import { Label } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import SearchableSelectInput from 'common/components/searchableSelectInput/SearchableSelectInput';
import { Col, Row } from 'react-bootstrap';
import FamiliesService from 'api/families/FamiliesService';
import Toast from 'common/services/Toast';
import Logger from 'common/services/Logger';
import { DEFAULT_DECIMAL_PLACES, LOGGER_LOG_TYPE } from 'Config';
import ElementsService from 'api/elements/ElementsService';
import { OptionTitleSubTitle, SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';
import BudgetLineColumn from '../../column/BudgetLineColumn';
import Button from 'common/components/button/Button';
import { Image } from 'common/components/image/Image';
import { SmallTabs, Tab } from 'common/components/smallTabs/SmallTabs';
import ConfigureTab from './configureTab/ConfigureTab';
import PricesTab from './pricesTab/PricesTab';

import { ParameterSectionParameterType } from 'common/components/parametersSections/models';
import { BudgetLineParameterByElementDto, BudgetLineParameterDto } from 'api/budgets/models/BudgetLineParameterDto';
import InformationModal from 'common/components/modal/informationModal/InformationModal';
import DescriptionTab from './descriptionTab/DescriptionTab';
import store from 'store/store';
import { Reducers } from 'store/types';
import { useDispatch, useSelector } from 'react-redux';
import MathUtils from 'common/services/MathUtils';
import { ElementSelectTitleSubTitleOptionDto } from 'common/components/configurableElementsLine/ConfigurableElementsLine';
import useNoInitialEffect from 'common/hooks/useNoInitialEffect';
import BudgetsService from 'api/budgets/BudgetsService';
import { updateLine, updateLineQuantity, calculateLineTotals, addLine, removeLine, removeLineChildren } from 'store/budget/action';
import { BudgetLineType } from 'api/budgets/enums/BudgetLineType';
import Utils from 'common/services/Utils';
import { LoadingSpinnerCenter } from 'common/components/loading/LoadingSpinner';
import { BudgetData } from 'store/budget/type';

type TabKey = 'configure' | 'description' | 'prices';

interface Props {
    line: BudgetLineDto;
    disabled: boolean;
    level: number;
    isActive: boolean;
    isEditing: boolean;
    onChange: (line: AtLeast<BudgetLineDto, 'key'>, calculateTotals: boolean) => void;
    onAddedElement: () => void;
    isComponentElement: boolean
}

function BudgetElementLine({ line, disabled, isActive, isEditing, level, onChange, onAddedElement, isComponentElement }: Props, ref: Ref<HTMLDivElement>): JSX.Element | null {
    const { t } = useTranslation();
    const { taxes, units, hiddenColumns } = useContext(LinesDataProvider);
    const [currentTab, setCurrentTab] = useState<TabKey>(isComponentElement ? 'description' : 'configure');
    const [isLoading, setIsLoading] = useState(false);
    const [showParametersQuantityInformation, setShowParametersQuantityInformation] = useState(false);
    const decimalPlaces = useSelector<Reducers, number>(x => x.budget.budget.decimalPlaces ?? DEFAULT_DECIMAL_PLACES);
    const isFirstElementQuery = useRef(true);

    const unitsOptions = units.map(u => ({ selectedLabel: u.symbol, label: u.name, value: u.id }));
    const taxesOptions = taxes.map(t => ({ selectedLabel: t.value?.toString() + ' %', label: t.description, value: t.id }));

    const showImageColumn = isColumnVisible(hiddenColumns, LinesTableColumn.ELEMENT_IMAGE);
    const showLineNumber = isColumnVisible(hiddenColumns, LinesTableColumn.LINE_NUMBER);
    const dispatch = useDispatch();
    const hasQuantityParameters = useMemo(
        () => {
            const isQuantityParameter = (param: BudgetLineParameterDto): boolean => {
                if (param.parameterType === ParameterSectionParameterType.QUANTITY) {
                    return true;
                }

                return param.children?.some(isQuantityParameter) ?? false;
            };

            return (line.parameters ?? []).some(isQuantityParameter);
        },
        [line.parameters],
    );

    const onChangeValue = (newLine: Partial<BudgetLineDto>, calculateTotals: boolean) => {
        onChange({ key: line.key, ...newLine }, calculateTotals);
    }

    const onChangeFamily = (v: string | null | undefined, option: SearchableSelectTitleSubTitleOption | null | undefined) => {
        onChangeValue({ tempFamily: option, tempElement: null }, false);
    }

    const onChangeElement = (v: string | null | undefined, option: SearchableSelectTitleSubTitleOption | null | undefined) => {
        onChangeValue({ tempElement: option }, false);
    }

    const onLoadFamilies = (
        inputValue: string,
        callback: (options: SearchableSelectTitleSubTitleOption[], hasMore: boolean) => void,
        _isStart: boolean,
        page: number
    ) => {
        FamiliesService.getList({
            hasParentId: false,
            isComponent: isComponentElement,
            isElement: !isComponentElement,
            itemsPerPage: 10,
            page,
            name: inputValue,
            includeFamiliesAndSubFamilies: true,
        }).then(result => {
            const options: SearchableSelectTitleSubTitleOption[] = result.items.map(item => ({
                label: item.name,
                subTitle: item.parentName ?? '',
                value: item.id,
            }));
            callback(options, result.currentPage < result.totalPages);
        }).catch((error) => {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get families', error);
            Toast.error(t('messages.error_load_info'));
        });
    };

    const onLoadElements = (
        inputValue: string,
        callback: (options: SearchableSelectTitleSubTitleOption[], hasMore: boolean) => void,
        _isStart: boolean,
        page: number
    ) => {
        ElementsService.getElementsCatalogByFamilyId({
            itemsPerPage: 10,
            page,
            name: inputValue,
            familyId: line.tempFamily?.value ?? undefined,
            includeId: isFirstElementQuery.current && line.elementId ? line.elementId : undefined,
            isElement: !isComponentElement,
            isComponent: isComponentElement
        }).then(result => {
            const options: SearchableSelectTitleSubTitleOption[] = result.items.map(item => ({
                ...item,
                imageUrl: showImageColumn ? item.imageUrl : undefined,
                canShowNoImage: !!showImageColumn,
                data: item,
            }));

            if (isFirstElementQuery.current && !line.tempElement && line.elementId) {
                onChangeElement(line.elementId, options.find(x => x.value === line.elementId));
            }

            isFirstElementQuery.current = false;

            callback(options, result.currentPage < result.totalPages);
        }).catch((error) => {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get elements', error);
            Toast.error(t('messages.error_load_info'));
        });
    };

    const onAddElement = async () => {
        if (!line.tempElement) {
            return;
        }
        if (isComponentElement && line.elementId) {
            dispatch(removeLineChildren(line.key));
        }
        const elementData = (line.tempElement as any).data as ElementSelectTitleSubTitleOptionDto;
        const budget = store.getState().budget.budget;

        try {
            setIsLoading(() => true);

            const parametersData = await BudgetsService.getElementParameters(line.tempElement.value,true);

            onChangeValue({
                imageUrl: parametersData.imageUrl,
                designation: parametersData.name,
                subDesignation: elementData.subTitle,
                elementId: parametersData.elementId,
                grossUnitPrice: MathUtils.round(parametersData.price ?? 0, decimalPlaces),
                unitId: parametersData.unitId ?? null,
                taxId: budget.defaultTaxId ? budget.defaultTaxId : (parametersData.taxId ?? null),
                taxValue: budget.defaultTaxValue ? budget.defaultTaxValue : (parametersData.taxId ? taxes.find(x => x.id === parametersData.taxId)?.value ?? 0 : 0),
                description: parametersData.description,
                parameters: isComponentElement ? [] : (parametersData.parameters ?? []),
                checkedForParameters: true,
                pdfVisibility: parametersData.pdfVisibility,
                changedParameters: true,
                discountPercentage: budget.defaultDiscountPercentage ? budget.defaultDiscountPercentage : 0
            }, false);
            dispatch(updateLineQuantity(line.key));
            dispatch(calculateLineTotals(line.key))
            if (isComponentElement && parametersData.children) {
                buildChildrenLines(parametersData.children,line.key,budget)
            }
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get the element data', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            setIsLoading(() => false);
        }
        onAddedElement();
    }

    const buildChildrenLines = (children: BudgetLineParameterByElementDto[],parentKey: string,budget: BudgetData) => {
        children.forEach(element => {
            const key = Utils.newGuid()
            dispatch(addLine(element.isComponent ? BudgetLineType.COMPONENT_ELEMENT : BudgetLineType.ELEMENT, 1, parentKey, key));
            dispatch(updateLine({
                key,
                imageUrl: element.imageUrl,
                designation: element.name ?? '',
                subDesignation: element.subTitle ?? '',
                elementId: element.elementId ?? '',
                grossUnitPrice: MathUtils.round(element.price ?? 0, decimalPlaces),
                quantity: element.quantity ?? 0,
                unitId: element.unitId ?? null,
                taxId: budget.defaultTaxId ? budget.defaultTaxId : (element.taxId ?? null),
                taxValue: budget.defaultTaxValue ? budget.defaultTaxValue : (element.taxId ? taxes.find(x => x.id === element.taxId)?.value ?? 0 : 0),
                description: element.description,
                parameters: element.parameters ?? [],
                checkedForParameters: true,
                pdfVisibility: element.pdfVisibility,
                changedParameters: true,
                discountPercentage: budget.defaultDiscountPercentage ? budget.defaultDiscountPercentage : 0
            }));
            dispatch(calculateLineTotals(key))

            if (element.children) {
                buildChildrenLines(element.children,key,budget)
            }
        });
    }

    useNoInitialEffect(() => {
        isFirstElementQuery.current = true;
        onChangeValue({ tempElement: null, tempFamily: null }, false);
    }, [isEditing]);

    const renderColumns = () => (
        <div className={styles.columnsContainer}>
            <div className={styles.columnsInnerContainer}>
                <BudgetLineColumn column={LinesTableColumn.DESIGNATION} className={`${utilsStyles.column} ${utilsStyles.columnAlignLeft} ${utilsStyles.designationColumn} ${styles.designationColumn}`}>
                    {showImageColumn && <Image src={line.imageUrl ?? null} containerClassName={styles.image} />}
                    <div className={styles.designationInformation}>
                        <div className={styles.designationWithLevel}>
                            {showLineNumber && <div className={styles.number}>{line.number}</div>}
                            <InlineTextInput
                                value={line.designation}
                                onBlur={v => onChangeValue({ designation: v ?? '' }, false)}
                                className={styles.designation}
                                placeholder={t('budgets.budget.lines.without_designation')}
                                disabled={disabled}
                            />

                        </div>
                        <InlineTextInput
                            value={line.subDesignation}
                            onBlur={v => onChangeValue({ subDesignation: v ?? '' }, false)}
                            className={styles.information}
                            placeholder={t('budgets.budget.lines.without_information')}
                            disabled={disabled}
                        />
                    </div>
                </BudgetLineColumn>
                <BudgetLineColumn column={LinesTableColumn.QUANTITY} className={`${utilsStyles.column} ${utilsStyles.quantityColumn}`}>
                    <InlineNumberInput
                        value={line.quantity}
                        onBlur={v => onChangeValue({ quantity: v ?? 0 }, true)}
                        className={'text-center'}
                        disabled={hasQuantityParameters || disabled}
                        onClick={hasQuantityParameters ? () => setShowParametersQuantityInformation(true) : undefined}
                    />
                </BudgetLineColumn>
                <BudgetLineColumn column={LinesTableColumn.UNIT} className={`${utilsStyles.column} ${utilsStyles.unityColumn}`}>
                    <InlineDropdownInput
                        value={line.unitId}
                        options={unitsOptions}
                        onChange={v => onChangeValue({ unitId: v }, false)}
                        className={'text-center'}
                        disabled={disabled}
                    />
                </BudgetLineColumn>
                <BudgetLineColumn column={LinesTableColumn.GROSS_UNIT_PRICE} className={`${utilsStyles.column} ${utilsStyles.grossUnityColumn}`}>
                    <InlineNumberInput
                        value={line.grossUnitPrice}
                        onBlur={v => onChangeValue({ grossUnitPrice: v ?? 0 }, true)}
                        className={'text-center'}
                        type="money"
                        decimalScale={decimalPlaces}
                        disabled={disabled}
                    />
                </BudgetLineColumn>
                <BudgetLineColumn column={LinesTableColumn.MARGIN} className={`${utilsStyles.column} ${utilsStyles.marginColumn}`}>
                    <InlineNumberInput
                        value={line.marginPercentage}
                        onBlur={v => onChangeValue({ marginPercentage: v ?? 0 }, true)}
                        className={'text-center'}
                        type="percentage"
                        disabled={disabled}
                    />
                </BudgetLineColumn>
                <BudgetLineColumn column={LinesTableColumn.DISCOUNT} className={`${utilsStyles.column} ${utilsStyles.discountColumn}`}>
                    <InlineNumberInput
                        value={line.discountPercentage}
                        onBlur={v => onChangeValue({ discountPercentage: v ?? 0 }, true)}
                        className={'text-center'}
                        type="percentage"
                        disabled={disabled}
                    />
                </BudgetLineColumn>
                <BudgetLineColumn column={LinesTableColumn.UNIT_PRICE} className={`${utilsStyles.column} ${utilsStyles.pricePerUnityColumn}`}>
                    <InlineNumberInput
                        value={line.unitPrice}
                        className={'text-center'}
                        type="money"
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </BudgetLineColumn>
                <BudgetLineColumn column={LinesTableColumn.UNIT_PRICE_WITH_CHILDREN} className={`${utilsStyles.column} ${utilsStyles.unitPriceWithChildrenColumn}`}>
                    <InlineNumberInput
                        value={line.unitPriceWithChildren}
                        className={'text-center'}
                        type="money"
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </BudgetLineColumn>
                <BudgetLineColumn column={LinesTableColumn.TOTAL_EXCLUDING_TAX} className={`${utilsStyles.column} ${utilsStyles.totalWithoutTaxColumn}`}>
                    <InlineNumberInput
                        value={line.totalExcludingTax}
                        className={'text-center'}
                        type="money"
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </BudgetLineColumn>
                <BudgetLineColumn column={LinesTableColumn.TOTAL_UNIT_EXCLUDING_TAX} className={`${utilsStyles.column} ${utilsStyles.totalWithoutTaxColumn}`}>
                    <InlineNumberInput
                        value={line.totalUnitExcludingTax}
                        className={'text-center'}
                        type="money"
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </BudgetLineColumn>
                <BudgetLineColumn column={LinesTableColumn.TAX} className={`${utilsStyles.column} ${utilsStyles.taxColumn}`}>
                    <InlineDropdownInput
                        value={line.taxId}
                        options={taxesOptions}
                        onChange={v => onChangeValue({ taxId: v, taxValue: taxes.find(x => x.id === v)?.value ?? 0 }, true)}
                        className={'text-center'}
                        disabled={disabled}
                    />
                </BudgetLineColumn>
                <BudgetLineColumn column={LinesTableColumn.TOTAL_INCLUDING_TAX} className={`${utilsStyles.column} ${utilsStyles.totalWithTaxColumn}`}>
                    <InlineNumberInput
                        value={line.totalIncludingTax}
                        className={'text-center'}
                        type="money"
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </BudgetLineColumn>
            </div>
            {isActive && (
                <div>
                    <SmallTabs activeKey={currentTab} onSelect={(k) => setCurrentTab(k)}>
                        {!isComponentElement && <Tab eventKey={'configure'} title={t('budgets.budget.lines.tabs.configure')}>
                            <ConfigureTab line={line} disabled={disabled} />
                        </Tab>}
                        <Tab eventKey={'description'} title={t('budgets.budget.lines.tabs.description')}>
                            <DescriptionTab line={line} disabled={disabled} />
                        </Tab>
                        <Tab eventKey={'prices'} title={t('budgets.budget.lines.tabs.price')}>
                            <PricesTab line={line} disabled={disabled} />
                        </Tab>
                    </SmallTabs>
                </div>
            )}
        </div>
    );

    const renderEditionMode = () => (
        <Row className={`${styles.editionRow} align-items-end`}>
            <Col xs={12} sm={4}>
                <Label space>{isComponentElement ? t('budgets.budget.lines.ouvrage_family_subfamily') : t('budgets.budget.lines.element_family_subfamily')}</Label>
                <SearchableSelectInput
                    value={line.tempFamily?.value}
                    loadOptions={onLoadFamilies}
                    onChange={onChangeFamily}
                    formatOptionLabel={OptionTitleSubTitle}
                    noFilter={true}
                />
            </Col>
            <Col xs={12} sm>
                <Label space>{isComponentElement ? t('budgets.budget.lines.ouvrage') : t('budgets.budget.lines.element')}</Label>
                <SearchableSelectInput
                    value={line.tempElement?.value}
                    loadOptions={onLoadElements}
                    onChange={onChangeElement}
                    onClickGoToDetails={() => {
                        if (line.tempElement?.value) {
                            window.open('/library/elements-items/details/' + line.tempElement?.value);
                        }
                    }}
                    formatOptionLabel={OptionTitleSubTitle}
                    key={line.tempFamily?.value + '-elements'}
                    noFilter={true}
                />
            </Col>
            <Col xs={12} sm={'auto'}>
                <Button className={styles.editionAddButton} disabled={!line.tempElement} onClick={onAddElement}>
                    {t('common.ok')}
                </Button>
            </Col>
        </Row>
    );

    return (
        <div
            className={styles.container}
            ref={ref}
        >
            <div className={styles.element}>
                {isLoading && <LoadingSpinnerCenter />}
                {isEditing && !isLoading && renderEditionMode()}
                {!isEditing && !isLoading && renderColumns()}
            </div>

            <InformationModal
                message={t('budgets.budget.lines.has_parameters_quantities_info')}
                onClose={() => setShowParametersQuantityInformation(false)}
                isOpen={showParametersQuantityInformation}
            />
        </div>
    );
}

export default forwardRef<HTMLDivElement, Props>(BudgetElementLine);
