import { FaAngleLeft } from 'react-icons/fa';
import { Header, InformationText } from '../texts/Texts';
import styles from './PageHeader.module.scss';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
    title: string;
    subTitle?: string;
    informationText: string;
    showGoBack?: boolean;
    addSidebarSpacing?: boolean;
    onGoBack?: () => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const PageHeader = ({ children, title, subTitle, informationText, showGoBack = true, addSidebarSpacing = false, onGoBack, ...props }: Props) => {
    const navigate = useNavigate();

    const goBack = () => {
        if (onGoBack) {
            onGoBack();
        } else {
            navigate(-1);
        }
    }

    return (
        <div {...props} className={`${styles.container} ${props.className ?? ''}`}>
            {showGoBack && <div className={styles.iconContainer} onClick={goBack}>
                <FaAngleLeft />
            </div>}
            <div className={styles.titles}>
                <InformationText small>{informationText}</InformationText>
                <div>
                    <Header className={`d-inline-block ${styles.header}`}>{title}</Header>
                    {Boolean(subTitle) && <InformationText medium className='d-inline-block'>{subTitle}</InformationText>}
                </div>
            </div>
            <div className={addSidebarSpacing ? styles.sidebarSpacing : undefined}>
                {children}
            </div>
        </div>
    );
};

export default PageHeader;
