import { Paged } from 'api/common/types/Page';
import Http from 'common/services/Http';
import { WorksSearchCriteria } from './models/WorksSearchCriteria';
import { WorkDto, WorkExternalPartDto } from './models/WorkDto';
import { SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';

class WorksService {
    public getList (criteria: WorksSearchCriteria) {
        return Http.post<Paged<WorkDto>>('works/get-works', criteria);
    }

    public getById (id: string) {
        return Http.get<WorkDto>('works/' + id);
    }

    public getCatalog () {
        return Http.get<SearchableSelectTitleSubTitleOption[]>('works/catalog');
    }

    public getCatalogByExternalPartId(externalPartId: string) {
        return Http.get<SearchableSelectTitleSubTitleOption[]>('works/catalog-by-external-part-id/' + externalPartId);
    }

    public getWorkExternalPartContactsCatalog(workId: string) {
        return Http.get<SearchableSelectTitleSubTitleOption[]>('works/work-external-part-contacts-catalog/' + workId);
    }

    public getInitialExternalParts () {
        return Http.get<WorkExternalPartDto[]>('works/initial-external-parts');
    }

    public create(model: WorkDto): Promise<string> {
        return Http.post('works', model);
    }

    public update(model: WorkDto) {
        return Http.put('works', model);
    }

    public delete (id: string, deleteRelations: boolean) {
        return Http.delete('works/' + id + '/' + deleteRelations);
    }
}

export default new WorksService();
