import { arrayMove } from '@dnd-kit/sortable';

import type { FlattenedItem, TreeItem, TreeItems } from './types';
import { UniqueIdentifier } from '@dnd-kit/core';

export const iOS =
    typeof window !== 'undefined'
        ? /iPad|iPhone|iPod/.test(navigator.platform)
        : false;

function getDragDepth(offset: number, indentationWidth: number) {
    return Math.round(offset / indentationWidth);
}

let _revertLastChanges = () => { };
export function getProjection<T>(
    items: FlattenedItem<T>[],
    activeId: UniqueIdentifier | null,
    overId: UniqueIdentifier | null,
    dragOffset: number,
    indentationWidth: number,
    keepGhostInPlace: boolean,
    canRootHaveChildren?: boolean | ((dragItem: FlattenedItem<T>) => boolean),
    _canHaveChildren?: boolean | ((dragItem: FlattenedItem<T>) => boolean),
): {
        depth: number;
        parentKey: UniqueIdentifier | null;
        parent: FlattenedItem<T> | null;
        isLast: boolean;
    } | null {
    _revertLastChanges();
    _revertLastChanges = () => { };
    if (!activeId || !overId) return null;

    const overItemIndex = items.findIndex(({ key }) => key === overId);
    const activeItemIndex = items.findIndex(({ key }) => key === activeId);
    const activeItem = items[activeItemIndex];
    if (keepGhostInPlace) {
        let parent: FlattenedItem<T> | null | undefined = items[overItemIndex];
        parent = findParentWhichCanHaveChildren(
            parent,
            activeItem,
            canRootHaveChildren,
            _canHaveChildren
        );
        if (parent === undefined) return null;
        return {
            depth: parent?.depth ?? 0 + 1,
            parentKey: parent?.key ?? null,
            parent,
            isLast: !!parent?.isLast,
        };
    }
    const newItems = arrayMove(items, activeItemIndex, overItemIndex);
    const previousItem = newItems[overItemIndex - 1];
    const nextItem = newItems[overItemIndex + 1];
    const dragDepth = getDragDepth(dragOffset, indentationWidth);
    const projectedDepth = activeItem.depth + dragDepth;

    let depth = projectedDepth;
    const directParent = findParentWithDepth(depth - 1, previousItem);

    const parent = findParentWhichCanHaveChildren(
        directParent,
        activeItem,
        undefined, // canRootHaveChildren,
        _canHaveChildren
    );
    if (parent === undefined) return null;

    const maxDepth = (previousItem?.depth ?? -1) + 1;
    const minDepth = nextItem?.depth ?? 0;
    if (minDepth > maxDepth) return null;
    if (depth >= maxDepth) {
        depth = maxDepth;
    } else if (depth < minDepth) {
        depth = minDepth;
    }

    const isLast = (nextItem?.depth ?? -1) < depth;

    if (parent && parent.isLast) {
        _revertLastChanges = () => {
            parent.isLast = true;
        };
        parent.isLast = false;
    }

    if (depth === 0) {
        const rootCanHaveChildren =
            typeof canRootHaveChildren === 'function'
                ? canRootHaveChildren(activeItem)
                : canRootHaveChildren;
        if (rootCanHaveChildren === false) {
            return null;
        }
    }

    return {
        depth,
        parentKey: getParentKey(),
        parent,
        isLast,
    };

    function findParentWithDepth(depth: number, previousItem: FlattenedItem<T>) {
        if (!previousItem) return null;
        while (depth < previousItem.depth) {
            if (previousItem.parent === null) return null;
            previousItem = previousItem.parent;
        }
        return previousItem;
    }
    function findParentWhichCanHaveChildren(
        parent: FlattenedItem<T> | null,
        dragItem: FlattenedItem<T>,
        canRootHaveChildren?: boolean | ((dragItem: FlattenedItem<T>) => boolean),
        _canHaveChildren?: boolean | ((dragItem: FlattenedItem<T>) => boolean)
    ): FlattenedItem<T> | null | undefined {
        if (!parent) {
            const rootCanHaveChildren =
                typeof canRootHaveChildren === 'function'
                    ? canRootHaveChildren(dragItem)
                    : canRootHaveChildren;
            if (rootCanHaveChildren === false) return undefined;
            return parent;
        }
        const canHaveChildren =
            typeof _canHaveChildren === 'function'
                ? _canHaveChildren(parent)
                : _canHaveChildren;
        // const canHaveChildren =
        //     typeof parent.canHaveChildren === 'function'
        //         ? parent.canHaveChildren(dragItem)
        //         : parent.canHaveChildren;
        if (canHaveChildren === false) {
            return findParentWhichCanHaveChildren(
                parent.parent,
                activeItem,
                canRootHaveChildren,
                _canHaveChildren
            );
        }
        return parent;
    }

    function getParentKey() {
        if (depth === 0 || !previousItem) {
            return null;
        }

        if (depth === previousItem.depth) {
            return previousItem.parentKey;
        }

        if (depth > previousItem.depth) {
            return previousItem.key;
        }

        const newParent = newItems
            .slice(0, overItemIndex)
            .reverse()
            .find((item) => item.depth === depth)?.parentKey;

        return newParent ?? null;
    }
}

export function flatten<T extends Record<string, any>>(
    items: TreeItem<T>[]
): FlattenedItem<T>[] {
    const itemMap = new Map<string, TreeItem<T>>();
    const parentMap = new Map<string, TreeItem<T>[]>();

    // Create a map of items for quick lookup
    items.forEach(item => itemMap.set(item.key?.toString(), item));

    // Populate parentMap
    items.forEach(item => {
        if (item.parentKey) {
            const parent = itemMap.get(item.parentKey.toString());
            if (parent) {
                if (!parentMap.has(item.parentKey.toString())) {
                    parentMap.set(item.parentKey.toString(), []);
                }
                parentMap.get(item.parentKey.toString())!.push(item);
            }
        }
    });

    // Helper function to calculate depth and collect parents' keys
    const calculateDepthAndParents = (item: TreeItem<T>, depth: number, parentsKeys: string[]): [number, string[]] => {
        if (!item.parentKey) return [depth, parentsKeys];

        const parent = itemMap.get(item.parentKey.toString());
        if (parent) {
            const [parentDepth, parentKeys] = calculateDepthAndParents(parent, depth + 1, [item.parentKey.toString(), ...parentsKeys]);
            return [parentDepth, parentKeys];
        }
        return [depth, parentsKeys];
    };

    const levelIndices = new Map<number, number>();

    // Prepare the new items with additional metadata
    const newItems: FlattenedItem<T>[] = items.map((item) => {
        const [depth, parentsKeys] = calculateDepthAndParents(item, 0, []);
        if (!levelIndices.has(depth)) {
            levelIndices.set(depth, 0);
        }
        const index = levelIndices.get(depth)!;
        levelIndices.set(depth, index + 1);

        return {
            ...item,
            depth,
            index,
            isLast: false, // Set after
            parent: null, // Set after
            parentsKeys,
        };
    });

    const result = newItems.map((item, _, arr) => {
        const isLast = !arr.some(i => i.depth === item.depth && i.index > item.index);
        return {
            ...item,
            parent: item.parentKey ? (newItems.find(x => x.key === item.parentKey) ?? null) : null,
            isLast,
        };
    });

    return result;
}

export function flattenTree<T extends Record<string, any>>(
    items: TreeItems<T>
): FlattenedItem<T>[] {
    return flatten(items);
}

export function findItem<T>(items: TreeItem<T>[], itemId: UniqueIdentifier) {
    return items.find(({ key }) => key === itemId);
}

export function findItemDeep<T extends Record<string, any>>(
    items: TreeItems<T>,
    itemKey: UniqueIdentifier
): TreeItem<T> | undefined {
    for (const item of items) {
        if (item.key === itemKey) {
            return item;
        }
    }

    return undefined;
}

export function getChildCount<T extends Record<string, any>>(
    items: TreeItems<T>,
    key: UniqueIdentifier
) {
    if (!key) {
        return 0;
    }

    return items.filter(x => x.parentKey === key).length;
}

export function removeChildrenOf<T>(
    items: FlattenedItem<T>[],
    ids: UniqueIdentifier[]
) {
    const excludeParentKeys = [...ids];

    return items.filter((item) => {
        if (item.parentKey && excludeParentKeys.includes(item.parentKey)) {
            // if (item.children?.length) {
            excludeParentKeys.push(item.key);
            // }
            return false;
        }

        return true;
    });
}

export function getIsOverParent<T>(
    parent: FlattenedItem<T> | null,
    overId: UniqueIdentifier
): boolean {
    if (!parent || !overId) return false;
    if (parent.key === overId) return true;
    return getIsOverParent(parent.parent, overId);
}
