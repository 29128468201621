import { LOGGER_LOG_TYPE } from 'Config';
import Button from 'common/components/button/Button';
import InputError from 'common/components/inputError/InputError';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { TextInputController } from 'common/components/textInput/TextInput';
import { Header, Label } from 'common/components/texts/Texts';
import Loading from 'common/services/Loading';
import Toast from 'common/services/Toast';
import { useState, useEffect } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaExclamationTriangle, FaTrashAlt } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import Logger from 'common/services/Logger';
import { ExternalPartContactDto, ExternalPartDto } from 'api/externalPart/models/ExternalPartDto';
import ExternalPartsService from 'api/externalPart/ExternalPartsService';
import { useSelector } from 'react-redux';
import UsersService from 'api/users/UsersService';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import { SearchableSelectInputController } from 'common/components/searchableSelectInput/SearchableSelectInputController';
import { CountryDto } from 'api/countries/models/CountryDto';
import CountriesService from 'api/countries/CountriesService';
import { PhoneCodeOption } from 'common/components/phoneInput/PhoneInput';
import { MoneyInputController } from 'common/components/moneyInput/MoneyInput';
import { TaxDto } from 'api/taxes/models/TaxDto';
import TaxesService from 'api/taxes/TaxesService';
import ExternalPartCategoriesService from 'api/externalPartCategory/ExternalPartCategoriesService';
import { MultiLineDynamicListController } from 'common/components/multiLineDynamicList/MultiLineDynamicList';
import ContactsSelector from 'common/components/contactsSelector/ContactsSelector';
import WorksList from './works/WorksList';
import styles from './ExternalPartScreen.module.scss'
import colors from 'styles/export/colors.module.scss';
import { Form } from 'common/components/form/Form';

type TabKey = 'infos' | 'works';

function ExternalPartScreen(): JSX.Element | null {
    const { id, type, categoryId } = useParams<{ id: string, type: string, categoryId: string }>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [categoryName, setCategoryName] = useState<string>('');

    const [currentTab, setCurrentTab] = useState<TabKey>('infos');

    const form = useForm<ExternalPartDto>({ shouldUnregister: false });
    const errors = form.formState.errors;

    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['EXTERNAL_PARTS_READ'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['EXTERNAL_PARTS_WRITE'])

    const [countries, setCountries] = useState<CountryDto[]>([]);
    const phoneCodesOptions: PhoneCodeOption[] = countries.filter(x => x.phoneCode).map(p => ({ value: p.id, name: p.isoCodeSmall, phoneCode: p.phoneCode }));

    const url = `/external-parts/${categoryId}`;
    const currentUrl = url + '/' + type + (id ? ('/' + id) : '');

    useEffect(() => {
        void getData()
    }, [id, type]);

    const getData = async () => {
        Loading.show();
        try {
            let result: Partial<ExternalPartDto> = { id, companyName: '' };
            if (id) {
                result = await ExternalPartsService.getById(id);
            }
            form.reset(result);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get external part information', error);
            Toast.error(t('messages.error_load_info'));
        }
    };

    const onLoadCountriesOptions = (
        inputValue: string,
        callback: (options: any[], hasMore: boolean) => void
    ) => {
        CountriesService.getCatalog().then(result => {
            const options: CountryDto[] = result.map(item => ({ ...item, label: item.name, value: item.id }))

            setCountries(options);
            callback(options, false);
        }).catch((error) => {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get countries', error);
            Toast.error(t('messages.error_load_info'));
        });
    };

    const onLoadTaxOptions = (
        inputValue: string,
        callback: (options: any[], hasMore: boolean) => void) => {
        TaxesService.getFormattedCatalog().then(result => {
            const options: TaxDto[] = result.map(item => ({ ...item, label: item.description, value: item.id } as any))

            callback(options, false);
        }).catch((error) => {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get taxes', error);
            Toast.error(t('messages.error_load_info'));
        });
    };

    useEffect(() => {
        void getExternalPartCategory();
    }, [categoryId]);

    const getExternalPartCategory = async () => {
        try {
            if (!categoryId) { return; }
            Loading.show();
            const result = await ExternalPartCategoriesService.getById(categoryId);
            setCategoryName(result.name);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get external part category', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/external-parts/${categoryId}/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate(`/external-parts/${categoryId}`);
        }
    }

    const onSubmit = async (model: ExternalPartDto) => {
        try {
            Loading.show();

            if (categoryId) {
                model.externalPartCategoryId = categoryId;
            }

            if (model && model.id) {
                await ExternalPartsService.update(model)
                // navigateTo('details', id);
                void getData();
            } else if (model) {
                const id = await ExternalPartsService.create(model)
                navigateTo('edit', id);
            }

            Loading.hide();
            Toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the external part with id: ${id}`, error);
            Toast.error(t('messages.record_save_error'));

            Loading.hide();
        }
    };

    const onInvalid = () => {
        Toast.warning(t('messages.required_fields_empty'));
        setCurrentTab('infos');
    }

    const onDelete = async (result: boolean) => {
        if (!result) {
            setShowConfirmDeleteModal(false)
            return;
        }

        Loading.show();
        try {
            await ExternalPartsService.remove(form.getValues())
            Toast.success(t('messages.record_delete_success'));
            navigateTo();
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete external part', error);
            Toast.error(t('messages.record_delete_error'));
        } finally {
            Loading.hide();
            setShowConfirmDeleteModal(false)
        }
    }

    const renderContactItem = (contact: any, index: any) => (
        <ContactsSelector
            index={index}
            key={contact.id}
            item={contact}
            phoneCodesOptions={phoneCodesOptions}
            onChange={(items: ExternalPartContactDto[]) => {
                return form.setValue('contacts', items);
            }}
            form={form}
            errors={errors}
            disabled={isDetails} />
    );

    const title = (type === 'create' ? t('common.new') : (type === 'edit' ? t('common.edit') : t('common.details'))) + ' ' + t('external_parts.title');

    if (!canRead) {
        return null;
    }

    const CustomTab = () => (
        <div>
            {(errors.companyName || errors.address || errors.zipCode || errors.city || errors.defaultDiscount) && <span className={styles.alertIconContent}><FaExclamationTriangle fontSize={19} color={colors.warning} /></span>}
            <span>{t('common.details')}</span>
        </div>
    );

    return (
        <ScreenTitle title={t('external_parts.title')}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: categoryName, url },
                    { name: title, url: currentUrl },
                ]}
            />

            <PageHeader title={title} onGoBack={() => navigateTo()} informationText={t('common.go_back')}>
                {canWrite && !isDetails && <Button form='externalPartForm' type='submit'>{t('common.save')}</Button>}
                {canWrite && isDetails && <Button fw onClick={() => { navigateTo('edit', id); }}>{t('common.edit')}</Button>}
                {canWrite && isDetails && (
                    <Button variant='danger' onClick={() => setShowConfirmDeleteModal(true)}>
                        <FaTrashAlt />
                    </Button>
                )}
            </PageHeader>
            <PageContainer>
                <FormProvider {...form}>
                    <Form id="externalPartForm" handleSubmit={form.handleSubmit} onSubmit={onSubmit} onInvalid={onInvalid} noValidate className={styles.form}>
                        <Tabs activeKey={currentTab} onSelect={(k) => setCurrentTab(k as TabKey)}>
                            <Tab eventKey={'infos'} title={<CustomTab />} className={styles.tabContainer}>
                                <Row>
                                    <Col xs={12} className="mb-3">
                                        <Label space>{t('external_parts.list.company_name')}{!isDetails ? '*' : ''}</Label>
                                        <TextInputController
                                            name='companyName' control={form.control}
                                            placeholder={t('external_parts.list.company_name')} disabled={isDetails}
                                            rules={{ required: true, maxLength: 250 }}
                                            hasError={Boolean(errors.companyName)} />
                                        <InputError error={errors.companyName} maxLength={250} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className="mb-3">
                                        <Label space>{t('external_parts.list.address')}</Label>
                                        <TextInputController
                                            name='address' control={form.control}
                                            placeholder={t('external_parts.list.address')} disabled={isDetails}
                                            rules={{ maxLength: 250 }}
                                            hasError={Boolean(errors.address)} />
                                        <InputError error={errors.address} maxLength={250} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6} className="mb-3">
                                        <Label space>{t('external_parts.list.zip_code')}</Label>
                                        <TextInputController
                                            name='zipCode' control={form.control}
                                            placeholder={t('external_parts.list.zip_code')} disabled={isDetails}
                                            rules={{ maxLength: 250 }}
                                            hasError={Boolean(errors.zipCode)} />
                                        <InputError error={errors.zipCode} maxLength={250} />
                                    </Col>
                                    <Col sm={12} md={6} className="mb-3">
                                        <Label space>{t('external_parts.list.city')}</Label>
                                        <TextInputController
                                            name='city' control={form.control}
                                            placeholder={t('external_parts.list.city')} disabled={isDetails}
                                            rules={{ maxLength: 250 }}
                                            hasError={Boolean(errors.city)} />
                                        <InputError error={errors.city} maxLength={250} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className="mb-3">
                                        <Label space>{t('external_parts.list.country')}</Label>
                                        <SearchableSelectInputController
                                            name='countryId'
                                            control={form.control}
                                            loadOptions={onLoadCountriesOptions}
                                            disabled={isDetails}
                                            hasError={Boolean(errors.countryId)}
                                        />
                                        <InputError error={errors.countryId} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6} className="mb-3">
                                        <Label space>{t('external_parts.list.tax')}</Label>
                                        <SearchableSelectInputController
                                            name='taxId'
                                            control={form.control}
                                            loadOptions={onLoadTaxOptions}
                                            disabled={isDetails}
                                            hasError={Boolean(errors.taxId)}
                                        />
                                        <InputError error={errors.taxId} />
                                    </Col>
                                    <Col sm={12} md={6} className="mb-3">
                                        <Label space>{t('external_parts.list.default_discount')}</Label>
                                        <MoneyInputController
                                            name='defaultDiscount'
                                            control={form.control}
                                            unitPrefix='%'
                                            disabled={isDetails} />
                                        <InputError error={errors.defaultDiscount} maxLength={250} />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Header space>{t('external_parts.contacts')}</Header>
                                    <Col>
                                        <MultiLineDynamicListController
                                            control={form.control}
                                            name='contacts'
                                            onChange={() => { }}
                                            disabled={isDetails}
                                            renderItem={renderContactItem}
                                            labelAdd={t('external_parts.contact.add_contact')}
                                            labelEmptyList={t('external_parts.contact.without_contacts')}
                                        />
                                    </Col>
                                </Row>
                            </Tab>
                            {id && <Tab eventKey={'works'} title={t('works.list.title')}>
                                <WorksList externalPartId={id} />
                            </Tab>}
                        </Tabs>
                    </Form>
                </FormProvider>

            </PageContainer>
            <ConfirmDeleteModal
                itemName={form.getValues('companyName')}
                isOpen={showConfirmDeleteModal}
                onClose={(result) => onDelete(result)}
            />

        </ScreenTitle >
    )
}

export default ExternalPartScreen;
