import { BsLayoutThreeColumns } from 'react-icons/bs';
import styles from './ColumnsOptionsSidebar.module.scss';
import { useTranslation } from 'react-i18next';
import { FaEye } from 'react-icons/fa';
import { useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { IoMoveOutline } from 'react-icons/io5';
import { StrictModeDroppable } from 'common/components/strictModeDroppable/StrictModeDroppable';

export function columnsReorder<TRow>(list: ColumnsOptionsSidebarColumn<TRow>[], startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export interface ColumnsOptionsSidebarColumn<TRow> {
    field: TRow;
    name: string;
}

export interface ColumnsOptionsSidebarColumnrder<TRow> {
    i: number;
    field: TRow;
}

interface Props<TRow> {
    columns: ColumnsOptionsSidebarColumn<TRow>[];
    hiddenColumns: TRow[];
    canDrag?: boolean;
    onChangeHiddenColumns: (hiddenColumns: TRow[]) => void;
    onChangeColumnsOrder: (columnsOrder: ColumnsOptionsSidebarColumnrder<TRow>[]) => void;
}

const ColumnsOptionsSidebar = <TRow extends any>({ columns, hiddenColumns, canDrag = true, onChangeHiddenColumns, onChangeColumnsOrder }: Props<TRow>) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const onClickItem = (col: ColumnsOptionsSidebarColumn<TRow>) => {
        if (hiddenColumns.find(c => c === col.field)) {
            onChangeHiddenColumns(hiddenColumns.filter(c => c !== col.field));
        } else {
            onChangeHiddenColumns([...hiddenColumns, col.field]);
        }
    }

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const items = columnsReorder(
            [...columns],
            result.source.index,
            result.destination.index
        );
        onChangeColumnsOrder(items.map((col, i) => ({ i, field: col.field })));
    }

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        userSelect: 'none',
        padding: '0.5rem',
        ...draggableStyle
    });

    return (
        <div className={`${styles.sidebar} ${isOpen && styles.isOpen}`}>
            <div className={styles.toggle} onClick={() => setIsOpen(o => !o)}>
                <BsLayoutThreeColumns />
            </div>
            <div className={styles.content}>
                <div className={styles.title}>
                    {t('common.visible_columns')}
                </div>
                <div className={styles.list}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <StrictModeDroppable droppableId="droppable" isDropDisabled={!canDrag}>
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {columns.map((col, index) => {
                                        const isHidden = hiddenColumns.find(c => c === col.field);
                                        return (
                                            <Draggable key={col.field as string} draggableId={col.field as string} index={index} isDragDisabled={!canDrag}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <div
                                                            key={col.field as string}
                                                            className={`${styles.listItem} ${(!isHidden && styles.listItemActive)}`}
                                                            onClick={() => onClickItem(col)}
                                                        >
                                                            <span className={styles.itemIcon}>
                                                                <FaEye />
                                                            </span>
                                                            <span className={styles.itemText}>{col.name}</span>
                                                            {canDrag && <span {...provided.dragHandleProps} className={styles.columnConfigCursor}>
                                                                <IoMoveOutline />
                                                            </span>}
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>
                </div>
            </div>
        </div>
    );
}

export default ColumnsOptionsSidebar;
