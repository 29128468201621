import { useCallback } from 'react';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import styles from './FileInput.module.scss';
import { LuUpload } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';
import { AttachmentDto } from 'api/common/models/AttachmentDto';
import CustomFile from 'common/models/CustomFile';
import { Accept, useDropzone } from 'react-dropzone';
import { FaTrashAlt } from 'react-icons/fa';
import Utils from 'common/services/Utils';

interface FileInputProps {
    value: AttachmentDto | undefined | null;
    hasError?: boolean;
    containerName?: string;
    accept?: Accept;
    disabled?: boolean;
    className?: string,
    onChange: (file: AttachmentDto | null) => void;
}

const FileInput = ({ value, hasError, accept, disabled, className, containerName = '', onChange, ...props }: FileInputProps) => {
    const { t } = useTranslation();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (!acceptedFiles.length) {
            return;
        }

        const customFile: CustomFile = acceptedFiles[0] as CustomFile;
        customFile.containerName = containerName;
        const newFile: AttachmentDto = {
            id: null,
            key: Utils.newGuid(),
            tempFile: customFile,
            contentType: customFile.type,
            name: customFile.name,
            size: customFile.size,
            url: URL.createObjectURL(customFile),
        };
        onChange(newFile);
    }, [onChange])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept,
        disabled,
        noDrag: true,
    });

    const onRemove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        onChange(null);
    }

    const openFile = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (value?.url) {
            e.preventDefault();
            e.stopPropagation();
            window.open(value.url, '_blank');
        }
    }

    return (
        <div className={`${styles.container} ${disabled ? styles.disabled : ''} ${className ?? ''}`} {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={`${styles.inputFill} ${hasError ? styles.hasError : ''}`} onClick={openFile}>
                {Boolean(value) && <span className={styles.name} >{value!.name}</span>}
                {Boolean(!value) && <span className={styles.placeholder}>{t('common.click_to_add_file')}</span>}
            </div>
            {Boolean(value && !disabled) && (
                <div className={`${styles.sideButton} ${styles.sideButtonExtraLeft}`} onClick={onRemove}>
                    <FaTrashAlt />
                </div>
            )}
            <div className={`${styles.sideButton} ${value && !disabled ? styles.sideButtonExtraRight : ''}`}>
                <LuUpload className={styles.icon} />
            </div>
        </div>
    );
};

export default FileInput;

type InputControllerProps<T extends FieldValues> = FileInputProps & UseControllerProps<T>;

export const FileInputController = <T extends FieldValues>(props: InputControllerProps<T>) => {
    const { field } = useController({ ...props, disabled: false });
    return (
        <FileInput
            {...props}
            value={field.value ?? null}
            onChange={(e) => {
                field.onChange(e);
                if (props.onChange) {
                    props.onChange(e);
                }
            }}
        />
    );
};
