import { BudgetDto } from 'api/budgets/models/BudgetDto';
import MoneyInput from 'common/components/moneyInput/MoneyInput';
import { Label } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { calculateBudgetTotals, updateBudget } from 'store/budget/action';
import { Reducers } from 'store/types';
import styles from './BudgetTotals.module.scss';
import { useContext } from 'react';
import SelectInput from 'common/components/selectInput/SelectInput';
import { DEFAULT_DECIMAL_PLACES } from 'Config';
import { LinesDataProvider } from '../../lines/LinesDataProvider';

interface Props {
    disabled: boolean;
}

function BudgetTotals({ disabled }: Props): JSX.Element | null {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { taxes } = useContext(LinesDataProvider);
    const budget = useSelector<Reducers, Partial<BudgetDto>>(x => x.budget.budget);
    const decimalPlaces = useSelector<Reducers, number>(x => x.budget.budget.decimalPlaces ?? DEFAULT_DECIMAL_PLACES);
    const taxesOptions = taxes.map(t => ({ selectedLabel: t.value?.toString() + ' %', label: t.description, value: t.id }));

    const onChange = (newBudget: Partial<BudgetDto>) => {
        dispatch(updateBudget(newBudget));
        dispatch(calculateBudgetTotals());
    }

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.labelContainer}>
                    <Label>{t('budgets.budget.lines.totals.total_net_without_tax')}</Label>
                </div>
                <div className={styles.inputContainer}>
                    <MoneyInput
                        value={budget.totalNetExcludingTax}
                        unitPrefix='€'
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.labelContainer}>
                    <Label>{t('budgets.budget.lines.totals.discount')}</Label>
                </div>
                <div className={styles.inputContainer}>
                    <MoneyInput
                        value={budget.discount}
                        unitPrefix='€'
                        decimalScale={decimalPlaces}
                        onChange={v => onChange({ discount: v ?? 0 })}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.labelContainer}>
                    <Label>{t('budgets.budget.lines.totals.discount_tax')}</Label>
                </div>
                <div className={styles.inputContainer}>
                    <SelectInput
                        options={taxesOptions}
                        value={budget.discountTaxId}
                        onChange={v => onChange({ discountTaxId: v, discountTaxValue: taxes.find(x => x.id === v)?.value ?? 0 })}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.labelContainer}>
                    <Label>{t('budgets.budget.lines.totals.total_without_tax')}</Label>
                </div>
                <div className={styles.inputContainer}>
                    <MoneyInput
                        value={budget.totalExcludingTax}
                        unitPrefix='€'
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.labelContainer}>
                    <Label>{t('budgets.budget.lines.totals.total_tax')}</Label>
                </div>
                <div className={styles.inputContainer}>
                    <MoneyInput
                        value={budget.totalTax}
                        unitPrefix='€'
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.labelContainer}>
                    <Label>{t('budgets.budget.lines.totals.total_with_tax')}</Label>
                </div>
                <div className={styles.inputContainer}>
                    <MoneyInput
                        value={budget.totalIncludingTax}
                        unitPrefix='€'
                        decimalScale={decimalPlaces}
                        disabled
                    />
                </div>
            </div>
        </div>
    );
}

export default BudgetTotals;
