import { DATE_TIME_FORMAT_DEFAULT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { UserProfile } from 'api/account/models/UserProfile';

import UsersService from 'api/users/UsersService';
import ListingTable from 'common/components/listingTable/ListingTable';
import ListingTableCache, { ListingTableData } from 'common/components/listingTable/ListingTableCache';
import TableActionButton from 'common/components/listingTable/actionButton/TableActionButton';
import { OrderData, ListingTableColumn, FilterData } from 'common/components/listingTable/models';
import ListingTableSidebar from 'common/components/listingTable/sidebar/ListingTableSidebar';
import { orderColumns } from 'common/components/listingTable/tableUtils';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Loading from 'common/services/Loading';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Logger from 'common/services/Logger';
import { Reducers } from 'store/types';
import Toast from 'common/services/Toast';
import Button from 'common/components/button/Button';
import { WorkTypeDto } from 'api/workTypes/models/WorkTypeDto';
import { WorkTypeSearchCriteria } from 'api/workTypes/models/WorkTypeSearchCriteria';
import WorkTypesService from 'api/workTypes/WorkTypesService';
import DateFormat from 'common/components/dateFormat/dateFormat';
import YesNoModal from 'common/components/modal/yesNoModal/YesNoModal';

const PAGE_ID = 'LIBRARY_WORKTYPES_LIST';

function WorkTypesListScreen(): JSX.Element | null {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<ListingTableData<WorkTypeDto, Partial<WorkTypeSearchCriteria>>>(ListingTableCache.get(PAGE_ID, {
        order: { field: 'name', orderColumn: 'w.name', isOrderAsc: true },
        hiddenColumns: [],
        columnsOrder: [],
        columnsWidth: [],
        page: 1,
        filters: {},
        lastRowId: null,
    }));
    const [workTypes, setworkTypes] = useState<WorkTypeDto[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0)
    const [workTypeToDelete, setworkTypeToDelete] = useState<WorkTypeDto | null>(null);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmDeleteRelationsModal, setShowConfirmDeleteRelationsModal] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['WORK_TYPES_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['WORK_TYPES_READ'])
    const [refreshKey, setRefreshKey] = useState(0);
    const [scrollToRowId, setScrollToRowId] = useState<string | null>(null);
    const firstLoad = useRef(true);

    const columns: ListingTableColumn<WorkTypeDto>[] = orderColumns([
        {
            name: t('work_types.list.name'),
            field: 'name',
            filter: { type: 'text', value: tableData.filters.name ?? '', placeholder: t('work_types.list.name') },
            minWidth: '18rem',
        },
        {
            name: t('common.updated_date'),
            field: 'updatedDate',
            filter: {
                type: 'date-range',
                value: {
                    start: tableData.filters.updatedDate?.start ? new Date(tableData.filters.updatedDate.start) : null,
                    end: tableData.filters.updatedDate?.end ? new Date(tableData.filters.updatedDate.end) : null
                }
            },
            minWidth: '22rem',
            orderColumn: 'updated_date',
            renderCell: (row) => {
                return row.updatedDate ? <DateFormat value={row.updatedDate} format={DATE_TIME_FORMAT_DEFAULT} /> : '';
            }
        },
        {
            name: t('common.user_updated'),
            field: 'userUpdatedName',
            filter: { type: 'text', value: tableData.filters.userUpdatedName ?? '', placeholder: t('common.user_updated') },
            minWidth: '21rem',
            orderColumn: 'user_updated_name',
        },
    ], tableData.columnsOrder);

    const renderAction = (row: WorkTypeDto) => (
        <TableActionButton
            options={[
                { label: <span><FaEdit /> {t('common.edit')}</span>, onClick: () => navigateTo('edit', row.id) },
                { label: <span><FaTrash /> {t('common.delete')}</span>, onClick: () => onOpenModalToDelete(row) },
            ]}
        />
    )

    const onFilter = (col: ListingTableColumn<WorkTypeDto>, filterData: FilterData) => {
        setTableData(d => {
            const data = ({
                ...d, page: 1
            })
            if (col.field === 'name') {
                data.filters = {
                    ...data.filters,
                    name: filterData.value as string,
                };
            }
            if (col.field === 'updatedDate') {
                data.filters = {
                    ...data.filters,
                    updatedDate: filterData.value,
                    updatedDateStart: filterData.value?.start as Date,
                    updatedDateEnd: filterData.value?.end as Date
                };
            }

            if (col.field === 'userUpdatedName') {
                data.filters = {
                    ...data.filters,
                    userUpdatedName: filterData.value as string
                };
            }
            return data;
        });
        setRefreshKey(k => k + 1);
    }

    const onClearFilters = () => {
        setTableData(d => ({ ...d, page: 1, filters: {}, lastRowId: null }));
        setRefreshKey(k => k + 1);
    }

    const onOrder = (orderData: OrderData<WorkTypeDto>) => {
        setRefreshKey(k => k + 1);
        setTableData(d => ({ ...d, order: orderData, page: 1 }));
    }

    useEffect(() => {
        void getData();
    }, [tableData.page, tableData.filters, tableData.order, refreshKey]);

    useEffect(() => {
        ListingTableCache.save(PAGE_ID, tableData);
    }, [tableData]);

    const getData = async () => {
        try {
            if (isLoading) {
                return;
            }

            Loading.show();
            setIsLoading(true);

            const data = await WorkTypesService.getList({
                ...tableData.filters,
                page: firstLoad.current && tableData.page > 1 ? 1 : tableData.page,
                itemsPerPage: firstLoad.current && tableData.page > 1 ? DEFAULT_PAGINATION_ITEMS_PER_PAGE * tableData.page : DEFAULT_PAGINATION_ITEMS_PER_PAGE,
                orderColumn: tableData.order.orderColumn,
                orderBy: tableData.order.isOrderAsc ? 'asc' : 'desc',
            });

            const newListElements = data.currentPage === 1 ? data.items : [...workTypes, ...data.items]
            setHasMore(data.totalItems > newListElements.length);
            setworkTypes(newListElements);
            setTotalItems(data.totalItems)

            if (firstLoad.current) {
                setScrollToRowId(tableData.lastRowId);
            }
            firstLoad.current = false;
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get work types list', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
            setIsLoading(false);
        }
    }

    const navigateTo = (type: string, id?: string) => {
        if (type === 'create') {
            navigate(`/library/work-types/${type}`);
        } else if (id) {
            ListingTableCache.saveLastRowId(PAGE_ID, id);
            navigate(`/library/work-types/${type}/${id}`);
        }
    }

    const onLoadMore = () => {
        setTableData(d => ({ ...d, page: d.page + 1 }));
    }
    const onOpenModalToDelete = (row: WorkTypeDto) => {
        setworkTypeToDelete(row);
        setShowConfirmDeleteModal(true)
    }

    const onDelete = async (result: boolean) => {
        if (!showConfirmDeleteModal) {
            return;
        }

        setShowConfirmDeleteModal(false);

        if (result) {
            setShowConfirmDeleteRelationsModal(true);
        }
    }

    const onDeleteRelations = async (result: boolean) => {
        Loading.show();
        try {
            await WorkTypesService.delete(workTypeToDelete?.id ?? '', result)

            setTableData(d => ({ ...d, page: 1 }));
            setRefreshKey(k => k + 1);

            Toast.success(t('messages.record_delete_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete work type', error);
            Toast.error(t('messages.record_delete_error'));
        } finally {
            Loading.hide();
            setShowConfirmDeleteRelationsModal(false)
        }
    }

    if (!canRead) {
        return null;
    }

    return (
        <ScreenTitle title={t('work_types.title')}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: t('work_types.title'), url: '/library/work-types' },
                ]}
            />
            <PageHeader title={t('work_types.title')} subTitle={`${totalItems} ${t('common.total_results')}`} showGoBack={false} addSidebarSpacing informationText={t('common.list')} >
                {canWrite && <Button onClick={() => navigateTo('create')}>{t('common.new')}</Button>}
            </PageHeader>
            <PageContainer>
                <ListingTable<WorkTypeDto>
                    columns={columns}
                    rows={workTypes}
                    order={tableData.order}
                    hiddenColumns={tableData.hiddenColumns}
                    columnsWidths={tableData.columnsWidth}
                    onOrder={onOrder}
                    onFilter={onFilter}
                    onClearFilters={onClearFilters}
                    onRenderAction={canWrite ? renderAction : undefined}
                    onRowClick={(row) => navigateTo('details', row.id)}
                    infiniteScroll={{
                        isLoading,
                        hasMore,
                        onLoadMore,
                    }}
                    onRowId={r => r.id}
                    scrollToRowId={scrollToRowId}
                    onColResize={(w) => setTableData(d => ({ ...d, columnsWidth: w }))}
                />
            </PageContainer>
            <ListingTableSidebar
                columns={columns}
                hiddenColumns={tableData.hiddenColumns}
                onChangeHiddenColumns={(hc) => setTableData(d => ({ ...d, hiddenColumns: hc }))}
                onChangeColumnsOrder={(oc) => setTableData(d => ({ ...d, columnsOrder: oc }))}
            />
            <ConfirmDeleteModal
                itemName={workTypeToDelete?.name}
                isOpen={showConfirmDeleteModal}
                onClose={(result) => onDelete(result)}
            />
            <YesNoModal
                title={t('common.question')}
                message={t('work_types.delete_relations')}
                onClose={onDeleteRelations}
                isOpen={showConfirmDeleteRelationsModal}
            />
        </ScreenTitle>
    );
}

export default WorkTypesListScreen;
