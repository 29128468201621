import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import { AtLeast } from 'common/types/Atleast';
import styles from './BudgetNoteLine.module.scss';
import utilsStyles from '../../Utils.module.scss';
import { lineActiveStyle } from '../../utils';
import RichTextInput from 'common/components/richTextInput/RichTextInput';
import { forwardRef, Ref, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';

interface Props {
    line: BudgetLineDto;
    disabled: boolean;
    level: number;
    isActive: boolean;
    onChange: (line: AtLeast<BudgetLineDto, 'key'>, calculateTotals: boolean) => void;
}

function BudgetNoteLine({ line, disabled, isActive, level, onChange }: Props, ref: Ref<HTMLDivElement>): JSX.Element | null {
    const inputRef = useRef<ReactQuill>(null);

    const onChangeValue = (newLine: Partial<BudgetLineDto>) => {
        onChange({ key: line.key, ...newLine }, false);
    }

    const focusInput = () => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current?.focus();
            }
        }, 100);
    }

    useEffect(() => {
        if (isActive) {
            focusInput();
        }
    }, [isActive])

    return (
        <div
            className={styles.note}
            style={lineActiveStyle(isActive)}
            ref={ref}
        >
            <div className={`${utilsStyles.column} ${utilsStyles.columnAlignLeft} ${utilsStyles.designationColumn} ${styles.designationColumn}`}>
                <div className={styles.input}>
                    <RichTextInput
                        value={line.designation}
                        onChange={v => onChangeValue({ designation: v ?? '' })}
                        isInline
                        ref={inputRef}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
}

export default forwardRef<HTMLDivElement, Props>(BudgetNoteLine);
