import React from 'react';
import ReactModal from 'react-modal';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../Modal';
import { Header, Text } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/button/Button';
import { FaTrash } from 'react-icons/fa';

type Props = {
    itemName?: string;
    message?: string;
    secundaryMessage?: string;
    onClose: (del: boolean) => void;
} & ReactModal.Props;

const ConfirmDeleteModal: React.FC<Props> = ({ message,secundaryMessage, itemName, onClose, ...props }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal {...props} style={{ content: { minWidth: 400 } }}>
            <div>
                <ModalHeader>
                    <Header>{t('common.delete').toUpperCase()}</Header>
                </ModalHeader>
                <ModalBody>
                    <Text>{message || (t('messages.remove_question') + (itemName ? (' ' + itemName) : '') + '?')}</Text>
                    {secundaryMessage && <Text>{secundaryMessage}</Text>}
                </ModalBody>
                <ModalFooter className="text-right">
                    <Button fw variant='secondary' onClick={() => onClose(false)}>{t('common.cancel')}</Button>
                    <Button variant="danger" onClick={() => onClose(true)}><FaTrash /> {t('common.delete')}</Button>
                </ModalFooter>
            </div>
        </Modal >
    );
};

export default ConfirmDeleteModal;
