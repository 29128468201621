import { BudgetLineType } from 'api/budgets/enums/BudgetLineType';
import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import BudgetSectionLine from './section/BudgetSectionLine';
import BudgetElementLine from './element/BudgetElementLine';
import BudgetPageBreakLine from './pageBreak/BudgetPageBreakLine';
import BudgetNoteLine from './note/BudgetNoteLine';
import styles from './BudgetLine.module.scss';

import BudgetLineMenu from '../lineMenu/BudgetLineMenu';
import { forwardRef, Ref, useState } from 'react';
import { getLevelFromParentKeys, isLastInLevel, isLineBeingCut, lineInnerActiveStyle, lineInnerLevelStyle, lineLevelStyle, lineOutterLineStyle } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { addLine, calculateBudgetTotals, calculateLineTotals, cloneLine, copyLine, cutLine, moveLineDown, moveLineUp, pasteLine, removeLine, setSelectedLineKey, updateLine } from 'store/budget/action';
import { AtLeast } from 'common/types/Atleast';
import { AddLinePlacement } from 'store/budget/type';
import Utils from 'common/services/Utils';
import store from 'store/store';
import { TreeItemComponentProps } from 'common/components/sortableTree/types';
import clsx from 'clsx';
import useIsMobileOrTablet from 'common/hooks/useDeviceType';

export const BUDGET_LINE_ID_PREFIX = 'budgetLine_';

function BudgetLine({
    item: line,
    depth: level,
    handleProps,
    clone,
    ghost,
    disableInteraction,
    disableSelection,
    style,
    parentsKeys,
    childCount,
    disabled,
    wrapperRef,
}: TreeItemComponentProps<BudgetLineDto>, ref: Ref<HTMLDivElement>): JSX.Element | null {
    const selectedLineKey = useSelector<Reducers, string | null>(x => x.budget.selectedLineKey);
    const totalRootSections = useSelector<Reducers, number>(x => x.budget.lines.filter(l => l.lineType === BudgetLineType.SECTION).length);
    const isLastInSelectedLevel = useSelector<Reducers, boolean>(x => x.budget.selectedLineKey != null ? isLastInLevel(x.budget.lines, x.budget.selectedLineKey, line.key) : false);
    const dispatch = useDispatch();
    const isActive = selectedLineKey === line.key;
    const [isEditing, setIsEditing] = useState((line.lineType === BudgetLineType.ELEMENT || line.lineType === BudgetLineType.COMPONENT_ELEMENT) && !line.elementId);
    const isMobileOrTablet = useIsMobileOrTablet();
    const isBeingCut = useSelector<Reducers, boolean>(x => x.budget.copyType === 'cut' && x.budget.copiedLineKey != null && isLineBeingCut(x.budget.lines, line.key, x.budget.copiedLineKey));
    const copiedOrCutAnyLine = useSelector<Reducers, boolean>(x => x.budget.copiedLineKey != null);
    const copiedLineKey = useSelector<Reducers, string | null>(x => x.budget.copiedLineKey);

    const onClickLine = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (line.key !== store.getState().budget.selectedLineKey) {
            dispatch(setSelectedLineKey(line.key));
        }
    }

    const onAddedElement = () => {
        setIsEditing(false);
    }

    const onClone = () => {
        dispatch(cloneLine(line.key));
        dispatch(calculateLineTotals(line.key));
    }

    const onCopy = () => {
        dispatch(copyLine(copiedLineKey === line.key ? null : line.key));
    }

    const onCut = () => {
        dispatch(cutLine(copiedLineKey === line.key ? null : line.key));
    }

    const onPaste = () => {
        const newLineKey = Utils.newGuid();
        dispatch(pasteLine(line.key, newLineKey));
        dispatch(calculateLineTotals(line.key));
        dispatch(setSelectedLineKey(newLineKey));

        if (line.lineType === BudgetLineType.SECTION) {
            dispatch(updateLine({
                key: line.key,
                isOpen: true,
            }));
        }
    }

    const onAdd = (lineType: BudgetLineType, placement: AddLinePlacement) => {
        const newLineKey = Utils.newGuid();
        dispatch(addLine(lineType, placement, line.key, newLineKey));

        if (line.lineType === BudgetLineType.SECTION) {
            dispatch(updateLine({
                key: line.key,
                isOpen: true,
            }));
        }

        dispatch(setSelectedLineKey(newLineKey));
    }

    const onChange = (newLine: AtLeast<BudgetLineDto, 'key'>, calculateTotals: boolean) => {
        dispatch(updateLine(newLine));

        if (calculateTotals) {
            dispatch(calculateLineTotals(line.key));
        }
    }

    const onRemove = () => {
        dispatch(removeLine(line.key));
        dispatch(setSelectedLineKey(null));
        if (line.parentKey) {
            dispatch(calculateLineTotals(line.parentKey));
        } else {
            dispatch(calculateBudgetTotals());
        }
    }

    const onToggleDataSheet = () => {
        onChange({ key: line.key, enableDataSheet: !line.enableDataSheet }, false);
    }

    const onMoveUp = () => {
        dispatch(moveLineUp(line.key));
        if (line.parentKey) {
            dispatch(calculateLineTotals(line.parentKey));
        }
    }

    const onMoveDown = () => {
        dispatch(moveLineDown(line.key));
        if (line.parentKey) {
            dispatch(calculateLineTotals(line.parentKey));
        }
    }

    let content: JSX.Element | null;

    switch (line.lineType) {
        case BudgetLineType.SECTION:
            content = <BudgetSectionLine
                ref={ref}
                line={line}
                disabled={disabled}
                level={level}
                isActive={isActive}
                onChange={onChange}
                childCount={childCount ?? 0}
            />
            break;
        case BudgetLineType.ELEMENT:
            content = <BudgetElementLine
                ref={ref}
                line={line}
                disabled={disabled}
                level={level}
                isActive={isActive}
                isEditing={isEditing}
                onChange={onChange}
                onAddedElement={onAddedElement}
                isComponentElement={false}
            />
            break;
        case BudgetLineType.COMPONENT_ELEMENT:
            content = <BudgetElementLine
                ref={ref}
                line={line}
                disabled={disabled}
                level={level}
                isActive={isActive}
                isEditing={isEditing}
                onChange={onChange}
                onAddedElement={onAddedElement}
                isComponentElement={true}
            />
            break;
        case BudgetLineType.PAGE_BREAK:
            content = <BudgetPageBreakLine
                ref={ref}
                line={line}
                disabled={disabled}
                level={level}
                isActive={isActive}
                onChange={onChange}
            />
            break;
        case BudgetLineType.NOTE:
            content = <BudgetNoteLine
                ref={ref}
                line={line}
                disabled={disabled}
                level={level}
                isActive={isActive}
                onChange={onChange}
            />
            break;
        default:
            throw new Error(`Couldn't find a line type with the name '${line.lineType}'`);
    }

    if (clone) {
        return null;
    }

    const parentIsSelected = selectedLineKey != null ? parentsKeys.includes(selectedLineKey) : false;
    const levelsUntilParent = selectedLineKey != null && parentIsSelected ? getLevelFromParentKeys(selectedLineKey, parentsKeys) : 0;

    const borderTopLeftWithoutRadius = (isActive && (
        !disabled ||
        ((line.lineType === BudgetLineType.ELEMENT || line.lineType === BudgetLineType.COMPONENT_ELEMENT) && Boolean(line.elementId)) ||
        (isMobileOrTablet && !disabled)))
        || !isActive;

    return (
        <div
            className={clsx(
                styles.line,
                clone && styles.lineClone,
                ghost && styles.lineGhost,
                ghost && styles.indicator,
                disableSelection && styles.lineDisableSelection,
                disableInteraction && styles.lineDisableIteraction,
                isBeingCut && styles.isBeingCut
            )}
            onClick={onClickLine}
            style={{
                ...style,
                ...lineLevelStyle(level, clone ?? false, levelsUntilParent),
                ...lineOutterLineStyle(isActive),
            }}
            id={BUDGET_LINE_ID_PREFIX + line.key}
            ref={wrapperRef as any}
        >
            {isActive && (
                <BudgetLineMenu
                    disabled={disabled}
                    showRemove={!disabled && !(totalRootSections === 1 && line.lineType === BudgetLineType.SECTION && level === 0)}
                    showAdd={!disabled}
                    showClone={!disabled}
                    showCopy={!disabled}
                    showCut={!disabled}
                    showPaste={!disabled && !isBeingCut && copiedOrCutAnyLine && (line.lineType === BudgetLineType.SECTION || line.lineType === BudgetLineType.ELEMENT || line.lineType === BudgetLineType.COMPONENT_ELEMENT)}
                    showPreview={(line.lineType === BudgetLineType.ELEMENT || line.lineType === BudgetLineType.COMPONENT_ELEMENT) && Boolean(line.elementId)}
                    showToggleDataSheet={(line.lineType === BudgetLineType.ELEMENT || line.lineType === BudgetLineType.COMPONENT_ELEMENT) && Boolean(line.elementId)}
                    showEdit={!disabled && (line.lineType === BudgetLineType.ELEMENT || line.lineType === BudgetLineType.COMPONENT_ELEMENT)}
                    showEditCancel={!disabled && (line.lineType === BudgetLineType.ELEMENT || line.lineType === BudgetLineType.COMPONENT_ELEMENT) && isEditing && Boolean(line.elementId)}
                    showMove={!isMobileOrTablet && !disabled}
                    showMoveUp={isMobileOrTablet && !disabled}
                    showMoveDown={isMobileOrTablet && !disabled}
                    isEditing={isEditing}
                    isEnableDataSheet={Boolean(line.enableDataSheet)}
                    level={level}
                    handleProps={handleProps}
                    line={line}
                    onRemove={onRemove}
                    onAdd={onAdd}
                    onClone={onClone}
                    onCopy={onCopy}
                    onCut={onCut}
                    onPaste={onPaste}
                    onEdit={(v) => setIsEditing(v)}
                    onPreview={() => {
                        if (line.elementId) {
                            window.open('/library/elements-items/details/' + line.elementId, '_blank');
                        }
                    }}
                    onToggleDataSheet={onToggleDataSheet}
                    onMoveUp={onMoveUp}
                    onMoveDown={onMoveDown}
                />
            )}
            <div
                className={styles.contentContainer}
                style={{
                    ...(lineInnerLevelStyle(parentIsSelected, levelsUntilParent)),
                    ...(lineInnerActiveStyle(isActive, parentIsSelected, isLastInSelectedLevel, (childCount ?? 0) > 0, line.isOpen ?? false, borderTopLeftWithoutRadius)),
                }}
            >
                {content}
            </div>
        </div>
    );
}

export default forwardRef<HTMLDivElement, TreeItemComponentProps<BudgetLineDto>>(BudgetLine);
