import BigNumber from 'bignumber.js';

class MathUtils {
    public plus(...args: number[]): number {
        if (!args || args.length === 0) {
            return 0;
        }
        if (args.length === 1) {
            return args[0];
        }
        return args.reduce((acc, curr) => acc.plus(curr), new BigNumber(0)).toNumber();
    }

    public minus(...args: number[]): number {
        if (!args || args.length === 0) {
            return 0;
        }
        if (args.length === 1) {
            return args[0];
        }
        return args.slice(1).reduce((acc, curr) => acc.minus(curr), new BigNumber(args[0])).toNumber();
    }

    public multiply(...args: number[]): number {
        if (!args || args.length === 0) {
            return 0;
        }
        if (args.length === 1) {
            return args[0];
        }
        return args.reduce((acc, curr) => acc.times(curr), new BigNumber(1)).toNumber();
    }

    public divide(...args: number[]): number {
        if (!args || args.length === 0) {
            return 0;
        }
        if (args.length === 1) {
            return args[0];
        }
        return args.slice(1).reduce((acc, curr) => acc.div(curr), new BigNumber(args[0])).toNumber();
    }

    public round(v: number, dc: number = 2): number {
        if (!v) {
            return 0;
        }
        return new BigNumber(v).decimalPlaces(dc).toNumber();
    }
}

export default new MathUtils();
