import { FaArrowDown, FaArrowUp, FaEye, FaFilePdf, FaPaste, FaPencilAlt, FaPlus, FaTimes } from 'react-icons/fa';
import styles from './BudgetLineMenu.module.scss';
import { IoCopyOutline, IoTrash, IoMove, IoCutOutline, IoDuplicateOutline } from 'react-icons/io5';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BudgetLineType } from 'api/budgets/enums/BudgetLineType';
import { AddLinePlacement } from 'store/budget/type';
import { BODY_TOOLTIP } from 'Config';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { canMoveDown, canMoveUp } from 'store/budget/reducer';
import { BudgetLineDto } from 'api/budgets/models/BudgetDto';

interface AddLineMenuItem {
    label: string;
    lineType: BudgetLineType;
    placement: AddLinePlacement;
}

interface Props {
    showAdd: boolean;
    showClone: boolean;
    showCopy: boolean;
    showCut: boolean;
    showPaste: boolean;
    showPreview: boolean;
    showRemove: boolean;
    showEditCancel: boolean;
    showToggleDataSheet: boolean;
    isEditing: boolean;
    showEdit: boolean;
    isEnableDataSheet: boolean;
    level: number;
    handleProps?: any;
    showMoveUp: boolean;
    showMoveDown: boolean;
    showMove: boolean;
    line: BudgetLineDto;
    disabled: boolean;
    onAdd: (lineType: BudgetLineType, placement: AddLinePlacement) => void;
    onClone: () => void;
    onCopy: () => void;
    onCut: () => void;
    onPaste: () => void;
    onPreview: () => void;
    onRemove: () => void;
    onEdit: (v: boolean) => void;
    onToggleDataSheet: () => void;
    onMoveUp: () => void;
    onMoveDown: () => void;
}

function BudgetLineMenu({
    showAdd, onAdd, showClone, onClone, showCopy, onCopy, showCut, onCut, showPaste, onPaste,
    showPreview, onPreview, showRemove, onRemove, showEdit, isEditing, onEdit,
    onToggleDataSheet, showToggleDataSheet, isEnableDataSheet,
    level, showEditCancel, handleProps, showMoveUp, showMoveDown, showMove,
    onMoveDown, onMoveUp, line, disabled
}: Props): JSX.Element | null {
    const { t } = useTranslation();
    const [showAddMenu, setShowAddMenu] = useState(false);
    const isSubsection = level > 0;
    const disableMoveUp = useSelector<Reducers, boolean>(x => showMoveUp && !canMoveUp(x.budget.lines, x.budget.selectedLineKey));
    const disableMoveDown = useSelector<Reducers, boolean>(x => showMoveDown && !canMoveDown(x.budget.lines, x.budget.selectedLineKey));
    const isBeingCut = useSelector<Reducers, boolean>(x => x.budget.copiedLineKey === line.key && x.budget.copyType === 'cut');
    const isBeingCopied = useSelector<Reducers, boolean>(x => x.budget.copiedLineKey === line.key && x.budget.copyType === 'copy');

    const onAddLine = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, menuItem: AddLineMenuItem) => {
        e.stopPropagation();

        onAdd(menuItem.lineType, menuItem.placement);
        setShowAddMenu(false);
    }

    const canHaveChildren = line.lineType === BudgetLineType.SECTION || line.lineType === BudgetLineType.ELEMENT || line.lineType === BudgetLineType.COMPONENT_ELEMENT;

    const addMenuOptions: AddLineMenuItem[] = [
        {
            label: t('budgets.budget.lines.add_options.section'),
            lineType: BudgetLineType.SECTION,
            placement: AddLinePlacement.ROOT,
        },
        ...(!isSubsection
            ? [{
                label: t('budgets.budget.lines.add_options.subsection'),
                lineType: BudgetLineType.SECTION,
                placement: AddLinePlacement.INSIDE,
            }]
            : []),
        ...(isSubsection
            ? [
                {
                    label: t('budgets.budget.lines.add_options.subsection') + ' ' + t('budgets.budget.lines.add_options.same_level'),
                    lineType: BudgetLineType.SECTION,
                    placement: AddLinePlacement.SAME,
                },
                ...(canHaveChildren
                    ? [
                        {
                            label: t('budgets.budget.lines.add_options.subsection') + ' ' + t('budgets.budget.lines.add_options.inside_level'),
                            lineType: BudgetLineType.SECTION,
                            placement: AddLinePlacement.INSIDE,
                        },
                    ]
                    : []),
            ]
            : []),
        ...(!isSubsection
            ? [{
                label: t('budgets.budget.lines.add_options.element'),
                lineType: BudgetLineType.ELEMENT,
                placement: AddLinePlacement.INSIDE,
            }]
            : []),
        ...(isSubsection
            ? [
                {
                    label: t('budgets.budget.lines.add_options.element') + ' ' + t('budgets.budget.lines.add_options.same_level'),
                    lineType: BudgetLineType.ELEMENT,
                    placement: AddLinePlacement.SAME,
                },
                ...(canHaveChildren
                    ? [
                        {
                            label: t('budgets.budget.lines.add_options.element') + ' ' + t('budgets.budget.lines.add_options.inside_level'),
                            lineType: BudgetLineType.ELEMENT,
                            placement: AddLinePlacement.INSIDE,
                        },
                    ]
                    : []),
            ]
            : []),

        ...(!isSubsection
            ? [{
                label: t('budgets.budget.lines.add_options.component'),
                lineType: BudgetLineType.COMPONENT_ELEMENT,
                placement: AddLinePlacement.INSIDE,
            }]
            : []),
        ...(isSubsection
            ? [
                {
                    label: t('budgets.budget.lines.add_options.component') + ' ' + t('budgets.budget.lines.add_options.same_level'),
                    lineType: BudgetLineType.COMPONENT_ELEMENT,
                    placement: AddLinePlacement.SAME,
                },
                ...(canHaveChildren
                    ? [
                        {
                            label: t('budgets.budget.lines.add_options.component') + ' ' + t('budgets.budget.lines.add_options.inside_level'),
                            lineType: BudgetLineType.COMPONENT_ELEMENT,
                            placement: AddLinePlacement.INSIDE,
                        },
                    ]
                    : []),
            ]
            : []),
        {
            label: t('budgets.budget.lines.add_options.note') + (!canHaveChildren ? (' ' + t('budgets.budget.lines.add_options.same_level')) : ''),
            lineType: BudgetLineType.NOTE,
            placement: canHaveChildren ? AddLinePlacement.INSIDE : AddLinePlacement.SAME,
        },
        {
            label: t('budgets.budget.lines.add_options.page_break') + (!canHaveChildren ? (' ' + t('budgets.budget.lines.add_options.same_level')) : ''),
            lineType: BudgetLineType.PAGE_BREAK,
            placement: canHaveChildren ? AddLinePlacement.INSIDE : AddLinePlacement.SAME,
        },

    ];

    return (
        <div className={styles.container}>
            <div className={styles.buttons}>
                {showMove && <button
                    className={`${styles.button} ${styles.handleButton}`}
                    type="button"
                    {...handleProps}
                >
                    <IoMove />
                </button>}
                {showMoveUp && <button className={styles.button} type="button" onClick={onMoveUp} disabled={disableMoveUp}>
                    <FaArrowUp />
                </button>}
                {showMoveDown && <button className={styles.button} type="button" onClick={onMoveDown} disabled={disableMoveDown}>
                    <FaArrowDown />
                </button>}
                {showAdd && !isEditing && (
                    <button
                        className={`${styles.button} ${showAddMenu ? styles.active : ''}`}
                        type="button"
                        onClick={() => setShowAddMenu(s => !s)}
                        data-tooltip-id={BODY_TOOLTIP}
                        data-tooltip-content={t('budgets.budget.lines.floating_menu.add')}
                    >
                        <FaPlus />
                    </button>
                )}
                {showClone && !isEditing && (
                    <button
                        className={styles.button}
                        type="button"
                        onClick={onClone}
                        data-tooltip-id={BODY_TOOLTIP}
                        data-tooltip-content={t('budgets.budget.lines.floating_menu.clone')}
                    >
                        <IoDuplicateOutline />
                    </button>
                )}
                {showCopy && !isEditing && (
                    <button
                        className={`${styles.button} ${isBeingCopied ? styles.active : ''}`}
                        type="button"
                        onClick={onCopy}
                        data-tooltip-id={BODY_TOOLTIP}
                        data-tooltip-content={isBeingCopied ? t('budgets.budget.lines.floating_menu.being_copied') : t('budgets.budget.lines.floating_menu.copy')}
                    >
                        <IoCopyOutline />
                    </button>
                )}
                {showCut && !isEditing && (
                    <button
                        className={`${styles.button} ${isBeingCut ? styles.active : ''}`}
                        type="button"
                        onClick={onCut}
                        data-tooltip-id={BODY_TOOLTIP}
                        data-tooltip-content={isBeingCut ? t('budgets.budget.lines.floating_menu.being_cut') : t('budgets.budget.lines.floating_menu.cut')}
                    >
                        <IoCutOutline />
                    </button>
                )}
                {showPaste && !isEditing && (
                    <button
                        className={styles.button}
                        type="button"
                        onClick={onPaste}
                        data-tooltip-id={BODY_TOOLTIP}
                        data-tooltip-content={t('budgets.budget.lines.floating_menu.paste')}
                    >
                        <FaPaste />
                    </button>
                )}
                {showPreview && !isEditing && (
                    <button
                        className={styles.button}
                        type="button"
                        onClick={onPreview}
                        data-tooltip-id={BODY_TOOLTIP}
                        data-tooltip-content={t('budgets.budget.lines.floating_menu.preview')}
                    >
                        <FaEye />
                    </button>
                )}
                {(showToggleDataSheet && !isEditing) && (
                    <button
                        className={`${styles.button} ${isEnableDataSheet ? styles.active : ''} ${disabled ? styles.passiveDisabled : ''}`}
                        type="button"
                        onClick={!disabled ? onToggleDataSheet : undefined}
                        data-tooltip-id={BODY_TOOLTIP}
                        data-tooltip-content={isEnableDataSheet ? t('budgets.budget.lines.floating_menu.datasheet_visible') : t('budgets.budget.lines.floating_menu.datasheet_not_visible')}
                    >
                        <FaFilePdf />
                    </button>
                )}
                {showEdit && (
                    <button
                        className={`${styles.button} ${isEditing ? styles.active : ''}`}
                        type="button"
                        onClick={() => onEdit(true)}
                        data-tooltip-id={BODY_TOOLTIP}
                        data-tooltip-content={t('budgets.budget.lines.floating_menu.edit')}
                    >
                        <FaPencilAlt />
                    </button>
                )}
                {showEditCancel && (
                    <button
                        className={styles.button}
                        type="button"
                        onClick={() => onEdit(false)}
                        data-tooltip-id={BODY_TOOLTIP}
                        data-tooltip-content={t('budgets.budget.lines.floating_menu.cancel')}
                    >
                        <FaTimes />
                    </button>
                )}
                {showRemove && (
                    <button
                        className={styles.button}
                        type="button"
                        onClick={onRemove}
                        data-tooltip-id={BODY_TOOLTIP}
                        data-tooltip-content={t('budgets.budget.lines.floating_menu.remove')}
                    >
                        <IoTrash />
                    </button>
                )}
            </div>
            {showAddMenu && (
                <div className={styles.menu}>
                    {addMenuOptions.map((option, i) => (
                        <div key={i} className={styles.menuItem} onClick={(e) => onAddLine(e, option)}>
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default BudgetLineMenu;
