import styles from './FamilySelector.module.scss';
import { Label, Text } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import SelectInput from 'common/components/selectInput/SelectInput';
import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { FamilySelectModel } from 'api/elements/Models/ElementDto';
import colors from 'styles/export/colors.module.scss';

export interface FamilySelectorProps {
    items: FamilySelectModel[];
    item: FamilySelectModel;
    options: FamilySelectInputOption[];
    disabled?: boolean;
    hasError?: boolean;
    errorMessage?: string;
    onChange: (items: FamilySelectModel[], previousId: string | null) => void;
}

export interface FamilySelectInputOption {
    label: string;
    value: string;
    subFamilies: FamilySelectInputOption[];
    isElement: boolean,
    isComponent: boolean;
}

const FamilySelector = ({ items, item, options, disabled, hasError, errorMessage, onChange }: FamilySelectorProps) => {
    const { t } = useTranslation();
    const [subFamilies, setSubFamilies] = useState<FamilySelectInputOption[]>([]);

    const onChangeFamily = (item: FamilySelectModel, id: string | null | undefined,selectedFamily: FamilySelectInputOption | undefined) => {
        let prevId = null

        const updatedItems = items.map((x: FamilySelectModel) => {
            if (x.id === item.id) {
                prevId = x.familyId
                return { ...x, subFamilyId: x.familyId !== id ? null : x.subFamilyId, familyId: id ?? null,isComponent: selectedFamily?.isComponent, isElement: selectedFamily?.isElement }
            }
            return x;
        });

        onChange(updatedItems,prevId);
    };

    const onChangeFamilySubFamily = (item: FamilySelectModel, id: string | null | undefined) => {
        let prevId = null
        const updatedItems = items.map((x: FamilySelectModel) => {
            if (x.id === item.id) {
                prevId = x.subFamilyId
                return { ...x,subFamilyId: id ?? null }
            }
            return x;
        });
        onChange(updatedItems,prevId);
    };

    useEffect(() => {
        if (item.familyId) {
            const selectedFamily = options.find(x => x.value === item.familyId);
            setSubFamilies(selectedFamily?.subFamilies ?? []);
        } else {
            setSubFamilies([]);
        }
    }, [item.familyId, options]);

    const defineSubFamilies = (id: string | null | undefined) => {
        const selectedFamily = options.find(x => x.value === id);
        onChangeFamily(item, id,selectedFamily);
        setSubFamilies(selectedFamily?.subFamilies ?? []);
        // onChangeFamilySubFamily(item, null);
    };

    return (
        <Row className="mb-3">
            <Col xs={12} sm={12} md={6}>
                <Label space>{t('elements.family')}</Label>
                <SelectInput
                    value={item.familyId}
                    options={options}
                    disabled={disabled}
                    onChange={(x) => defineSubFamilies(x)}
                    hasError={hasError}
                />
                {hasError && <div style={{ color: colors.danger }}>{errorMessage}</div>}
            </Col>
            <Col xs={12} sm={12} md={6}>
                <Label space>{t('elements.sub_family')}</Label>
                <SelectInput
                    value={item.subFamilyId}
                    options={subFamilies}
                    disabled={disabled}
                    onChange={(x) => onChangeFamilySubFamily(item, x)}
                    hasError={hasError}
                />
            </Col>
        </Row>
    );
};

export default FamilySelector;
