import styles from './Section.module.scss';
import { ParameterSectionBaseModel } from '../models';
import { IoMoveOutline, IoTrash, IoCopyOutline } from 'react-icons/io5';
import { Label } from 'common/components/texts/Texts';
import { useTranslation } from 'react-i18next';
import TextInput from 'common/components/textInput/TextInput';
import SectionList from '../sectionList/SectionList';
import ParametersList from '../parametersList/ParametersList';
import { CSS } from '@dnd-kit/utilities';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSSProperties } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
    !(isSorting || wasDragging);

interface Props {
    section: ParameterSectionBaseModel;
    isChild: boolean;
    hasClearBackground: boolean;
    disabled?: boolean;
    unitOptions?: SearchableSelectTitleSubTitleOption[];
    selectInputOptions?: SearchableSelectTitleSubTitleOption[];
    onChange: (section: ParameterSectionBaseModel) => void;
    onRemove: () => void;
    onDuplicate: () => void;
}

const Section = ({ section, isChild, hasClearBackground, disabled, unitOptions, selectInputOptions, onChange, onRemove, onDuplicate }: Props) => {
    const { t } = useTranslation();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: section.key,
        animateLayoutChanges,
        disabled,
    });

    const style: CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition,
        opacity: isDragging ? 0.3 : 1,
    };

    const onChangeChildren = (items: ParameterSectionBaseModel[]) => {
        onChange({
            ...section,
            // children: items,
        });
    }

    const onToggle = () => {
        onChange({
            ...section,
            closed: !section.closed,
        });
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={`${styles.container} ${isChild ? styles.isChild : ''}`}
        >
            <div>
                {!disabled && <div className={styles.header}>
                    <div className={styles.headerLeft}>
                        <span className={`${styles.headerButton} ${styles.dragHandle}`} {...attributes} {...listeners}>
                            <IoMoveOutline />
                        </span>
                    </div>
                    <div className={styles.headerRight}>
                        <span className={styles.headerButton} onClick={onDuplicate}>
                            <IoCopyOutline />
                        </span>
                        <span className={styles.headerButton} onClick={onRemove}>
                            <IoTrash />
                        </span>
                        <span className={styles.headerButton} onClick={onToggle}>
                            {section.closed ? <FaAngleUp /> : <FaAngleDown />}
                        </span>
                    </div>
                </div>}

                {(section.closed && !disabled) && <div className={`${styles.innerContainer} ${styles.sectionClosedPlaceholder} ${hasClearBackground ? styles.hasClearBackground : ''}`}></div>}

                {(!section.closed || disabled) && <div className={`${styles.innerContainer} ${hasClearBackground ? styles.hasClearBackground : ''} ${disabled ? styles.disabled : ''}`}>
                    <div className={styles.titleContainer}>
                        <div className={`${styles.titleButtonContainer}`}></div>
                        <div className={styles.titleControl}>
                            <Label space>{isChild ? t('common.parameters_sections.sub_parameter_title') : t('common.parameters_sections.parameter_title')}</Label>
                            <TextInput
                                value={section.title ?? ''}
                                placeholder={t('common.write_here')}
                                disabled={disabled}
                                onChange={(e) => onChange({ ...section, title: e.target.value })}
                            />
                        </div>
                    </div>

                    <ParametersList
                        id={section.key}
                        items={[]} // section.children
                        disabled={disabled}
                        unitOptions={unitOptions}
                        selectInputOptions={selectInputOptions}
                        onChange={onChangeChildren}
                    />

                    <SectionList
                        id={section.key}
                        items={[]} // section.children
                        isChild={true}
                        hasClearBackground={!hasClearBackground}
                        disabled={disabled}
                        unitOptions={unitOptions}
                        selectInputOptions={selectInputOptions}
                        onChange={onChangeChildren}
                    />
                </div>}
            </div>
        </div>
    );
};

export default Section;
