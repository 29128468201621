import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import styles from './CarouselModal.module.scss';
import Modal, { ModalBody, ModalFooter } from '../modal/Modal';
import Button from '../button/Button';

export interface CarouselProps {
    isOpen: boolean;
    images: string[];
    onClose: () => void;
}

const CarouselModal = ({ images, isOpen, onClose }: CarouselProps) => {
    const { t } = useTranslation();
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

    const items = images.map((image: string) => {
        return {
            original: image,
            thumbnail: image,
            renderItem: () => <img src={image} className={!isFullScreen ? styles.image : styles.image_full_screen} />
        }
    });

    return (
        <Modal isOpen={isOpen} style={{ content: { width: '90%',height: '90%' } }}>
            <ModalBody>
                <div style={{ position: 'relative' }}>
                    <ImageGallery
                        items={items}
                        showPlayButton={false}
                        infinite={false}
                        onScreenChange={(fullScreen) => {
                            setIsFullScreen(fullScreen)
                        }} />
                </div>
            </ModalBody>
            <ModalFooter className="text-right">
                <Button onClick={onClose}>{t('common.ok')}</Button>
            </ModalFooter>
        </Modal >
    );
}

export default CarouselModal;
