import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { ExternalPartContactDto, ExternalPartDto } from './models/ExternalPartDto';
import { ExternalPartContactsSearchCriteria, ExternalPartSearchCriteria } from './models/ExternalPartSearchCriteria';
import { SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';

class ExternalPartsService {
    public getList (criteria: ExternalPartSearchCriteria) {
        return Http.get<Paged<ExternalPartDto>>('externalParts', criteria);
    }

    public getCatalog () {
        return Http.get<SearchableSelectTitleSubTitleOption[]>('externalParts/catalog');
    }

    public getById (id: string) {
        return Http.get<ExternalPartDto>('externalParts/' + id);
    }

    public getContactsByExternalPartsIds (criteria: ExternalPartContactsSearchCriteria) {
        return Http.get<ExternalPartContactDto[]>('externalParts/get-contacts-by-external-parts-ids' , criteria);
    }

    public create (model: ExternalPartDto): Promise<string> {
        return Http.post('externalParts', model);
    }

    public update (model: ExternalPartDto) {
        return Http.put('externalParts', model);
    }

    public remove (model: ExternalPartDto) {
        return Http.put('externalParts/deactivate', model);
    }
}

export default new ExternalPartsService();
