import { BudgetLineType } from 'api/budgets/enums/BudgetLineType';
import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import { BudgetLineParameterDto } from 'api/budgets/models/BudgetLineParameterDto';
import { CSSProperties } from 'react';
import { findLineByKey } from 'store/budget/reducer';
import colors from 'styles/export/colors.module.scss';

const levelMultiplier = 10;
const defaultBorderRadius = 8;

export const lineLevelStyle = (level: number, clone: boolean, insideLevel: number): CSSProperties => {
    return {
        marginLeft: clone ? level : ((level - insideLevel) * levelMultiplier),
    };
}

export const lineInnerLevelStyle = (parentIsSelected: boolean, insideLevel: number): CSSProperties => {
    if (parentIsSelected) {
        return {
            paddingLeft: (insideLevel * levelMultiplier),
        };
    }
    return {};
}

export const lineOutterLineStyle = (isActive: boolean): CSSProperties => {
    return {
        marginTop: isActive ? 4 : undefined,
    };
}

export const lineActiveStyle = (isActive: boolean): CSSProperties => {
    return {
        borderWidth: isActive ? 0 : undefined,
    };
}

export const lineInnerActiveStyle = (isActive: boolean, parentIsSelected: boolean, isLast: boolean, hasChildren: boolean, isOpen: boolean, borderTopLeftWithoutRadius: boolean = true): CSSProperties => {
    const defaultBorderColor = isActive || parentIsSelected ? colors.primaryDark : 'transparent';

    let result: CSSProperties = {
        borderWidth: isActive || parentIsSelected ? 1 : 0,
        borderStyle: 'solid',
        borderTopColor: defaultBorderColor,
        borderBottomColor: defaultBorderColor,
        borderLeftColor: defaultBorderColor,
        borderRightColor: defaultBorderColor,
        borderTopRightRadius: defaultBorderRadius,
        borderTopLeftRadius: borderTopLeftWithoutRadius ? 0 : defaultBorderRadius,
        borderBottomLeftRadius: defaultBorderRadius,
        borderBottomRightRadius: defaultBorderRadius,
    }

    if (parentIsSelected) {
        result = {
            ...result,
            borderTopColor: 'transparent',
            borderBottomColor: isLast ? colors.primaryDark : 'transparent',
            borderBottomLeftRadius: !isLast ? 0 : defaultBorderRadius,
            borderBottomRightRadius: !isLast ? 0 : defaultBorderRadius,
            borderTopRightRadius: 0,
        };
    }

    if (hasChildren && isActive && isOpen) {
        result = {
            ...result,
            borderBottomColor: 'transparent',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        };
    }

    if (isActive) {
        result.paddingTop = 0;
    }

    return result;
}

export enum LinesTableColumn {
    DESIGNATION = 'DESIGNATION',
    ELEMENT_IMAGE = 'ELEMENT_IMAGE',
    QUANTITY = 'QUANTITY',
    UNIT = 'UNIT',
    GROSS_UNIT_PRICE = 'GROSS_UNIT_PRICE',
    UNIT_PRICE = 'UNIT_PRICE',
    UNIT_PRICE_WITH_CHILDREN = 'UNIT_PRICE_WITH_CHILDREN',
    TOTAL_EXCLUDING_TAX = 'TOTAL_EXCLUDING_TAX',
    TOTAL_INCLUDING_TAX = 'TOTAL_INCLUDING_TAX',
    TOTAL_UNIT_EXCLUDING_TAX = 'TOTAL_UNIT_EXCLUDING_TAX',
    TAX = 'TAX',
    MARGIN = 'MARGIN',
    DISCOUNT = 'DISCOUNT',
    LINE_NUMBER = 'LINE_NUMBER',
}

export function isColumnVisible(hiddenColumns: LinesTableColumn[] | null, field: LinesTableColumn) {
    return !(hiddenColumns ?? []).find(c => c === field)
}

export function getLevelFromParentKeys(parentKey: string, parentKeys: string[]): number {
    const index = parentKeys.indexOf(parentKey);
    if (index === -1) {
        throw new Error(`Key "${parentKey}" not found in the parentKeys.`);
    }
    return parentKeys.length - index;
}

export function isLastInLevel(allLines: BudgetLineDto[], parentKey: string, lineKey: string): boolean {
    if (allLines.length === 0) {
        return false;
    }

    function isLast(lines: BudgetLineDto[]): boolean {
        const lastLine = lines[lines.length - 1];
        if (!lastLine) {
            return false;
        }

        const lastLineChildren = allLines.filter(x => x.parentKey === lastLine.key)

        if ((lastLine.lineType !== BudgetLineType.SECTION || (lastLine.lineType === BudgetLineType.SECTION && lastLine.isOpen)) && lastLineChildren && lastLineChildren.length > 0) {
            return isLast(lastLineChildren);
        }

        if (lastLine.key === lineKey) {
            return true;
        }

        return false;
    }

    const parentChildren = allLines.filter(x => x.parentKey === parentKey)
    return isLast(parentChildren);
}

export function isLineBeingCut(allLines: BudgetLineDto[], lineKey: string, lineBeingCutKey: string): boolean {
    if (allLines.length === 0) {
        return false;
    }

    const isCutOrParentCut = (lineKey: string): boolean => {
        if (lineKey === lineBeingCutKey) {
            return true;
        }

        const line = findLineByKey(lineKey, allLines);
        if (line && line.parentKey) {
            return isCutOrParentCut(line.parentKey);
        }

        return false;
    };

    return isCutOrParentCut(lineKey);
}

export const mergeWithExistingParameters = (newParameters: BudgetLineParameterDto[], existingParameters: BudgetLineParameterDto[]): BudgetLineParameterDto[] => {
    if (!existingParameters || existingParameters.length === 0) {
        return newParameters
    }

    const existingParametersMap = new Map<string, BudgetLineParameterDto>();
    for (const param of existingParameters) {
        if (param.elementParameterId) {
            existingParametersMap.set(param.elementParameterId, param);
        }
    }

    const mergedParameters = newParameters.map(newParam => {
        const existingParam = existingParametersMap.get(newParam.elementParameterId);
        if (existingParam) {
            return {
                ...existingParam,
                children: mergeWithExistingParameters(newParam.children || [], existingParam.children || [])
            };
        }
        return {
            ...newParam,
            children: newParam.children ? mergeWithExistingParameters(newParam.children, []) : []
        };
    });

    const existingOnlyParameters = existingParameters.filter(existingParam => !newParameters.find(newParam => newParam.elementParameterId === existingParam.elementParameterId));

    const result = [...mergedParameters, ...existingOnlyParameters];

    result.sort((a, b) => a.position - b.position);

    return result;
}
