import { WorkDto, WorkExternalPartContactDto, WorkExternalPartDto } from 'api/works/models/WorkDto';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Logger from 'common/services/Logger';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOGGER_LOG_TYPE } from 'Config';
import WorksService from 'api/works/WorksService';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'common/services/Loading';
import { FormProvider, useForm } from 'react-hook-form';
import { Col, Row, Tabs, Tab } from 'react-bootstrap';
import { Label } from 'common/components/texts/Texts';
import { TextInputController } from 'common/components/textInput/TextInput';
import Button from 'common/components/button/Button';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import { FaTrashAlt } from 'react-icons/fa';
import InputError from 'common/components/inputError/InputError';
import { Reducers } from 'store/types';
import { useSelector } from 'react-redux';
import UsersService from 'api/users/UsersService';
import { UserProfile } from 'api/account/models/UserProfile';
import Toast from 'common/services/Toast';
import { SelectInputController, SelectInputOption } from 'common/components/selectInput/SelectInput';
import CountriesService from 'api/countries/CountriesService';
import Utils from 'common/services/Utils';
import WorkExternalParts from './WorkExternalParts';
import { Form } from 'common/components/form/Form';
import WorkExternalPartsContactsList from './contacts/WorkExternalPartsContactsList';
import styles from './WorkScreen.module.scss'
import YesNoModal from 'common/components/modal/yesNoModal/YesNoModal';

type TabKey = 'details' | 'contacts';
const url = '/business/works';

function WorkScreen(): JSX.Element | null {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id, type } = useParams<{ id: string, type: 'create' | 'details' | 'edit' }>();
    const form = useForm<WorkDto>({ shouldUnregister: false });
    const errors = form.formState.errors;
    const currentUrl = url + '/' + type + (id ? ('/' + id) : '');
    const isDetails = type === 'details';
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmDeleteRelationsModal, setShowConfirmDeleteRelationsModal] = useState(false);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['WORKS_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['WORKS_READ'])
    const [countries, setCountries] = useState<SelectInputOption[]>([]);

    const [currentTab, setCurrentTab] = useState<TabKey>('details');

    const [externalPartsSelected, setExternalPartsSelected] = useState<WorkExternalPartDto[]>([]);
    const [contactsList, setContactsList] = useState<WorkExternalPartContactDto[]>([]);

    const [confirmChangeTierModal, setConfirmChangeTierModal] = useState<any | null>(null);
    const getData = async () => {
        try {
            let result: Partial<WorkDto> = {
                id,
                name: '',
                countryId: null,
                externalParts: [],
                externalPartsContacts: [],
            }

            Loading.show();

            if (id) {
                result = await WorksService.getById(id);
            }

            const [countriesData, initialExternalPartsData] = await Promise.all([
                CountriesService.getCatalog(),
                WorksService.getInitialExternalParts(),
            ]);

            setCountries(countriesData.map(x => ({
                label: x.name,
                value: x.id,
            })));

            for (const externalPart of initialExternalPartsData) {
                if (!result.externalParts?.find(x => x.externalPartCategoryId === externalPart.externalPartCategoryId)) {
                    result.externalParts?.push({
                        ...externalPart,
                        key: Utils.newGuid(),
                    });
                }
            }

            if (result.externalParts) {
                const filteredExternalParts = result.externalParts.filter(x => x.externalPartId);
                setExternalPartsSelected(filteredExternalParts);
            }
            if (result.externalPartsContacts) {
                setContactsList(result.externalPartsContacts);
            }

            form.reset(result);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get works data', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const onClickRefreshExternalParts = async (externalPartCategoryId: string, index: number) => {
        try {
            Loading.show();

            const initialExternalPartsData = await WorksService.getInitialExternalParts();

            const externalPartCategory = initialExternalPartsData.find(x => x.externalPartCategoryId === externalPartCategoryId);
            if (externalPartCategory) {
                form.setValue(
                    `externalParts.${index}.externalPartsOptions` as 'externalParts.0.externalPartsOptions',
                    externalPartCategory.externalPartsOptions
                );
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t refresh external parts', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const onSubmit = async (model: WorkDto) => {
        try {
            Loading.show();

            if (model && model.id) {
                await WorksService.update(model)
                // navigateTo('details', model.id);
                void getData();
            } else if (model) {
                const id = await WorksService.create(model)
                navigateTo('edit', id);
            }
            Toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t update save the work', error);
            Toast.error(t('messages.record_save_error'));
        }
        Loading.hide();
    }

    const onInvalid = () => {
        Toast.warning(t('messages.required_fields_empty'));
        setCurrentTab('details');
    }

    const onDelete = async (result: boolean) => {
        setShowConfirmDeleteModal(false);

        if (result) {
            setShowConfirmDeleteRelationsModal(true);
        }
    }

    const onDeleteRelations = async (result: boolean) => {
        Loading.show();
        try {
            await WorksService.delete(id!, result)
            Toast.success(t('messages.record_delete_success'));
            onGoBack();
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete work', error);
            Toast.error(t('messages.record_delete_error'));
        } finally {
            Loading.hide();
            setShowConfirmDeleteRelationsModal(false)
        }
    }

    const onGoBack = () => {
        navigate(url);
    }

    const navigateTo = (type: string, id?: string) => {
        navigate(url + '/' + type + (id ? ('/' + id) : ''));
    }

    const handleExternalPartsChange = (externalParts: WorkDto['externalParts'],externalPartCategory: WorkExternalPartDto,newValue: string | null | undefined,oldValue: string | null | undefined) => {
        if (!externalPartsSelected.find(x => x.externalPartId === (externalPartCategory.externalPartId ?? '')) && contactsList.some(x => x.externalPartCategoryId === externalPartCategory.externalPartCategoryId)) {
            setConfirmChangeTierModal({ externalParts: [...externalParts.map(x => ({ ...x }))], externalPartCategory: { ...externalPartCategory } ,newValue, oldValue })
            return;
        }
        const filteredExternalParts = externalParts.filter((x: any) => x.externalPartId).map(x => ({ ...x }));
        updateExternalParts(filteredExternalParts)
    };

    const onCloseChangeTierModal = (res: boolean) => {
        if (res) {
            updateExternalParts(confirmChangeTierModal.externalParts)
            onDeleteContacts()
        } else {
            const i = confirmChangeTierModal.externalParts.findIndex((x: WorkExternalPartDto) => x.externalPartCategoryId === confirmChangeTierModal.externalPartCategory.externalPartCategoryId)
            form.setValue(`externalParts.${i}.externalPartId`,confirmChangeTierModal.oldValue)
        }
        setConfirmChangeTierModal(null)
    }

    const updateExternalParts = (filteredExternalParts: WorkExternalPartDto[]) => {
        setExternalPartsSelected(filteredExternalParts);
    }

    const handleNewContact = (contact: WorkExternalPartContactDto) => {
        const contacts = form.getValues('externalPartsContacts') ?? [];
        contacts.push(contact);

        form.setValue('externalPartsContacts', contacts);
        setContactsList(contacts);
    };

    const handleRemoveContact = (contactToRemove: string) => {
        let contacts = form.getValues('externalPartsContacts');
        contacts = contacts.filter(x => x.externalPartContactId !== contactToRemove);

        form.setValue('externalPartsContacts', contacts);
        setContactsList(contacts);
    };

    const onDeleteContacts = () => {
        const contacts = contactsList.filter(x => x.externalPartCategoryId !== confirmChangeTierModal.externalPartCategory?.externalPartCategoryId)
        setContactsList(contacts)
        form.setValue('externalPartsContacts', contacts);
    }

    useEffect(() => {
        void getData();
    }, [id, type]);

    if (!canRead) {
        return null;
    }

    const title = (type === 'create' ? t('common.new') : (type === 'edit' ? t('common.edit') : t('common.details'))) + ' ' + t('works.work.work');

    return (
        <ScreenTitle title={title}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: t('works.list.title'), url },
                    { name: title, url: currentUrl },
                ]}
            />
            <PageHeader title={title} informationText={t('common.go_back')} showGoBack onGoBack={onGoBack}>
                {canWrite && !isDetails && <Button form='formWork' type='submit'>{t('common.save')}</Button>}
                {canWrite && isDetails && <Button fw onClick={() => { navigateTo('edit', id); }}>{t('common.edit')}</Button>}
                {canWrite && isDetails && (
                    <Button variant='danger' onClick={() => setShowConfirmDeleteModal(true)}>
                        <FaTrashAlt />
                    </Button>
                )}
            </PageHeader>
            <PageContainer addBottomSpace>
                <FormProvider {...form}>
                    <Form id='formWork' handleSubmit={form.handleSubmit} onSubmit={onSubmit} onInvalid={onInvalid} noValidate className={styles.form}>
                        <Tabs activeKey={currentTab} onSelect={(k) => setCurrentTab(k as TabKey)}>
                            <Tab eventKey={'details'} title={t('common.details')}>
                                <Row className="mb-3">
                                    <Col xs={12}>
                                        <Label space>{t('works.work.name')}{!isDetails ? '*' : ''}</Label>
                                        <TextInputController
                                            name='name'
                                            control={form.control}
                                            placeholder={t('works.work.name')}
                                            disabled={isDetails}
                                            rules={{ required: true, maxLength: 500 }}
                                            hasError={Boolean(errors.name)}
                                        />
                                        <InputError error={errors.name} maxLength={500} />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs={12}>
                                        <Label space>{t('works.work.address')}</Label>
                                        <TextInputController
                                            name='address'
                                            control={form.control}
                                            placeholder={t('works.work.address')}
                                            disabled={isDetails}
                                            rules={{ maxLength: 300 }}
                                            hasError={Boolean(errors.address)}
                                        />
                                        <InputError error={errors.address} maxLength={300} />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs={12} md={6}>
                                        <Label space>{t('works.work.zip_code')}</Label>
                                        <TextInputController
                                            name='zipCode'
                                            control={form.control}
                                            placeholder={t('works.work.zip_code')}
                                            disabled={isDetails}
                                            rules={{ maxLength: 100 }}
                                            hasError={Boolean(errors.zipCode)}
                                        />
                                        <InputError error={errors.zipCode} maxLength={100} />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Label space>{t('works.work.country')}</Label>
                                        <SelectInputController
                                            control={form.control}
                                            name='countryId'
                                            options={countries || []}
                                            disabled={isDetails}
                                        />
                                    </Col>
                                </Row>

                                <WorkExternalParts
                                    type={type}
                                    onClickRefreshExternalParts={onClickRefreshExternalParts}
                                    onExternalPartsChange={handleExternalPartsChange}
                                />
                            </Tab>
                            <Tab eventKey={'contacts'} title={t('works.work.contacts')}>
                                <WorkExternalPartsContactsList
                                    workContactsList={contactsList}
                                    type={type}
                                    selectedExternalParts={externalPartsSelected}
                                    onAddNewContact={handleNewContact}
                                    onRemoveContact={handleRemoveContact}
                                />
                            </Tab>
                        </Tabs>
                    </Form>
                </FormProvider>
            </PageContainer>

            <ConfirmDeleteModal
                itemName={form.getValues('name')}
                isOpen={showConfirmDeleteModal}
                onClose={onDelete}
            />

            <YesNoModal
                title={t('common.question')}
                message={t('works.delete_relations')}
                onClose={onDeleteRelations}
                isOpen={showConfirmDeleteRelationsModal}
            />

            <YesNoModal
                title={t('works.work.change_tier_confirmation_title').replace('{0}',confirmChangeTierModal?.externalPartCategory.externalPartCategoryName ?? '')}
                message={t('works.work.contacts_from_tier_deleted').replace('{0}',confirmChangeTierModal?.externalPartCategory.externalPartCategoryName ?? '')}
                isOpen={!!confirmChangeTierModal}
                onClose={(res) => onCloseChangeTierModal(res)}
            />
        </ScreenTitle>
    );
}

export default WorkScreen;
