
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000'
/**
 * Languages
 */
export const DEFAULT_LANGUAGE = 'fr';
export const DEFAULTNS = 'translations';
export const USE_LOCAL_TRANSLATIONS = process.env.REACT_APP_USE_LOCAL_TRANSLATIONS?.trim() === 'true';

/**
* API
*/
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const SERVER_BASE_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Pagination
 */
export const DEFAULT_PAGINATION_ITEMS_PER_PAGE = 10;
export const MAX_PAGINATION_ITEMS_PER_PAGE = 999999;

/**
 * Storage
 */
export const STORAGE_KEY = 'cQfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s';
export const STORAGE = {
    CURRENT_LOCALE: 'CURRENT_LOCALE',
    AUTH_USER_TOKEN: 'AUTH_USER_TOKEN',
    AUTH_USER_PROFILE: 'AUTH_USER_PROFILE',
    LAST_SEEN_GROUPS: 'LAST_SEEN_GROUPS',
};

/**
 * Http errors
 */
export const HTTP_ERRORS = {
    UNAUTHORIZED: 401,
    CONFLICT: 409
};

/**
 * Logger
 */
export enum LOGGER_MESSAGE_TYPE {
    INFO = 1,
    ERROR,
}
export enum LOGGER_LOG_TYPE {
    REQUEST
}
export const LOGGER_PRINT_LOGS = Boolean(process.env.REACT_APP_LOGGER_PRINT_LOGS);
export const LOGGER_PRINT_LOGS_LEVEL = LOGGER_MESSAGE_TYPE.INFO;

/**
 * MIMETYPES
 */
export const MIMETYPES_IMAGE = 'image/png, image/gif, image/jpeg';
export const MIMETYPES_ALL_IMAGE = 'image/*';

export const isTablet = (width: number) => {
    return (width <= 1200);
};
/**
 * DATE FORMAT
 */
export const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY';
export const DATEPICKER_FORMAT_DEFAULT = 'dd/MM/yyyy';
export const DATE_TIME_FORMAT_DEFAUT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_MONTH_YEAR = 'MM/YYYY';
export const DATE_TIME_FORMAT_DEFAULT = 'DD/MM/YYYY | HH:mm';
export const DATE_FORMAT_MONTH_TEXT_YEAR = 'MMM/YYYY';
export const TIME_FORMAT_DEFAULT = 'HH:mm';

/**
 * React Form
 */

/**
 * POLICIES
 */
export const POLICIES = {
    USERS_READ: 'USERS_READ',
    USERS_WRITE: 'USERS_WRITE',
    ROLES_READ: 'ROLES_READ',
    ROLES_WRITE: 'ROLES_WRITE',
    RULES_READ: 'RULES_READ',
    RULES_WRITE: 'RULES_WRITE',
    FAMILIES_READ: 'FAMILIES_READ',
    FAMILIES_WRITE: 'FAMILIES_WRITE',
    TAXES_READ: 'TAXES_READ',
    TAXES_WRITE: 'TAXES_WRITE',
    WORK_TYPES_READ: 'WORK_TYPES_READ',
    WORK_TYPES_WRITE: 'WORK_TYPES_WRITE',
    UNITS_READ: 'UNITS_READ',
    UNITS_WRITE: 'UNITS_WRITE',
    ELEMENTS_READ: 'ELEMENTS_READ',
    ELEMENTS_WRITE: 'ELEMENTS_WRITE',
    EXTERNAL_PARTS_READ: 'EXTERNAL_PARTS_READ',
    EXTERNAL_PARTS_WRITE: 'EXTERNAL_PARTS_WRITE',
    WORKS_READ: 'WORKS_READ',
    WORKS_WRITE: 'WORKS_WRITE',
    BUDGETS_READ: 'BUDGETS_READ',
    BUDGETS_WRITE: 'BUDGETS_WRITE',
    BUDGETS_FILE_MODELS_READ: 'BUDGETS_FILE_MODELS_READ',
    BUDGETS_FILE_MODELS_WRITE: 'BUDGETS_FILE_MODELS_WRITE',
    NUMERATIONS_READ: 'NUMERATIONS_READ',
    NUMERATIONS_WRITE: 'NUMERATIONS_WRITE',
    BUDGETS_FIELDS_READ: 'BUDGETS_FIELDS_READ',
    BUDGETS_FIELDS_WRITE: 'BUDGETS_FIELDS_WRITE',
};

export const ApplicationName = 'Decayeux';

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message'
};

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out'
};

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'login-callback',
    LoginFailed: 'login-failed',
    Profile: 'profile',
    Register: 'register',
    Reset: 'resetPassword'
};

if (!SERVER_BASE_URL) {
    throw new Error('The variable SERVER_BASE_URL is not defined');
}

const prefix = '/authentication';
export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: `${SERVER_BASE_URL}/_configuration/web`,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: 'Identity/Account/Register',
    IdentityManagePath: 'Identity/Account/Manage',
    IdentityTwoFactorAuthenticationPath: `${SERVER_BASE_URL}/Identity/Account/Manage/TwoFactorAuthentication`,
    Reset: `${prefix}/${LoginActions.Reset}`,
    Home: '/home'
};

export const DEFAULT_DECIMAL_PLACES = 2;
export const MIN_DECIMAL_PLACES = 0;
export const MAX_DECIMAL_PLACES = Number.MAX_SAFE_INTEGER;

export const BODY_TOOLTIP = 'body-tooltip';
