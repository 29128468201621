import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import styles from './DescriptionTab.module.scss';
import { useTranslation } from 'react-i18next';
import RichTextInput from 'common/components/richTextInput/RichTextInput';
import { updateLine } from 'store/budget/action';
import { useDispatch } from 'react-redux';

interface Props {
    line: BudgetLineDto;
    disabled: boolean;
}

function DescriptionTab({ line, disabled }: Props): JSX.Element | null {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onChange = (newLine: Partial<BudgetLineDto>) => {
        dispatch(updateLine({ key: line.key, ...newLine }));
    }

    return (
        <div className={styles.container}>
            <RichTextInput
                value={line.description}
                onChange={v => onChange({ description: v })}
                placeholder={t('budgets.budget.lines.tabs.description')}
                disabled={disabled}
            />
        </div>
    );
}

export default DescriptionTab;
