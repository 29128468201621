
import authentication from './authentication/reducer';
import budget from './budget/reducer';

const reducers = {
    authentication,
    budget,
};

export default reducers;
