import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { createPortal } from 'react-dom';
import {
    Announcements,
    closestCenter,
    defaultDropAnimation,
    DndContext,
    DragEndEvent,
    DragMoveEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    DropAnimation,
    // MeasuringStrategy,
    // KeyboardSensor,
    Modifier,
    PointerSensor,
    PointerSensorOptions,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import {
    arrayMove,
    SortableContext,
    UseSortableArguments,
} from '@dnd-kit/sortable';

import {
    flattenTree,
    getChildCount,
    getProjection,
    removeChildrenOf,
} from './utilities';
import type {
    FlattenedItem,
    ItemChangedReason,
    SensorContext,
    TreeItemComponentType,
    TreeItems,
} from './types';
// import { sortableTreeKeyboardCoordinates } from './keyboardCoordinates';
import { SortableTreeItem } from './SortableTreeItem';
import { customListSortingStrategy } from './SortingStrategy';
import { AtLeast } from 'common/types/Atleast';

export interface SortableTreeProps<
    TData extends Record<string, any>,
    TElement extends HTMLElement
> {
    items: TreeItems<TData>;
    onItemsChanged: (
        items: TreeItems<TData>,
        reason: ItemChangedReason<TData>
    ) => void;
    onItemChange?: (item: AtLeast<FlattenedItem<TData>, 'key'>, type: 'change' | 'remove' | 'change-self-and-children') => void;
    onItemCreate?: (item: TData) => void;
    onMoveItem?: (key: string,type: 'up' | 'down') => void;
    onSelectItem?: (key: string) => void;
    TreeItemComponent: TreeItemComponentType<TData, TElement>;
    indentationWidth?: number;
    indicator?: boolean;
    pointerSensorOptions?: PointerSensorOptions;
    disableSorting?: boolean;
    dropAnimation?: DropAnimation | null;
    dndContextProps?: React.ComponentProps<typeof DndContext>;
    sortableProps?: Omit<UseSortableArguments, 'id'>;
    keepGhostInPlace?: boolean;
    canRootHaveChildren?: boolean | ((dragItem: FlattenedItem<TData>) => boolean);
    canHaveChildren?: boolean | ((parentItem: FlattenedItem<TData>) => boolean);
}
const defaultPointerSensorOptions: PointerSensorOptions = {
    activationConstraint: {
        distance: 3,
    },
};

export const dropAnimationDefaultConfig: DropAnimation = {
    keyframes({ transform }) {
        return [
            { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
            {
                opacity: 0,
                transform: CSS.Transform.toString({
                    ...transform.final,
                    x: transform.final.x + 5,
                    y: transform.final.y + 5,
                }),
            },
        ];
    },
    easing: 'ease-out',
    sideEffects({ active }) {
        active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: defaultDropAnimation.duration,
            easing: defaultDropAnimation.easing,
        });
    },
};

export function SortableTree<
    TreeItemData extends Record<string, any>,
    TElement extends HTMLElement = HTMLDivElement
>({
    items,
    indicator,
    indentationWidth = 20,
    onItemsChanged,
    TreeItemComponent,
    pointerSensorOptions,
    disableSorting,
    dropAnimation,
    dndContextProps,
    sortableProps,
    keepGhostInPlace,
    canRootHaveChildren,
    canHaveChildren,
    onItemChange,
    onItemCreate,
    onMoveItem,
    onSelectItem,
    ...rest
}: SortableTreeProps<TreeItemData, TElement>) {
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const [currentPosition, setCurrentPosition] = useState<{
        parentKey: UniqueIdentifier | null;
        overId: UniqueIdentifier;
    } | null>(null);

    const flattenedItems = useMemo(() => {
        const flattenedTree = flattenTree(items);
        const collapsedItems = flattenedTree.reduce<UniqueIdentifier[]>(
            (acc, { isOpen, key }) =>
                !isOpen ? [...acc, key] : acc,
            []
        );

        const result = removeChildrenOf(
            flattenedTree,
            activeId ? [activeId, ...collapsedItems] : collapsedItems
        );

        return result;
    }, [activeId, items]);

    const projected = getProjection(
        flattenedItems,
        activeId,
        overId,
        offsetLeft,
        indentationWidth,
        keepGhostInPlace ?? false,
        canRootHaveChildren,
        canHaveChildren
    );
    const sensorContext: SensorContext<TreeItemData> = useRef({
        items: flattenedItems,
        offset: offsetLeft,
    });
    // const [coordinateGetter] = useState(() =>
    //   sortableTreeKeyboardCoordinates(sensorContext, indentationWidth)
    // );
    const sensors = useSensors(
        useSensor(
            PointerSensor,
            pointerSensorOptions ?? defaultPointerSensorOptions
        )
        // useSensor(KeyboardSensor, {
        //   coordinateGetter,
        // })
    );

    const sortedIds = useMemo(
        () => flattenedItems.map(({ key }) => key),
        [flattenedItems]
    );
    const activeItem = activeId
        ? flattenedItems.find(({ key }) => key === activeId)
        : null;

    const activeItemIndex = activeId
        ? flattenedItems.find(({ key }) => key === activeId)?.index
        : null;

    useEffect(() => {
        sensorContext.current = {
            items: flattenedItems,
            offset: offsetLeft,
        };
    }, [flattenedItems, offsetLeft]);

    const itemsRef = useRef(items);
    itemsRef.current = items;
    const handleRemove = useCallback(
        (key: UniqueIdentifier) => {
            // const item = findItemDeep(itemsRef.current, key)!;
            // onItemsChanged(removeItem(itemsRef.current, key.toString()), {
            //     type: 'removed',
            //     item,
            // });
        },
        [onItemsChanged]
    );

    const handleCollapse = useCallback(
        function handleCollapse(key: UniqueIdentifier) {
            // const item = findItemDeep(itemsRef.current, key)!;
            // onItemsChanged(
            //     setProperty(itemsRef.current, key.toString(), 'isOpen', ((value: boolean) => {
            //         return !value;
            //     }) as any),
            //     {
            //         type: !item.isOpen ? 'collapsed' : 'expanded',
            //         item,
            //     }
            // );
        },
        [onItemsChanged]
    );

    const announcements: Announcements = useMemo(
        () => ({
            onDragStart({ active }) {
                return `Picked up ${active.id}.`;
            },
            onDragMove({ active, over }) {
                return getMovementAnnouncement('onDragMove', active.id, over?.id);
            },
            onDragOver({ active, over }) {
                return getMovementAnnouncement('onDragOver', active.id, over?.id);
            },
            onDragEnd({ active, over }) {
                return getMovementAnnouncement('onDragEnd', active.id, over?.id);
            },
            onDragCancel({ active }) {
                return `Moving was cancelled. ${active.id} was dropped in its original position.`;
            },
        }),
        []
    );

    const strategyCallback = useCallback(() => {
        return !!projected;
    }, [projected]);

    return (
        <DndContext
            accessibility={{ announcements }}
            sensors={disableSorting ? undefined : sensors}
            modifiers={indicator ? modifiersArray : undefined}
            collisionDetection={closestCenter}
            onDragStart={disableSorting ? undefined : handleDragStart}
            onDragMove={disableSorting ? undefined : handleDragMove}
            onDragOver={disableSorting ? undefined : handleDragOver}
            onDragEnd={disableSorting ? undefined : handleDragEnd}
            onDragCancel={disableSorting ? undefined : handleDragCancel}
            key={'dnd-' + disableSorting}
            {...dndContextProps}
        >
            <SortableContext
                items={sortedIds}
                strategy={
                    disableSorting
                        ? undefined
                        : customListSortingStrategy(strategyCallback)
                }
            >
                {flattenedItems.map((item,index) => {
                    const childrenLength = flattenedItems.filter(x => x.parentKey === item.key).length;
                    return (
                        <SortableTreeItem
                            {...rest}
                            key={item.key}
                            id={item.key.toString()}
                            item={item}
                            index={index}
                            childCount={childrenLength}
                            depth={
                                item.key === activeId && projected && !keepGhostInPlace
                                    ? projected.depth
                                    : item.depth
                            }
                            indentationWidth={indentationWidth}
                            indicator={indicator}
                            isOpen={Boolean(item.isOpen)}
                            onCollapse={childrenLength ? handleCollapse : undefined}
                            onRemove={handleRemove}
                            isLast={
                                item.key === activeId && projected
                                    ? projected.isLast
                                    : item.isLast
                            }
                            parent={
                                item.key === activeId && projected
                                    ? projected.parent
                                    : item.parent
                            }
                            parentsKeys={item.parentsKeys}
                            TreeItemComponent={TreeItemComponent}
                            disableSorting={disableSorting}
                            sortableProps={sortableProps}
                            keepGhostInPlace={keepGhostInPlace}
                            onItemChange={onItemChange}
                            onItemCreate={onItemCreate}
                            onMoveItem={onMoveItem}
                            onSelectItem={onSelectItem}
                        />
                    );
                })}
                {createPortal(
                    <DragOverlay
                        dropAnimation={
                            dropAnimation === undefined
                                ? dropAnimationDefaultConfig
                                : dropAnimation
                        }
                    >
                        {activeId && activeItem
                            ? (
                                <TreeItemComponent
                                    {...rest}
                                    item={activeItem}
                                    depth={activeItem.depth}
                                    clone
                                    childCount={getChildCount(items, activeId) + 1}
                                    indentationWidth={indentationWidth}
                                    isLast={false}
                                    parent={activeItem.parent}
                                    isOver={false}
                                    isOverParent={false}
                                    parentsKeys={activeItem.parentsKeys}
                                    disabled={false}
                                    index={activeItemIndex ?? 0}
                                />
                            )
                            : null}
                    </DragOverlay>,
                    document.body
                )}
            </SortableContext>
        </DndContext>
    );

    function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
        setActiveId(activeId);
        setOverId(activeId);

        const activeItem = flattenedItems.find(({ key }) => key === activeId);

        if (activeItem) {
            setCurrentPosition({
                parentKey: activeItem.parentKey,
                overId: activeId,
            });
        }

        document.body.style.setProperty('cursor', 'grabbing');
    }

    function handleDragMove({ delta }: DragMoveEvent) {
        setOffsetLeft(delta.x);
    }

    function handleDragOver({ over }: DragOverEvent) {
        setOverId(over?.id ?? null);
    }

    function handleDragEnd({ active, over }: DragEndEvent) {
        resetState();

        if (projected && over) {
            const { depth, parentKey } = projected;
            if (keepGhostInPlace && over.id === active.id) return;
            const clonedItems: FlattenedItem<TreeItemData>[] = flattenTree(items);
            const overIndex = clonedItems.findIndex(({ key }) => key === over.id);
            const activeIndex = clonedItems.findIndex(({ key }) => key === active.id);
            const activeTreeItem = clonedItems[activeIndex];

            clonedItems[activeIndex] = { ...activeTreeItem, depth, parentKey };

            const children: FlattenedItem<TreeItemData>[] = [];
            const findChildren = (parentKey: string) => {
                clonedItems.forEach(item => {
                    if (item.parentKey === parentKey) {
                        children.push(item);
                        findChildren(item.key.toString()); // Recursively find children
                    }
                });
            };
            findChildren(activeTreeItem.key.toString());

            // Remove the active item and its children from the original position
            const itemsToMove = [activeTreeItem, ...children];
            let itemsAfterMove = clonedItems.filter(item => !itemsToMove.find(x => x.key === item.key));

            // Insert the active item and its children at the new position
            const insertionIndex = overIndex > activeIndex ? overIndex - children.length : overIndex;
            itemsAfterMove.splice(insertionIndex, 0, ...itemsToMove);

            // Update the depth and parentKey of the moved items
            itemsAfterMove = itemsAfterMove.map(item => {
                if (item.key === activeTreeItem.key) {
                    return { ...item, depth, parentKey };
                } else if (children.includes(item)) {
                    const newParent = itemsAfterMove.find(x => x.key === item.parentKey);
                    const newDepth = newParent ? newParent.depth + 1 : item.depth;
                    return { ...item, depth: newDepth };
                }
                return item;
            });

            const newActiveItem = itemsAfterMove.find((x) => x.key === active.id)!;
            const draggedFromParent = activeTreeItem.parent;
            const currentParent = newActiveItem.parentKey
                ? itemsAfterMove.find((x) => x.key === newActiveItem.parentKey)!
                : null;

            // removing setTimeout leads to an unwanted scrolling
            // Use case:
            //   There are a lot of items in a tree (so that the scroll exists).
            //   You take the node from the bottom and move it to the top
            //   Without `setTimeout` when you drop the node the list gets scrolled to the bottom.
            setTimeout(() =>
                onItemsChanged(itemsAfterMove, {
                    type: 'dropped',
                    draggedItem: newActiveItem,
                    draggedFromParent,
                    droppedToParent: currentParent,
                })
            );
        }
    }

    function handleDragCancel() {
        resetState();
    }

    function resetState() {
        setOverId(null);
        setActiveId(null);
        setOffsetLeft(0);
        setCurrentPosition(null);

        document.body.style.setProperty('cursor', '');
    }

    function getMovementAnnouncement(
        eventName: string,
        activeId: UniqueIdentifier,
        overId?: UniqueIdentifier
    ) {
        if (overId && projected) {
            if (eventName !== 'onDragEnd') {
                if (
                    currentPosition &&
                    projected.parentKey === currentPosition.parentKey &&
                    overId === currentPosition.overId
                ) {
                    return;
                } else {
                    setCurrentPosition({
                        parentKey: projected.parentKey,
                        overId,
                    });
                }
            }

            const clonedItems: FlattenedItem<TreeItemData>[] = flattenTree(items);
            const overIndex = clonedItems.findIndex(({ key }) => key === overId);
            const activeIndex = clonedItems.findIndex(({ key }) => key === activeId);
            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

            const previousItem = sortedItems[overIndex - 1];

            let announcement;
            const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
            const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

            if (!previousItem) {
                const nextItem = sortedItems[overIndex + 1];
                announcement = `${activeId} was ${movedVerb} before ${nextItem.key}.`;
            } else {
                if (projected.depth > previousItem.depth) {
                    announcement = `${activeId} was ${nestedVerb} under ${previousItem.key}.`;
                } else {
                    let previousSibling: FlattenedItem<TreeItemData> | undefined =
                        previousItem;
                    while (previousSibling && projected.depth < previousSibling.depth) {
                        const parentKey: UniqueIdentifier | null = previousSibling.parentKey;
                        previousSibling = sortedItems.find(({ key }) => key === parentKey);
                    }

                    if (previousSibling) {
                        announcement = `${activeId} was ${movedVerb} after ${previousSibling.key}.`;
                    }
                }
            }

            return announcement;
        }
    }
}

const adjustTranslate: Modifier = ({ transform }) => {
    return {
        ...transform,
        y: transform.y - 25,
    };
};
const modifiersArray = [adjustTranslate];
