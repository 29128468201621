import { Paged } from 'api/common/types/Page';
import Http from 'common/services/Http';
import { FamiliesCatalogSearchCriteria, FamiliesSearchCriteria } from './models/FamiliesSearchCriteria';
import { FamilyDto } from './models/FamilyDto';
import IdVersion from 'api/common/types/IdVersion';
import { SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';
import { ParameterSectionBaseModel } from 'common/components/parametersSections/models';

class FamiliesService {
    public getList (criteria: FamiliesSearchCriteria) {
        return Http.get<Paged<FamilyDto>>('families', criteria);
    }

    public getById (id: string) {
        return Http.get<FamilyDto>('families/' + id);
    }

    public getFamilyParameters (id: string) {
        return Http.get<ParameterSectionBaseModel[]>('families/family/parameters/' + id);
    }

    public catalog () {
        return Http.get<SearchableSelectTitleSubTitleOption[]>('families/catalog');
    }

    public getElementsCatalog (criteria?: FamiliesCatalogSearchCriteria) {
        return Http.get<FamilyDto[]>('families/elements/catalog', criteria || {});
    }

    public create(model: FamilyDto): Promise<string> {
        return Http.post('families', model);
    }

    public update(model: FamilyDto) {
        return Http.put('families', model);
    }

    public delete (id: string, deleteRelations: boolean) {
        return Http.delete('families/' + id + '/' + deleteRelations);
    }
}

export default new FamiliesService();
