import ListingTable from 'common/components/listingTable/ListingTable';
import ListingTableCache, { ListingTableData } from 'common/components/listingTable/ListingTableCache';
import TableActionButton, { TableActionButtonOption } from 'common/components/listingTable/actionButton/TableActionButton';
import { FilterData, ListingTableColumn, OrderData } from 'common/components/listingTable/models';
import ListingTableSidebar from 'common/components/listingTable/sidebar/ListingTableSidebar';
import { orderColumns } from 'common/components/listingTable/tableUtils';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Logger from 'common/services/Logger';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrash, FaUpload } from 'react-icons/fa';
import { DATE_FORMAT_DEFAULT, DATE_TIME_FORMAT_DEFAULT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import WorksService from 'api/works/WorksService';
import Button from 'common/components/button/Button';
import { useNavigate } from 'react-router-dom';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import Loading from 'common/services/Loading';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import Toast from 'common/services/Toast';
import { SelectInputOption } from 'common/components/selectInput/SelectInput';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { BudgetDto, BudgetToPdfDto } from 'api/budgets/models/BudgetDto';
import { BudgetsSearchCriteria } from 'api/budgets/models/BudgetsSearchCriteria';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import { BudgetStatus } from 'api/budgets/enums/BudgetStatus';
import BudgetsService from 'api/budgets/BudgetsService';
import WorkTypesService from 'api/workTypes/WorkTypesService';
import BudgetsFieldsService from 'api/budgetsFields/BudgetsFieldsService';
import { BudgetFieldDto } from 'api/budgetsFields/models/BudgetFieldDto';
import { BudgetFieldType } from 'api/budgetsFields/enums/BudgetFieldType';
import ExternalPartCategoriesService from 'api/externalPartCategory/ExternalPartCategoriesService';
import { ExternalPartCategoryDto } from 'api/externalPartCategory/models/ExternalPartCategoryDto';
import { WorkExternalPartsSearchCriteria } from 'api/works/models/WorksSearchCriteria';

const PAGE_ID = 'BUSINESS_BUDGETS_LIST';
const baseUrl = '/business/budgets';

function BudgetsListScreen(): JSX.Element | null {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState<BudgetDto[]>([]);
    const [tableData, setTableData] = useState<ListingTableData<BudgetDto, Partial<BudgetsSearchCriteria>>>(ListingTableCache.get(PAGE_ID, {
        order: { field: 'description', isOrderAsc: true },
        hiddenColumns: [],
        columnsOrder: [],
        columnsWidth: [],
        page: 1,
        filters: {},
        lastRowId: null,
    }));
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<BudgetDto | null>(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['BUDGETS_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['BUDGETS_READ'])
    const [totalItems, setTotalItems] = useState(0);
    const [scrollToRowId, setScrollToRowId] = useState<string | null>(null);
    const firstLoad = useRef(true);
    const [works, setWorks] = useState<SelectInputOption[]>()
    const [worksTypes, setWorksTypes] = useState<SelectInputOption[]>()
    const [budgetsFields, setBudgetsFields] = useState<BudgetFieldDto[]>([])
    const statuses = useMemo(() => Object.keys(BudgetStatus).map(key => ({
        label: t('budgets.status.' + key),
        value: key,
    })), [i18n.language]);

    const [categoriesData,setCategoriesData] = useState<ExternalPartCategoryDto[]>([])

    const columns: ListingTableColumn<BudgetDto>[] = orderColumns([
        {
            name: t('budgets.list.description'),
            field: 'description',
            orderColumn: 'description',
            filter: { type: 'text', value: tableData.filters.description ?? '', placeholder: t('budgets.list.description') },
            minWidth: '18rem',
        },
        {
            name: t('budgets.list.number'),
            field: 'number',
            orderColumn: 'number',
            filter: { type: 'text', value: tableData.filters.number ?? '', placeholder: t('budgets.list.number') },
            minWidth: '18rem',
        },
        {
            name: t('budgets.list.date'),
            field: 'date',
            filter: {
                type: 'date-range',
                value: {
                    start: tableData.filters.dateStart ? new Date(tableData.filters.dateStart) : null,
                    end: tableData.filters.dateEnd ? new Date(tableData.filters.dateEnd) : null
                }
            },
            minWidth: '22rem',
            orderColumn: 'date',
            renderCell: (row) => {
                return row.date ? <DateFormat value={row.date} format={DATE_FORMAT_DEFAULT} /> : '';
            }
        },
        {
            name: t('budgets.list.work'),
            field: 'workId',
            filter: { type: 'dropdown', value: tableData.filters.workId ?? '', options: works || [] },
            minWidth: '18rem',
            orderColumn: 'w.name',
            renderCell: (row) => <div>{row.workName}</div>
        },
        {
            name: t('budgets.list.work_type'),
            field: 'workTypeId',
            filter: { type: 'dropdown', value: tableData.filters.workTypeId ?? '', options: worksTypes || [] },
            minWidth: '18rem',
            orderColumn: 'wt.name',
            renderCell: (row) => <div>{row.workTypeName}</div>
        },
        {
            name: t('budgets.list.validation_date'),
            field: 'validationDate',
            filter: {
                type: 'date-range',
                value: {
                    start: tableData.filters.validationDateStart ? new Date(tableData.filters.validationDateStart) : null,
                    end: tableData.filters.validationDateEnd ? new Date(tableData.filters.validationDateEnd) : null
                }
            },
            minWidth: '22rem',
            orderColumn: 'validation_date',
            renderCell: (row) => {
                return row.validationDate ? <DateFormat value={row.validationDate} format={DATE_FORMAT_DEFAULT} /> : '';
            }
        },
        {
            name: t('budgets.list.total_net_excluding_tax'),
            field: 'totalNetExcludingTax',
            filter: {
                type: 'money-range',
                value: {
                    start: tableData.filters.totalNetExcludingTaxStart ? tableData.filters.totalNetExcludingTaxStart : null,
                    end: tableData.filters.totalNetExcludingTaxEnd ? tableData.filters.totalNetExcludingTaxEnd : null
                },
                unitPrefix: '€'
            },
            minWidth: '18rem',
            orderColumn: 'total_net_excluding_tax',
            renderCell: (row) => {
                return row.totalNetExcludingTax ? <MoneyFormat value={row.totalNetExcludingTax} /> : '';
            }
        },
        {
            name: t('budgets.list.discount_excluding_tax'),
            field: 'discount',
            filter: {
                type: 'money-range',
                value: {
                    start: tableData.filters.discountStart ? tableData.filters.discountStart : null,
                    end: tableData.filters.discountEnd ? tableData.filters.discountEnd : null
                },
                unitPrefix: '€'
            },
            minWidth: '18rem',
            orderColumn: 'discount',
            renderCell: (row) => {
                return row.discount ? <MoneyFormat value={row.discount} /> : '';
            }
        },
        {
            name: t('budgets.list.total_tax'),
            field: 'totalTax',
            filter: {
                type: 'money-range',
                value: {
                    start: tableData.filters.totalTaxStart ? tableData.filters.totalTaxStart : null,
                    end: tableData.filters.totalTaxEnd ? tableData.filters.totalTaxEnd : null
                },
                unitPrefix: '€'
            },
            minWidth: '18rem',
            orderColumn: 'total_tax',
            renderCell: (row) => {
                return row.totalTax ? <MoneyFormat value={row.totalTax} /> : '';
            }
        },
        {
            name: t('budgets.list.total_including_tax'),
            field: 'totalIncludingTax',
            filter: {
                type: 'money-range',
                value: {
                    start: tableData.filters.totalIncludingTaxStart ? tableData.filters.totalIncludingTaxStart : null,
                    end: tableData.filters.totalIncludingTaxEnd ? tableData.filters.totalIncludingTaxEnd : null
                },
                unitPrefix: '€'
            },
            minWidth: '18rem',
            orderColumn: 'total_including_tax',
            renderCell: (row) => {
                return row.totalIncludingTax ? <MoneyFormat value={row.totalIncludingTax} /> : '';
            }
        },
        {
            name: t('budgets.list.total_excluding_tax'),
            field: 'totalExcludingTax',
            filter: {
                type: 'money-range',
                value: {
                    start: tableData.filters.totalExcludingTaxStart ? tableData.filters.totalExcludingTaxStart : null,
                    end: tableData.filters.totalExcludingTaxEnd ? tableData.filters.totalExcludingTaxEnd : null,
                },
                unitPrefix: '€'
            },
            minWidth: '18rem',
            orderColumn: 'total_excluding_tax',
            renderCell: (row) => {
                return row.totalExcludingTax ? <MoneyFormat value={row.totalExcludingTax} /> : '';
            }
        },
        {
            name: t('budgets.list.status'),
            field: 'status',
            filter: { type: 'dropdown', value: tableData.filters.status ?? '', options: statuses || [] },
            minWidth: '18rem',
            orderColumn: 'status',
            renderCell: (row) => <div>{t('budgets.status.' + row.status)}</div>
        },
        {
            name: t('common.updated_date'),
            field: 'updatedDate',
            filter: {
                type: 'date-range',
                value: {
                    start: tableData.filters.updatedDateStart ? new Date(tableData.filters.updatedDateStart) : null,
                    end: tableData.filters.updatedDateEnd ? new Date(tableData.filters.updatedDateEnd) : null
                }
            },
            minWidth: '22rem',
            orderColumn: 'updated_date',
            renderCell: (row) => {
                return row.updatedDate ? <DateFormat value={row.updatedDate} format={DATE_TIME_FORMAT_DEFAULT} /> : '';
            }
        },
        {
            name: t('common.user_updated'),
            field: 'userUpdatedName',
            filter: { type: 'text', value: tableData.filters.userUpdatedName ?? '', placeholder: t('common.user_updated') },
            minWidth: '18rem',
            orderColumn: 'user_updated_name',
        },
        ...(budgetsFields.map((bf) => {
            const f: ListingTableColumn<BudgetDto> = {
                name: bf.name,
                field: bf.id as any,
                orderColumn: 'bf__' + bf.id as any,
                filter: bf.fieldType === BudgetFieldType.SELECT
                    ? { type: 'dropdown', value: tableData.filters.fieldsValues?.find(x => x.id === bf.id)?.budgetFieldValueOptionId ?? '', options: bf.options.map(op => ({ label: op.label, value: op.id! })) }
                    : (bf.fieldType === BudgetFieldType.NUMBER
                        ? {
                            type: 'money-range',
                            value: {
                                start: tableData.filters.fieldsValues?.find(x => x.id === bf.id)?.valueNumberStart ?? null,
                                end: tableData.filters.fieldsValues?.find(x => x.id === bf.id)?.valueNumberEnd ?? null
                            },
                            unitPrefix: ''
                        }
                        : { type: 'text', value: tableData.filters.fieldsValues?.find(x => x.id === bf.id)?.value ?? '', placeholder: bf.name }),
                minWidth: '18rem',
                renderCell: (row) => {
                    const fv = row.fieldsValues?.find(x => x.budgetFieldId === bf.id);

                    if (bf.fieldType === BudgetFieldType.NUMBER) {
                        return fv?.value ? <MoneyFormat value={fv.value} suffix='' /> : '';
                    }

                    return fv?.value ?? '';
                },
                isDynamic: true,
            }
            return f;
        })),
        ...(categoriesData.map((x: ExternalPartCategoryDto) => {
            const f: ListingTableColumn<BudgetDto> = {
                name: x.name,
                field: x.id as any,
                filter: { type: 'dropdown', value: tableData.filters.workExternalParts?.find(y => y.id === x.id)?.externalPartOptionId ?? '', options: (x.externalParts ?? []).map(op => ({ label: op.companyName, value: op.id! })) },
                minWidth: '18rem',
                renderCell: (row: BudgetDto) => {
                    return <div>{row.workExternalParts.find(y => y.externalPartCategoryId === x.id)?.externalPartName}</div>
                },
                orderColumn: `${x.id}`,
                isWorkExternalPart: true
            }

            return f
        }))
    ], tableData.columnsOrder);

    const onFilter = (col: ListingTableColumn<BudgetDto>, filterData: FilterData) => {
        setTableData(d => {
            const data = ({
                ...d, page: 1
            })
            if (col.field === 'description') {
                data.filters = {
                    ...data.filters,
                    description: filterData.value as string,
                };
            }
            if (col.field === 'number') {
                data.filters = {
                    ...data.filters,
                    number: filterData.value as string,
                };
            }
            if (col.field === 'date') {
                data.filters = {
                    ...data.filters,
                    dateStart: filterData.value?.start as Date,
                    dateEnd: filterData.value?.end as Date,
                };
            }
            if (col.field === 'workId') {
                data.filters = {
                    ...data.filters,
                    workId: filterData.value as string,
                };
            }
            if (col.field === 'workTypeId') {
                data.filters = {
                    ...data.filters,
                    workTypeId: filterData.value as string,
                };
            }
            if (col.field === 'validationDate') {
                data.filters = {
                    ...data.filters,
                    validationDateStart: filterData.value?.start as Date,
                    validationDateEnd: filterData.value?.end as Date,
                };
            }
            if (col.field === 'totalNetExcludingTax') {
                data.filters = {
                    ...data.filters,
                    totalNetExcludingTaxStart: filterData.value?.start as number,
                    totalNetExcludingTaxEnd: filterData.value?.end as number,
                };
            }
            if (col.field === 'discount') {
                data.filters = {
                    ...data.filters,
                    discountStart: filterData.value?.start as number,
                    discountEnd: filterData.value?.end as number,
                };
            }
            if (col.field === 'totalTax') {
                data.filters = {
                    ...data.filters,
                    totalTaxStart: filterData.value?.start as number,
                    totalTaxEnd: filterData.value?.end as number,
                };
            }
            if (col.field === 'totalIncludingTax') {
                data.filters = {
                    ...data.filters,
                    totalIncludingTaxStart: filterData.value?.start as number,
                    totalIncludingTaxEnd: filterData.value?.end as number,
                };
            }
            if (col.field === 'totalExcludingTax') {
                data.filters = {
                    ...data.filters,
                    totalExcludingTaxStart: filterData.value?.start as number,
                    totalExcludingTaxEnd: filterData.value?.end as number,
                };
            }
            if (col.field === 'status') {
                data.filters = {
                    ...data.filters,
                    status: filterData.value as BudgetStatus
                };
            }
            if (col.field === 'updatedDate') {
                data.filters = {
                    ...data.filters,
                    updatedDateStart: filterData.value?.start as Date,
                    updatedDateEnd: filterData.value?.end as Date
                };
            }
            if (col.field === 'userUpdatedName') {
                data.filters = {
                    ...data.filters,
                    userUpdatedName: filterData.value as string
                };
            }
            if (col.isDynamic) {
                if (!data.filters.fieldsValues) {
                    data.filters.fieldsValues = [];
                }

                const exists = data.filters.fieldsValues?.find(x => x.id === col.field);
                const budgetField = budgetsFields.find(x => x.id === col.field);
                if (!exists) {
                    data.filters.fieldsValues.push({
                        id: col.field,
                        value: budgetField?.fieldType === BudgetFieldType.TEXT ? filterData.value as string : null,
                        budgetFieldValueOptionId: budgetField?.fieldType === BudgetFieldType.SELECT ? filterData.value as string : null,
                        valueNumberStart: budgetField?.fieldType === BudgetFieldType.NUMBER ? filterData.value?.start as number : null,
                        valueNumberEnd: budgetField?.fieldType === BudgetFieldType.NUMBER ? filterData.value?.end as number : null,
                        fieldType: budgetField!.fieldType,
                    });
                } else {
                    exists.value = budgetField?.fieldType === BudgetFieldType.TEXT ? filterData.value as string : null;
                    exists.budgetFieldValueOptionId = budgetField?.fieldType === BudgetFieldType.SELECT ? filterData.value as string : null;
                    exists.valueNumberStart = budgetField?.fieldType === BudgetFieldType.NUMBER ? filterData.value?.start as number : null;
                    exists.valueNumberEnd = budgetField?.fieldType === BudgetFieldType.NUMBER ? filterData.value?.end as number : null;
                }
            }
            if (col.isWorkExternalPart) {
                data.filters.workExternalParts = data.filters?.workExternalParts?.map(x => {
                    if (x.id === col.field) {
                        return { ...x,externalPartOptionId: filterData.value }
                    }

                    return x
                });
            }
            return { ...data };
        });
        setRefreshKey(k => k + 1);
    }

    const onOrder = (orderData: OrderData<BudgetDto>) => {
        setRefreshKey(k => k + 1);
        setTableData(d => ({ ...d, order: orderData, page: 1 }));
    }

    const onClearFilters = () => {
        setTableData(d => ({ ...d, page: 1, filters: { workExternalParts: buildExternalPartsFilter(categoriesData) }, lastRowId: null }));
        setRefreshKey(k => k + 1);
    }

    const renderAction = (row: BudgetDto) => {
        let printOptions: TableActionButtonOption[] = [];
        if (row.budgetFileModelId) {
            printOptions = [
                { label: <span><FaUpload /> {t('budgets.budget.print_with_technical_file')}</span>, onClick: () => printBudget(row, true) },
                { label: <span><FaUpload /> {t('budgets.budget.print_without_technical_file')}</span>, onClick: () => printBudget(row, false) },
            ];
        }
        return (<TableActionButton
            options={[
                { label: <span><FaEdit /> {t('common.edit')}</span>, onClick: () => onClickEdit(row.id) },
                ...printOptions,
                { label: <span><FaTrash /> {t('common.delete')}</span>, onClick: () => setShowConfirmDeleteModal(row) },
            ]}
        />)
    }

    const printBudget = async (budget: BudgetDto, includeTechnicalFiles: boolean) => {
        try {
            Loading.show();

            const model: BudgetToPdfDto = {
                budgetId: budget.id,
                budgetFileModelId: budget.budgetFileModelId as string,
                includeTechnicalFiles
            }
            const result = await BudgetsService.printBudget(model);
            BudgetsService.downloadBudget(result, budget.number);
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t print budget', error);
            Toast.error(t('messages.record_save_error'));
        }
        Loading.hide();
    }

    const getData = async () => {
        if (isLoading) {
            return;
        }

        try {
            setIsLoading(true);

            let budgetsFieldsData: BudgetFieldDto[] = budgetsFields;
            if (firstLoad.current) {
                budgetsFieldsData = await BudgetsFieldsService.getCatalog();
                setBudgetsFields(budgetsFieldsData);

                const categories = await ExternalPartCategoriesService.GetAvailableForWorkCreation(true);
                setCategoriesData(categories)
                setTableData(d => ({ ...d, filters: { ...d.filters, workExternalParts: buildExternalPartsFilter(categories) } }))
            }

            const workExternalParts: WorkExternalPartsSearchCriteria[] = tableData.filters.workExternalParts?.filter(x => x.externalPartOptionId !== '' && x.externalPartOptionId !== null) ?? []
            let orderColumnHelper = tableData.order.orderColumn
            let orderColumnIsInFilters = null;
            let orderColumnExternalPartId = null;

            // if order column is one of the dynamic columns
            if (tableData.filters.workExternalParts?.some(x => tableData.order.orderColumn?.includes(x.id))) {
                orderColumnIsInFilters = workExternalParts?.some(x => tableData.order.orderColumn?.includes(x.id)) ?? false;
                orderColumnExternalPartId = orderColumnIsInFilters ? null : tableData.order.orderColumn
                if (!orderColumnIsInFilters) {
                    orderColumnHelper = 'OrderCategory.company_name'
                } else {
                    orderColumnHelper = `ep${workExternalParts?.findIndex(x => x.id === tableData.order.orderColumn)}.company_name`
                }
            }
            const budgetsData = await BudgetsService.getList({
                ...tableData.filters,
                page: firstLoad.current && tableData.page > 1 ? 1 : tableData.page,
                itemsPerPage: firstLoad.current && tableData.page > 1 ? DEFAULT_PAGINATION_ITEMS_PER_PAGE * tableData.page : DEFAULT_PAGINATION_ITEMS_PER_PAGE,
                orderColumn: orderColumnIsInFilters === null ? tableData.order.orderColumn : orderColumnHelper,
                orderBy: tableData.order.isOrderAsc ? 'asc' : 'desc',
                fieldsValues: tableData.filters.fieldsValues ?? [],
                allBudgetFieldsIds: budgetsFieldsData.map(x => x.id),
                workExternalParts,
                orderColumnExternalPartId
            });

            const newListElements = budgetsData.currentPage === 1 ? budgetsData.items : [...data, ...budgetsData.items]
            setHasMore(budgetsData.totalItems > newListElements.length);
            setData(newListElements);
            setTotalItems(budgetsData.totalItems)

            if (firstLoad.current) {
                const [worksData, workTypesData] = await Promise.all([
                    WorksService.getCatalog(),
                    WorkTypesService.getCatalog(),
                ]);
                setWorks(worksData);
                setWorksTypes(workTypesData);

                setScrollToRowId(tableData.lastRowId);
            }
            firstLoad.current = false;
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get budgets', error);
            Toast.error(t('messages.error_load_info'));
        }

        setIsLoading(false);
    }

    const onDelete = async (result: boolean) => {
        if (!result) {
            setShowConfirmDeleteModal(null)
            return;
        }

        if (!showConfirmDeleteModal) {
            return;
        }

        try {
            Loading.show();

            await BudgetsService.remove(showConfirmDeleteModal)

            Loading.hide();
            Toast.success(t('messages.record_delete_success'));

            setTableData(d => ({ ...d, page: 1 }));
            setRefreshKey(k => k + 1);
        } catch (error: any) {
            Loading.hide();
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete budget', error);
            Toast.error(t('messages.record_delete_error'));
        }

        setShowConfirmDeleteModal(null)
    }

    const onLoadMore = () => {
        setTableData(d => ({ ...d, page: d.page + 1 }));
    }

    const onClickEdit = (id: string) => {
        ListingTableCache.saveLastRowId(PAGE_ID, id);
        navigate(baseUrl + '/edit/' + id)
    }

    const onClickNew = () => {
        navigate(baseUrl + '/create')
    }

    const onRowClick = (id: string) => {
        ListingTableCache.saveLastRowId(PAGE_ID, id);
        navigate(baseUrl + '/details/' + id)
    }

    const buildExternalPartsFilter = (data: ExternalPartCategoryDto[]) => {
        const filterHelper: any[] = [];
        data.forEach((x) => {
            filterHelper.push({
                id: x.id,
                externalPartOptionId: ''
            });
        })

        return filterHelper
    }

    const getAvailableForWorkCreationExternalPartCategories = async () => {
        try {
            const categories = await ExternalPartCategoriesService.GetAvailableForWorkCreation(true);
            setCategoriesData(categories)
            setTableData(d => ({ ...d, filters: { ...d.filters, workExternalParts: buildExternalPartsFilter(categories) } }))
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get the external part categories available for work creation on works list', error);
            Toast.error(t('messages.error_load_info'));
        }
        setIsLoading(false);
    }
    useEffect(() => {
        void getAvailableForWorkCreationExternalPartCategories();
    }, []);

    useEffect(() => {
        void getData();
    }, [tableData.page, tableData.filters, tableData.order, refreshKey]);

    useEffect(() => {
        ListingTableCache.save(PAGE_ID, tableData);
    }, [tableData]);

    if (!canRead) {
        return null;
    }

    return (
        <ScreenTitle title={t('budgets.list.title')}>
            <PageBreadcrumbsPortal
                breadcrumbs={[
                    { name: t('home.title'), url: '/' },
                    { name: t('budgets.list.title'), url: baseUrl },
                ]}
            />
            <PageHeader title={t('budgets.list.title')} subTitle={`${totalItems} ${t('common.total_results')}`} informationText={t('common.list')} showGoBack={false} addSidebarSpacing>
                {canWrite && <Button onClick={onClickNew}>{t('common.new')}</Button>}
            </PageHeader>
            <PageContainer>
                <ListingTable<BudgetDto>
                    columns={columns}
                    rows={data}
                    order={tableData.order}
                    hiddenColumns={tableData.hiddenColumns}
                    columnsWidths={tableData.columnsWidth}
                    onOrder={onOrder}
                    onFilter={onFilter}
                    onClearFilters={onClearFilters}
                    onRenderAction={canWrite ? renderAction : undefined}
                    infiniteScroll={{
                        isLoading,
                        hasMore,
                        onLoadMore,
                    }}
                    onRowClick={(r) => onRowClick(r.id)}
                    onRowId={r => r.id}
                    scrollToRowId={scrollToRowId}
                    onColResize={(w) => setTableData(d => ({ ...d, columnsWidth: w }))}
                />
            </PageContainer>
            <ListingTableSidebar
                columns={columns}
                hiddenColumns={tableData.hiddenColumns}
                onChangeHiddenColumns={(hc) => setTableData(d => ({ ...d, hiddenColumns: hc }))}
                onChangeColumnsOrder={(oc) => setTableData(d => ({ ...d, columnsOrder: oc }))}
            />
            <ConfirmDeleteModal
                itemName={showConfirmDeleteModal?.description}
                isOpen={!!showConfirmDeleteModal}
                onClose={onDelete}
            />
        </ScreenTitle>
    );
}

export default BudgetsListScreen;
