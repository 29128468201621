import styles from './Panel.module.scss';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

type Props = {
    title: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Panel = ({ children, title, ...props }: Props) => {
    return (
        <div {...props} className={`${styles.container} ${props.className ?? ''}`}>
            <div className={styles.title}>
                {title}
            </div>

            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
};

export default Panel;
