import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { RoleDto } from './models/RoleDto';
import { SelectInputOption } from 'common/components/selectInput/SelectInput';

class RolesService {
    public getList (criteria: PagedSearchCriteria) {
        return Http.get<Paged<RoleDto>>('roles/all', criteria);
    }

    public getAllForSelectItem () {
        return Http.get<RoleDto[]>('roles/get-All-for-select-item');
    }

    public getById (id: string) {
        return Http.get<RoleDto>('roles/' + id);
    }

    public getAll () {
        return Http.get<RoleDto[]>('roles/all-without-system');
    }

    public catalog () {
        return Http.get<SelectInputOption[]>('roles/catalog');
    }

    public create (model: RoleDto): Promise<string> {
        return Http.post('roles', model);
    }

    public update (model: RoleDto) {
        return Http.put('roles', model);
    }

    public remove (model: RoleDto) {
        return Http.put('roles/deactivate', model);
    }
}

export default new RolesService();
