import { ParameterSectionBaseModel, ParameterSectionType } from './models';
import SectionList from './sectionList/SectionList';
import { DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, DropAnimation, KeyboardSensor, PointerSensor, closestCorners, defaultDropAnimationSideEffects, useSensor, useSensors } from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import Section from './section/Section';
import { getItem, moveItem } from './utils';
import Parameter from './parameter/Parameter';
import { SearchableSelectTitleSubTitleOption } from '../searchableSelectInput/optionsFormats/OptionTitleSubTitle';

const VoidFunction = () => {};

const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.5',
            },
        },
    }),
};

interface Props {
    sections: ParameterSectionBaseModel[];
    disabled?: boolean;
    unitOptions?: SearchableSelectTitleSubTitleOption[];
    selectInputOptions?: SearchableSelectTitleSubTitleOption[];
    onChange: (sections: ParameterSectionBaseModel[]) => void;
}

const ParametersSections = ({ sections, disabled, unitOptions, selectInputOptions, onChange }: Props) => {
    const [activeItem, setActiveItem] = useState<ParameterSectionBaseModel | null>(null);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const onDragStart = (event: DragStartEvent) => {
        if (!event.active) {
            return;
        }

        const item = getItem(event.active.id.toString(), sections);
        setActiveItem(item);
    }

    const onDragOver = ({ active, over }: DragOverEvent) => {
        // console.log('onDragOver', sections);

        // if (!over) {
        //     return;
        // }

        // const id = active.id.toString();
        // const overId = over.id.toString();

        // const activeParentKey = getParentSectionKey(id, sections);
        // const overParentKey = getParentSectionKey(overId, sections);

        // console.log('activeParentKey ', activeParentKey);
        // console.log('overParentKey ', overParentKey);

        // // Do nothing if haven't moved out of current container
        // if (
        //     !activeParentKey ||
        //     !overParentKey ||
        //     activeParentKey === overParentKey
        // ) {
        //     return;
        // }
        // console.log('went through');

        // const newItems = moveSectionFromContainerToContainer(id, overParentKey, sections);
        // // console.log('newItems ', newItems);
        // onChange(newItems);
    }

    const onDragEnd = (event: DragEndEvent) => {
        setActiveItem(null);

        if (!event.over) {
            return;
        }

        const newItems = moveItem(sections, event.active.id.toString(), event.over.id.toString());
        onChange(newItems);
    }

    const onDragCancel = () => {
        setActiveItem(null);
    }

    return (
        <div>
            <DndContext
                collisionDetection={closestCorners}
                sensors={sensors}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
                onDragCancel={onDragCancel}
            >
                <SectionList
                    id="root"
                    items={sections}
                    isChild={false}
                    hasClearBackground={false}
                    disabled={disabled}
                    onChange={onChange}
                    unitOptions={unitOptions}
                    selectInputOptions={selectInputOptions}
                />
                {createPortal(
                    <DragOverlay
                        dropAnimation={dropAnimationConfig}
                    >
                        {activeItem
                            ? (activeItem.type === ParameterSectionType.SECTION
                                ? (
                                    <Section
                                        section={activeItem}
                                        onChange={VoidFunction}
                                        onRemove={VoidFunction}
                                        onDuplicate={VoidFunction}
                                        isChild={false}
                                        hasClearBackground={false}
                                        unitOptions={unitOptions}
                                        selectInputOptions={selectInputOptions}
                                    />
                                )
                                : <Parameter
                                    parameter={activeItem}
                                    onChange={VoidFunction}
                                    onRemove={VoidFunction}
                                    unitOptions={unitOptions}
                                    selectInputOptions={selectInputOptions}
                                />)
                            : null}
                    </DragOverlay>,
                    document.body
                )}
            </DndContext>
        </div>
    );
};

export default ParametersSections;
