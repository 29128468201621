import React from 'react';
import { IconProps } from './types';
import { calculateAspectRatioFit } from 'common/utils/calculateAspectRatioFit';

export const Budgets: React.FC<IconProps> = ({ size = 21, ...rest }) => {
    const newSize = calculateAspectRatioFit(14.085, 19.783, size, size);
    return (
        <svg id="icon-devis" xmlns="http://www.w3.org/2000/svg" width={newSize.width} height={newSize.height} viewBox="0 0 14.085 19.783">
            <defs>
                <clipPath id="clip-path-budgets">
                    <rect id="Retângulo_489" data-name="Retângulo 489" width={newSize.width} height={newSize.height} fill="#27449c"/>
                </clipPath>
            </defs>
            <g id="Grupo_295" data-name="Grupo 295" clipPath="url(#clip-path-budgets)">
                <path id="Caminho_1205" data-name="Caminho 1205" d="M13.843,3.9Q12.021,2.095,10.216.271A.829.829,0,0,0,9.574,0C6.954.009,4.335.005,1.715.005A1.573,1.573,0,0,0,0,1.713Q0,5.554,0,9.4T0,17.08a1.572,1.572,0,0,0,1.691,1.7h10.7a1.569,1.569,0,0,0,1.687-1.677q0-6.305,0-12.611a.767.767,0,0,0-.242-.6M10.167,1.449,12.6,3.917c-.527,0-1.11.023-1.691-.008a.734.734,0,0,1-.739-.759c-.026-.572-.006-1.146-.006-1.7M13.3,5.036q0,5.984,0,11.967c0,.711-.288,1-.99,1H1.756c-.683,0-.979-.3-.979-.984q0-7.626,0-15.252c0-.689.294-.986.976-.986q3.666,0,7.331,0h.3c0,.774,0,1.506,0,2.238A1.57,1.57,0,0,0,11.071,4.7l2.141.005a.469.469,0,0,1,.093.031Z" transform="translate(0 0)" fill="#27449c"/>
                <path id="Caminho_1206" data-name="Caminho 1206" d="M16.251,26.638c-.068,0-.137,0-.205,0H7.873c-.088,0-.176,0-.263.008a.348.348,0,0,0-.342.367.368.368,0,0,0,.322.392,1.245,1.245,0,0,0,.263.009H15.7c.176,0,.352.006.527,0a.385.385,0,0,0,.417-.394.365.365,0,0,0-.4-.379" transform="translate(-4.916 -18.018)" fill="#27449c"/>
                <path id="Caminho_1207" data-name="Caminho 1207" d="M11.983,34.654h3.164c.381,0,.762.006,1.142,0a.364.364,0,0,0,.39-.387.357.357,0,0,0-.389-.383c-.068,0-.137,0-.2,0h-8.2a.6.6,0,0,0-.26.016c-.112.059-.252.14-.289.244a.413.413,0,0,0,.071.368.667.667,0,0,0,.418.142c1.386.01,2.773.006,4.16.006" transform="translate(-4.95 -22.913)" fill="#27449c"/>
                <path id="Caminho_1208" data-name="Caminho 1208" d="M16.3,41.152c-.1-.008-.2-.008-.292-.008H8.278c-.205,0-.41,0-.615,0a.365.365,0,0,0-.4.374.387.387,0,0,0,.412.4c.059,0,.117,0,.176,0h8.2a1.115,1.115,0,0,0,.291-.014.366.366,0,0,0,.3-.411.346.346,0,0,0-.337-.342" transform="translate(-4.912 -27.832)" fill="#27449c"/>
                <path id="Caminho_1209" data-name="Caminho 1209" d="M8.312,11.2c.346.039.51.174.5.414S8.627,12,8.293,12c-.225,0-.449,0-.673.008a.347.347,0,0,0-.359.379.365.365,0,0,0,.361.386,3.248,3.248,0,0,0,.372,0,.411.411,0,0,0,.057.087c.125.1.241.268.381.294.2.036.331-.116.389-.321a.373.373,0,0,1,.149-.19,1.175,1.175,0,0,0-.515-2.216c-.259-.03-.434-.19-.41-.4a.382.382,0,0,1,.441-.367,3.393,3.393,0,0,0,.7-.013.629.629,0,0,0,.376-.263A.394.394,0,0,0,9.5,9.02a.663.663,0,0,0-.386-.156c-.157-.02-.279-.009-.365-.2-.111-.244-.541-.255-.624-.014-.089.261-.3.353-.463.522a1.482,1.482,0,0,0-.345.528,1.143,1.143,0,0,0,1,1.5" transform="translate(-4.909 -5.733)" fill="#27449c"/>
                <path id="Caminho_1210" data-name="Caminho 1210" d="M24.344,19.381q-2.24,0-4.479,0c-.334,0-.48.119-.486.373s.153.4.49.4c.752,0,1.5,0,2.254,0q1.1,0,2.2,0c.334,0,.514-.133.523-.379s-.164-.4-.5-.4" transform="translate(-13.109 -13.11)" fill="#27449c"/>
                <path id="Caminho_1211" data-name="Caminho 1211" d="M12.266,48.4c-.761,0-1.523,0-2.284,0q-1.083,0-2.167,0c-.388,0-.567.13-.558.4s.19.38.551.38H9.916c.781,0,1.561,0,2.342,0,.305,0,.452-.125.462-.37s-.138-.4-.455-.4" transform="translate(-4.909 -32.737)" fill="#27449c"/>
            </g>
        </svg>
    );
}
